const { format, parse } = require('date-fns')
import { es } from 'date-fns/locale'
import isSameDay from 'date-fns/isSameDay'
import { crayolaPallete, babyPallete, springPallete } from './colors-generator'
import {
  tellMeWhichYearSemester,
  tellMeWhichYearTrimester,
  giveMeYearAndMonth,
  calcSpanYears,
  formYMToDate,
  dateParsedToGraphic,
  whichNumberOfWeek,
  areInTheSameWeek
} from '../helpers/dates-calculations'

const combinedColors = [...crayolaPallete, ...babyPallete, ...springPallete]

const monthsArr = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sept',
  'Oct',
  'Nov',
  'Dic'
]

const assignCumulative = (value) => {
  if (value === null) {
    return true
  } else {
    return value
  }
}

const humanizeMonth = (ymPair) => {
  const year = ymPair.split('-')[0]
  const month = ymPair.split('-')[1]
  const word = monthsArr[parseInt(month) - 1]
  return `${word} ${year}`
}

const getYear = (date) => {
  return date.split('T')[0].split('-')[0]
}

// Líneas mensuales----------------------------------------------------------------------------------
export const genMonthlyLineData = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  const assignedCumulative = assignCumulative(cumulativeness)
  const dataBucket = { datasets: [] }
  //   dataBucket.labels = motherDataset.labels

  // encontramos todos los años y meses en los que se han registrado valores para este indicador
  let yearMonths = []
  motherDataset.datasets.forEach((datasetData) =>
    datasetData.forEach((entry) => yearMonths.push(giveMeYearAndMonth(entry.x)))
  )

  const yearAndMonthsList = yearMonths.filter(
    (item, i, ar) => ar.indexOf(item) === i
  )

  let datesOfInterest = yearAndMonthsList.map((item) => formYMToDate(item))
  let sorted = datesOfInterest.sort((a, b) => a - b)
  const orderedYearsAndMonths = sorted.map((date) => giveMeYearAndMonth(date))

  // ** generar dataset con la forma [1,2,3,4,5], donde cada elemento es la suma de los registros en ese mes y año
  motherDataset.labels.forEach((divisionName, iOfDiv) => {
    // por cada año y mes, encontramos los valores para esta división
    let entries = []
    orderedYearsAndMonths.forEach((YMPair) => {
      let entriesForThisYMPair = motherDataset.datasets[iOfDiv].filter(
        (entry) => giveMeYearAndMonth(entry.x) === YMPair
      )

      let valuesForThisYMPair = entriesForThisYMPair.map((ele) => ele.y)

      let totalsForThisMonth = valuesForThisYMPair.reduce(
        (curr, acc) => curr + acc,
        0
      )

      if (indicatorMeasure === '%' || !assignedCumulative) {
        totalsForThisMonth =
          valuesForThisYMPair.reduce((curr, acc) => curr + acc, 0) /
          entriesForThisYMPair.length
      }
      if (isNaN(totalsForThisMonth)) {
        totalsForThisMonth = null
      }
      entries.push({ x: dateParsedToGraphic(YMPair), y: totalsForThisMonth })
    })
    // creamos el objeto específico para esta división
    let obj = {}
    obj.data = entries
    obj.label = motherDataset.labels[iOfDiv]
    obj.borderColor = combinedColors[iOfDiv]
    obj.fill = false
    obj.tension = 0.02
    dataBucket.datasets.push(obj)
  })
  return dataBucket
}

// Líneas semanales----------------------------------------------------------------------------------
export const genWeeklyData = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  const assignedCumulative = assignCumulative(cumulativeness)
  const dataBucket = { datasets: [] }
  //   dataBucket.labels = motherDataset.labels
  motherDataset.labels.forEach((divisionName, iOfDiv) => {
    // creamos el objeto específico para esta división
    let obj = {}
    // aca
    if (indicatorMeasure === '%' || !assignedCumulative) {
      obj.data =
        motherDataset.datasets[iOfDiv] / motherDataset.datasets[iOfDiv].length
    } else {
      obj.data = motherDataset.datasets[iOfDiv]
    }
    obj.data = motherDataset.datasets[iOfDiv]
    obj.label = motherDataset.labels[iOfDiv]
    obj.borderColor = combinedColors[iOfDiv]
    obj.fill = false
    obj.tension = 0.02
    dataBucket.datasets.push(obj)
  })

  return dataBucket
}

// líneas para el histórico del indicador, por mes----------------------------------------------

export const genHistoryLineData = (motherDataset) => {
  if (!motherDataset.datasets) {
    return
  }
  const dataBucket = { datasets: [] }
  //   dataBucket.labels = motherDataset.labels
  motherDataset.labels.forEach((divisionName, iOfDiv) => {
    // creamos el objeto específico para esta división
    let obj = {}
    // aca
    obj.data = motherDataset.datasets[iOfDiv]
    obj.label = motherDataset.labels[iOfDiv]
    obj.borderColor = combinedColors[iOfDiv]
    obj.fill = false
    obj.tension = 0.02
    dataBucket.datasets.push(obj)
  })

  return dataBucket
}

// Barras semestrales ------------------------------------------------------------------------
export const genSemestralBarData = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  const assignedCumulative = assignCumulative(cumulativeness)

  // Preparar categorías
  // tomar el período total desde el primero hasta el último registro
  const spanYears = calcSpanYears(motherDataset.domain)

  // dividimos en períodos de 6 meses
  // Por cada año transcurrido desde el inicio de mediciones
  let exportlabels = []

  spanYears.forEach((year) => {
    exportlabels.push(`1° Semestre ${year}`)
    exportlabels.push(`2° Semestre ${year}`)
  })

  // Preparar valores
  // iterar en la data y clasificar por cada período, con label

  let datasetToExport = []

  motherDataset.datasets.forEach((labelDataset, index) => {
    let tempDataset = {}
    tempDataset.division = motherDataset.labels[index]
    let labelData = []
    const labelIndeed = motherDataset.labels[index]
    const backgroundColor = combinedColors[index]
    let countObj = {}
    labelDataset.forEach((entry, idx) => {
      if (tempDataset[tellMeWhichYearSemester(entry.x)]) {
        tempDataset[tellMeWhichYearSemester(entry.x)] += entry.y
        countObj[tellMeWhichYearSemester(entry.x)]++
      } else {
        tempDataset[tellMeWhichYearSemester(entry.x)] = entry.y
        countObj[tellMeWhichYearSemester(entry.x)] = 1
      }
    })
    exportlabels.forEach((label, index) => {
      if (tempDataset.hasOwnProperty(label)) {
        if (indicatorMeasure === '%' || !assignedCumulative) {
          labelData[index] = tempDataset[label] / countObj[label]
        } else {
          labelData[index] = tempDataset[label]
        }
      }
    })
    datasetToExport.push({
      label: labelIndeed,
      data: labelData,
      backgroundColor
    })
  })

  return {
    // para cada una de estas divisiones
    labels: exportlabels,
    datasets: datasetToExport
  }
}

// Líneas semestrales para no acumulativos -------------------------------------------------

export const genLineSemestralData = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  const assignedCumulative = assignCumulative(cumulativeness)
  // Preparar categorías
  // tomar el período total desde el primero hasta el último registro
  const spanYears = calcSpanYears(motherDataset.domain)

  // dividimos en períodos de 6 meses
  // Por cada año transcurrido desde el inicio de mediciones
  let exportlabels = []

  spanYears.forEach((year) => {
    exportlabels.push(`1° Semestre ${year}`)
    exportlabels.push(`2° Semestre ${year}`)
  })

  // Preparar valores
  // iterar en la data y clasificar por cada período, con label

  let datasetToExport = []

  motherDataset.datasets.forEach((labelDataset, index) => {
    let tempDataset = {}
    tempDataset.division = motherDataset.labels[index]
    let labelData = []
    const labelIndeed = motherDataset.labels[index]
    const backgroundColor = combinedColors[index]
    let countObj = {}
    labelDataset.forEach((entry, idx) => {
      if (tempDataset[tellMeWhichYearSemester(entry.x)]) {
        tempDataset[tellMeWhichYearSemester(entry.x)] += entry.y
        countObj[tellMeWhichYearSemester(entry.x)]++
      } else {
        tempDataset[tellMeWhichYearSemester(entry.x)] = entry.y
        countObj[tellMeWhichYearSemester(entry.x)] = 1
      }
    })
    exportlabels.forEach((label, index) => {
      if (tempDataset.hasOwnProperty(label)) {
        if (indicatorMeasure === '%' || !assignedCumulative) {
          labelData[index] = tempDataset[label] / countObj[label]
        } else {
          labelData[index] = tempDataset[label]
        }
      } else {
        labelData[index] = null
      }
    })
    datasetToExport.push({
      label: labelIndeed,
      data: labelData,
      backgroundColor
    })
  })

  return {
    // para cada una de estas divisiones
    labels: exportlabels,
    datasets: datasetToExport
  }
}

// Barras trimestrales ------------------------------------------------------------------------
export const genTrimestralBarData = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  const assignedCumulative = assignCumulative(cumulativeness)
  // Preparar categorías
  // tomar el período total desde el primero hasta el último registro
  const spanYears = calcSpanYears(motherDataset.domain)

  // dividimos en períodos de 3 meses
  // Por cada año transcurrido desde el inicio de mediciones
  let exportlabels = []

  spanYears.forEach((year) => {
    exportlabels.push(`1° Trimestre ${year}`)
    exportlabels.push(`2° Trimestre ${year}`)
    exportlabels.push(`3° Trimestre ${year}`)
    exportlabels.push(`4° Trimestre ${year}`)
  })

  // Preparar valores
  // iterar en la data y clasificar por cada período, con label

  let datasetToExport = []

  motherDataset.datasets.forEach((labelDataset, index) => {
    let tempDataset = {}
    tempDataset.division = motherDataset.labels[index]
    let labelData = []
    const labelIndeed = motherDataset.labels[index]
    const backgroundColor = combinedColors[index]

    let countObj = {}
    labelDataset.forEach((entry, idx) => {
      if (tempDataset[tellMeWhichYearTrimester(entry.x)]) {
        tempDataset[tellMeWhichYearTrimester(entry.x)] += entry.y
        countObj[tellMeWhichYearTrimester(entry.x)] += 1
      } else {
        tempDataset[tellMeWhichYearTrimester(entry.x)] = entry.y
        countObj[tellMeWhichYearTrimester(entry.x)] = 1
      }
    })
    exportlabels.forEach((label, index) => {
      if (tempDataset.hasOwnProperty(label)) {
        if (indicatorMeasure === '%' || !assignedCumulative) {
          labelData[index] = tempDataset[label] / countObj[label]
        } else {
          labelData[index] = tempDataset[label]
        }
      }
    })
    datasetToExport.push({
      label: labelIndeed,
      data: labelData,
      backgroundColor
    })
  })

  return {
    // para cada una de estas divisiones
    labels: exportlabels,
    datasets: datasetToExport
  }
}

// Barras genéricas ----------------------------------------------------------------------------
export const genBarData = (motherDataset) => {
  if (!motherDataset.datasets) {
    return
  }
  // let renderedData = []
  let onlyDataArr = []
  let finalDatasets = []
  if (motherDataset.labels.length > 0) {
    // cálculo del acumulado por año y creación de punto x, y con esos datos
    // [{año, valor}, {año, valor}]

    motherDataset.datasets.forEach((arrOfxyPoints, index) => {
      // encontramos los años presentes, los filtramos por únicos
      let arrForTheLabel = []
      const presentYears = arrOfxyPoints.map((xyPoint) => getYear(xyPoint.x))
      const uniqueYears = presentYears.filter(
        (item, i, ar) => ar.indexOf(item) === i
      )

      uniqueYears.forEach((year) => {
        let logsInthisYear = arrOfxyPoints.filter(
          (xyPoint) => getYear(xyPoint.x) === year
        )
        let valuesInThisYear = logsInthisYear.map((value) => value.y)
        let totalsForThisYear = valuesInThisYear.reduce(
          (curr, acc) => curr + acc,
          0
        )
        arrForTheLabel.push({ x: year, y: totalsForThisYear })
      })
      onlyDataArr.push(arrForTheLabel)
    })
  }
  // vamos hacer de cada branch, un dataset

  onlyDataArr.forEach((setOfData, index) => {
    finalDatasets.push({
      label: motherDataset.labels[index],
      data: setOfData,
      backgroundColor: combinedColors[index],
      borderColor: combinedColors[index]
    })
  })

  return { datasets: finalDatasets }
}

// Barras anuales --------------------------------------------------------------------
export const generateYearlyBarData = (
  motherDataset,
  measureUnit,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  const assignedCumulative = assignCumulative(cumulativeness)

  // let renderedData = []
  let onlyDataArr = []
  let finalDatasets = []
  if (motherDataset.labels.length > 0) {
    // cálculo del acumulado por año y creación de punto x, y con esos datos
    // [{año, valor}, {año, valor}]

    motherDataset.datasets.forEach((arrOfxyPoints, index) => {
      // encontramos los años presentes, los filtramos por únicos
      let arrForTheLabel = []
      const presentYears = arrOfxyPoints.map((xyPoint) => getYear(xyPoint.x))
      const uniqueYears = presentYears.filter(
        (item, i, ar) => ar.indexOf(item) === i
      )

      uniqueYears.forEach((year) => {
        let logsInthisYear = arrOfxyPoints.filter(
          (xyPoint) => getYear(xyPoint.x) === year
        )
        let valuesInThisYear = logsInthisYear.map((value) => value.y)
        let totalsForThisYear = valuesInThisYear.reduce(
          (curr, acc) => curr + acc,
          0
        )
        if (measureUnit === '%' || !assignedCumulative) {
          totalsForThisYear = totalsForThisYear / logsInthisYear.length
        }
        arrForTheLabel.push({ x: year, y: totalsForThisYear })
      })
      onlyDataArr.push(arrForTheLabel)
    })
  }
  // vamos hacer de cada branch, un dataset

  onlyDataArr.forEach((setOfData, index) => {
    finalDatasets.push({
      label: motherDataset.labels[index],
      data: setOfData,
      backgroundColor: combinedColors[index],
      borderColor: combinedColors[index]
    })
  })

  return { datasets: finalDatasets }
}

// Lineas anuales --------------------------------------------------------------------
export const generateYearlyLineData = (
  motherDataset,
  measureUnit,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }

  const assignedCumulative = assignCumulative(cumulativeness)

  // let renderedData = []
  let onlyDataArr = []
  let finalDatasets = []
  if (motherDataset.labels.length > 0) {
    // cálculo del acumulado por año y creación de punto x, y con esos datos
    // [{año, valor}, {año, valor}]

    motherDataset.datasets.forEach((arrOfxyPoints, index) => {
      // encontramos los años presentes, los filtramos por únicos
      let arrForTheLabel = []
      const presentYears = arrOfxyPoints.map((xyPoint) => getYear(xyPoint.x))
      const uniqueYears = presentYears.filter(
        (item, i, ar) => ar.indexOf(item) === i
      )

      uniqueYears.forEach((year) => {
        let logsInthisYear = arrOfxyPoints.filter(
          (xyPoint) => getYear(xyPoint.x) === year
        )
        let valuesInThisYear = logsInthisYear.map((value) => value.y)
        let totalsForThisYear = valuesInThisYear.reduce(
          (curr, acc) => curr + acc,
          0
        )
        if (measureUnit === '%' || !assignedCumulative) {
          totalsForThisYear = totalsForThisYear / logsInthisYear.length
        }
        arrForTheLabel.push({ x: year, y: totalsForThisYear })
      })
      onlyDataArr.push(arrForTheLabel)
    })
  }
  // vamos hacer de cada branch, un dataset

  onlyDataArr.forEach((setOfData, index) => {
    finalDatasets.push({
      label: motherDataset.labels[index],
      data: setOfData,
      backgroundColor: combinedColors[index],
      borderColor: combinedColors[index],
      fill: false,
      tension: 0.02,
      pointRadius: 7
    })
  })

  return { datasets: finalDatasets }
}

export const genTotalBarData = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  if (!motherDataset.labels.length > 0) {
    return motherDataset
  }
  const assignedCumulative = assignCumulative(cumulativeness)
  const theTotalArr = []

  motherDataset.datasets.forEach((label, index) => {
    let valsPerLabel = label.map((ele) => ele.y)
    let sumInLabel = valsPerLabel.reduce((prev, acc) => prev + acc, 0)
    if (indicatorMeasure === '%' || !assignedCumulative) {
      sumInLabel = sumInLabel / label.length
    }
    theTotalArr.push(sumInLabel)
    // theTotalArr.push(sumInLabel)
  })

  const data = {
    labels: motherDataset.labels,
    datasets: [
      {
        label: 'Total acumulado',
        data: theTotalArr,
        backgroundColor: combinedColors
      }
    ]
  }

  return data
}

export const genCorpAccData = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  const assignedCumulative = assignCumulative(cumulativeness)
  const totalValues = motherDataset.datasets.map((ele) => ele.y)
  let totalSum = totalValues.reduce((curr, acc) => curr + acc, 0)
  if (indicatorMeasure === '%' || !assignedCumulative) {
    totalSum = totalSum / motherDataset.datasets.length
  }

  return {
    labels: ['Acumulado'],
    datasets: [
      {
        label: `Acumulado histórico actual`,
        data: [totalSum],
        backgroundColor: combinedColors[0],
        borderColor: combinedColors[0]
      }
    ]
  }
}

export const genCorpYearlyData = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  const assignedCumulative = assignCumulative(cumulativeness)
  let finalDatasets = []
  const presentYears = motherDataset.datasets.map((xyPoint) => {
    if (Array.isArray(xyPoint) && xyPoint.length === 1) {
      const d = xyPoint[0].x
      return getYear(d)
    }

    return getYear(xyPoint.x)
  })
  const uniqueYears = presentYears.filter(
    (item, i, ar) => ar.indexOf(item) === i
  )
  uniqueYears.forEach((year, index) => {
    // registros para este año
    let tempObj = {}
    let logsInThisYear = motherDataset.datasets.filter((xyPoint) => {
      let y
      if (Array.isArray(xyPoint) && xyPoint.length === 1) {
        const d = xyPoint[0].x
        y = getYear(d)
      }
      return y === year
    })

    // valores para este año
    let valuesInThisYear = logsInThisYear.map((value) => value.y)
    let totalsForThisYear = valuesInThisYear.reduce(
      (curr, acc) => curr + acc,
      0
    )
    if (indicatorMeasure === '%' || !assignedCumulative) {
      totalsForThisYear = totalsForThisYear / valuesInThisYear.length
    }
    tempObj.label = uniqueYears[index]
    ;(tempObj.data = [{ x: new Date(year, 0, 1), y: totalsForThisYear }]),
      (tempObj.backgroundColor = combinedColors[index]),
      (tempObj.borderColor = combinedColors[index])
    finalDatasets.push(tempObj)
  })
  // ajuste para que se muestre una continuidad entre los años con gapline entre los puntos
  const onlyValues = finalDatasets.map((ele) => ele.data[0])
  const fullData = {
    datasets: [
      {
        label: '',
        data: onlyValues,
        fill: false,
        borderColor: combinedColors[0],
        backgroundColor: combinedColors[0],
        tension: 0.02
      }
    ]
  }

  return fullData

  // return finalDatasets
}

export const genCorpMonthlyData = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  const assignedCumulative = assignCumulative(cumulativeness)
  // encontramos todos los años y meses en los que se han registrado valores para este indicador
  let yearMonths = []
  motherDataset.datasets.forEach((entry) =>
    yearMonths.push(giveMeYearAndMonth(entry.x))
  )
  const yearAndMonthsList = yearMonths.filter(
    (item, i, ar) => ar.indexOf(item) === i
  )
  let datesOfInterest = yearAndMonthsList.map((item) => formYMToDate(item))

  let sorted = datesOfInterest.sort((a, b) => a - b)
  const orderedYearsAndMonths = sorted.map((date) => giveMeYearAndMonth(date))

  let entries = []
  orderedYearsAndMonths.forEach((YMPair) => {
    // let YMLabel = formYMToDate(YMPair)
    let entriesForThisYMPair = motherDataset.datasets.filter(
      (entry) => giveMeYearAndMonth(entry.x) === YMPair
    )

    let valuesForThisYMPair = entriesForThisYMPair.map((ele) => ele.y)
    let totalsForThisMonth = valuesForThisYMPair.reduce(
      (curr, acc) => curr + acc,
      0
    )
    if (indicatorMeasure === '%' || !assignedCumulative) {
      totalsForThisMonth = totalsForThisMonth / entriesForThisYMPair.length
    }

    entries.push({
      x: dateParsedToGraphic(YMPair),
      y: totalsForThisMonth
    })
  })
  const data = {
    datasets: [
      {
        label: '',
        data: entries,
        fill: false,
        borderColor: combinedColors[0],
        tension: 0.02
      }
    ]
  }
  return data
}

export const genCorpSemestralData = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  const assignedCumulative = assignCumulative(cumulativeness)
  // Preparar categorías
  // tomar el período total desde el primero hasta el último registro
  const spanYears = calcSpanYears(motherDataset.domain)
  // dividimos en períodos de 6 meses
  // Por cada año transcurrido desde el inicio de mediciones
  let exportlabels = []
  spanYears.forEach((year) => {
    exportlabels.push(`1° Semestre ${year}`)
    exportlabels.push(`2° Semestre ${year}`)
  })

  // Preparar valores
  // iterar en la data y clasificar por cada período
  let tempDataset = {}
  let countObj = {}
  motherDataset.datasets.forEach((entry) => {
    if (tempDataset[tellMeWhichYearSemester(entry.x)]) {
      tempDataset[tellMeWhichYearSemester(entry.x)] += entry.y
      countObj[tellMeWhichYearSemester(entry.x)]++
    } else {
      tempDataset[tellMeWhichYearSemester(entry.x)] = entry.y
      countObj[tellMeWhichYearSemester(entry.x)] = 1
    }
  })

  const values = []
  // si queremos mostrar solo los trimestres donde ha habido registros
  // const labels = Object.keys(tempDataset)
  exportlabels.forEach((label) => {
    if (indicatorMeasure === '%' || !assignedCumulative) {
      values.push(tempDataset[label] / countObj[label])
    } else {
      values.push(tempDataset[label])
    }
  })
  const semData = {
    labels: exportlabels,
    datasets: [{ label: '', data: values, backgroundColor: combinedColors[0] }]
  }
  return semData
}

export const genCorpTrimestralData = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  const assignedCumulative = assignCumulative(cumulativeness)
  // Preparar categorías
  // tomar el período total desde el primero hasta el último registro
  const spanYears = calcSpanYears(motherDataset.domain)
  let exportlabels = []
  spanYears.forEach((year) => {
    exportlabels.push(`1° Trimestre ${year}`)
    exportlabels.push(`2° Trimestre ${year}`)
    exportlabels.push(`3° Trimestre ${year}`)
    exportlabels.push(`4° Trimestre ${year}`)
  })

  // Preparar valores
  // iterar en la data y clasificar por cada período
  let tempDataset = {}
  let countObj = {}

  motherDataset.datasets.forEach((entry) => {
    if (tempDataset[tellMeWhichYearTrimester(entry.x)]) {
      tempDataset[tellMeWhichYearTrimester(entry.x)] += entry.y
      countObj[tellMeWhichYearTrimester(entry.x)]++
    } else {
      tempDataset[tellMeWhichYearTrimester(entry.x)] = entry.y
      countObj[tellMeWhichYearTrimester(entry.x)] = 1
    }
  })
  const values = []
  // si queremos mostrar solo los trimestres donde ha habido registros
  // const labels = Object.keys(tempDataset)
  exportlabels.forEach((label) => {
    if (indicatorMeasure === '%' || !assignedCumulative) {
      values.push(tempDataset[label] / countObj[label])
    } else {
      values.push(tempDataset[label])
    }
  })
  const trimData = {
    labels: exportlabels,
    datasets: [{ label: '', data: values, backgroundColor: combinedColors[0] }]
  }
  return trimData
}

export const genCorpWeeklyData = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  let entries = []
  motherDataset.datasets.forEach((entry) => {
    let obj = {}
    obj.x = entry.x
    obj.y = entry.y
    entries.push(obj)
  })
  const data = {
    // labels: orderedYearsAndMonths,
    datasets: [
      {
        label: '',
        data: entries,
        fill: false,
        borderColor: combinedColors[0],
        tension: 0.02
      }
    ]
  }
  return data
}

export const genCorpDailyData = (motherDataset) => {
  if (!motherDataset.datasets) {
    return
  }
  let entries = []
  motherDataset.datasets.forEach((entry) => {
    let obj = {}
    obj.x = entry.x
    obj.y = entry.y
    entries.push(obj)
  })
  const data = {
    // labels: orderedYearsAndMonths,
    datasets: [
      {
        label: '',
        data: entries,
        fill: false,
        borderColor: combinedColors[0],
        tension: 0.02
      }
    ]
  }
  return data
}

export const genBubbleChart = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  const assignedCumulative = assignCumulative(cumulativeness)
  const dataBucket = { datasets: [] }
  //   dataBucket.labels = motherDataset.labels
  motherDataset.labels.forEach((divisionName, iOfDiv) => {
    // creamos el objeto específico para esta división
    let obj = {}
    // aca
    if (indicatorMeasure === '%' || !assignedCumulative) {
      obj.data =
        motherDataset.datasets[iOfDiv] / motherDataset.datasets[iOfDiv].length
    } else {
      obj.data = motherDataset.datasets[iOfDiv]
    }
    obj.data = motherDataset.datasets[iOfDiv]
    obj.label = motherDataset.labels[iOfDiv]
    obj.backgroundColor = combinedColors[iOfDiv]
    obj.tension = 0.02
    dataBucket.datasets.push(obj)
  })

  return dataBucket
}

// cálculos específicos para indicadores no acumulativos -----------------------

export const convertToBubbleData = (inCData) => {
  if (inCData.datasets.length > 0) {
    const processedData = inCData.datasets.map((ele) => {
      const dt = ele.data
      const dtWitR = dt.map((entry) => {
        return { ...entry, r: 9 }
      })
      return { ...ele, data: dtWitR }
    })
    return { datasets: processedData }
  }
  if (inCData.data) {
    return []
  }
}

export const convertToSpecialLine = (inCData) => {
  if (!inCData) {
    return
  }
  if (inCData.datasets.length > 0) {
    const biggerPD = inCData.datasets.map((ele) => {
      return {
        data: ele.data,
        label: ele.label,
        backgroundColor: ele.borderColor
          ? ele.borderColor
          : ele.backgroundColor,
        borderColor: ele.backgroundColor
          ? ele.backgroundColor
          : ele.borderColor,
        fill: false,
        pointRadius: 7
      }
    })
    return { labels: inCData.labels, datasets: biggerPD }
    // return inCData
  }
  if (inCData.data) {
    return []
  }
}

export const genTotalLineData = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  if (!motherDataset.labels.length > 0) {
    return motherDataset
  }
  const assignedCumulative = assignCumulative(cumulativeness)
  const theTotalArr = []

  motherDataset.datasets.forEach((label, index) => {
    let valsPerLabel = label.map((ele) => ele.y)
    let sumInLabel = valsPerLabel.reduce((prev, acc) => prev + acc, 0)
    if (indicatorMeasure === '%' || !assignedCumulative) {
      sumInLabel = sumInLabel / label.length
    }
    theTotalArr.push(sumInLabel)
    // theTotalArr.push(sumInLabel)
  })

  const data = {
    labels: motherDataset.labels,
    datasets: [
      {
        label: 'Total acumulado',
        data: theTotalArr,
        backgroundColor: combinedColors
      }
    ]
  }

  return data
}

export const genTotalSweetData = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  if (!motherDataset.labels.length > 0) {
    return motherDataset
  }
  const assignedCumulative = assignCumulative(cumulativeness)
  let dataArr = []
  let colorsArr = []
  motherDataset.datasets.forEach((label, index) => {
    let valsPerLabel = label.map((ele) => ele.y)
    let sumInLabel = valsPerLabel.reduce((prev, acc) => prev + acc, 0)
    if (indicatorMeasure === '%' || !assignedCumulative) {
      sumInLabel = sumInLabel / label.length
    }
    dataArr.push(sumInLabel)
    colorsArr.push(combinedColors[index])

    // theTotalArr.push(sumInLabel)
  })

  const data = {
    labels: motherDataset.labels,
    datasets: [
      {
        label: 'testing',
        data: dataArr,
        backgroundColor: colorsArr,
        hoverOffset: 4
      }
    ]
  }

  return data
}

export const genTrimestralLineData = (
  motherDataset,
  indicatorMeasure,
  cumulativeness
) => {
  if (!motherDataset.datasets) {
    return
  }
  const assignedCumulative = assignCumulative(cumulativeness)
  // Preparar categorías
  // tomar el período total desde el primero hasta el último registro
  const spanYears = calcSpanYears(motherDataset.domain)

  // dividimos en períodos de 3 meses
  // Por cada año transcurrido desde el inicio de mediciones
  let exportlabels = []

  spanYears.forEach((year) => {
    exportlabels.push(`1° Trimestre ${year}`)
    exportlabels.push(`2° Trimestre ${year}`)
    exportlabels.push(`3° Trimestre ${year}`)
    exportlabels.push(`4° Trimestre ${year}`)
  })

  // Preparar valores
  // iterar en la data y clasificar por cada período, con label

  let datasetToExport = []

  motherDataset.datasets.forEach((labelDataset, index) => {
    let tempDataset = {}
    tempDataset.division = motherDataset.labels[index]
    let labelData = []
    const labelIndeed = motherDataset.labels[index]
    const borderColor = combinedColors[index]

    let countObj = {}
    labelDataset.forEach((entry, idx) => {
      if (tempDataset[tellMeWhichYearTrimester(entry.x)]) {
        tempDataset[tellMeWhichYearTrimester(entry.x)] += entry.y
        countObj[tellMeWhichYearTrimester(entry.x)] += 1
      } else {
        tempDataset[tellMeWhichYearTrimester(entry.x)] = entry.y
        countObj[tellMeWhichYearTrimester(entry.x)] = 1
      }
    })
    exportlabels.forEach((label, index) => {
      if (tempDataset.hasOwnProperty(label)) {
        if (indicatorMeasure === '%' || !assignedCumulative) {
          labelData[index] = tempDataset[label] / countObj[label]
        } else {
          labelData[index] = tempDataset[label]
        }
      }
    })
    datasetToExport.push({
      label: labelIndeed,
      data: labelData,
      borderColor
    })
  })

  return {
    // para cada una de estas divisiones
    labels: exportlabels,
    datasets: datasetToExport
  }
}

export const extendedClassifyByYear = (data) => {
  const dates = []
  for (let division in data) {
    data[division].forEach((entry) => dates.push(entry.date))
  }
  const yearsPresent = dates.map((date) => {
    return date.split('T')[0].split('-')[0]
  })
  const uniqueYears = yearsPresent.filter(
    (item, i, ar) => ar.indexOf(item) === i
  )
  const divisionsAndPeriods = {}
  // asignamos tantas propiedades como divisiones existan en la data
  for (let division of Object.keys(data)) {
    divisionsAndPeriods[`${division}`] = {}
  }
  // asignamos tantos años como existan en cada division
  for (let year of uniqueYears) {
    for (let division in divisionsAndPeriods) {
      divisionsAndPeriods[division][`${year}`] = []
    }
  }

  // asignamos los valores de cada division en el array correspondiente a división/período
  for (let division in data) {
    for (let year in uniqueYears) {
      for (let log in data[division]) {
        if (
          new Date(data[division][log].date).getFullYear() ===
          parseInt(uniqueYears[year])
        ) {
          divisionsAndPeriods[`${division}`][uniqueYears[year]].push(
            data[division][log].value
          )
        }
      }
    }
  }

  for (let division in divisionsAndPeriods) {
    Object.keys(divisionsAndPeriods[division]).forEach((year) => {
      divisionsAndPeriods[division][year] = divisionsAndPeriods[division][
        year
      ].reduce((acc, curr) => curr + acc, 0)
    })
  }
  // Preparamos el dataset
  let output = {
    datasets: [],
    labels: uniqueYears
  }

  Object.keys(divisionsAndPeriods).forEach((division, index) => {
    const datasetElement = {}
    datasetElement.label = division
    datasetElement.data = []
    datasetElement.backgroundColor = crayolaPallete[index]
    datasetElement.borderColor = crayolaPallete[index]
    for (let year in divisionsAndPeriods[division]) {
      const itemInData = {}
      itemInData.x = new Date(year, 0)
      itemInData.y = divisionsAndPeriods[division][year]
      datasetElement.data.push(itemInData)
    }
    output.datasets.push(datasetElement)
  })
  return output
}
export const extendedClassifyBySemester = (data) => {
  const dates = []
  for (let division in data) {
    data[division].forEach((entry) => dates.push(entry.date))
  }
  const semestersPresent = dates.map((date) => {
    return tellMeWhichYearSemester(new Date(date))
  })
  let uniqueSemesters = semestersPresent.filter(
    (item, i, ar) => ar.indexOf(item) === i
  )
  uniqueSemesters = uniqueSemesters.reverse()
  const divisionsAndPeriods = {}
  for (let division of Object.keys(data)) {
    divisionsAndPeriods[`${division}`] = {}
  }
  for (let semester of uniqueSemesters) {
    for (let division in divisionsAndPeriods) {
      divisionsAndPeriods[division][`${semester}`] = []
    }
  }
  // asignamos los valores de cada division en el array correspondiente a división/período
  for (let division in data) {
    for (let semester in uniqueSemesters) {
      for (let log in data[division]) {
        if (
          tellMeWhichYearSemester(new Date(data[division][log].date)) ===
          uniqueSemesters[semester]
        ) {
          divisionsAndPeriods[`${division}`][uniqueSemesters[semester]].push(
            data[division][log].value
          )
        }
      }
    }
  }

  for (let division in divisionsAndPeriods) {
    Object.keys(divisionsAndPeriods[division]).forEach((semester) => {
      divisionsAndPeriods[division][semester] = divisionsAndPeriods[division][
        semester
      ].reduce((acc, curr) => curr + acc, 0)
    })
  }
  // Preparamos el dataset
  let output = {
    datasets: [],
    labels: uniqueSemesters
  }
  Object.keys(divisionsAndPeriods).forEach((division, index) => {
    const datasetElement = {}
    datasetElement.label = division
    datasetElement.data = []
    datasetElement.backgroundColor = crayolaPallete[index]
    datasetElement.borderColor = crayolaPallete[index]
    for (let semester in divisionsAndPeriods[division]) {
      const itemInData = divisionsAndPeriods[division][semester]
      datasetElement.data.push(itemInData)
    }
    output.datasets.push(datasetElement)
  })
  return output
}

export const extendedClassifyByTrimester = (data) => {
  const dates = []
  for (let division in data) {
    data[division].forEach((entry) => dates.push(entry.date))
  }
  const trimestersPresent = dates.map((date) => {
    return tellMeWhichYearTrimester(new Date(date))
  })
  let uniqueTrimesters = trimestersPresent.filter(
    (item, i, ar) => ar.indexOf(item) === i
  )
  uniqueTrimesters = uniqueTrimesters.reverse()
  const divisionsAndPeriods = {}
  for (let division of Object.keys(data)) {
    divisionsAndPeriods[`${division}`] = {}
  }
  for (let trimester of uniqueTrimesters) {
    for (let division in divisionsAndPeriods) {
      divisionsAndPeriods[division][`${trimester}`] = []
    }
  }
  // asignamos los valores de cada division en el array correspondiente a división/período
  for (let division in data) {
    for (let trimester in uniqueTrimesters) {
      for (let log in data[division]) {
        if (
          tellMeWhichYearTrimester(new Date(data[division][log].date)) ===
          uniqueTrimesters[trimester]
        ) {
          divisionsAndPeriods[`${division}`][uniqueTrimesters[trimester]].push(
            data[division][log].value
          )
        }
      }
    }
  }
  for (let division in divisionsAndPeriods) {
    Object.keys(divisionsAndPeriods[division]).forEach((trimester) => {
      divisionsAndPeriods[division][trimester] = divisionsAndPeriods[division][
        trimester
      ].reduce((acc, curr) => curr + acc, 0)
    })
  }
  // Preparamos el dataset
  let output = {
    datasets: [],
    labels: uniqueTrimesters
  }
  Object.keys(divisionsAndPeriods).forEach((division, index) => {
    const datasetElement = {}
    datasetElement.label = division
    datasetElement.data = []
    datasetElement.backgroundColor = crayolaPallete[index]
    datasetElement.borderColor = crayolaPallete[index]
    for (let semester in divisionsAndPeriods[division]) {
      const itemInData = divisionsAndPeriods[division][semester]
      datasetElement.data.push(itemInData)
    }
    output.datasets.push(datasetElement)
  })
  return output
}
export const extendedClassifyByMonth = (data) => {
  let dates = []
  for (let division in data) {
    data[division].forEach((entry) => dates.push(entry.date))
  }
  dates = dates.sort((a, b) => new Date(b) - new Date(a))
  const yMonthsPresent = dates.map((date) => {
    return giveMeYearAndMonth(new Date(date))
  })
  let uniqueYMPairs = yMonthsPresent.filter(
    (item, i, ar) => ar.indexOf(item) === i
  )
  uniqueYMPairs = uniqueYMPairs.reverse()
  const divisionsAndPeriods = {}
  for (let division of Object.keys(data)) {
    divisionsAndPeriods[`${division}`] = {}
  }
  for (let ymPair of uniqueYMPairs) {
    for (let division in divisionsAndPeriods) {
      divisionsAndPeriods[division][`${ymPair}`] = []
    }
  }
  // asignamos los valores de cada division en el array correspondiente a división/período
  for (let division in data) {
    for (let ymPair in uniqueYMPairs) {
      for (let log in data[division]) {
        if (
          giveMeYearAndMonth(new Date(data[division][log].date)) ===
          uniqueYMPairs[ymPair]
        ) {
          divisionsAndPeriods[`${division}`][uniqueYMPairs[ymPair]].push(
            data[division][log].value
          )
        }
      }
    }
  }
  for (let division in divisionsAndPeriods) {
    Object.keys(divisionsAndPeriods[division]).forEach((yMPair) => {
      divisionsAndPeriods[division][yMPair] = divisionsAndPeriods[division][
        yMPair
      ].reduce((acc, curr) => curr + acc, 0)
    })
  }
  // Preparamos el dataset
  let output = {
    datasets: [],
    labels: uniqueYMPairs.map((ymPair) => humanizeMonth(ymPair))
  }
  Object.keys(divisionsAndPeriods).forEach((division, index) => {
    const datasetElement = {}
    datasetElement.label = division
    datasetElement.data = []
    datasetElement.backgroundColor = crayolaPallete[index]
    datasetElement.borderColor = crayolaPallete[index]
    for (let YMPair in divisionsAndPeriods[division]) {
      const itemInData = divisionsAndPeriods[division][YMPair]
      datasetElement.data.push(itemInData)
    }
    output.datasets.push(datasetElement)
  })
  return output
}
export const extendedClassifyByWeek = (data) => {
  const dates = []
  for (let division in data) {
    data[division].forEach((entry) => dates.push(entry.date))
  }

  const datesWithoutTime = dates.map((ele) => {
    const wt = ele.split('T')[0]
    const splitted = {
      year: wt.split('-')[0],
      month: parseInt(wt.split('-')[1]) - 1,
      day: wt.split('-')[2]
    }
    const rt = new Date(splitted.year, splitted.month, splitted.day, 0)
    return rt.toISOString()
  })
  const uniqueDatesPresent = datesWithoutTime
    .filter((item, i, ar) => ar.indexOf(item) === i)
    .sort((a, b) => new Date(a) - new Date(b))
  // a qué semana y año corresponde cada fecha
  const weeksIdentifiers = uniqueDatesPresent.map((date) =>
    whichNumberOfWeek(new Date(date))
  )

  // clasificación de divisiones y períodos
  const divisionsAndPeriods = {}
  for (let division of Object.keys(data)) {
    divisionsAndPeriods[`${division}`] = {}
  }
  for (let week of weeksIdentifiers) {
    for (let division in divisionsAndPeriods) {
      divisionsAndPeriods[division][
        `${week.targetYear}-${week.weekNumber}-${week.beginOfWeek}`
      ] = []
    }
  }

  // asignamos los valores de cada division en el array correspondiente a división/período
  for (let division in data) {
    for (let week in weeksIdentifiers) {
      for (let log in data[division]) {
        if (
          areInTheSameWeek(
            weeksIdentifiers[week].beginOfWeek,
            new Date(data[division][log].date)
          )
        ) {
          divisionsAndPeriods[`${division}`][
            `${weeksIdentifiers[week].targetYear}-${weeksIdentifiers[week].weekNumber}-${weeksIdentifiers[week].beginOfWeek}`
          ].push(data[division][log].value)
        }
      }
    }
  }
  // probando la necesidad de esta pieza de código
  for (let division in divisionsAndPeriods) {
    Object.keys(divisionsAndPeriods[division]).forEach((week) => {
      divisionsAndPeriods[division][week] = divisionsAndPeriods[division][
        week
      ].reduce((acc, curr) => curr + acc, 0)
    })
  }

  // Preparamos el dataset
  let output = {
    datasets: [],
    labels: weeksIdentifiers
      .map((ele) => ele.beginOfWeek)
      .map((d) => `Sem ${format(d, 'dd/MM/yyyy', { locale: es })}`)
  }

  Object.keys(divisionsAndPeriods).forEach((division, index) => {
    const datasetElement = {}
    datasetElement.label = division
    datasetElement.data = []
    datasetElement.backgroundColor = crayolaPallete[index]
    datasetElement.borderColor = crayolaPallete[index]
    for (let date in divisionsAndPeriods[division]) {
      const itemInData = divisionsAndPeriods[division][date]
      datasetElement.data.push(itemInData)
    }
    output.datasets.push(datasetElement)
  })
  return output
}
export const extendedClassifyByDays = (data) => {
  const dates = []
  for (let division in data) {
    data[division].forEach((entry) => dates.push(entry.date))
  }
  // dado que puede existir registro para una misma fecha pero diferentes hh:mm:ss
  // e interesa los registros por fecha para clasificar por semana
  // se procesan las fechas de la siguiente manera
  const datesWithoutTime = dates.map((ele) => {
    const wt = ele.split('T')[0]
    const splitted = {
      year: wt.split('-')[0],
      month: parseInt(wt.split('-')[1]) - 1,
      day: wt.split('-')[2]
    }
    const rt = new Date(splitted.year, splitted.month, splitted.day, 0)
    return rt.toISOString()
  })
  const uniqueDatesPresent = datesWithoutTime
    .filter((item, i, ar) => ar.indexOf(item) === i)
    .sort((a, b) => new Date(a) - new Date(b))
  // clasificación de divisiones y períodos
  const divisionsAndPeriods = {}
  for (let division of Object.keys(data)) {
    divisionsAndPeriods[`${division}`] = {}
  }
  uniqueDatesPresent.forEach((date) => {
    for (let division in divisionsAndPeriods) {
      divisionsAndPeriods[division][date] = []
    }
  })
  // asignamos los valores de cada division en el array correspondiente a división/período
  for (let division in data) {
    uniqueDatesPresent.forEach((date) => {
      for (let log in data[division]) {
        if (isSameDay(new Date(date), new Date(data[division][log].date))) {
          divisionsAndPeriods[division][date].push(data[division][log].value)
        }
      }
    })
  }
  for (let division in divisionsAndPeriods) {
    Object.keys(divisionsAndPeriods[division]).forEach((day) => {
      divisionsAndPeriods[division][day] = divisionsAndPeriods[division][
        day
      ].reduce((acc, curr) => curr + acc, 0)
    })
  }
  let output = {
    datasets: [],
    labels: uniqueDatesPresent.map((d) =>
      format(new Date(d), 'dd/MM/yyyy', { locale: es })
    )
  }
  Object.keys(divisionsAndPeriods).forEach((division, index) => {
    const datasetElement = {}
    datasetElement.label = division
    datasetElement.data = []
    datasetElement.backgroundColor = crayolaPallete[index]
    datasetElement.borderColor = crayolaPallete[index]
    for (let date in divisionsAndPeriods[division]) {
      const itemInData = divisionsAndPeriods[division][date]
      datasetElement.data.push(itemInData)
    }
    output.datasets.push(datasetElement)
  })
  return output
}
