import { authConstants, userConstants } from '../types'

let token = JSON.parse(localStorage.getItem('token'))

const initialState = token
  ? {
      loading: false,
      error: null,
      loggedIn: true,
      token: token,
      refreshToken: null,
      modal: '',
      openModal: false,
      keepSession: false,
      registerSuccess: false,
      valid: false
    }
  : {
      loading: false,
      error: null,
      loggedIn: false,
      token: null,
      refreshToken: null,
      modal: '',
      openModal: false,
      keepSession: false,
      registerSuccess: false,
      valid: false
    }

export function authentication(state = initialState, action) {
  switch (action.type) {
    /*--- Manejador de dialog Login/Register ---*/
    case authConstants.MODAL_OPEN:
      return {
        ...state,
        openModal: true,
        error: null,
        ...action.payload
      }
    case authConstants.MODAL_CLOSE:
      return {
        ...state,
        openModal: false,
        modal: '',
        error: null,
        keepSession: false,
        registerSuccess: false
      }
    /*--- Manejador de dialog Login/Register ---*/

    /*--- REGISTER ---*/
    case authConstants.REGISTER_REQUEST:
      return { ...state, loading: true, error: null }
    case authConstants.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        registerSuccess: true
      }
    case authConstants.REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    /*--- REGISTER ---*/

    /*--- LOGIN ---*/
    case authConstants.LOGIN_STARTED:
      return { ...state, loading: true, error: null }
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        loggedIn: true,
        openModal: false,
        modal: '',
        // user: action.payload.user,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken
      }
    case authConstants.LOGIN_FAILURE:
      return { ...state, loading: false, error: action.payload }
    /*--- LOGIN ---*/

    /*--- LOGOUT ---*/
    case authConstants.LOGOUT:
      return {
        ...initialState,
        token: null,
        loggedIn: false
      }
    /*--- LOGOUT ---*/

    /*--- MANTENER SESIÓN ACTIVADA ---*/
    case authConstants.KEEP_SESSION:
      return {
        ...state,
        keepSession: !state.keepSession
      }
    /*--- MANTENER SESIÓN ACTIVADA ---*/

    /*--- CHANGE TOKEN ---*/
    case authConstants.CHANGE_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload
      }
    /*--- CHANGE TOKEN ---*/

    case userConstants.UPLOAD_PROFILE_PICTURE_STARTED:
      return {
        loggedIn: state.loggedIn,
        user: state.user,
        token: state.token,
        updatingProfilePicture: true
      }
    case userConstants.UPLOAD_PROFILE_PICTURE_SUCCESS:
      return {
        loggedIn: state.loggedIn,
        token: state.token,
        user: { ...state.user, profilePicture: action.payload.profilePicture }
      }
    case userConstants.UPLOAD_PROFILE_PICTURE_FAILURE:
      return {
        loggedIn: state.loggedIn,
        token: state.token,
        user: state.user
      }

    default:
      return state
  }
}
