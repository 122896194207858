export const authConstants = {
    MODAL_OPEN: 'AUTH_MODAL_OPEN',
    MODAL_CLOSE: 'AUTH_MODAL_CLOSE',
  
    LOGIN_STARTED: 'USER_LOGIN_STARTED',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  
    REGISTER_REQUEST: 'USER_REGISTER_STARTED',
    REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USER_REGISTER_FAILURE',
  
    REGISTER_MODAL: {
      OPEN: 'USER_REGISTER_MODAL_OPEN',
      CLOSE: 'USER_REGISTER_MODAL_CLOSE',
      SET: 'USER_REGISTER_MODAL_SET',
      CLEAR: 'USER_REGISTER_MODAL_CLEAR'
    },
  
    LOGOUT: 'USER_LOGOUT',
  
    KEEP_SESSION: 'KEEP_SESSION',
  
    CHANGE_TOKEN_STARTED: 'CHANGE_TOKEN_STARTED',
    CHANGE_TOKEN_SUCCESS: 'CHANGE_TOKEN_SUCCESS',
    CHANGE_TOKEN_FAILURE: 'CHANGE_TOKEN_FAILURE'
  }