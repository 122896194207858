export const parseIndicatorDataToSubmit = (data) => {
  let tempData = data
  delete tempData.perspectiveId
  delete tempData.subperspective
  delete tempData.createdAt
  delete tempData.updatedAt
  delete tempData.logs
  delete tempData.company
  delete tempData.branches
  delete tempData.manager
  delete tempData.areas
  delete tempData.id
  delete tempData.ods
  const dataToSubmit = { indicator: { ...tempData } }
  return dataToSubmit
}
