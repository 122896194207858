import { linesConstants } from '../types'
const defaultLineDialog = {
  open: false,
  create: false,
  tabValue: 0,
  lineValid: false,
  clearBranchesNow: false,
  data: {
    id: null,
    name: '',
    description: '',
    branches: []
  }
}
const initialState = {
  lines: [],
  linesDialog: defaultLineDialog
}

export default function (state = initialState, action) {
  switch (action.type) {
    /*--- SET initial state ---*/
    case linesConstants.SET_INITIAL_STATE:
      return initialState

    /*--- CREATE ---*/
    case linesConstants.CREATE:
      return {
        ...state,
        linesDialog: {
          ...state.linesDialog,
          data: {
            ...state.linesDialog.data,
            ...action.payload
          }
        },
        lines: [...state.lines, action.payload]
      }
    /*--- CREATE ---*/

    /*--- FETCH ---*/
    case linesConstants.FETCH_STARTED:
      return { ...state, loading: true, error: null }
    case linesConstants.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        lines: action.payload.lines
      }
    case linesConstants.FETCH_FAILURE:
      return { ...state, loading: false, error: action.payload }
    /*--- FETCH ---*/

    /*--- UPDATE ---*/
    case linesConstants.UPDATE.SUCCESS:
      const filterTheLines = () => {
        const unChanged = state.lines.filter(
          (line) => line.id !== action.payload.line.id
        )
        const newLines = [...unChanged, action.payload.line]
        return newLines
      }
      return {
        ...state,
        lines: [...filterTheLines()],
        linesDialog: {
          ...state.linesDialog,
          data: {
            ...state.linesDialog.data,
            ...action.payload.line
          }
        }
      }
    /*--- UPDATE ---*/

    /*------------------ CREATE_LINE_DIALOG -----------------------------*/
    case linesConstants.CREATE_LINE_DIALOG.OPEN:
      return {
        ...state,
        linesDialog: {
          ...defaultLineDialog,
          open: true,
          create: true
        }
      }

    case linesConstants.CREATE_LINE_DIALOG.SET:
      return {
        ...state,
        linesDialog: {
          ...defaultLineDialog,
          open: true,
          create: true
        }
      }

    case linesConstants.CREATE_LINE_DIALOG.CLOSE:
      return {
        ...state,
        linesDialog: defaultLineDialog
      }

    case linesConstants.EDIT_LINE_DIALOG.SET:
      return {
        ...state,
        linesDialog: {
          ...state.linesDialog,
          tabValue:
            action.payload.tabValue !== undefined
              ? action.payload.tabValue
              : state.linesDialog.tabValue,
          lineValid:
            action.payload.lineValid !== undefined
              ? action.payload.lineValid
              : state.linesDialog.lineValid,

          data: {
            ...state.linesDialog.data,
            ...action.payload.data
          }
        }
      }

    case linesConstants.CREATE_LINE_DIALOG.CLEAR:
      return {
        ...state,
        linesDialog: {
          ...state.linesDialog,
          tabValue: 0,
          valid: false,
          error: null,
          data: defaultLineDialog.data
        }
      }
    /*------------------ CREATE_LINE_DIALOG -----------------------------*/

    /*------------------ EDIT_LINE_DIALOG -----------------------------*/

    case linesConstants.EDIT_LINE_DIALOG.OPEN:
      return {
        ...state,
        linesDialog: {
          ...state.linesDialog,
          open: true,
          create: false,
          data: action.payload
            ? { ...state.linesDialog.data, ...action.payload.data }
            : state.linesDialog.data
        }
      }

    case linesConstants.EDIT_LINE_DIALOG.SET:
      return {
        ...state,
        linesDialog: {
          ...state.linesDialog,
          tabValue:
            action.payload.tabValue !== undefined
              ? action.payload.tabValue
              : state.linesDialog.tabValue,
          lineValid:
            action.payload.lineValid !== undefined
              ? action.payload.lineValid
              : state.linesDialog.lineValid,

          data: {
            ...state.linesDialog.data,
            //ojo nueva pieza probando
            branches:
              state.linesDialog.data.branches > 0
                ? state.linesDialog.data.branches
                : [],
            //
            ...action.payload.data
          }
        }
      }

    /*------------------ EDIT_LINE_DIALOG -----------------------------*/

    /*------------------ SET BRANCHES -----------------------------*/
    case linesConstants.SETBRANCHES.STARTED:
      return {
        ...state,
        linesDialog: {
          ...state.linesDialog,
          loading: true,
          error: null
        }
      }

    case linesConstants.SETBRANCHES.SUCCESS:
      return {
        ...state,
        lines: state.lines.map((line) => {
          if (line.id !== action.payload.line.id) {
            return line
          }
          return {
            ...action.payload.line
          }
        }),
        linesDialog: {
          ...state.linesDialog,
          data: {
            ...state.linesDialog.data,
            ...action.payload.line
          }
        }
      }

    case linesConstants.SETBRANCHES.FAILED:
      return {
        ...state,
        linesDialog: {
          ...state.linesDialog,
          loading: false,
          error: action.payload
        }
      }
    /*------------------ SET BRANCHES -----------------------------*/

    /*------------------ CLEAR NOT OWN -----------------------------*/
    case linesConstants.CLEARBRANCHES.CLEAR:
      return {
        ...state,
        linesDialog: {
          ...state.linesDialog,
          loading: false,
          clearBranchesNow: true,
          data: {
            ...state.linesDialog.data,
            branches: [state.linesDialog.branches]
          }
        }
      }

    case linesConstants.CLEARBRANCHES.RESET:
      return {
        ...state,
        linesDialog: {
          ...state.linesDialog,
          loading: false,
          clearBranchesNow: false,
          data: {
            ...state.linesDialog.data,
            branches: state.linesDialog.branches
          }
        }
      }

    /*------------------ CLEAR NOT OWN -----------------------------*/

    default:
      return state
  }
}
