import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Check as CheckIcon } from '@material-ui/icons'

/**
 * Botón con ícono de carga
 */

const ButtonWithLoading = ({
  text,
  color,
  fullWidth,
  variant,
  disabled,
  onClick,
  style,
  loading,
  submitOk
}) => {
  return (
    <Button
      color={color}
      fullWidth={fullWidth}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      style={{ ...style, color: variant === 'text' ? color : '#fff' }}
    >
      {loading && (
        <CircularProgress
          size={24}
          style={{ marginRight: 4 }}
          color='inherit'
        />
      )}
      {submitOk && (
        <CheckIcon color='inherit' style={{ fontSize: 24, marginRight: 4 }} />
      )}
      {text}
    </Button>
  )
}

ButtonWithLoading.defaultProps = {
  text: '',
  color: 'primary',
  fullWidth: false,
  variant: 'contained',
  disabled: false,
  onClick: () => console.log('click'),
  style: { color: '#FFF' },
  loading: false,
  submitOk: false
}

ButtonWithLoading.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  loading: PropTypes.bool,
  submitOk: PropTypes.bool
}

export default ButtonWithLoading
