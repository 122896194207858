import React, { useEffect, useState, useRef } from 'react'
import { Chart } from 'chart.js'

const fallBackData = {
  datasets: [
    {
      borderColor: 'transparent',
      label: '',
      fill: false,
      tension: 0.02,
      data: [{ x: new Date(), y: 0 }]
    }
  ]
}

const MonthlyCombinedChart = ({ reach, period, data, options }) => {
  const chartCanvasRef = useRef()
  // console.log('data referencia', data)
  const [theDatasets, setTheDatasets] = useState(
    data
      ? data.datasets
        ? data.datasets
        : fallBackData.datasets
      : fallBackData.datasets
  )
  // booleano para controlar el render
  const [isRebuilding, setIsRebuilding] = useState(false)

  // cambiar a verdadero cuando period cambie
  useEffect(() => {
    setIsRebuilding(true)
  }, [period])

  useEffect(() => {
    if (isRebuilding) {
      setIsRebuilding(false)
    }
  }, [isRebuilding])

  // preparar la data

  const renderedLinesData = theDatasets.map((ele) => {
    return {
      label: ele.label,
      data: [...ele.data],
      type: 'line',
      borderColor: ele.borderColor
    }
  })
  // preparamos un arreglo numérico para simplificar la sumatoria
  let sumDivisionsInperiod = Array.apply(
    null,
    Array(theDatasets[0].data.length)
  ).map(Number.prototype.valueOf, 0)

  // conseguimos cuáles períodos se están considerando en la data
  const numberOfPeriods = theDatasets[0].data.length

  // hacemos la sumatoria de los valores para cada período
  for (let i = 0; i < numberOfPeriods; i++) {
    for (let j = 0; j < theDatasets.length; j++) {
      sumDivisionsInperiod[i] += theDatasets[j].data[i].y
    }
  }

  let barsLabels = theDatasets[0].data.map((entry) => entry.x)

  const renderedBarsData = {
    label: 'Sumatoria en el período',
    data: [...sumDivisionsInperiod],
    type: 'bar',
    backgroundColor: 'rgba(0, 187, 255, 0.55)',
    borderColor: '#00BBFF'
  }

  // cambio interno del gráfico

  useEffect(() => {
    const chartCanvas = chartCanvasRef.current
    // en caso de que el canvas no haya sido instanciado
    // o se esté reconstruyendo el elemento canvas

    if (isRebuilding || !chartCanvas) {
      return
    }

    // instanciación del Chart

    const chartInstance = new Chart(chartCanvas, {
      data: {
        datasets: [...renderedLinesData, renderedBarsData],
        labels: [...barsLabels]
      },
      options: {
        ...options
      }
    })

    // cleanup del useEffect
    return () => {
      chartInstance.destroy()
    }
  }, [period, isRebuilding])

  return (
    <div>
      {isRebuilding ? undefined : (
        <canvas ref={chartCanvasRef} height={500} width={300} />
      )}
    </div>
  )
}

export default MonthlyCombinedChart
