// import React from 'react';

export const validate = (element) => {
  let error = [true, ""];

  // Validación de required
  if (element.validation.required) {
    const valid = element.value.toString().trim() !== "";
    const message = `${!valid ? "Este campo es requerido" : ""}`;
    error = !valid ? [valid, message] : error;
  }

  // Validar el largo del texto
  if (element.validation.length) {
    const valid = element.validation.length - element.value.length > 0;
    const message = `${
      !valid
        ? `${element.config.label} debe tener como máximo ${element.validation.length} caracteres`
        : ""
    }`;
    error = !valid ? [valid, message] : error;
  }

  if (element.validation.rut) {
    let valid = false;
    let message = "Ingrese un RUT/RUN válido";
    const dv = validateRut(element.value);
    if (dv !== false) {
      if (
        dv ===
        (element.value.slice(-1).toUpperCase() === "K"
          ? "K"
          : parseInt(element.value.slice(-1)))
      ) {
        valid = true;
        message = "";
      } else {
        valid = false;
      }
    }
    error = !valid ? [valid, message] : error;
  }

  return error;
};

const validateRut = (rut) => {
  // Limpieza de . y -
  // eslint-disable-next-line
  let value = rut.replace(/\./g, "").replace(/\-/g, "");

  // Extracción de dígito cuerpo y verificador
  let body = value.slice(0, -1);

  if (body.length < 7) {
    return false;
  }

  // Invertimos el orden de los factores
  let rutReversed = body.trim().split("").reverse();

  // Multiplicación
  let sum = 0;
  let multiplier = 2;
  for (let i = 0; i < rutReversed.length; i++) {
    sum += parseInt(rutReversed[i]) * multiplier;
    multiplier = multiplier === 7 ? 2 : multiplier + 1;
  }

  // Divide la suma por 11
  let quotient = Math.trunc(sum / 11);
  // Multiplica el cociente por 11
  let product = quotient * 11;
  // A sum se le resta product
  let minus = Math.abs(sum - product);
  // Finalmente se obtiene el dígito verificador
  let checkDigit = 11 - minus < 10 ? 11 - minus : 11 - minus === 11 ? 0 : "K";

  return checkDigit;
};
