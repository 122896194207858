import Axios from '../../axios'
import { taskConstants } from '../types'
import { enqueueSnackbar } from './notification.actions'
import { getExpenses } from '../../helpers/tasks.helper'

/**
 * Crea una tarea de iniciativa
 */
const create = (initiativeId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post('/tasks', { ...data })
        .then((response) => {
          dispatch({
            type: taskConstants.CREATE,
            payload: { initiativeId, data: response.data.task }
          })
          resolve()
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido crear la tarea'
            })
          )
          reject(err.response)
        })
    })
  }
}

/**
 * Actualiza una tarea de iniciativa
 */

const update = (initiativeId, taskId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.patch(`/initiatives/${initiativeId}/tasks/${taskId}`, data)
        .then((response) => {
          dispatch({
            type: taskConstants.UPDATE,
            payload: { initiativeId, taskId, data: response.data.task }
          })
          resolve()
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido actualizar la tarea'
            })
          )
          reject()
        })
    })
  }
}
/**
 * Elimina una tarea
 */

const destroy = (initiativeId, taskId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.delete(`/initiatives/${initiativeId}/tasks/${taskId}`)
        .then((response) => {
          dispatch({
            type: taskConstants.DELETE,
            payload: { initiativeId, taskId }
          })
          resolve()
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido eliminar la tarea'
            })
          )
          reject()
        })
    })
  }
}
/**
 * Permite cambiar el estado de una tarea de iniciativa
 */

const changeStatus = (initiativeId, taskId, newStatus) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      Axios.patch(`/initiatives/${initiativeId}/tasks/${taskId}`, {
        task: { finished: newStatus }
      })
        .then((response) => {
          dispatch({
            type: taskConstants.UPDATE,
            payload: { initiativeId, taskId, data: response.data.task }
          })
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Estado de tarea actualizado'
            })
          )
          // Notifica si se ha pasado del presupuesto
          let initiative = getState().initiatives.initiatives.find(
            (initiative) => initiative.id === response.data.task.initiativeId
          )
          let expenses = initiative.tasks.reduce(getExpenses, 0)
          if (expenses > initiative.budget) {
            dispatch(
              enqueueSnackbar({
                variant: 'error',
                message: 'Tu iniciativa se ha pasado del presupuesto'
              })
            )
          }
          resolve()
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido cambiar el estado de la tarea'
            })
          )
          reject()
        })
    })
  }
}

export const taskActions = {
  create,
  update,
  changeStatus,
  destroy
}
