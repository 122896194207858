import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Text
} from 'recharts'
import { beautifyNumber } from '../../helpers/numbers.helper'

const colors = [
  '#8884d8',
  '#1c418b',
  '#23ba23',
  '#21ccd4',
  '#ff4c00',
  '#ff00cd',
  '#9e5882',
  '#c3d020',
  '#575d00',
  '#febfa1'
]

const VerticalLabel = ({ children }) => {
  return (
    <Text
      x={0}
      y={0}
      dx={-300}
      dy={40}
      textAnchor='start'
      width={180}
      transform='rotate(-90)'
    >
      {children}
    </Text>
  )
}

const Linechart = ({ indicator, dataset, keys }) => {
  // console.log('dataset', dataset, 'keys', keys)
  return (
    <LineChart
      width={900}
      height={500}
      data={dataset}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis
        dataKey='date'
        label={{ value: 'Fecha', position: 'insideBottom', offset: -5 }}
      />
      <YAxis
        type='number'
        domain={[0, 'datamax']}
        // label={{ value: indicator.measure, position: "insideLeft" }}
        label={<VerticalLabel>{indicator.measure}</VerticalLabel>}
        tickMargin={5}
      />
      <Tooltip
        formatter={(value, name, props) =>
          beautifyNumber(value) ? beautifyNumber(value) : 0
        }
        // content={<CustomTooltip />} indicator={indicator}
      />
      <Legend verticalAlign='top' height={36} />
      {indicator.goal && (
        <ReferenceLine
          y={Number(indicator.goal)}
          label={`Meta ${indicator.goal}`}
          stroke='red'
          strokeDasharray='3 3'
          ifOverflow='extendDomain'
        />
      )}
      {keys.map((key, index) => (
        <Line
          key={index}
          type='linear'
          dataKey={key}
          stroke={colors[index]}
          isAnimationActive={false}
          unit={indicator.measure}
        />
      ))}
    </LineChart>
  )
}

export default Linechart
