export const createMotherDataset = (indicator) => {
  // extraemos la data de interés y ordenamos de menor a mayor
  if (indicator.logs.length === 0) {
    return []
  }
  const logs = indicator.logs
  let dataBucket = {
    datasets: [],
    labels: [],
    domain: [],
    measureUnit: indicator.measure,
    cumulativeness: indicator.cumulative
  }
  let years
  let uniqueYears
  const cleanedLogs = logs.map((log) => {
    return {
      date: log.date,
      value: log.value,
      branchId: log.branchId ? log.branchId : null,
      lineId: log.lineId ? log.lineId : null
    }
  })

  const sortedLogs = cleanedLogs.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  )

  // preparamos el rango de fechas en total para este indicador
  let initialDate = sortedLogs[0].date
  let finalDate = sortedLogs[sortedLogs.length - 1].date
  dataBucket.domain[0] = initialDate
  dataBucket.domain[1] = finalDate
  // para indicadores de alcance nivel corporativo
  if (indicator.type === 'GLOBAL') {
    let valuesInCorporate = sortedLogs.map((ele) => {
      return { x: ele.date, y: ele.value }
    })

    // agregamos la propiedad en el objeto a exportar
    dataBucket.datasets = valuesInCorporate
    return dataBucket
  }

  // para indicadores de alcance nivel líneas de negocios o instalaciones
  if (indicator.type === 'BSNS' || indicator.type === 'AGGREGATE') {
    // creamos el objeto a exportar

    // colectamos los años presentes en la data
    years = sortedLogs.map((ele) => ele.date.split('T')[0])

    // filtramos por unique
    uniqueYears = years.filter((item, i, ar) => ar.indexOf(item) === i)

    // extraemos los ids de las líneas de negocios, si existen
    let linesIds = sortedLogs
      .map((ele) => (ele.lineId ? ele.lineId : null))
      .filter((value) => value !== null)

    // filtramos por unique
    let uniqueLinesIds = linesIds
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .sort((a, b) => a - b)

    // extraemos los ids de las instalaciones, si existen
    let branchesIds = sortedLogs
      .map((ele) => (ele.branchId ? ele.branchId : null))
      .filter((value) => value !== null)

    // filtramos por unique
    let uniqueBranchesIds = branchesIds
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .sort((a, b) => a - b)

    // separamos por los logs líneas o instalaciones
    // por líneas
    let logsPerLine = []
    let linesLabels = []
    if (uniqueLinesIds.length > 0) {
      let filteredLineLogs = uniqueLinesIds.map((id) => {
        return sortedLogs.filter((log) => log.lineId === id)
      })
      logsPerLine = filteredLineLogs

      // creamos el array de nombres de las instalaciones de manera ordenada
      uniqueLinesIds.forEach((lnId) => {
        let found = indicator.lines.find((line) => line.id === lnId)
        if (found) {
          linesLabels.push(found.name)
        }
      })
      // agregamos la propiedad en el objeto a exportar
      dataBucket.labels = linesLabels

      let valuesPerLine = logsPerLine.map((ele) => {
        return Object.keys(ele).map((obj) => {
          return { x: ele[obj].date, y: ele[obj].value }
        })
      })

      // agregamos la propiedad en el objeto a exportar
      dataBucket.datasets = valuesPerLine
    }

    // por instalaciones
    let logsPerBranch = []
    let branchesLabels = []

    if (uniqueBranchesIds.length > 0) {
      let filteredBranchLogs = uniqueBranchesIds.map((id) => {
        return sortedLogs.filter((log) => log.branchId === id)
      })

      logsPerBranch = [...filteredBranchLogs]

      // creamos el array de nombres de las instalaciones de manera ordenada
      uniqueBranchesIds.forEach((brId) => {
        let found = indicator.branches.find((branch) => branch.id === brId)
        if (found) {
          branchesLabels.push(found.name)
        }
      })

      dataBucket.labels = branchesLabels

      let valuesPerBranch = logsPerBranch.map((ele) => {
        return Object.keys(ele).map((obj) => {
          return { x: ele[obj].date, y: ele[obj].value }
        })
      })

      // agregamos la propiedad en el objeto a exportar
      dataBucket.datasets = valuesPerBranch
    }

    return dataBucket
  }
}
