export const organizationConstants = {
  SET_INITIAL_STATE: 'ORGANIZATION_SET_INITIAL_STATE',

  FETCH_STARTED: 'ORGANIZATION_FETCH_STARTED',
  FETCH_SUCCESS: 'ORGANIZATION_FETCH_SUCCESS',
  FETCH_FAILURE: 'ORGANIZATION_FETCH_FAILURE',

  CREATE_STARTED: 'ORGANIZATION_CREATE_STARTED',
  CREATE_FAILURE: 'ORGANIZATION_CREATE_FAILURE',
  CREATE_SUCCESS: 'ORGANIZATION_CREATE_SUCCESS',

  CREATE_SET_FORM: 'ORGANIZATION_CREATE_SET_FORM',

  UPDATE_STARTED: 'ORGANIZATION_UPDATE_STARTED',
  UPDATE_SUCCESS: 'ORGANIZATION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ORGANIZATION_UPDATE_FAILURE',

  DELETE_STARTED: 'ORGANIZATION_DELETE_STARTED',
  DELETE_SUCCESS: 'ORGANIZATION_DELETE_SUCCESS',
  DELETE_FAILURE: 'ORGANIZATION_DELETE_FAILURE',

  EMPLOYEES_FETCH_STARTED: 'ORGANIZATION_EMPLOYEES_FETCH_STARTED',
  EMPLOYEES_FETCH_FAILURE: 'ORGANIZATION_EMPLOYEES_FETCH_FAILURE',
  EMPLOYEES_FETCH_SUCCESS: 'ORGANIZATION_EMPLOYEES_FETCH_SUCCESS',

  DIALOG: {
    OPEN: 'ORGANIZATION_DIALOG_OPEN',
    CLOSE: 'ORGANIZATION_DIALOG_CLOSE',
    SET: 'ORGANIZATION_DIALOG_SET',
    CLEAR: 'ORGANIZATION_DIALOG_CLEAR'
  },

  BRANCHES: {
    FETCH: 'ORGANIZATION_BRANCHES_FETCH',
    CREATE: 'ORGANIZATION_BRANCHES_CREATE',
    UPDATE: 'ORGANIZATION_BRANCHES_UPDATE',
    DELETE: 'ORGANIZATION_BRANCHES_DELETE'
  },
  LINES: {
    FETCH: 'ORGANIZATION_LINES_FETCH',
    DELETE: 'ORGANIZATION_LINES_DELETE',
    UPDATE: 'ORGANIZATION_LINES_UPDATE'
  }
}
