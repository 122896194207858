export const newDataIndicatorsParser = (data) => {
  let tempData = {}
  tempData.subperspectiveId = data.subperspectiveId
  tempData.contentId = data.contentId
  tempData.odsId = data.odsId
  tempData.areaId = data.areaId ? data.areaId : null
  tempData.managerId = data.managerId ? data.managerId : null
  tempData.name = data.name
  tempData.description = data.description
  tempData.measure = data.measure
  tempData.frecuency = parseInt(data.frecuency)
  tempData.frecuencyType = data.frecuencyType
  tempData.goal = data.goal
  tempData.type = data.type
  tempData.cumulative = data.cumulative
  tempData.encargados = data.encargados
  tempData.cualitativo = data.cualitativo
  // ojo acá adaptar a la lógica asociada a LDN
  if (data.type !== 'GLOBAL') {
    if (data.branchesIds && data.branchesIds.length > 0) {
      tempData.branches = data.branchesIds
    } else {
      tempData.branches = []
    }
    if (data.linesIds && data.linesIds.length > 0) {
      tempData.lines = data.linesIds
    } else {
      tempData.lines = []
    }
  }

  const dataToSubmit = { indicator: { ...tempData } }
  return dataToSubmit
}
