import Axios from '../../axios'
import { areaConstants } from '../types'
import { uiActions } from './ui.actions'
import { enqueueSnackbar } from './notification.actions'

function fetchAll(organizationId) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.get(`/companies/${organizationId}/areas`)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido cargar las áreas'
          })
        )
      })
  }

  function start() {
    return {
      type: areaConstants.FETCH_STARTED
    }
  }

  function success(data) {
    return {
      type: areaConstants.FETCH_SUCCESS,
      payload: data.areas
    }
  }

  function failure(error) {
    return {
      type: areaConstants.FETCH_FAILURE,
      payload: error
    }
  }
}

/**
 * Crea un área
 */

const create = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post('/areas', { ...data })
        .then((response) => {
          dispatch({ type: areaConstants.CREATE, payload: response.data.area })
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Área creada exitosamente'
            })
          )
          resolve()
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido crear el área'
            })
          )
          reject(err.response)
        })
    })
  }
}

/**
 * Actualiza un área
 */

const update = (areaId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.patch(`/areas/${areaId}`, { ...data })
        .then((response) => {
          dispatch({
            type: areaConstants.UPDATE,
            payload: { areaId, data: response.data.area }
          })
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Área actualizada exitosamente'
            })
          )
          resolve()
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido actualizar el área'
            })
          )
          reject()
        })
    })
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.delete(`/areas/${id}`)
      .then((response) => {
        dispatch(success(id))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Área eliminada exitosamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido eliminar el área'
          })
        )
      })
  }

  function start() {
    return {
      type: areaConstants.DELETE_STARTED
    }
  }

  function success(id) {
    return {
      type: areaConstants.DELETE_SUCCESS,
      payload: {
        id
      }
    }
  }

  function failure(error) {
    return {
      type: areaConstants.DELETE_FAILURE,
      payload: {
        error
      }
    }
  }
}

export const areaActions = {
  fetchAll,
  create,
  update,
  _delete
}
