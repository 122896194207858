import { reportConstants } from '../types'

const initialGlobalReportDialog = {
  open: false,
  create: false,
  currentTab: 0,
  report: {}
}

const initialState = {
  reports: [],
  globalReportDialog: initialGlobalReportDialog
}

export default function (state = initialState, action) {
  switch (action.type) {
    case reportConstants.FETCH:
      return { ...state, reports: action.payload }
    case reportConstants.CREATE:
      return { ...state, reports: [action.payload, ...state.reports] }
    case reportConstants.UPDATE:
      return {
        ...state,
        reports: state.reports.map((report) => {
          if (report.id !== action.payload.reportId) {
            return report
          }
          return {
            ...report,
            ...action.payload.data
          }
        })
      }
    case reportConstants.DELETE:
      return {
        ...state,
        reports: state.reports.filter(
          (report) => report.id !== action.payload.reportId
        )
      }
    case reportConstants.GLOBAL_REPORT_DIALOG.OPEN:
      return {
        ...state,
        globalReportDialog: {
          ...state.globalReportDialog,
          open: true,
          create: action.payload.create,
          currentTab: action.payload.currentTab ? action.payload.currentTab : 0,
          report: action.payload.report
        }
      }
    case reportConstants.GLOBAL_REPORT_DIALOG.CLOSE:
      const reportsAfterClose = state.globalReportDialog.create
        ? state.globalReportDialog.report?.id
          ? [...state.reports, state.globalReportDialog.report]
          : state.reports
        : state.reports.map((report) => {
            if (report.id !== state.globalReportDialog.report?.id) {
              return report
            }
            return {
              ...state.globalReportDialog.report
            }
          })
      return {
        ...state,
        globalReportDialog: initialGlobalReportDialog,
        reports: reportsAfterClose
      }
    case reportConstants.GLOBAL_REPORT_DIALOG.CHANGE_TAB:
      return {
        ...state,
        globalReportDialog: {
          ...state.globalReportDialog,
          currentTab: action.payload
        }
      }
    case reportConstants.GLOBAL_REPORT_DIALOG.CREATE:
      return {
        ...state,
        globalReportDialog: {
          ...state.globalReportDialog,
          report: action.payload
        }
      }
    case reportConstants.GLOBAL_REPORT_DIALOG.UPDATE:
      return {
        ...state,
        globalReportDialog: {
          ...state.globalReportDialog,
          report: { ...state.globalReportDialog.report, ...action.payload.data }
        }
      }
    case reportConstants.GLOBAL_REPORT_DIALOG.SAVE:
      return {
        ...state,
        globalReportDialog: {
          ...state.globalReportDialog,
          report: {
            ...state.globalReportDialog.report,
            snapshots: [
              action.payload.data,
              ...state.globalReportDialog.report.snapshots
            ]
          }
        }
      }
    case reportConstants.GLOBAL_REPORT_DIALOG.DELETE_SNAPSHOT:
      return {
        ...state,
        globalReportDialog: {
          ...state.globalReportDialog,
          report: {
            ...state.globalReportDialog.report,
            snapshots: state.globalReportDialog.report.snapshots.filter(
              (snapshot) => snapshot.id !== action.payload.snapshotId
            )
            //   [
            //   action.payload.data,
            //   ...state.globalReportDialog.report.snapshots
            // ]
          }
        }
      }
    default:
      return state
  }
}
