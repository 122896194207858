import { useEffect } from "react";

export const useStorageListener = callback => {
  useEffect(() => {
    window.addEventListener("storage", callback);
    return () => {
      window.removeEventListener("storage", callback);
    };
  }, [callback]);
};
