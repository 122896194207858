import { socialConstants, organizationConstants } from '../types'

// const newOrganization = {
//   name: "",
//   country: "",
//   direction: "",
//   domain: "",
//   employees: "",
//   sector: "",
//   industry: "",
//   industryCategory: "",
//   rut: ""
// };

const defaultOrganizationDialog = {
  open: false,
  valid: false,
  loading: false,
  error: null,
  data: {
    id: null,
    name: '',
    country: '',
    direction: '',
    domain: '',
    employees: '',
    sectorId: '',
    industry: {},
    industryCategoryId: '',
    industryId: '',
    rut: '',
    logo: '',
    city: '',
    income: '',
    incomeText: '',
    web: ''
  }
}

const initialState = {
  loading: false,
  error: null,
  organization: null,
  organizationDialog: defaultOrganizationDialog
}

export default function (state = initialState, action) {
  switch (action.type) {
    /*--- SET initial state ---*/
    case organizationConstants.SET_INITIAL_STATE:
      return initialState
    /*--- SET initial state ---*/

    /*--- CREATE ---*/
    case organizationConstants.CREATE_STARTED:
      return {
        ...state,
        organizationDialog: {
          ...state.organizationDialog,
          loading: true,
          error: null
        }
      }
    case organizationConstants.CREATE_SUCCESS:
      return {
        ...state,
        organizationDialog: defaultOrganizationDialog,
        organization: action.payload
      }
    case organizationConstants.CREATE_FAILURE:
      return {
        ...state,
        organizationDialog: {
          ...state.organizationDialog,
          loading: false,
          error: action.payload
        }
      }
    /*--- CREATE ---*/

    /*--- FETCH ---*/
    case organizationConstants.FETCH_STARTED:
      return { ...state, loading: true, error: null }
    case organizationConstants.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        organization: {
          ...action.payload,
          branches: action.payload.branches.map((branch) => ({
            ...branch,
            label: branch.name,
            value: branch.id
          }))
        }
      }
    case organizationConstants.FETCH_FAILURE:
      return { ...state, loading: false, error: action.payload }
    /*--- FETCH ---*/

    /*--- UPDATE ---*/
    case organizationConstants.UPDATE_STARTED:
      return {
        ...state,
        organizationDialog: {
          ...state.organizationDialog,
          loading: true,
          error: null
        }
      }
    case organizationConstants.UPDATE_SUCCESS:
      return {
        ...state,
        organization: action.payload.organization,
        organizationDialog: defaultOrganizationDialog
      }
    case organizationConstants.UPDATE_FAILURE:
      return {
        ...state,
        organizationDialog: {
          ...state.organizationDialog,
          loading: false,
          error: action.payload
        }
      }
    /*--- UPDATE ---*/

    /*--- DIALOG ---*/
    case organizationConstants.DIALOG.OPEN:
      return {
        ...state,
        organizationDialog: {
          ...state.organizationDialog,
          open: true,
          data: action.payload
            ? {
                ...state.organizationDialog.data,
                ...action.payload.data,
                sectorId:
                  action.payload.data.industry.industryCategory.sector.id,
                industryCategoryId:
                  action.payload.data.industry.industryCategory.id,
                industryId: action.payload.data.industry.id
              }
            : { ...defaultOrganizationDialog.data }
        }
      }
    case organizationConstants.DIALOG.CLOSE:
      return { ...state, organizationDialog: defaultOrganizationDialog }
    case organizationConstants.DIALOG.SET:
      return {
        ...state,
        organizationDialog: {
          ...state.organizationDialog,
          valid:
            action.payload.valid !== undefined
              ? action.payload.valid
              : state.organizationDialog.valid,
          data: action.payload.data
            ? { ...state.organizationDialog.data, ...action.payload.data }
            : state.organizationDialog.data
        }
      }
    case organizationConstants.DIALOG.CLEAR:
      return {
        ...state,
        organizationDialog: {
          ...defaultOrganizationDialog,
          open: true
        }
      }
    /*--- DIALOG ---*/

    /*--- BRANCHES ---*/
    case organizationConstants.BRANCHES.FETCH:
      return {
        ...state,
        organization: {
          ...state.organization,
          branches: action.payload.branches
        }
      }
    case organizationConstants.BRANCHES.CREATE:
      return {
        ...state,
        organization: {
          ...state.organization,
          branches: [
            ...state.organization.branches,
            {
              ...action.payload.branch,
              label: action.payload.branch.name,
              value: action.payload.branch.id
            }
          ]
        }
      }
    case organizationConstants.BRANCHES.DELETE:
      return {
        ...state,
        organization: {
          ...state.organization,
          branches: state.organization.branches.filter(
            (branch) => branch.id !== action.payload.branchId
          )
        }
      }
    case organizationConstants.BRANCHES.UPDATE:
      return {
        ...state,
        organization: {
          ...state.organization,
          branches: state.organization.branches.map((branch) => {
            if (branch.id !== action.payload.branch.id) {
              return branch
            }
            return action.payload.branch
          })
        }
      }
    /*--- BRANCHES ---*/
    /*--- LINES ---*/
    case organizationConstants.LINES.FETCH:
      return {
        ...state,
        organization: {
          ...state.organization,
          lines: action.payload.lines
        }
      }

    case organizationConstants.LINES.UPDATE:
      return {
        ...state,
        organization: {
          ...state.organization,
          lines: state.organization.lines.map((line) => {
            if (line.id !== action.payload.line.id) {
              return line
            }
            return action.payload.line
          })
        }
      }

    case organizationConstants.LINES.DELETE:
      return {
        ...state,
        organization: {
          ...state.organization,
          lines: state.organization.lines.filter(
            (line) => line.id !== action.payload.lineId
          )
        }
      }
    /*--- LINES ---*/

    /*--- Hashtags ---*/
    case socialConstants.PROFILE.SOCIAL_PROFILE_FOLLOW_HASHTAG:
      return {
        ...state,
        organization: {
          ...state.organization,
          hashtags: [...state.organization.hashtags, action.payload]
        }
      }
    case socialConstants.PROFILE.SOCIAL_PROFILE_UNFOLLOW_HASHTAG:
      return {
        ...state,
        organization: {
          ...state.organization,
          hashtags: state.organization.hashtags.filter(
            (hashtag) => hashtag.id !== action.payload.id
          )
        }
      }
    /*--- Hashtags ---*/
    default:
      return state
  }
}
