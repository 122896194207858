import React, { useState, useEffect , Fragment} from 'react'
import IconButton from '@mui/material/IconButton';
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import MUIDataTable, {ExpandButton} from "mui-datatables";
import { InputLabel, FormControl, Select,Typography, Button,Grid, Container, useTheme } from '@material-ui/core'
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SvgIcon from '@mui/material/SvgIcon';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ThemeProvider } from "@mui/styles";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import ButtonWithLoading from '../../components/UI/ButtonWithLoading'

// Users/anibalurrea/Desktop/Inosoft/Tero/tero-frontend-master/src/components/UI/ButtonWithLoading

import { Bar, Line, Chart, Bubble } from 'react-chartjs-2'
import BarChartMemo from './BarChartMemo'
import BarChartCorpMemo from './BarChartCorpMemo'
import NotCumulativeChart from './NotCumulativeChart'
import MonthlyCombinedChart from './MonthlyCombinedChart'
import ExtendedChart from './ExtendedChart'
import MouseIcon from '@material-ui/icons/Mouse'
import { createMotherDataset } from '../../helpers/logs-calculations4'
import CustomDialog from '../../components/UI/Dialog'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  title: {
    maxWidth: '160px',
    fontSize: 14,
    color: '#939191',
    fontWeight: 'bold',
    paddingRight: 4,
    paddingTop: 8
  },
  titleLink: {
    fontSize: 14,
    color: '#939191',
    fontWeight: 'bold',
    paddingLeft: 3
  },
  content: {
    fontSize: 14,
    fontWeight: 'lighter',
    color: '#6E6E6E'
  },
  managername: {
    fontSize: 12,
    fontWeight: 'lighter',
    color: '#6E6E6E'
  },
  indicatordescript: {
    fontSize: 13,
    fontWeight: 'lighter',
    fontStyle: 'italic',
    color: '#6E6E6E'
  },
  contentLink: {
    fontSize: 14,
    fontWeight: 'lighter',
    color: '#6E6E6E',
    padding: 8
  },
  contentBlack: {
    fontSize: 14,
    fontWeight: 'lighter',
    color: '#363535'
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  paper: {
    padding: theme.spacing(0, 1),
    minWidth: '100%'
  },
  divider: {
    width: theme.spacing(0.1),
    color: '#E8E9EC',
    backgroundColor: theme.palette.secondary.main
  },
  actionIcon: {
    fontSize: 22
  },
  showMoreButton: {
    margin: theme.spacing(1),
    width: '100%'
  },
  topContent: {
    display: 'flex',
    flexAlign: 'center',
    position: 'relative',
    minHeight: 120,
    padding: theme.spacing(1)
  },
  indicatorTypeWrapper: {
    // background: theme.palette.primary.main,
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 70
    // flex: 1
    // position: 'absolute',
    // top: 0,
    // left: 0
  },
  indicatorType: {
    color: '#fff'
  },
  typeIcon: {
    color: theme.palette.secondary.main,
    fontSize: 38
  },
  typeLabel: {
    fontSize: 14,
    fontWeight: 'lighter',
    color: theme.palette.secondary.main
  },
  topContentDivider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  indicatorManagerContainer: {
    display: 'flex',
    alignItems: 'center',
    color: '#939191'
  },
  topContentData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1
  },
  menuWrapper: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  contentWrapper: {
    position: 'relative',
    width: '100%'
  },
  
  logValue: {
    fontSize: 13,
    fontFamily: 'Helvetica',
    color: '#808080'
  },
  card: {},
  titleContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  viewWrapper: {
    border: "1px solid black",
      width: 100,
      height: 80,
      borderRadius: 8
  },
  titleShowMore: {
    bottom: 0,
    right: 25,
    position: 'absolute'
    // backgroundColor: '#fff'
  },
  editOrRemove: {
    fontSize: 18,
    paddingLeft: 3,
    marginLeft: 3
  }
}));

const findThePeriod = (frequencyType) => {
  console.log(frequencyType);
  switch (frequencyType) {
    case 'year':
      return 'yearly'
    case 'week':
      return 'weekly'
    case 'month':
      
      return 'monthly'
    case 'week':
      return 'weekly'
    case 'day':
      return 'daily'
    default:
      return 'yearly'
  }
};
var arre = [];
var peri = [];

var myArray1 = [
  {name:"Sucursal Las Delias",  date: "2017-01-01",valo: "2", branchId:"14"},
  {name:"Sucursal Las Delias",  date: "2017-01-02",valo: "3", branchId:"14"},
  {name:"Sucursal Las Delias",  date: "2017-01-02",valo: "5", branchId:"14"},
  {name:"Sucursal Las Delias",  date: "2017-02-04",valo: "7", branchId:"7"},
  {name:"Sucursal Las Delias",  date: "2017-02-04",valo: "6", branchId:"14"},
  {name:"Sucursal Las Delias",  date: "2017-01-02",valo: "5", branchId:"14"},
  {name:"Sucursal Las Delias",  date: "2017-02-04",valo: "7", branchId:"7"},
  {name:"Sucursal Las Delias",  date: "2017-02-04",valo: "6", branchId:"14"},
  {name:"Sucursal Las Delias",  date: "2017-01-02",valo: "5", branchId:"14"},
  {name:"Sucursal Las Delias",  date: "2017-02-04",valo: "7", branchId:"7"},
  {name:"Sucursal Las Delias",  date: "2017-02-04",valo: "6", branchId:"14"},
  {name:"Sucursal Las Delias",  date: "2017-01-02", valo:"5", branchId:"14"},
  {name:"Sucursal Las Delias",  date: "2017-03-04",valo: "2", branchId:"7"},
  {name:"Sucursal Las Delias",  date: "2017-03-04",valo: "6", branchId:"14"},
  {name:"Sucursal Las Delias",  date: "2017-03-05", valo:"15", branchId:"14"},
  {name:"Sucursal Las Delias",  date: "2017-05-04",valo: "7", branchId:"7"},
  {name:"Sucursal Las Delias",  date: "2017-05-04",valo: "6", branchId:"14"},
  {name:"Sucursal Las Delias",  date: "2017-07-02", valo:"5", branchId:"14"},
  {name:"Sucursal Las Delias",  date: "2017-07-04",valo: "7", branchId:"7"},
  {name:"Sucursal Las Delias",  date: "2017-08-04", valo: "6", branchId:"14"},
  {name:"Sucursal Las Delias",  date: "2017-08-05", valo:"15", branchId:"14"}
],groupKey = 0;

var myAr1=
{
  id: 364,
  name: "Empleo local",
  measure: "Trabajadores",
  description: "personas",
  formula: null,
  frecuency: 6,
  frecuencyType: "month",
  cumulative: false,
  goal: "",
  type: "AGGREGATE",
  createdAt: "2021-10-14T13:50:15.84+00:00",
  updatedAt: "2021-10-14T13:50:15.942+00:00",
  companyId: 104,
  contentId: 7,
  subperspectiveId: 3,
  managerId: 146,
  odsId: 11,
  quantitative: true,
  manager: {
      name: "Colbun",
      lastName: "S.A",
      id: 146
    },
  subperspective: {
      id: 3,
      name: "203: Impactos económicos indirectos",
      perspective: {
          id: 1,
          name: "GRI 200: Económicos"
        }
    },
  content: {
      id: 7,
      name: "203-1 Inversiones en infraestructuras y servicios apoyados",
      subperspectiveId: 3,
      createdAt: "2021-07-23T03:57:30.582+00:00",
      updatedAt: "2021-07-23T03:57:30.582+00:00"
    },
  ods: {
      fullName: "#11 ODS 11: Ciudades y comunidades sostenibles",
      id: 11,
      name: "ODS 11: Ciudades y comunidades sostenibles",
      number: 11,
      createdAt: "2019-06-03T05:16:44.101+00:00",
      updatedAt: "2019-06-03T05:16:44.101+00:00"
    },
  areas: [
        {
          id: 153,
          name: "Operaciones",
          description: "descripcion",
          createdAt: "2021-09-09T20:11:13.332+00:00",
          updatedAt: "2021-09-09T20:11:13.332+00:00",
          managerId: 178
        }
    ],
  branches: [{
    id: 221,
    name: "Avenida Apoquindo 4775"
  },
  {
  id: 220,
    name: "Avenida Apoquindo 4757"
  }
],
  lines: [],
  company: {
      incomeText: "Más de 800.000 UF.",
      id: 104,
      name: "Colbun",
      employees: 1000,
      domain: "colbun.cl",
      web: "http://www.colbun.cl",
      rut: "965057609",
      country: "Chile",
      city: "Región Metropolitana de Santiago",
      purpose: null,
      direction: "Avenida Apoquindo 4775",
      logo: "https://teroapp-uploads.s3.amazonaws.com/fd2610f9-8b3b-4a16-9942-0c94e08905b8.jpeg",
      income: "T8",
      incomeCurrency: "UF",
      createdAt: "2021-07-23T16:12:36.411+00:00",
      updatedAt: "2021-10-26T01:41:17.361+00:00",
      industryId: 248
    },
  logs: [
    {
      id: 5518,
      value: 2,
      date: "2019-06-01T00:00:00+00:00",
      isGoal: false,
      indicatorId: 364,
      userId: 112,
      comments: null,
      createdAt: "2021-10-14T14:00:22.265+00:00",
      updatedAt: "2021-10-14T14:00:22.265+00:00",
      branch: {
        id: 218,
        name: "Avenida Apoquindo 4775"
      },
      lineId: null,
      manager: {
        id: 112,
        roleAdmin: true,
        roleLeader: true,
        roleUser: true,
        roleManageIndicators: true,
        roleManageInitiatives: true
      }
    }, {
      id: 5519,
      value: 0,
      date: "2019-12-01T00:00:00+00:00",
      isGoal: false,
      indicatorId: 364,
      userId: 112,
      comments: null,
      createdAt: "2021-10-14T14:00:22.345+00:00",
      updatedAt: "2021-10-14T14:00:22.345+00:00",
      branch: {
        id: 218,
        name: "Avenida Apoquindo 4775"
      },
      lineId: null,
      manager: {
        id: 112,
        roleAdmin: true,
        roleLeader: true,
        roleUser: true,
        roleManageIndicators: true,
        roleManageInitiatives: true
      }
    }, {
      id: 5520,
      value: 0,
      date: "2020-06-01T00:00:00+00:00",
      isGoal: false,
      indicatorId: 364,
      userId: 112,
      comments: null,
      createdAt: "2021-10-14T14:00:22.406+00:00",
      updatedAt: "2021-10-14T14:00:22.406+00:00",
      branch: {
        id: 218,
        name: "Avenida Apoquindo 4775"
      },
      lineId: null,
      manager: {
        id: 112,
        roleAdmin: true,
        roleLeader: true,
        roleUser: true,
        roleManageIndicators: true,
        roleManageInitiatives: true
      }
    }, {
      id: 5521,
      value: 0,
      date: "2020-12-01T00:00:00+00:00",
      isGoal: false,
      indicatorId: 364,
      userId: 112,
      comments: null,
      createdAt: "2021-10-14T14:00:22.514+00:00",
      updatedAt: "2021-10-14T14:00:22.514+00:00",
      branch: {
        id: 218,
        name: "Avenida Apoquindo 4775"
      },
      lineId: null,
      manager: {
        id: 112,
        roleAdmin: true,
        roleLeader: true,
        roleUser: true,
        roleManageIndicators: true,
        roleManageInitiatives: true
      }
    }, {
      id: 5522,
      value: 4,
      date: "2021-06-01T00:00:00+00:00",
      isGoal: false,
      indicatorId: 364,
      userId: 112,
      comments: null,
      createdAt: "2021-10-14T14:00:22.663+00:00",
      updatedAt: "2021-10-14T14:00:22.663+00:00",
      branch: {
        id: 218,
        name: "Avenida Apoquindo 4775"
      },
      lineId: null,
      manager: {
        id: 112,
        roleAdmin: true,
        roleLeader: true,
        roleUser: true,
        roleManageIndicators: true,
        roleManageInitiatives: true
      }
    }, {
      id: 5523,
      value: 4,
      date: "2019-06-01T00:00:00+00:00",
      isGoal: false,
      indicatorId: 364,
      userId: 112,
      comments: null,
      createdAt: "2021-10-14T14:00:22.735+00:00",
      updatedAt: "2021-10-14T14:00:22.735+00:00",
      branch: {
        id: 219,
        name: "Avenida Apoquindo 4757"
      },
      lineId: null,
      manager: {
        id: 112,
        roleAdmin: true,
        roleLeader: true,
        roleUser: true,
        roleManageIndicators: true,
        roleManageInitiatives: true
      }
    }, {
      id: 5525,
      value: 6,
      date: "2020-06-01T00:00:00+00:00",
      isGoal: false,
      indicatorId: 364,
      userId: 112,
      comments: null,
      createdAt: "2021-10-14T14:00:22.882+00:00",
      updatedAt: "2021-10-14T14:00:22.882+00:00",
      branch: {
        id: 219,
        name: "Avenida Apoquindo 4757"
      },
      lineId: null,
      manager: {
        id: 112,
        roleAdmin: true,
        roleLeader: true,
        roleUser: true,
        roleManageIndicators: true,
        roleManageInitiatives: true
      }
    }, {
      id: 5526,
      value: 6,
      date: "2020-12-01T00:00:00+00:00",
      isGoal: false,
      indicatorId: 364,
      userId: 112,
      comments: null,
      createdAt: "2021-10-14T14:00:22.96+00:00",
      updatedAt: "2021-10-14T14:00:22.96+00:00",
      branch: {
        id: 219,
        name: "Avenida Apoquindo 4757"
      },
      lineId: null,
      manager: {
        id: 112,
        roleAdmin: true,
        roleLeader: true,
        roleUser: true,
        roleManageIndicators: true,
        roleManageInitiatives: true
      }
    }, {
      id: 5527,
      value: 3,
      date: "2021-06-01T00:00:00+00:00",
      isGoal: false,
      indicatorId: 364,
      userId: 112,
      comments: null,
      createdAt: "2021-10-14T14:00:23.044+00:00",
      updatedAt: "2021-10-14T14:00:23.044+00:00",
      branch: {
        id: 219,
        name: "Avenida Apoquindo 4757"
      },
      lineId: null,
      manager: {
        id: 112,
        roleAdmin: true,
        roleLeader: true,
        roleUser: true,
        roleManageIndicators: true,
        roleManageInitiatives: true
      }
    }, {
      id: 5528,
      value: 7,
      date: "2019-06-01T00:00:00+00:00",
      isGoal: false,
      indicatorId: 364,
      userId: 112,
      comments: null,
      createdAt: "2021-10-14T14:00:23.182+00:00",
      updatedAt: "2021-10-14T14:00:23.182+00:00",
      branch: {
        id: 220,
        name: "Avenida Apoquindo 7457"
      },
      lineId: null,
      manager: {
        id: 112,
        roleAdmin: true,
        roleLeader: true,
        roleUser: true,
        roleManageIndicators: true,
        roleManageInitiatives: true
      }
    }, {
      id: 5530,
      value: 2,
      date: "2020-06-01T00:00:00+00:00",
      isGoal: false,
      indicatorId: 364,
      userId: 112,
      comments: null,
      createdAt: "2021-10-14T14:00:23.493+00:00",
      updatedAt: "2021-10-14T14:00:23.493+00:00",
      branch: {
        id: 220,
        name: "Avenida Apoquindo 7457"
      },
      lineId: null,
      manager: {
        id: 112,
        roleAdmin: true,
        roleLeader: true,
        roleUser: true,
        roleManageIndicators: true,
        roleManageInitiatives: true
      }
    }, {
      id: 5531,
      value: 1,
      date: "2020-12-01T00:00:00+00:00",
      isGoal: false,
      indicatorId: 364,
      userId: 112,
      comments: null,
      createdAt: "2021-10-14T14:00:23.586+00:00",
      updatedAt: "2021-10-14T14:00:23.586+00:00",
      branch: {
        id: 220,
        name: "Avenida Apoquindo 7457"
      },
      lineId: null,
      manager: {
        id: 112,
        roleAdmin: true,
        roleLeader: true,
        roleUser: true,
        roleManageIndicators: true,
        roleManageInitiatives: true
      }
    }, {
      id: 5532,
      value: 2,
      date: "2021-06-01T00:00:00+00:00",
      isGoal: false,
      indicatorId: 364,
      userId: 112,
      comments: null,
      createdAt: "2021-10-14T14:00:23.684+00:00",
      updatedAt: "2021-10-14T14:00:23.684+00:00",
      branch: {
        id: 220,
        name: "Avenida Apoquindo 7457"
      },
      lineId: null,
      manager: {
        id: 112,
        roleAdmin: true,
        roleLeader: true,
        roleUser: true,
        roleManageIndicators: true,
        roleManageInitiatives: true
      }
    }, {
      id: 5533,
      value: 8,
      date: "2019-06-01T00:00:00+00:00",
      isGoal: false,
      indicatorId: 364,
      userId: 112,
      comments: null,
      createdAt: "2021-10-14T14:00:23.786+00:00",
      updatedAt: "2021-10-14T14:00:23.786+00:00",
      branch: {
        id: 221,
        name: "Avenida Apoquindo 1457"
      },
      lineId: null,
      manager: {
        id: 112,
        roleAdmin: true,
        roleLeader: true,
        roleUser: true,
        roleManageIndicators: true,
        roleManageInitiatives: true
      }
    }, {
      id: 5636,
      value: 2000,
      date: "2021-10-21T14:06:00+00:00",
      isGoal: false,
      indicatorId: 364,
      userId: 146,
      comments: "",
      createdAt: "2021-10-21T14:06:56.275+00:00",
      updatedAt: "2021-10-21T14:06:56.275+00:00",
      branch: {
        id: 221,
        name: "Avenida Apoquindo 1457"
      },
      lineId: null,
      manager: {
        id: 146,
        roleAdmin: false,
        roleLeader: true,
        roleUser: true,
        roleManageIndicators: true,
        roleManageInitiatives: true
      }
    } ],
  logs_cuali: [],
  subc: [
        {
          id: 9,
          name: "test5",
          indicatorId: 364,
          createdAt: "2021-12-07T13:19:32.542+00:00",
          updatedAt: "2021-12-07T13:19:32.542+00:00",
          logs: [
                {
                  id: 19,
                  value: 800,
                  date: "2017-01-05T03:00:00+00:00",
                  isGoal: null,
                  indicatorId: null,
                  userId: 146,
                  comments: null,
                  createdAt: "2022-01-05T15:51:51.848+00:00",
                  updatedAt: "2022-01-05T15:51:51.848+00:00",
                  branchId: 221,
                  lineId: null
                },
                {
                  id: 22,
                  value: 150,
                  date: "2017-01-05T18:33:18.477+00:00",
                  isGoal: null,
                  indicatorId: null,
                  userId: 146,
                  comments: null,
                  createdAt: "2022-01-05T18:33:32.601+00:00",
                  updatedAt: "2022-01-05T18:33:32.601+00:00",
                  branchId: 221,
                  lineId: null
                },
                {
                  id: 39,
                  value: 455,
                  date: "2017-01-06T20:03:10.153+00:00",
                  isGoal: null,
                  indicatorId: null,
                  userId: 146,
                  comments: null,
                  createdAt: "2022-01-06T20:03:34.152+00:00",
                  updatedAt: "2022-01-06T20:03:34.152+00:00",
                  branchId: 220,
                  lineId: null
                }
            ]
        }
    ],
  permission: 2
};
function YourCustomRowComponent(props) {
  const { data } = props;
  
  return (
    
    <TableRow>
      <TableCell>
        <IconButton>
          <KeyboardArrowRightIcon />
        </IconButton>
      </TableCell>
      {data.map(function (i) {
        return <TableCell>
        
         {i}
        
      </TableCell>;
      })}
    </TableRow>
  );
}

const LogsView = ({ indicator, organization }) => {

  console.log(indicator)

  const classes = useStyles();
  const data = [
    ["Sucursal Las Delias", 55, 200, 30],
    ["Planta Coquimbo", 0, 5,  55],
   
  ];
  
  // alcance de la visualización -- determinada por el alcance del indicador --- no es seleccionable
  const [myAr, setMyAr] = useState(indicator);
  const [list, setList] = useState();
  const [list_columns, setList_columns] = useState();
  const [list_subc, setList_subc] = useState();
  var list_subc_full;
  var full_list;
  function list_data(pers){    

    console.log("init")

    console.log(pers)
    
    setcolumns(list_cols(pers));    
    
    setList_subc(list_subc_func(pers));
    
    var temp_list;

    full_list = myAr.logs.reduce(function (r, o) {
      var m = o.date.split(('-'))[1];
      var y = o.date.split(('-'))[0];
      
      if (pers === 'yearly') {
        peri = y;
      }
      if (pers === 'semestral') {
        peri = Math.floor((m*1 + 2) / 6);
      }
      if (pers === 'trimestral') {
        peri = Math.floor((m*1 + 2) / 3);
      }
      if (pers === 'monthly') {
        peri = m;
      }
      if(indicator.type == "BSNS"){
        if((r[o.line.id])){
          if(r[o.line.id][peri+"-"+y] ){
            r[o.line.id][peri+"-"+y] =  o.value;
          }else{
            r[o.line.id][peri+"-"+y] =  o.value;  
          }
        }else{
          r[o.line.id] = [];
          r[o.line.id][0] = o.line.name;
          r[o.line.id][1] = o.line.id;
          r[o.line.id][peri+"-"+y] =  o.value;
        }
      } else if(indicator.type == "AGGREGATE") {
        if((r[o.branch.id])){
          if(r[o.branch.id][peri+"-"+y] ){
            r[o.branch.id][peri+"-"+y] =  o.value;
          }else{
            r[o.branch.id][peri+"-"+y] =  o.value;  
          }
        }else{
          r[o.branch.id] = [];
          r[o.branch.id][0] = o.branch.name;
          r[o.branch.id][1] = o.branch.id;
          r[o.branch.id][peri+"-"+y] =  o.value;
        }
      }else if(indicator.type == "GLOBAL") {
        if((r[o.branch.id])){
          if(r[o.branch.id][peri+"-"+y] ){
            r[o.branch.id][peri+"-"+y] =  o.value;
          }else{
            r[o.branch.id][peri+"-"+y] =  o.value;  
          }
        }else{
          r[o.branch.id] = [];
          r[o.branch.id][0] = "Nivel Corporativo";
          r[o.branch.id][1] = 0;
          r[o.branch.id][peri+"-"+y] =  o.value;

        }

      }      
      return r;
    }, {});
    
    
    temp_list = Object.keys(full_list).map(function(k){ return full_list[k]; });
    var compactArray = [];
    for (var i in temp_list) {
      var compactArray2 = [];
        for (var n in temp_list[i]) {
          compactArray2.push(temp_list[i][n]);
      }
      temp_list[i] = compactArray2;
      compactArray.push(temp_list[i]);
    }
    // console.log(compactArray)
    setList(compactArray);
  }

  function list_subc_func(pers){
    var temp_list;
    list_subc_full = myAr.subc.reduce(function (r, u) {
      console.log(r)
      console.log(u)
      if(u.logs!=undefined){
      
      temp_list = u.logs.reduce(function (data, o) {
        
        var m = o.date.split(('-'))[1];
        var y = o.date.split(('-'))[0];
        if (pers === 'yearly') {
          peri = y;
        }
        if (pers === 'semestral') {
          peri = Math.floor((m*1 + 2) / 6);
        }
        if (pers === 'trimestral') {
          peri = Math.floor((m*1 + 2) / 3);
        }
        if (pers === 'monthly') {
          peri = m;
        }

        if(indicator.type == "BSNS"){

          if((r[o.lineId+"-"+u.id])){
            if(r[o.lineId+"-"+u.id][peri+"-"+y] ){
              r[o.lineId+"-"+u.id][2] =  o.value;  
            }
          }else{
            r[o.lineId+"-"+u.id] = [];
            r[o.lineId+"-"+u.id][0] = o.lineId+"-"+u.id;
            r[o.lineId+"-"+u.id][1] =  peri+"-"+y;
            r[o.lineId+"-"+u.id][2] =  o.value;
            r[o.lineId+"-"+u.id][3] =  o;
            r[o.lineId+"-"+u.id][4] =  u.name;
            r[o.lineId+"-"+u.id][5] =  o.lineId;
            r[o.lineId+"-"+u.id][6] =  u.id;
          }

        // } else if(indicator.type == "AGGREGATE") {
        } else if(indicator.type == "AGGREGATE") {

          if((r[o.branchId+"-"+u.id])){
            if(r[o.branchId+"-"+u.id][peri+"-"+y] ){
              r[o.branchId+"-"+u.id][2] =  o.value;  
            }
          }else{
            r[o.branchId+"-"+u.id] = [];
            r[o.branchId+"-"+u.id][0] = o.branchId+"-"+u.id;
            r[o.branchId+"-"+u.id][1] =  peri+"-"+y;
            r[o.branchId+"-"+u.id][2] =  o.value;
            r[o.branchId+"-"+u.id][3] =  o;
            r[o.branchId+"-"+u.id][4] =  u.name;
            r[o.branchId+"-"+u.id][5] =  o.branchId;
            r[o.branchId+"-"+u.id][6] =  u.id;
          }
        }        

        return data;
      }, {});
    }
      return r;
    }, {});
    // console.log("compactArray_subc"); 
    var compactArray_subc = [];
    for (var i in list_subc_full) { 
      compactArray_subc.push(list_subc_full[i]);
    }
    console.log(compactArray_subc);
    return compactArray_subc;
  }

  function list_cols(pers){
    var list_cols;
    list_cols = myAr.logs.reduce(function (r, o) {
      // console.log(r)
      // console.log(o)
      var m = o.date.split(('-'))[1];
      var y = o.date.split(('-'))[0];

      if (pers === 'yearly') {
        peri = y;
      }
      if (pers === 'semestral') {
        peri = Math.floor((m*1 + 2) / 6);
      }
      if (pers === 'trimestral') {
        peri = Math.floor((m*1 + 2) / 3);
      }
      if (pers === 'monthly') {
        peri = m;
      }

    
      r[peri+"-"+y ]= {
        name: peri + " trimestral del "+y,
        code: peri+"-"+y,
        options: {
          filter: true,
        }};
      if( r[peri+"-"+y])
      return r;
    }, []); 
    
  
    list_cols [0] = 
    {
      name: indicator.type == "AGGREGATE" ? "Instalaciones" : indicator.type == "BSNS" ? "Linea de Negocio" : "Corporativo",
        options: {
          filter: true,
      }
    };
    list_cols [1] = 
    {
      name: "Code",
        options: {
          filter: true,
      }
    };
    var compactArray = [];
    for (var i in list_cols) {
        compactArray.push(list_cols[i]);
    }
    return compactArray;
  }
  const [reachVisualization, setReachVisualization] = useState(indicator.type);
  const [columns, setcolumns] = useState(list_cols('trimestral'));
  const [logsView, setLogsView] = useState([]);
  const [logsViewName, setLogsViewName] = useState([]);

  
  const options = {
    
    rowsExpanded: [],
    print: false,
    pagination: false,
    viewColumns: false,
    search: false,
    selectableRows: "none",
    filter: false,
    selectableRowsHeader: false,
    download: false,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    onCellClick: (colData, cellMeta) => {

      var a = []
      // console.log(list[cellMeta.rowIndex])
      // console.log(myAr)

      for (let i = 0; i < myAr.logs.length; i++) {

        // const element = array[index];
        // console.log(myAr.logs[i])
        if(myAr.logs[i].branch.id == list[cellMeta.rowIndex][1]){
          a.push(myAr.logs[i])
        }

      }

      setLogsView(a)
      console.log(a[0].branch.name)
      setLogsViewName(a[0].branch.name)
      // console.log(cellMeta.rowIndex)
      // console.log(myAr)
      // console.log(cellMeta.event.target)
      setViewSite(true);
      
    },
    isRowExpandable: (dataIndex, expandedRows) => {

      console.log(list_subc)
      
      for (var i in list_subc) {
        console.log("1")
      if (list_subc[i].length>0){
        console.log("2")
        if(list[dataIndex]){
          console.log("3")
          if(list[dataIndex][1]==list_subc[i][5]){
            console.log("4")
            console.log(list[dataIndex][1]);
            return true;
          } 
        }
      }
    }
    return true;
    },
    /*
    customRowRender: data => {
      
      const [name, date, valo] = data;

      return (
        <YourCustomRowComponent
        data={data}
        />
      );
    },*/
    renderExpandableRow: (rowData, rowMeta) => {
      
      //return setTimeout(() => fff(act, rowData), 100)
      var comp = []

      return (
        <React.Fragment>
          <tr>
            <td colSpan={columns.length+1}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                <TableBody>
                
                {list_subc.map(function (data, index) { 
                   console.log(columns.length)
                  if(rowData[1]==data[5]){
                  return(<TableRow>
                    <TableCell>
                      <Typography className={classes.typeLabel} align='center'>
                        <SubdirectoryArrowRightIcon style={{ fontSize: 14 }}/>
                      </Typography>
                    </TableCell>
                    {columns.map((cols, i) => {
                    var inte=<TableCell>0</TableCell>
                    // Return the element. Also pass key     
                    if(i>1){
                        if(data[1]==cols.code){
                            inte= (<TableCell>{data[2]}</TableCell>) 
                        }else{
                          inte= (<TableCell>0</TableCell>) 
                        }
                      return inte
                    }else{
                      return <TableCell>{data[4]}</TableCell>
                    }
              })}</TableRow>)
                  
            }  
                  })}

                    </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      )
    },
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => console.log(curExpanded, allExpanded, rowsExpanded)
  }
  const theme1 = useTheme()
  const theme = createTheme({
    overrides: {
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: 'hidden',
        },
      },
    },
  })

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: '0.8em',
      background:
        'linear-gradient(to bottom right, rgba(65, 178, 197, 0.5) 0%, rgba(36, 86, 119, 0.9) 100%)',
      // color: theme.palette.grey['700'],
      color: theme.palette.common.white,
      borderRadius: 9,
      maxWidth: 200,
      border: 0,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 3, 1)
    }
  }))(Tooltip)

  const components = {
    ExpandButton: function(props) {
      console.log(props)
      if (props.dataIndex === 1 || props.dataIndex === 4) return <div style={{width:'24px'}} />
      return <ExpandButton {...props} />
      // return <div style={{width:'24px'}} />
    }
  }


 // período de la visualización --- determinada por la frecuencia de medición del indicador -- seleccionable
 const [periodVisualization, setPeriodVisualization] = useState(
  findThePeriod(indicator.frecuencyType)
)

const [motherDataset, setMotherDataset] = useState(
  createMotherDataset(indicator)
)

const [cumulative, setCumulative] = useState(
  indicator.cumulative === true ? true : false
)
const [cumulativeIsNull, setCumulativeIsNull] = useState(
  indicator.cumulative === null
)

const [isPercentage, setIsPercentage] = useState(
  indicator.measure === '%' ? true : false
)

const [typeOfGraphic, setTypeOfGraphic] = useState(1)

const [viewSite, setViewSite] = useState(false)

const checkTheCumulative = (cumulative, cumulativeIsNull, isPercentage) => {
  // fallback de null para aquellos indicadores creados previamente
  // cuyo campo cumulative es asignado a null con la migración del nuevo campo cumulative
  // en este caso, si el usuario decide editar ¿es acumulativo? a No, se cargan los gráficos no acumulativos
  if (isPercentage) {
    return false
  }
  if (cumulative || cumulativeIsNull) {
    return true
  }
  if (!cumulative) {
    return false
  }
}
// función que chequea si el alcance seleccionado no es el alcance "nativo" del indicador (indicator.type)
const isItExpandedReach = (indi, reach) => {
  return indi.type !== reach
}

// Función para guardar vista del indicador+características del gráfico

const saveView = (indiId, rVisual, pVisual) => {
  dispatch(graphViewsActions.create(indiId, rVisual, pVisual))
}



useEffect(() => {
  
  // si el alcance es el nativo al del indicador
  if (!isItExpandedReach(indicator, reachVisualization)) {
    
    if (
      (reachVisualization === 'AGGREGATE' || reachVisualization === 'BSNS') &&
      checkTheCumulative(cumulative, cumulativeIsNull, isPercentage)
    ) {
      if (periodVisualization === 'accumulated') {
        setTypeOfGraphic(8)
      }
      if (periodVisualization === 'yearly') {
        setTypeOfGraphic(1)
      }
      if (periodVisualization === 'semestral') {
     

      }
      if (periodVisualization === 'trimestral') {
        setTypeOfGraphic(3)
      }
      if (periodVisualization === 'monthly') {
        setTypeOfGraphic(4)
      }
      if (periodVisualization === 'weekly') {
        setTypeOfGraphic(5)
      }
      if (periodVisualization === 'daily') {
        setTypeOfGraphic(6)
      }
    }
    if (
      (reachVisualization === 'AGGREGATE' || reachVisualization === 'BSNS') &&
      !checkTheCumulative(cumulative, cumulativeIsNull, isPercentage)
    ) {
      setTypeOfGraphic(16)
    }
    if (
      reachVisualization === 'GLOBAL' &&
      checkTheCumulative(cumulative, cumulativeIsNull, isPercentage)
    ) {
      if (periodVisualization === 'accumulated') {
        setTypeOfGraphic(9)
      }
      if (periodVisualization === 'yearly') {
        setTypeOfGraphic(10)
      }
      if (periodVisualization === 'semestral') {
        setTypeOfGraphic(11)
      }
      if (periodVisualization === 'trimestral') {
        setTypeOfGraphic(12)
      }
      if (periodVisualization === 'monthly') {
        setTypeOfGraphic(13)
      }
      if (periodVisualization === 'weekly') {
        setTypeOfGraphic(14)
      }
      if (periodVisualization === 'daily') {
        setTypeOfGraphic(15)
      }
    }
    if (
      reachVisualization === 'GLOBAL' &&
      !checkTheCumulative(cumulative, cumulativeIsNull, isPercentage)
    ) {
      setTypeOfGraphic(16)
    }
  } else {
    setTypeOfGraphic(17)
  }
}, [reachVisualization, periodVisualization])
const availableReaches = (indicatorType) => {
  switch (indicatorType) {
    case 'AGGREGATE':
      return [
        <option value='AGGREGATE'>Por instalación</option>,
        <option value='BSNS'>Líneas de negocio</option>,
        <option value='GLOBAL'>Nivel Corporativo</option>
      ]
    case 'BSNS':
      return [
        <option value='BSNS'>Líneas de negocio</option>,
        <option value='GLOBAL'>Nivel Corporativo</option>
      ]
    case 'GLOBAL':
      return <option value='GLOBAL'>Nivel Corporativo</option>

    default:
      break
  }
}


//Selección del alcance
const handleReachVisualization = (event) => {
  setReachVisualization(event.target.value)
}

// Selección del período
const handlePeriodVisualization = (event) => {

  setPeriodVisualization(event.target.value)
  
  list_data(event.target.value)
  
  
}

const test = async () => {

  setViewSite(0);

}

const closeDialogLogs = () => {         
  console.log("Cerrado Logs");
  setViewSite(false)
};

  return (
    <Container>

      <div className={classes.wrapper}>
        <div className={classes.formWrapper}>
        <div className={classes.formControlWrapper}>

          <FormControl className={classes.selectWrapper}>
            <InputLabel htmlFor='periodkind-native-simple'>Período</InputLabel>
            <Select
              native
              value={periodVisualization}
              onChange={handlePeriodVisualization}
              inputProps={{
                name: 'periodkind',
                id: 'periodkind-native-simple'
              }}
            >
              <option aria-label='None' value='' />
              <option value='yearly'>Anual</option>
              <option value='semestral'>Semestral</option>
              <option value='trimestral'>Trimestral</option>
              <option value='monthly'>Mensual</option>
              <option value='weekly'>Semanal</option>

              <option value='daily'>Diario</option>

            </Select>
          </FormControl>

        </div>
      </div>
      <ThemeProvider theme={theme}>
          <MUIDataTable data={list} columns={columns} options={options} components={components} />
        </ThemeProvider>
      </div>

      <CustomDialog
          maxWidth='md'
          // dialogtitle="Nuevo registro"
          dialogtitle={`Registros de ${logsViewName}`}
          titlebackground={theme1.palette.gradientTopBottom.main}
          dialogcontent={
          <ListLogs
              Indicator={myAr}
              Logs={logsView}
              close={() => closeDialogLogs()}
          />
          }
          open={viewSite}
          onClose={() => setViewSite(false)}
      />

  </Container>
)

}

const ListLogs = (data) => {

  const [dat, setDat] = useState([]);
  // const [data1, setData1] = useState([]);

      useEffect(() => {
        console.log("LOGSVIEWS")
        console.log(data.Indicator)
        console.log(data.Logs)

        // var a = []

        // data.Logs.map((us,i) => {
        
        // })

        // setData()
        // console.log(data);

            data.Logs.map((us,i) => {

              var a = us.createdAt;
              var b = a.split("T");
              var c = b[0].split("-")
                            setDat(arr => [...arr,[c[2]+'-'+c[1]+'-'+c[0],
                                                        us.value,
                                                        us.comments != null ? us.comments : '-']])
            }) 

      }, [])

    let theme = createTheme();
    theme = responsiveFontSizes(theme);

    const designtabla = makeStyles((theme) => ({
        fondo: {
            padding: "1rem",
            backgroundColor: "#f2f2f2"
        },
        fondo2: {
            backgroundColor: "#fff",
            padding: "1rem",
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)" 
        }
    }))

    const classes = designtabla();

    const columns = [
      //Por cada {} es una columna donde name es el titulo del header
      //Y options sirve para crear filtros personalizados etc.
      {
          name: "Fecha de registro",
          options: {}
      },
      {
          name: "Valor",
          options: {}
      },
      {
          name: "Comentario",
          options: {}
      },
      
    ];
  
    const options = {
      search: "enabled",
      searchOpen: true,
      filter: false,
      viewColumns: false,   
      selectableRows: 'none',
      responsive: "standard",
      selectToolbarPlacement: "none",
      print: false,
      download: false,
      textLabels: {
        pagination: {
          next: "Siguiente",
          previous: "Anterior",
          rowsPerPage: "Filas por página:",
          displayRows: "de" // 1-10 of 30
        },
        body: {
          noMatch: 'No se encontraron resultados',
        }      
      }
    }; 

      return (

        <Container>
            {/* <center>
                <h3>Registros de {data.Logs[0].branch.name}</h3>
            </center> */}

            {/* {data.Logs.map((us,i) =>

              <Grid container alignItems="center" display="flex" justifyContent="center" style={{width:"auto"}} direction="column">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    Registrado el {us.createdAt}
                  </Grid>
                  <Grid item xs={6}>
                    {us.value}
                  </Grid>
                </Grid>
              </Grid>

            )} */}
      
                <ThemeProvider theme={theme}>
                    <MUIDataTable
                        data={dat}
                        columns={columns}
                        options={options} />
                </ThemeProvider>

        </Container>
    )

}

export default LogsView
