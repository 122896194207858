// import 'date-fns';
import React, { useState, useEffect, Fragment } from 'react'
import FormField from '../UI/FormFields'
import { Grid, InputAdornment } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { indicatorActions } from '../../state/actions'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import { useHandleError } from '../../hooks/useHandleError'
import { updateFormData, validForm } from '../../helpers/form.helper'
import ButtonWithLoading from '../UI/ButtonWithLoading'
import { finalBeautify, numberFieldChange } from '../../helpers/numbers.helper'

// Componente que muestra el formulario para crear un registro
const LogIndicator = ({ indicator, close, log }) => {
  //LDNs de la organización
  const companyLines = useSelector((state) => state.linesOfBsns.lines)
  // Nombre de las LDN del indicador en cuestión
  const indicatorLines = indicator.linesIds
  const currentUser = useSelector((state) => state.user.user)
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = useState(log ? log.date : new Date())
  const [valid, setValid] = useState(false)
  // console.log(indicator);
  // console.log(indicator.quantitative);
  const indicatorType = indicator.type
  const loadProperOptions = () => {
    let properOptions = []
    switch (indicatorType) {
      case 'AGGREGATE':
        properOptions = [
          ...indicator.branches.map((branch) => ({
            ...branch,
            label: branch.name,
            value: branch.id
          }))
        ]
        // console.log('properBranches', properBranches)
        return properOptions
      case 'BSNS':
        properOptions = [
          ...indicator.lines.map((line) => ({
            ...line,
            label: line.name,
            value: line.id
          }))
        ]

        return properOptions

      default:
        break
    }
  }

  const [formData, setValues] = useState({
    value: {
      element: 'input',
      value: log ? log.value : '',
      config: {
        id: 'value',
        name: 'value',
        label: 'Valor',
        type: 'text',
        placeholder: indicator.quantitative ? '2.080,9' : 'Cualidad',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: true
      },
      valid: log ? true : '',
      validationMessage: 'Valor es requerido'
    },
    branchId: {
      element: 'select',
      value: log ? log.branchId : '',
      config: {
        id: 'branch',
        name: 'branchId',
        label: 'Instalación',
        select: true,
        fullWidth: true,
        margin: 'normal',
        variant: 'outlined',
        placeholder: 'Instalación'
      },
      validation: {
        required: !indicator.type === 'GLOBAL'
      },

      options: loadProperOptions(),
      valid: log
        ? true
        : indicator.type === 'GLOBAL' || indicator.type === 'BSNS',
      validationMessage: 'Instalación es requerido'
    },
    lineId: {
      element: 'select',
      value: log ? log.lineId : '',
      config: {
        id: 'line',
        name: 'lineId',
        label: 'Línea de negocio',
        select: true,
        fullWidth: true,
        margin: 'normal',
        variant: 'outlined',
        placeholder: 'Línea de negocio'
      },
      validation: {
        required: !indicator.type === 'GLOBAL'
      },

      options: loadProperOptions(),
      valid: log
        ? true
        : indicator.type === 'GLOBAL' || indicator.type === 'AGGREGATE',
      validationMessage: 'Línea de negocio es requerida'
    },
    comments: {
      element: 'input',
      value: log ? log.comments : '',
      config: {
        id: 'comments',
        name: 'comments',
        label: 'Comentarios',
        type: 'text',
        placeholder: 'Donación hecha a Bomberos',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: false
      },
      // valid: log ? true : ''
      valid: true
    }
  })

  const [loading, setLoading] = useState(false)
  const [submitOk, setSubmitOk] = useState(false)
  const [error, setError] = useState(null)
  const [formDataWithErrors] = useHandleError(formData, error)
  useEffect(() => {
    setValues(formDataWithErrors)
  }, [formDataWithErrors])

  const updateForm = (event) => {
    event.preventDefault()

    // if (event.target.name === 'value') {
    //   event.target.value = numberFieldChange(event.target.value)
    // }
    setValues(updateFormData(formData, event.target.name, event.target.value))
  }

  const onBlur = (event) => {
    event.preventDefault()
    if (event.target.name === 'value') {
      event.target.value = finalBeautify(event.target.value)
    }
    setValues(updateFormData(formData, event.target.name, event.target.value))
  }

  useEffect(() => {
    setValid(validForm(formData))
  }, [formData])
  // console.log('formData', formData)
  const submitForm = async (event) => {
    event.preventDefault()
    const dataToSubmit = {
      logIndicator: {
        value: formData.value.value,
        date: selectedDate,
        indicatorId: indicator.id,
        // userId: indicator.managerId,
        userId: currentUser.id,
        branchId: !formData.branchId.value ? null : formData.branchId.value,
        lineId: !formData.lineId.value ? null : formData.lineId.value,
        comments: formData.comments.value,
        quantitative: indicator.quantitative
      }
    }
    const submit = async () => {
      setLoading(true)
      setError(null)
      setSubmitOk(false)
      log
        ? await dispatch(
            indicatorActions.updateLog(indicator.id, log.id, dataToSubmit)
          ).then(() => {
            setSubmitOk(true)
            if (close) {
              setTimeout(() => {
                setSubmitOk(false)
                close()
              }, 500)
            }
          })
        : await dispatch(indicatorActions.createLog(indicator.id, dataToSubmit))
            .then(() => {
              setSubmitOk(true)
              if (close) {
                setTimeout(() => {
                  setSubmitOk(false)
                  close()
                }, 500)
              }
            })
            .catch((err) => {
              setError(err)
            })
      setLoading(false)
    }
    submit()
  }

  return (
    <Fragment>
      <form onSubmit={submitForm}>
        <Grid container spacing={1}>
          {indicator.type === 'AGGREGATE' && (
            <Grid item xs={12}>
              <FormField
                id='branchId'
                formData={formData.branchId}
                change={(event) => updateForm}
              />
            </Grid>
          )}
          {indicator.type === 'BSNS' && (
            <Grid item xs={12}>
              <FormField
                id='lineId'
                formData={formData.lineId}
                change={(event) => updateForm}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormField
              id={'value'}
              formData={{
                ...formData.value,
                config: { ...formData.value.config, onBlur }
              }}
              change={(event) => updateForm}
              inputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {indicator.quantitative ? indicator.measure ? indicator.measure : '%' : ''}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              id={'comments'}
              formData={formData.comments}
              change={(event) => updateForm}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant='inline'
                inputVariant='outlined'
                InputAdornmentProps={{ position: 'start' }}
                format='yyyy/MM/dd'
                margin='normal'
                id='date-picker-inline'
                label='Fecha de medición'
                value={selectedDate}
                onChange={setSelectedDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                fullWidth
                maxDate={new Date()}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <ButtonWithLoading
                text={log ? 'Editar registro' : 'Crear registro'}
                submitOk={submitOk}
                loading={loading}
                onClick={submitForm}
                disabled={!valid || loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  )
}

export default LogIndicator
