import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '13%'
  },
  magnifyer: {
    color: theme.palette.grey[600]
  },
  textField: {
    '& input::placeholder': {
      fontSize: '10px'
    }
  }
}))

const InputSearchFilter = ({ data, handleResult, placeholder }) => {
  const classes = useStyles()
  const inputEl = useRef()

  const [term, setTerm] = useState('')
  const [result, setResult] = useState([])

  const updateTerm = (event) => {
    //cuando se hace click a la equis nativa del componente
    if (!event.target.value) {
      setTerm('')
    }
    setTerm(inputEl.current.value)
  }

  const preventHandler = (event) => {
    event.preventDefault()
  }

  const filterAndSet = (term) => {
    const filtered = data.filter((ele) => {
      return Object.values(ele)
        .join(' ')
        .toLowerCase()
        .includes(term.toLowerCase())
    })
    setResult(filtered)
    handleResult(filtered)
  }
  useEffect(() => {
    filterAndSet(term)
  }, [term])

  return (
    <div>
      <form className={classes.inputContainer} onSubmit={preventHandler}>
        <TextField
          //   style={{ textAlign: 'right' }}
          className={classes.textField}
          inputRef={inputEl}
          type='search'
          id='search-term-input'
          label='Buscar'
          placeholder={placeholder}
          value={term}
          onChange={updateTerm}
          closeicon={
            <button onClick={() => console.log('clearing')}>clear</button>
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon className={classes.magnifyer} />
              </InputAdornment>
            )
          }}
        />
      </form>
    </div>
  )
}

export default InputSearchFilter
