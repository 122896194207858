import { searchTypes } from "../types";

const initialState = {
  search: "",
  results: "",
  recent: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case searchTypes.NEW:
      const { query, results } = action.payload;
      return {
        ...state,
        search: query,
        results: results,
        recent: state.recent.find((recentSearch) => recentSearch.text === query)
          ? state.recent
          : [{ text: query, type: "Búsquedas recientes" }, ...state.recent],
      };
    default:
      return state;
  }
}
