export const notificationConstants = {
  ENQUEUE_SNACKBAR: 'NOTIFICATIONS_ENQUEUE_SNACKBAR',
  REMOVE_SNACKBAR: 'NOTIFICATIONS_REMOVE_SNACKBAR',
  DISMISS_SNACKBAR: 'NOTIFICATIONS_DISMISS_SNACKBAR',

  FETCH_STARTED: 'NOTIFICATIONS_FETCH_STARTED',
  FETCH_SUCCESS: 'NOTIFICATIONS_FETCH_SUCCESS',
  FETCH_FAILURE: 'NOTIFICATIONS_FETCH_FAILURE',
  FETCH_MORE_STARTED: 'NOTIFICATIONS_FETCH_MORE_STARTED',
  FETCH_MORE_SUCCESS: 'NOTIFICATIONS_FETCH_MORE_SUCCESS',
  FETCH_MORE_FAILURE: 'NOTIFICATIONS_FETCH_MORE_FAILURE',

  SET_READ_STARTED: 'NOTIFICATIONS_SET_READ_STARTED',
  SET_READ_SUCCESS: 'NOTIFICATIONS_SET_READ_SUCCESS',
  SET_READ_FAILURE: 'NOTIFICATIONS_SET_READ_FAILURE',
  SET_UNREAD_SUCCESS: 'NOTIFICATIONS_SET_UNREAD_SUCCESS',
  SET_UNREAD_FAILURE: 'NOTIFICATIONS_SET_UNREAD_FAILURE',

  NEW: 'NOTIFICATIONS_NEW',
  CLOSE: 'NOTIFICATION_CLOSE'
}
