import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { indicatorConstants } from '../../state/types'
import { makeStyles } from '@material-ui/core/styles'
import { Tabs, Tab } from '@material-ui/core'
import IndicatorForm from './IndicatorForm'
import LabelsForm from './LabelsForm'
// import TagsForm from './TagsForm'
import BasicInfo from './BasicInfo'
import TypeForm from './TypeForm'
import BranchesForm from './BranchesForm'
import BusinessForm from './BusinessForm'
import SynthesisAndCreate from './SynthesisAndCreate'
import TransitionModal from '../UI/TransitionModal'
import Encargados from './Encargados'
import LogsNewVersion from './LogsNewVersion'
import LogsView from './LogsView'
import LogsNewVersionCuali from './LogsNewVersionCuali'

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
})

function TabContainer(props) {
  return <div>{props.children}</div>
}

const IndcTabs =({ indicator, organization, subindicator }) => {
  console.log(indicator)
  console.log(organization)
  console.log(subindicator)
  const classes = useStyles()
  const dispatch = useDispatch()
  // pieza que mira si el indicador desplegado es candidato para edición
  const { create } = useSelector(
    (state) => state.indicators.indicatorInProgress
  )
  const { tabValue, data } = useSelector(
    (state) => state.indicators.indicatorInProgress
  )

  const enableBasicInfoTab = useSelector(
    (state) => state.indicators.indicatorInProgress.data.contentId
  )

  const [isAnOldIndicator, setIsAnOldIndicator] = useState(data.type && data.id)
  // const enableIfIsAnOldIndicator = data.type && data.id
  console.log

  const enableTypeTab = useSelector(
    (state) => state.indicators.indicatorInProgress.data.frecuencyType
  )

  // habilitar pestañas en edición
  const disableTabs = useSelector(
    (state) => state.indicators.indicatorInProgress.data.id
  )
  const enableSynthesis = useSelector(
    (state) => state.indicators.indicatorInProgress.data.type
  )

  const enableLinesOrBranches = (enableSynthesis) => {
    switch (enableSynthesis) {
      case 'BSNS':
        return data.lines.length > 0 ? true : false
      case 'AGGREGATE':
        return data.branches.length > 0 ? true : false
      case 'GLOBAL':
        return true
      default:
        return false
    }
  }

  function handleChange(event, newValue) {
    dispatch({
      type: indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.SET,
      payload: {
        tabValue: newValue
      }
    })
  }

  return (
    <div className={classes.root}>
      {data.id && <TransitionModal create={create} />}
      <Tabs
        value={tabValue}
        onChange={handleChange}
        variant='fullWidth'
        indicatorColor='secondary'
        textColor='secondary'
      >
        {/* <Tab label='Etiquetas' disabled={!enableBasicInfoTab || !disableTabs} /> */}
        {/* <Tab label='Etiquetas' disabled={!enableBasicInfoTab} /> */}
        <Tab label='Gráfico' />

        <Tab
          label='Tabla'
          // disabled={!enableBasicInfoTab || !isAnOldIndicator}
        />

        
      </Tabs>
      {tabValue === 0 && (
        <TabContainer>
          {/* <IndicatorForm /> */}
          <LogsNewVersion indicator={indicator} organization={organization} />
        </TabContainer>
      )}
      {tabValue === 1 && (
        <TabContainer>
          <LogsView indicator={indicator} organization={organization} />
        </TabContainer>
      )}
      {/* {data.type === 'BSNS' && tabValue === 5 && (
        <TabContainer>
          <SynthesisAndCreate />
        </TabContainer>
      )}
      {data.type === 'AGGREGATE' && tabValue === 5 && (
        <TabContainer>
          <SynthesisAndCreate />
        </TabContainer>
      )} */}
      
    </div>
  )
}

export default IndcTabs
