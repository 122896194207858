import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import {
  generateLogsPerDivision,
  generateLinesDS,
  generateBranchesDS,
  generateCorporateDS
} from '../../helpers/compound-datasets-generator'
import {
  extendedClassifyByYear,
  extendedClassifyBySemester,
  extendedClassifyByTrimester,
  extendedClassifyByMonth,
  extendedClassifyByWeek,
  extendedClassifyByDays
} from '../../helpers/datasets-generator'
import {
  barYearlyOptions,
  categoricalOptions,
  lineDailyOptions
} from '../../helpers/charts-options'

const ExtendedChart = ({ reach, period, indicator }) => {
  const orgLines = useSelector((state) => state.linesOfBsns.lines)

  const orgBranches = useSelector(
    (state) => state.organization.organization.branches
  )

  const [reachInChart, setReachInChart] = useState(reach)
  const [periodInChart, setPeriodInChart] = useState(period)

  const [graphicOptions, setGraphicOptions] = useState(
    barYearlyOptions(indicator.measure)
  )

  const [motherDataset, setMotherDataset] = useState(
    generateLogsPerDivision(indicator, orgLines, orgBranches, periodInChart)
  )
  const [datasetWithDivisions, setDatasetWithDivisions] = useState([])
  const [dataForGraphic, setDataForGraphic] = useState({})

  // control de efectos
  useEffect(() => {
    setReachInChart(reach)
    setPeriodInChart(period)
  }, [reach, period])

  useEffect(() => {
    setDatasetWithDivisions(targetDivisionsGenerator(reachInChart))
  }, [reachInChart])

  useEffect(() => {
    setDataForGraphic(finalDatasetGenerator(periodInChart))
  }, [datasetWithDivisions, periodInChart])

  // preparar la data de acuerdo al alcance
  const targetDivisionsGenerator = (reachInChart) => {
    // construct es el alcance que queremos construir en el gráfico
    if (reachInChart === 'AGGREGATE') {
      return generateBranchesDS(motherDataset, orgBranches)
    }
    if (reachInChart === 'BSNS') {
      return generateLinesDS(motherDataset, orgLines)
    }
    if (reachInChart === 'GLOBAL') {
      return generateCorporateDS(motherDataset)
    }
  }

  const finalDatasetGenerator = (periodInChart) => {
    if (periodInChart === 'yearly') {
      setGraphicOptions(barYearlyOptions(indicator.measure))
      return extendedClassifyByYear(datasetWithDivisions)
    }
    if (periodInChart === 'semestral') {
      setGraphicOptions(categoricalOptions(indicator.measure))
      return extendedClassifyBySemester(datasetWithDivisions)
    }
    if (periodInChart === 'trimestral') {
      setGraphicOptions(categoricalOptions(indicator.measure))
      return extendedClassifyByTrimester(datasetWithDivisions)
    }
    if (periodInChart === 'monthly') {
      setGraphicOptions(categoricalOptions(indicator.measure))
      return extendedClassifyByMonth(datasetWithDivisions)
    }
    if (periodInChart === 'weekly') {
      setGraphicOptions(categoricalOptions(indicator.measure))
      return extendedClassifyByWeek(datasetWithDivisions)
    }
    if (periodInChart === 'daily') {
      setGraphicOptions(categoricalOptions(indicator.measure))
      // console.log('datasetWithDivisions', datasetWithDivisions)
      return extendedClassifyByDays(datasetWithDivisions)
    }
  }

  return (
    <React.Fragment>
      <Bar
        data={dataForGraphic}
        width={300}
        height={500}
        options={graphicOptions}
      />
    </React.Fragment>
  )
}

export default ExtendedChart
