export const areaConstants = {
  SET_INITIAL_STATE: 'AREA_SET_INITIAL_STATE',
  
  FETCH_STARTED: 'AREA_FETCH_STARTED',
  FETCH_SUCCESS: 'AREA_FETCH_SUCCESS',
  FETCH_FAILURE: 'AREA_FETCH_FAILURE',

  DELETE_STARTED: 'AREA_DELETE_STARTED',
  DELETE_SUCCESS: 'AREA_DELETE_SUCCESS',
  DELETE_FAILURE: 'AREA_DELETE_FAILURE',

  CREATE: 'AREA_CREATE',
  UPDATE: 'AREA_UPDATE'
}
