import Axios from '../../axios'
import { reportConstants } from '../types'
import { enqueueSnackbar } from './notification.actions'

/**
 * Obtiene los reportes
 */

const getAll = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Axios.get('/reports')
      .then((response) => {
        dispatch({
          type: reportConstants.FETCH,
          payload: response.data.reports
        })
        resolve(response.data)
      })
      .catch((err) => {
        reject(err.response)
      })
  })
}

/**
 * Crea un reporte
 * @param {Object} data - Data del reporte a crear
 */

const create = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Axios.post('/reports', data)
      .then((response) => {
        // dispatch({ type: reportConstants.CREATE, payload: response.data })
        dispatch({
          type: reportConstants.GLOBAL_REPORT_DIALOG.CREATE,
          payload: response.data.report
        })
        resolve(response.data)
      })
      .catch((err) => {
        reject(err.response)
      })
  })
}

/**
 * Actualiza un reporte
 * @param {Number} reportId - ID del reporte a actualizar
 * @param {Object} data - Data a actualizar
 */

const update = (reportId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/reports/${reportId}`, data)
      .then((response) => {
        dispatch({
          type: reportConstants.GLOBAL_REPORT_DIALOG.UPDATE,
          payload: { reportId, data: response.data.report }
        })
        resolve(response.data.report)
      })
      .catch((err) => {
        reject(err.response)
      })
  })
}

/**
 * Elimina un reporte
 * @param {Number} reportId - ID del reporte a eliminar
 */

const destroy = (reportId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/reports/${reportId}`)
      .then((response) => {
        dispatch({ type: reportConstants.DELETE, payload: { reportId } })
        resolve(response.data)
      })
      .catch((err) => {
        reject(err.response)
      })
  })
}

/**
 * Permite guardar una versión de un reporte
 * @param {Integer} reportId - ID del reporte
 * @param {Object} data - Información del reporte a guardar
 */

const save = (reportId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/reports/${reportId}/save`, data)
      .then((response) => {
        dispatch({
          type: reportConstants.GLOBAL_REPORT_DIALOG.SAVE,
          payload: { reportId, data: response.data.snapshot }
        })
        resolve()
      })
      .catch((err) => {
        reject(err.response.data)
      })
  })
}
/**
 * Descarga el último reporte
 * @param {Integer} reportId - ID del reporte a descargar
 */

const download = (reportId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/reports/${reportId}/download`, {
      responseType: 'arraybuffer'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: err.response?.data?.errors?.errorLabel
              ? `${err.response?.data?.errors?.label}: ${err?.response?.data?.errors?.errorLabel} `
              : 'Ha ocurrido un error'
          })
        )
        reject(err.response.data)
      })
  })
}

/**
 * Permite eliminar un snapshot de un reporte
 * @param {Number} reportId - ID del reporte del snapshot a eliminar
 * @param {Number} snapshotId - ID del snapshot a eliminar
 */

const deleteSnapshot = (reportId, snapshotId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/reports/${reportId}/snapshots/${snapshotId}`)
      .then(() => {
        dispatch({
          type: reportConstants.GLOBAL_REPORT_DIALOG.DELETE_SNAPSHOT,
          payload: { reportId, snapshotId }
        })
        resolve()
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido eliminar el snapshot'
          })
        )
        reject(err.response)
      })
  })
}

export const reportActions = {
  getAll,
  create,
  update,
  destroy,
  save,
  download,
  deleteSnapshot
}
