import React, { useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { createMotherDataset } from '../../helpers/logs-calculations4'

import { genCorpYearlyData } from '../../helpers/datasets-generator'
import { barYearlyOptions } from '../../helpers/charts-options'

const BarChartCorpMemo = (props) => {
  const [motherDataset, setMotherDataset] = useState(
    createMotherDataset(props.indicator)
  )

  const [indicatorMeasure, setIndicatorMeasure] = useState(
    props.indicator.measure
  )

  const [cumulativeness, setCumulativeness] = useState(
    props.indicator.cumulative
  )

  return (
    <Bar
      data={genCorpYearlyData(motherDataset, indicatorMeasure, cumulativeness)}
      width={300}
      height={500}
      options={barYearlyOptions(indicatorMeasure)}
    />
  )
}

const areEqual = (prevProps, nextProps) => true

export default React.memo(BarChartCorpMemo, areEqual)
