import React, { useState, useEffect } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Fab from '@mui/material/Fab';
import NavigateNext from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CloseIcon from '@mui/icons-material/Close';

const Organigrama = ({ organization }) => {

    const design = makeStyles((theme) => ({
        cuadrito1: {
            padding: "5px",
            borderRadius: "8px",
            display: "inline-block",
            border: "3px solid #275e7d",
            backgroundColor: "#5bced9",
            fontSize: "16px",
            color: "#000000",
            fontWeight: "bold",
            height: "60px",
            width: "100px",
            lineHeight: "60px",
            textAlign: "center",
        },
        cuadrito2: {
            padding: "5px",
            borderRadius: "8px",
            display: "inline-block",
            border: "3px solid #275e7d",
            backgroundColor: "#5bced9",
            fontSize: "12px",
            color: "#000000",
            fontWeight: "bold",
            height: "60px",
            width: "100px",
            lineHeight: "60px",
            textAlign: "center",
        },
        cuadrito3: {
            padding: "5px",
            borderRadius: "8px",
            display: "inline-block",
            border: "3px solid #275e7d",
            backgroundColor: "#5bced9",
            fontSize: "10px",
            color: "#000000",
            fontWeight: "bold",
            height: "60px",
            width: "100px",
            lineHeight: "60px",
            textAlign: "center",
        },
        cuadritoInside: {
            display: "inline-block",
            verticalAlign: "middle",
            lineHeight: "normal",
        }
    }))

    const classes = design();

    return (
        <div>
        <br></br><br></br><br></br>
        <Tree
            lineWidth={'3px'}
            lineColor={'#5d6a6a'}
            lineBorderRadius={'10px'}
            nodePadding={'10px'}
            label={<div className={classes.cuadrito1}><span className={classes.cuadritoInside}>{organization.name}</span></div>}
        >
            {organization.lines &&
            organization.lines?.length > 0 &&
            organization.lines.map((line, idx) => (
                <>
                {line.branches?.length === 0 && (
                    <TreeNode label={<div className={classes.cuadrito2}><span className={classes.cuadritoInside}>{line.name}</span></div>} />
                )}
                    
                {line.branches?.length > 0 && (
                    <TreeNode label={<div className={classes.cuadrito2}><span className={classes.cuadritoInside}>{line.name}</span></div>} >

                    {organization.branches &&
                    organization.branches?.length > 0 &&
                    organization.branches.map((branche, idxB) => (
                        <>
                        {branche.companyLineId === line.id && (
                            <TreeNode label={<div className={classes.cuadrito3}><span className={classes.cuadritoInside}>{branche.name}</span></div>} />
                        )}
                        </>
                        
                    ))}

                    </TreeNode>
                )}
                </>
            ))}
        </Tree>
        </div>
    )
}

export default Organigrama
