export const linesConstants = {
  SET_INITIAL_STATE: 'LINES_SET_INITIAL_STATE',
  FETCH_STARTED: 'LINES_FETCH_STARTED',
  FETCH_SUCCESS: 'LINES_FETCH_SUCCESS',
  FETCH_FAILURE: 'LINES_FETCH_FAILURE',
  CREATE: 'LINES_CREATE',
  DELETE: 'LINES_DELETE',
  UPDATE: {
    STARTED: 'UPDATE_LINES_STARTED',
    SUCCESS: 'UPDATE_LINES_SUCCESS'
    // FAILED: 'UPDATE_LINES_FAILED'
  },
  SETBRANCHES: {
    STARTED: 'SET_LINE_BRANCHES_STARTED',
    FAILED: 'SET_LINE_BRANCHES_FAILED',
    SUCCESS: 'SET_LINE_BRANCHES_SUCCES'
  },
  CLEARBRANCHES: {
    CLEAR: 'CLEAR_NOT_OWN',
    RESET: 'RESET_CLEANING_BOOLEAN'
  },
  CREATE_LINE_DIALOG: {
    OPEN: 'CREATE_LINES_DIALOG_OPEN',
    CLOSE: 'CREATE_LINES_CLOSE_DIALOG',
    SET: 'CREATE_LINES_SET_DIALOG',
    CLEAR: 'CREATE_LINES_CLEAR_DIALOG'
  },

  EDIT_LINE_DIALOG: {
    OPEN: 'EDIT_LINE_DIALOG_OPEN',
    CLOSE: 'EDIT_LINE_DIALOG_CLOSE',
    SET: 'EDIT_LINE_DIALOG_SET',
    CLEAR: 'EDIT_LINE_DIALOG_CLEAR'
  }
}
