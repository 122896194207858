import axios from 'axios'
const token = localStorage.getItem('token')
  ? JSON.parse(localStorage.getItem('token'))
  : JSON.parse(sessionStorage.getItem('token'))

const Axios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  // baseURL: 'http://localhost:5000',
  timeout: 35000,
  // mode: 'no-cors',
  maxBodyLength: 10000000000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
    common: {
      authorization: token
    }
  }
})

export default Axios
