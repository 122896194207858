export const materialityConstants = {
  SET_INITIAL_STATE: 'MATERIALITIES_SET_INITIAL_STATE',

  /*--- FETCH ---*/
  FETCH_STARTED: 'MATERIALITY_FETCH_STARTED',
  FETCH_SUCCESS: 'MATERIALITY_FETCH_SUCCESS',
  FETCH_FAILURE: 'MATERIALITY_FETCH_FAILURE',
  /*--- FETCH ---*/

  /*--- FETCH THE ONE ---*/
  FETCH_THEONE_STARTED: 'MATERIALITY_THEONE_FETCH_STARTED',
  FETCH_THEONE_SUCCESS: 'MATERIALITY_THEONE_FETCH_SUCCESS',
  FETCH_THEONE_FAILURE: 'MATERIALITY_THEONE_FETCH_FAILURE',
  /*--- FETCH THE ONE ---*/

  /*--- CRUD ---*/
  CREATE_STARTED: 'MATERIALITY_CREATE_STARTED',
  CREATE_SUCCESS: 'MATERIALITY_CREATE_SUCCESS',
  CREATE_FAILURE: 'MATERIALITY_CREATE_FAILURE',

  DELETE_STARTED: 'MATERIALITY_DELETE_STARTED',
  DELETE_SUCCESS: 'MATERIALITY_DELETE_SUCCESS',
  DELETE_FAILURE: 'MATERIALITY_DELETE_FAILURE',

  UPDATE_STARTED: 'MATERIALITY_UPDATE_STARTED',
  UPDATE_SUCCESS: 'MATERIALITY_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'MATERIALITY_UPDATE_FAILURE',

  UPDATE_STATUS_STARTED: 'MATERIALITY_UPDATE_STATUS_STARTED',
  UPDATE_STATUS_SUCCESS: 'MATERIALITY_UPDATE_STATUS_SUCCESS',
  UPDATE_STATUS_FAILURE: 'MATERIALITY_UPDATE_STATUS_FAILURE',
  /*--- CRUD ---*/
  /*-- POLL DISTRIBUTION LINKS FETCH --*/
  HARVEST_POLL_LINKS_START: 'HARVEST_POLL_LINKS_START',
  HARVEST_POLL_LINKS_SUCCESS: 'HARVEST_POLL_LINKS_SUCCESS',
  HARVEST_POLL_LINKS_FAILURE: 'HARVEST_POLL_LINKS_FAILURE',
  // Clear error 400 after public !pollLink
  CLEAR_ERROR: 'CLEAR_ERROR',
  /*-- POLL DISTRIBUTION LINKS FETCH --*/

  /*-- ANSWERS TO POLLS COUNT HISTORY FETCH --*/
  FETCH_ANSWERS_COUNT_START: 'FETCH_ANSWERS_COUNT_START',
  FETCH_ANSWERS_COUNT_SUCCESS: 'FETCH_ANSWERS_COUNT_SUCCESS',
  FETCH_ANSWERS_COUNT_FAILURE: 'FETCH_ANSWERS_COUNT_FAILURE',
  /*-- ANSWERS TO POLL COUNT HISTORY FETCH --*/

  /*--- MODALS ---*/
  CREATE_MATERIALITY_MODAL: {
    OPEN: 'CREATE_MATERIALITY_MODAL_OPEN',
    CLOSE: 'CREATE_MATERIALITY_MODAL_CLOSE',
    CLEAR: 'CREATE_MATERIALITY_MODAL_CLEAR',
    SET: 'CREATE_MATERIALITY_MODAL_SET'
  },

  INTEREST_MODAL: {
    OPEN: 'INTEREST_MODAL_OPEN',
    CLOSE: 'INTEREST_MODAL_CLOSE',
    CLEAR: 'INTEREST_MODAL_CLEAR',
    SET: 'INTEREST_MODAL_SET'
  },

  INTEREST_GROUP_MODAL: {
    OPEN: 'INTEREST_GROUP_MODAL_OPEN',
    CLOSE: 'INTEREST_GROUP_MODAL_CLOSE',
    CLEAR: 'INTEREST_GROUP_MODAL_CLEAR',
    SET: 'INTEREST_GROUP_MODAL_SET',
    SET_MEMBER: 'INTEREST_GROUP_MODAL_SET_MEMBER',
    DELETE_MEMBER: 'INTEREST_GROUP_MODAL_DELETE_MEMBER',
    READD_MEMBER: 'INTEREST_GROUP_MODAL_READD_MEMBER',
    SET_MASSIVE_LINK: 'SET_MASSIVE_LINK',
    REMOVE_MASSIVE_LINK: 'REMOVE_MASSIVE_LINK',
    DELETE_DISTRIBUTION_LINK: 'DELETE_DISTRIBUTION_LINK'
  },

  UPDATE_STATUS_DIALOG: 'MATERIALITY_UPDATE_STATUS_DIALOG',
  /*--- MODALS ---*/

  /*--- INTERESTS ---*/
  ADD_INTEREST_STARTED: 'ADD_INTEREST_STARTED',
  ADD_INTEREST_SUCCESS: 'ADD_INTEREST_SUCCESS',
  ADD_INTEREST_FAILURE: 'ADD_INTEREST_FAILURE',

  DELETE_INTEREST_STARTED: 'DELETE_INTEREST_STARTED',
  DELETE_INTEREST_SUCCESS: 'DELETE_INTEREST_SUCCESS',
  DELETE_INTEREST_FAILURE: 'DELETE_INTEREST_FAILURE',

  EDIT_INTEREST_STARTED: 'EDIT_INTEREST_STARTED',
  EDIT_INTEREST_SUCCESS: 'EDIT_INTEREST_SUCCESS',
  EDIT_INTEREST_FAILURE: 'EDIT_INTEREST_FAILURE',
  /*--- INTERESTS ---*/

  /*--- INTEREST GROUP ---*/
  ADD_INTEREST_GROUP_STARTED: 'ADD_INTEREST_GROUP_STARTED',
  ADD_INTEREST_GROUP_SUCCESS: 'ADD_INTEREST_GROUP_SUCCESS',
  ADD_INTEREST_GROUP_FAILURE: 'ADD_INTEREST_GROUP_FAILURE',

  DELETE_INTEREST_GROUP_STARTED: 'DELETE_INTEREST_GROUP_STARTED',
  DELETE_INTEREST_GROUP_SUCCESS: 'DELETE_INTEREST_GROUP_SUCCESS',
  DELETE_INTEREST_GROUP_FAILURE: 'DELETE_INTEREST_GROUP_FAILURE',

  EDIT_INTEREST_GROUP_STARTED: 'EDIT_INTEREST_GROUP_STARTED',
  EDIT_INTEREST_GROUP_SUCCESS: 'EDIT_INTEREST_GROUP_SUCCESS',
  EDIT_INTEREST_GROUP_FAILURE: 'EDIT_INTEREST_GROUP_FAILURE',
  /*--- INTEREST GROUP ---*/

  /*--- POLL ---*/
  SET_MATERIALITY_POLL: 'SET_MATERIALITY_POLL',
  SET_SCORE: 'SET_MATERIALITY_POLL_INTEREST_SCORE',
  SEND_MATERIALITY_POLL_STARTED: 'SEND_MATERIALITY_POLL_STARTED',
  SEND_MATERIALITY_POLL_SUCCESS: 'SEND_MATERIALITY_POLL_SUCCESS',
  SEND_MATERIALITY_POLL_FAILURE: 'SEND_MATERIALITY_POLL_FAILURE',
  GET_POLL_DATA_STARTED: 'MATERIALITY_GET_POLL_DATA_STARTED',
  GET_POLL_DATA_SUCCESS: 'MATERIALITY_GET_POLL_DATA_SUCCESS',
  GET_POLL_DATA_FAILURE: 'MATERIALITY_GET_POLL_DATA_FAILURE',
  /*--- POLL ---*/

  /*--- PUBLIC POLL ---*/
  GET_PUBLIC_POLL_DATA_STARTED: 'GET_PUBLIC_POLL_DATA_STARTED',
  GET_PUBLIC_POLL_DATA_SUCCESS: 'GET_PUBLIC_POLL_DATA_SUCCESS',
  GET_PUBLIC_POLL_DATA_FAILURE: 'GET_PUBLIC_POLL_DATA_FAILURE',
  /*--- PUBLIC POLL ---*/

  /*--- EDIT MULTIPLE INTERESTS ---*/
  EDIT_MULTIPLE_STARTED: 'MATERIALITY_EDIT_MULTIPLE_STARTED',
  EDIT_MULTIPLE_SUCCESS: 'MATERIALITY_EDIT_MULTIPLE_SUCCESS',
  EDIT_MULTIPLE_FAILURE: 'MATERIALITY_EDIT_MULTIPLE_FAILURE',
  /*--- EDIT MULTIPLE INTERESTS ---*/

  ON_CREATE_STARTED: 'MATERIALITY_ON_CREATE_STARTED',
  ON_CREATE_ADD_INTEREST: 'MATERIALITY_ON_CREATE_ADD_INTEREST',
  ON_CREATE_UPDATE_INTEREST: 'MATERIALITY_ON_CREATE_UPDATE_INTEREST',
  ON_CREATE_DELETE_INTEREST: 'MATERIALITY_ON_CREATE_DELETE_INTEREST',

  CLEAN_CREATE: 'CLEAN_CREATE',

  CLEAN_EDIT_INTEREST: 'CLEAN_EDIT_INTEREST',

  CREATE_INTEREST_GROUP_FORM_STARTED: 'CREATE_INTEREST_GROUP_FORM_STARTED',
  CREATE_INTEREST_GROUP_INFORMATION_STEP: 'CREATE_INTEREST_GROUP_INFORMATION_STEP',
  CREATE_INTEREST_GROUP_MEMBERS_STEP: 'CREATE_INTEREST_GROUP_MEMBERS_STEP',
  CREATE_INTEREST_GROUP_FORM_FINISHED: 'CREATE_INTEREST_GROUP_FORM_FINISHED',
  CREATE_INTEREST_GROUP_STARTED: 'CREATE_INTEREST_GROUP_STARTED',
  CREATE_INTEREST_GROUP_SUCCESS: 'CREATE_INTEREST_GROUP_SUCCESS',
  CREATE_INTEREST_GROUP_FAILURE: 'CREATE_INTEREST_GROUP_FAILURE',

  FETCH_SOURCES: 'INTEREST_FETCH_SOURCES',
  SEND_POLL_TO_GROUP: 'MATERIALITY_SEND_POLL_TO_GROUP',
  SEND_POLL_TO_ALL_GROUPS: 'MATERIALITY_SEND_POLL_TO_ALL_GROUPS',
  CHANGE_TAB: 'MATERIALITY_CHANGE_TAB',
  CLOSE_MATERIALITY: 'MATERIALITY_CLOSE',
  UPDATE_INTEREST_GROUP: 'MATERIALITY_UPDATE_INTEREST_GROUP',

  ADD_CSV_MAILS: 'MATERIALITY_INTEREST_GROUP_ADD_CSV_MAILS',

  /**REPORTS */

  REPORTS: {
    CREATE: 'MATERIALITY_REPORT_CREATE',
    UPDATE: 'MATERIALITY_REPORT_UPDATE',
    DELETE_SNAPSHOT: 'MATERIALITY_REPORT_DELETE',
    SAVE: 'MATERIALITY_REPORT_SAVE',
    DOWNLOAD: 'MATERIALITY_REPORT_DOWNLOAD',
    IN_PREP: {
      FETCH: 'MATERIALITY_REPORT_IN_PREP_FETCH',
    }
  }
  /**REPORTS */
}