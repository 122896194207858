import React, { useState } from 'react'
import ShowMoreText from 'react-show-more-text'

import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  Modal,
  Backdrop,
  Fade,
  Typography
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    // fontFamily: 'Roboto',
    // backgroundColor: 'rgba(0,0,0,0.5)',
    background:
      'linear-gradient(to bottom right, rgba(65, 178, 197, 0.5) 0%, rgba(36, 86, 119, 0.5) 100%)',
    color: theme.palette.common.white,
    borderRadius: 9,
    maxWidth: 500,
    height: '40%',
    // border: '2px solid #000',
    border: 0,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  smallerBtn: {
    maxWidth: 400
  },
  friendlyText: {
    fontSize: 12,
    textTransform: 'uppercase'
  },
  title: {
    fontSize: 14,
    color: theme.palette.secondary.light
  },
  textTail: {
    fontSize: 12,
    color: theme.palette.primary.contrastText
  }
}))

const TransitionModal = (props) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(!props.create)

  /// estados para showMore
  const [isExpanded, setIsExpanded] = useState(false)
  const executeOnClick = (isExpanded) => {
    console.log(isExpanded)
  }
  //

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const funButton = (
    <Button fullWidth variant='contained' color='secondary' disabled={false}>
      Guardar
    </Button>
  )

  const showMeMore = (
    <ShowMoreText
      lines={1}
      more='Leer más'
      less='Leer menos'
      onClick={executeOnClick}
      expanded={true}
      className={classes.textTail}
      anchorClass={classes.title}
    >
      <p style={{ lineHeight: '1.3em' }}>
        Si este indicador no tiene asignada la etiqueta "Contenido", se
        recomienda agregarla en este momento, seleccionando del menú desplegable
        y haciendo click en "Siguiente" de la pestaña{' '}
        <span className={classes.friendlyText}>ETIQUETAS</span>
      </p>
    </ShowMoreText>
  )

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Grid
            container
            direction='column'
            align='center'
            justifyContent='center'
            className={classes.paper}
            wrap='nowrap'
          >
            <Grid item id='transition-modal-title'>
              <EditIcon style={{ fontSize: 37 }} />
              {/* <Typography component='p'>Edición del indicador</Typography> */}
            </Grid>
            <Grid
              item
              container
              id='transition-modal-description'
              style={{ marginTop: '15px' }}
            >
              <Grid item>
                <Typography variant='body2' gutterBottom>
                  Te encuentras en "modo edición" del indicador. Para hacer
                  efectivos los cambios recuerda hacer click en
                </Typography>
                <Grid className={classes.smallerBtn} item>
                  {funButton}
                </Grid>
                <Typography>
                  de la pestaña{' '}
                  <span className={classes.friendlyText}>Datos actuales</span>
                </Typography>
                {showMeMore}
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </div>
  )
}

export default TransitionModal
