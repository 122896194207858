export const graphViewConstants = {
  SET_INITIAL_STATE: 'SET_INITIAL_STATE',

  GRAPH_VIEW_FETCH_STARTED: 'GRAPH_VIEW_FETCH_STARTED',
  GRAPH_VIEW_FETCH_SUCCESS: 'GRAPH_VIEW_FETCH_SUCCESS',
  GRAPH_VIEW_FETCH_FAILED: 'GRAPH_VIEW_FETCH_FAILED',

  GRAPH_VIEW_CREATE_STARTED: 'GRAPH_VIEW_CREATE_STARTED',
  GRAPH_VIEW_CREATE_SUCCESS: 'GRAPH_VIEW_CREATE_SUCCESS',
  GRAPH_VIEW_CREATE_FAILED: 'GRAPH_VIEW_CREATE_FAILED',

  GRAPH_VIEW_UPDATE_STARTED: 'GRAPH_VIEW_UPDATE_STARTED',
  GRAPH_VIEW_UPDATE_SUCCESS: 'GRAPH_VIEW_UPDATE_SUCCESS',
  GRAPH_VIEW_UPDATE_FAILED: 'GRAPH_VIEW_UPDATE_FAILED',

  GRAPH_VIEW_DELETE_STARTED: 'GRAPH_VIEW_DELETE_STARTED',
  GRAPH_VIEW_DELETE_SUCCESS: 'GRAPH_VIEW_DELETE_SUCCESS',
  GRAPH_VIEW_DELETE_FAILED: 'GRAPH_VIEW_DELETE_FAILED'
}
