import Axios from '../../axios'
import { organizationConstants } from '../types'
import { uiActions } from './ui.actions'
import { userActions } from './user.actions'
import { authenticationActions } from './authentication.actions'
import { enqueueSnackbar } from './notification.actions'

function fetch(organizationId) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.get(`/companies/${organizationId}/users/${localStorage.getItem('userId') ? localStorage.getItem('userId') : sessionStorage.getItem('userId')}`)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'warning',
            message: 'No se han podido cargar los datos de la organización'
          })
        )
      })
  }

  function start() {
    return {
      type: organizationConstants.FETCH_STARTED
    }
  }

  function success(data) {
    return {
      type: organizationConstants.FETCH_SUCCESS,
      payload: data.organization
    }
  }

  function failure(error) {
    return {
      type: organizationConstants.FETCH_FAILURE,
      payload: error
    }
  }
}

function create(data, userId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(start())
      dispatch(uiActions.startLoading())
      Axios.post('/companies', { ...data })
        .then((response) => {
          dispatch(success(response.data))
          dispatch(userActions.updateCompany(response.data.company.id, userId))
          dispatch(uiActions.endLoading())
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Organización creada exitosamente'
            })
          )
          dispatch(authenticationActions.changeToken())
          // setTimeout(() => {
          //   dispatch(push("/app/dashboard"));
          // }, 3000);
          resolve()
        })
        .catch((error) => {
          dispatch(failure(error.response))
          dispatch(uiActions.endLoading())
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido crear la organización'
            })
          )
        })
    })
  }

  function start() {
    return {
      type: organizationConstants.CREATE_STARTED
    }
  }

  function success(data) {
    return {
      type: organizationConstants.CREATE_SUCCESS,
      payload: data.company
    }
  }

  function failure(error) {
    return {
      type: organizationConstants.CREATE_FAILURE,
      payload: error
    }
  }
}

function update(id, data) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.patch(`/companies/${id}`, { ...data })
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Organización actualizada'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido actualizar la organización'
          })
        )
      })
  }

  function start() {
    return {
      type: organizationConstants.UPDATE_STARTED
    }
  }

  function success(data) {
    return {
      type: organizationConstants.UPDATE_SUCCESS,
      payload: {
        organization: data.organization
      }
    }
  }

  function failure(error) {
    return {
      type: organizationConstants.UPDATE_FAILURE,
      payload: {
        error
      }
    }
  }
}

function _delete(id) {
  // TODO: test e implementar en vistas

  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.delete(`/companies/${id}`)
      .then((response) => {
        dispatch(success(id))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Organización eliminada exitosamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido eliminar la organización'
          })
        )
      })
  }

  function start() {
    return {
      type: organizationConstants.DELETE_STARTED
    }
  }

  function success(id) {
    return {
      type: organizationConstants.DELETE_SUCCESS,
      payload: { id }
    }
  }

  function failure(error) {
    return {
      type: organizationConstants.DELETE_FAILURE,
      payload: { error }
    }
  }
}

const fetchBranchesSuccess = (companyId, branches) => ({
  type: organizationConstants.BRANCHES.FETCH,
  payload: { companyId, branches }
})
const fetchBranches = (companyId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.get(`/companies/${companyId}/branches`)
        .then((response) => {
          dispatch(fetchBranchesSuccess(companyId, response.data.branches))
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const createBranchSuccess = (organizationId, branch) => ({
  type: organizationConstants.BRANCHES.CREATE,
  payload: { organizationId, branch }
})
const createBranch = (companyId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/companies/${companyId}/branches`, data)
        .then((response) => {
          dispatch(createBranchSuccess(companyId, response.data.branch))
          resolve()
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }
}

const updateBranchSuccess = (organizationId, branchId, branch) => ({
  type: organizationConstants.BRANCHES.UPDATE,
  payload: { organizationId, branchId, branch }
})
const updateBranch = (companyId, branchId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.patch(`/companies/${companyId}/branches/${branchId}`, data)
        .then((response) => {
          dispatch(
            updateBranchSuccess(
              companyId,
              response.data.id,
              response.data.branch
            )
          )
          resolve()
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }
}

const deleteBranchSuccess = (organizationId, branchId) => ({
  type: organizationConstants.BRANCHES.DELETE,
  payload: { organizationId, branchId }
})
const deleteBranch = (companyId, branchId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.delete(`/companies/${companyId}/branches/${branchId}`)
        .then((response) => {
          dispatch(deleteBranchSuccess(companyId, branchId))
          resolve()
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido elimninar la instalación'
            })
          )
          reject(error.response)
        })
    })
  }
}

// // Lines
// const fetchLinesSuccess = (companyId, lines) => ({
//   type: organizationConstants.LINES.FETCH,
//   payload: { companyId, lines }
// })
// const fetchLines = (companyId) => {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       Axios.get(`/companies/${companyId}/lines`)
//         .then((response) => {
//           dispatch(fetchLinesSuccess(companyId, response.data.lines))
//           resolve()
//         })
//         .catch((error) => {
//           reject(error)
//         })
//     })
//   }
// }

// const deleteLineSuccess = (organizationId, lineId) => ({
//   type: organizationConstants.LINES.DELETE,
//   payload: { organizationId, lineId }
// })
// const deleteLine = (companyId, lineId) => {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       Axios.delete(`/companies/${companyId}/lines/${lineId}`)
//         .then((response) => {
//           dispatch(deleteLineSuccess(companyId, lineId))
//           resolve()
//         })
//         .catch((error) => {
//           dispatch(
//             enqueueSnackbar({
//               variant: 'error',
//               message: 'No se ha podido elimninar la línea de negocio'
//             })
//           )
//           reject(error.response)
//         })
//     })
//   }
// }

// const updateLineSuccess = (organizationId, lineId, line) => ({
//   type: organizationConstants.LINES.UPDATE,
//   payload: { organizationId, lineId, line }
// })
// const updateLine = (companyId, lineId, data) => {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       Axios.patch(`/companies/${companyId}/lines/${lineId}`, data)
//         .then((response) => {
//           dispatch(
//             updateLineSuccess(companyId, response.data.id, response.data.line)
//           )
//           resolve()
//         })
//         .catch((error) => {
//           reject(error.response)
//         })
//     })
//   }
// }

export const organizationActions = {
  fetch,
  create,
  update,
  _delete,
  createBranch,
  fetchBranches,
  updateBranch,
  deleteBranch
}
