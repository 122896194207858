export const initiativeConstants = {
  SET_INITIAL_STATE: 'INITIATIVES_SET_INITIAL_STATE',

  FETCH_STARTED: 'INITIATIVE_FETCH_STARTED',
  FETCH_FAILURE: 'INITIATIVE_FETCH_FAILURE',
  FETCH_SUCCESS: 'INITIATIVE_FETCH_SUCCESS',

  DELETE_STARTED: 'INITIATIVE_DELETE_STARTED',
  DELETE_SUCCESS: 'INITIATIVE_DELETE_SUCCESS',
  DELETE_FAILURE: 'INITIATIVE_DELETE_FAILURE',

  ADD_INDICATOR_STARTED: 'INITIATIVE_ADD_INDICATOR_STARTED',
  ADD_INDICATOR_FAILURE: 'INITIATIVE_ADD_INDICATOR_FAILURE',
  ADD_INDICATOR_SUCCESS: 'INITIATIVE_ADD_INDICATOR_SUCCESS',

  DELETE_INDICATOR_STARTED: 'INITIATIVE_DELETE_INDICATOR_STARTED',
  DELETE_INDICATOR_SUCCESS: 'INITIATIVE_DELETE_INDICATOR_SUCCESS',
  DELETE_INDICATOR_FAILURE: 'INITIATIVE_DELETE_INDICATOR_FAILURE',

  TASK_DIALOG: {
    OPEN: 'INITIATIVE_TASK_DIALOG_OPEN',
    CLOSE: 'INITIATIVE_TASK_DIALOG_CLOSE'
  },

  REPORTS: {
    CREATE: 'INITIATIVE_REPORTS_CREATE_SUCCESS',
    SAVE_SNAPSHOT: 'INITIATIVE_REPORTS_SAVE_SNAPSHOT',
    UPDATE: 'INITIATIVE_REPORTS_UPDATE_SUCCESS'
  },

  CREATE_INITIATIVE_MODAL: {
    OPEN: 'CREATE_INITIATIVE_MODAL_OPEN',
    CLOSE: 'CREATE_INITIATIVE_MODAL_CLOSE',
    SET: 'CREATE_INITIATIVE_MODAL_SET',
    CLEAR: 'CREATE_INITIATIVE_MODAL_CLEAR'
  },

  CREATE: 'INITIATIVE_CREATE',
  UPDATE: 'INITIATIVE_UPDATE'
}
