/**
 * Ordena las opciones de un select, alfabética o numéricamente en orden ascendente
 * @param {Array} opts opciones posibles
 * @returns {Array} lista ordenada
 */

export const orderOptions = (opts) => {
  const mapped = opts.map((ele) => parseInt(ele.name.split(':')[0])).sort()
  const ordered = mapped.map((idx) =>
    opts.find((ele) => ele.name.includes(idx.toString()))
  )
  return ordered
}
