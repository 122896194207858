import Axios from '../../axios'
import { linesConstants, uiConstants } from '../types'
import { uiActions } from './ui.actions'
import { enqueueSnackbar } from './notification.actions'

// CREATE
const create = (companyId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/companies/${companyId}/lines`, data)
        .then((response) => {
          dispatch({
            type: linesConstants.CREATE,
            payload: response.data.line
          })
          resolve()
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido crear la línea de negocio'
            })
          )
          reject(err.response)
        })
    })
  }
}

// FETCH
const fetchLinesSuccess = (companyId, lines) => ({
  type: linesConstants.FETCH_SUCCESS,
  payload: { companyId, lines }
})
const fetchLines = (companyId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.get(`/companies/${companyId}/lines`)
        .then((response) => {
          dispatch(fetchLinesSuccess(companyId, response.data.lines))
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

// DELETE

const deleteLineSuccess = (organizationId, lineId) => ({
  type: linesConstants.DELETE,
  payload: { organizationId, lineId }
})
const deleteLine = (companyId, lineId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.delete(`/companies/${companyId}/lines/${lineId}`)
        .then((response) => {
          dispatch(deleteLineSuccess(companyId, lineId))
          resolve()
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido eliminar la línea de negocio'
            })
          )
          reject(error.response)
        })
    })
  }
}

// UPDATE
const updateLineSuccess = (organizationId, lineId, line) => ({
  type: linesConstants.UPDATE.SUCCESS,
  payload: { organizationId, lineId, line }
})
const updateLine = (companyId, lineId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.patch(`/companies/${companyId}/lines/${lineId}`, data)
        .then((response) => {
          dispatch(
            updateLineSuccess(
              response.data.line.companyId,
              response.data.line.id,
              response.data.line
            )
          )
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Línea de negocio editada exitosamente'
            })
          )

          dispatch({ type: linesConstants.CREATE_LINE_DIALOG.CLEAR })
          resolve()
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }
}

// SET BRANCHES

const setBranches = (companyId, line, branches) => {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.patch(`/companies/${companyId}/lines/${line.id}/branches`, {
      line: { branches, name: line.name, description: line.description }
    })
      .then((response) => {
        dispatch(setBranchesSuccess(response.data.line))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: response.data.message.variant,
            message: response.data.message.text
          })
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function start() {
    return {
      type: linesConstants.SETBRANCHES.STARTED
    }
  }
  function setBranchesSuccess(line) {
    return {
      type: linesConstants.SETBRANCHES.SUCCESS,
      payload: { line }
    }
  }
}

export const lineActions = {
  create,
  updateLine,
  fetchLines,
  deleteLine,
  setBranches
}
