import React, { useState, useEffect, Fragment } from 'react'
import FormField from '../UI/FormFields'
import { Grid } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { indicatorActions } from '../../state/actions'
import { resourcesActions } from '../../state/actions'
import { indicatorConstants } from '../../state/types'
import {
  validForm,
  updateFormData,
  generateReduxData,
  updateSubperspectivesOptions,
  updateGRIContentOptions
} from '../../helpers/form.helper'
import { orderOptions } from '../../helpers/order-options-select'
import { useHandleError } from '../../hooks/useHandleError'
import { parseIndicatorDataToSubmit } from '../../helpers/indicators.helper'
import ButtonWithLoading from '../UI/ButtonWithLoading'

const LabelsForm = () => {
  const dispatch = useDispatch()
  // local state
  const [loading, setLoading] = useState(false)
  const [submitOk, setSubmitOk] = useState(false)
  const [error, setError] = useState(null)

  // redux states
  const areas = useSelector((state) => state.areas.areas)
  const employees = useSelector((state) => state.employees.active)
  const { ods, perspectives, griContents } = useSelector(
    (state) => state.resources
  )
  const { data, tagsValid, create } = useSelector(
    (state) => state.indicators.indicatorInProgress
  )
  // establecer las opciones según  la dimensión escogida
  const subperspectiveOptions = () => {
    // console.log(data.perspectiveId);
    let subs = []
    // console.log(perspectives)
    // console.log(data.perspectiveId)
    if (data.perspectiveId !== '') {
      subs = perspectives.filter((ele) => ele.id === data.perspectiveId)
      // console.log(subs)
      if (subs.length > 0) {
        subs = subs[0].subperspectives
      }
    }
    console.log(subs)
    return subs
  }

  console.log(griContents)

  // console.log(perspectives);
  const [formData, setValues] = useState({
    areaId: {
      element: 'select',
      value: data.areaId ? data.areaId : '',
      options: areas,
      config: {
        id: 'area',
        name: 'areaId',
        label: 'Área a cargo',
        select: true,
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true,
        multiple: true
      },
      validation: {
        required: false
      },
      valid: true,
      validationMessage: ''
    },
    managerId: {
      element: 'select',
      value: data.managerId ? data.managerId : '',
      options: employees,
      config: {
        id: 'managerId',
        name: 'managerId',
        label: 'Responsable',
        placeholder: 'Responsable',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true,
        select: true
      },
      validation: {
        required: false
      },
      valid: true,
      validationMessage: 'Responsable es requerido'
    },
    perspectiveId: {
      element: 'select',
      value: data.perspectiveId ? data.perspectiveId : '',
      options: perspectives,
      config: {
        name: 'perspectiveId',
        id: 'perspective',
        label: 'Dimensión GRI',
        select: true,
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: true
      },
      valid: true,
      validationMessage: ''
    },
    subperspectiveId: {
      element: 'select',
      value: data.subperspectiveId ? data.subperspectiveId : '',
      options: subperspectiveOptions(),
      config: {
        id: 'subperspective',
        name: 'subperspectiveId',
        label: 'Tema',
        select: true,
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: false
      },
      valid: true,
      validationMessage: ''
    },
    contentId: {
      element: 'select',
      value: data.contentId ? data.contentId : '',
      options: [],
      config: {
        id: 'content',
        name: 'contentId',
        label: 'Contenido',
        select: true,
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: false
      },
      valid: data.odsId && data.contentId ? true : false,
      validationMessage: 'Es necesario seleccionar el contenido'
    },
    odsId: {
      element: 'select',
      value: data.odsId ? data.odsId : '',
      options: ods,
      config: {
        id: 'ods',
        name: 'odsId',
        label: 'ODS',
        select: true,
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: true
      },
      valid: data.odsId && data.contentId ? true : false,
      validationMessage: ''
    }
  })

  const [formDataWithErrors] = useHandleError(formData, error)
  useEffect(() => {
    setValues(formDataWithErrors)
  }, [formDataWithErrors])

  const updateForm = (event) => {
    event.preventDefault()
    setValues(updateFormData(formData, event.target.name, event.target.value))
  }
  useEffect(
    () => {
      dispatch({
        type: indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.SET,
        payload: {
          tagsValid: validForm(formData),
          // tagsValid: data.contentId !== null && data.contentId !== '',
          data: generateReduxData(formData)
        }
      })
      // to clean the state
      return () => {}
    },
    // eslint-disable-next-line

    [formData]
  )

  // Actualización de temas de acuerdo a la dimensión escogida
  useEffect(() => {
    let perspective = perspectives.find(
      (perspective) => perspective.id === data.perspectiveId
    )
    if (perspective && data.perspectiveId) {
      setValues((oldFormData) =>
        updateSubperspectivesOptions(
          oldFormData,
          // ordenamos las opciones de tema
          orderOptions(perspective.subperspectives),
          data.subperspective,
          data.perspectiveId
        )
      )
    }
    // eslint-disable-next-line
  }, [data.perspectiveId])

  // Carga de contenido GRI
  useEffect(() => {
    // identificar de la pieza Redux los contenidos que corresponden al tema
    let subperspectiveparent = griContents.filter(
      (ele) => ele.subperspectiveId === data.subperspectiveId
    )
    console.log(subperspectiveparent)
    const theResult = updateGRIContentOptions(
      formData,
      subperspectiveparent,
      data.content
    )
    setValues(theResult)
  }, [data.subperspectiveId])

  const submitForm = (event) => {
    event.preventDefault()
    const submit = async () => {
      setLoading(true)
      setError(null)
      setSubmitOk(false)
      await dispatch(
        indicatorActions.update(data.id, parseIndicatorDataToSubmit(data))
      )
        .then(() => {
          setSubmitOk(true)
          if (data.type !== 'GLOBAL') {
            setTimeout(() => {
              dispatch({
                type: indicatorConstants.CREATE_INDICATOR_DIALOG.SET,
                payload: { tabValue: 2 }
              })
            }, 500)
          }
        })
        .catch((err) => {
          setError(err)
        })

      setLoading(false)
    }
    submit()
  }

  // fetch the content
  const fetchTheContent = (id) => {
    dispatch(indicatorActions.bringTheContent(id))
  }

  //TODO Guardar content como tal en estado Redux
  const thisStepIsDone = async (event) => {
    console.log(formData);
    console.log(data);
    event.preventDefault()
    // traer el nombre específico del contenido para almacenarlo en el estado Redux
    if (data.contentId !== '') {
      console.log(data.contentId)
      fetchTheContent(data.contentId)
    }
    const submit = () => {
      setTimeout(() => {
        dispatch({
          type: indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.SET,
          payload: { tabValue: 1 }
        })
      }, 500)
    }
    submit()
  }

  return (
    <Fragment>
      <form onSubmit={submitForm}>
        <Grid container spacing={1}>
          {/* Dimensión y Tema */}
          <Grid container spacing={2}>
            {/* Dimensión GRI */}
            <Grid item xs={12} md={6}>
              <FormField
                id={'perspective'}
                formData={formData.perspectiveId ? formData.perspectiveId : ''}
                change={() => updateForm}
              />
            </Grid>
            {/* Tema */}
            <Grid item xs={12} md={6}>
              <FormField
                id={'subperspective'}
                formData={
                  formData.subperspectiveId ? formData.subperspectiveId : ''
                }
                change={() => updateForm}
              />
            </Grid>
          </Grid>
          {/* contenido */}
          <Grid item xs={12}>
            <FormField
              id={'content'}
              formData={formData.contentId ? formData.contentId : ''}
              change={() => updateForm}
            />
          </Grid>
          {/* ODS */}
          <Grid item xs={12} md={12}>
            <FormField
              id={'ods'}
              formData={formData.odsId ? formData.odsId : ''}
              change={() => updateForm}
            />
          </Grid>
          {/* Área y Responsable */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormField
                id={'area'}
                formData={formData.areaId ? formData.areaId : ''}
                change={() => updateForm}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <FormField
                id={'managerId'}
                formData={formData.managerId}
                change={() => updateForm}
              />
            </Grid> */}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ButtonWithLoading
                text={'Siguiente'}
                loading={loading}
                submitOk={submitOk}
                onClick={thisStepIsDone}
                disabled={loading || !data.contentId}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  )
}

export default LabelsForm
