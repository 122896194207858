export const resourcesConstants = {
  SET_INITIAL_STATE: 'RESOURCES_SET_INITIAL_STATE',

  // ODS
  ODS_FETCH_STARTED: 'ODS_FETCH_STARTED',
  ODS_FETCH_SUCCESS: 'ODS_FETCH_SUCCESS',
  ODS_FETCH_FAILURE: 'ODS_FETCH_FAILURE',
  // Perspectives
  PERSPECTIVE_FETCH_STARTED: 'PERSPECTIVE_FETCH_STARTED',
  PERSPECTIVE_FETCH_SUCCESS: 'PERSPECTIVE_FETCH_SUCCESS',
  PERSPECTIVE_FETCH_FAILURE: 'PERSPECTIVE_FETCH_FAILURE',
  // Sectors
  SECTOR_FETCH_STARTED: 'SECTOR_FETCH_STARTED',
  SECTOR_FETCH_SUCCESS: 'SECTOR_FETCH_SUCCESS',
  SECTOR_FETCH_FAILURE: 'SECTOR_FETCH_FAILURE',

  COUNTRIES_FETCH: 'RESOURCES_COUNTRIES_FETCH',

  GRI_CONTENTS_STARTED: 'GRI_CONTENTS_STARTED',
  GRI_CONTENTS_SUCCESS: 'GRI_CONTENTS_SUCCESS',
  GRI_CONTENTS_FAILED: 'GRI_CONTENTS_FAILED',
}