import React, { useState, useEffect, useRef } from 'react'
import { Chart, Bubble, Line } from 'react-chartjs-2'
import { createMotherDataset } from '../../helpers/logs-calculations4'
import {
  bubbleOptions,
  barAccumOptions,
  lineMonthlyOptions,
  lineYearlyOptions,
  lineWeeklyOptions,
  lineDailyOptions,
  lineSemestralOptions,
  lineTotalOptions,
  sweetOptions
} from '../../helpers/charts-options'
import {
  genMonthlyLineData,
  generateYearlyBarData,
  genSemestralBarData,
  genTrimestralBarData,
  genTotalBarData,
  genWeeklyData,
  genCorpAccData,
  genCorpSemestralData,
  genCorpMonthlyData,
  genCorpTrimestralData,
  genCorpWeeklyData,
  genCorpDailyData,
  genBubbleChart,
  convertToBubbleData,
  convertToSpecialLine,
  generateYearlyLineData,
  genLineSemestralData,
  genTotalLineData,
  genTotalSweetData,
  genTrimestralLineData,
  genCorpYearlyData
} from '../../helpers/datasets-generator'

const NotCumulativeChart = ({ reach, period, indicator, tOfG }) => {
  const [motherDataset, setMotherDataset] = useState(
    createMotherDataset(indicator)
  )

  const [indicatorMeasure, setIndicatorMeasure] = useState(indicator.measure)

  const [typeOfGraphic, setTypeOfGraphic] = useState(tOfG)

  const [reachInChart, setReachInChart] = useState(reach)
  const [periodInChart, setPeriodInChart] = useState(period)

  const [dataForBubble, setDataForBubble] = useState({})
  const [dataForLine, setDataForLine] = useState({})
  // para gráficos Pie o Doughnut
  const [dataForSweet, setDataForSweet] = useState({})

  const [graphicOptions, setGraphicOptions] = useState(
    lineMonthlyOptions(indicatorMeasure)
  )
  const [timeUnit, setTimeUnit] = useState('')

  useEffect(() => {
    setReachInChart(reach)
    setPeriodInChart(period)
  }, [reach, period])

  useEffect(() => {
    dataGenerator(reachInChart, periodInChart)
  }, [reachInChart, periodInChart])

  useEffect(() => {
    dataGenerator(reachInChart, periodInChart)
  }, [reachInChart, periodInChart])

  const dataGenerator = (reachInChart, periodInChart) => {
    switch (periodInChart) {
      case 'accumulated':
        // --------------------------------------------------------CORPORATIVO (TOTAL HISTÓRICO)
        if (reachInChart === 'GLOBAL') {
          const data = genTotalBarData(
            motherDataset,
            motherDataset.measureUnit,
            motherDataset.cumulativeness
          )
          const bubbleRendered = convertToBubbleData(data)
          setDataForBubble(bubbleRendered)
        }
        // ----------------------------------------------------------LDN o INSTALACIONES  (TOTAL HISTÓRICO)
        if (reachInChart === 'AGGREGATE' || reachInChart === 'BSNS') {
          setTimeUnit('year')
          if (typeOfGraphic === 'sweet') {
            // ------------------------------------------------------------- Para gráficos de burbuja
            setGraphicOptions(sweetOptions(motherDataset.measureUnit, timeUnit))
            const data = genTotalSweetData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )
            // const bubblesRendered = convertToBubbleData(data)
            // setDataForBubble(bubblesRendered)
            // setDataForBubble(data)
            setDataForSweet(data)
          }
          if (typeOfGraphic === 'line') {
            // ------------------------------------------------------------- Para gráficos lineales
            setGraphicOptions(lineTotalOptions(motherDataset.measureUnit))
            const data = genTotalLineData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )
            const lineRendered = convertToSpecialLine(data)
            setDataForLine(lineRendered)
          }
        }
        break
      case 'yearly':
        // -------------------------------------------------------------CORPORATIVO Y ANUAL
        setTimeUnit('year')
        if (reachInChart === 'GLOBAL') {
          if (typeOfGraphic === 'line') {
            setGraphicOptions(lineYearlyOptions(motherDataset.measureUnit))
            const data = genCorpYearlyData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )
            const lineRendered = convertToSpecialLine(data)
            setDataForLine(lineRendered)
          }
        }
        // -------------------------------------------------------------LDN O INST Y ANUAL
        if (reachInChart === 'AGGREGATE' || reachInChart === 'BSNS') {
          // ------------------------------------------------------------- Para gráficos de burbuja
          if (typeOfGraphic === 'bubble') {
            setGraphicOptions(bubbleOptions(indicator.measure, timeUnit))
            const data = genTotalBarData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )
            const bubbleRendered = convertToBubbleData(data)
            setDataForBubble(bubbleRendered)
          }
          // ------------------------------------------------------------- Para gráficos lineales
          if (typeOfGraphic === 'line') {
            setGraphicOptions(lineYearlyOptions(motherDataset.measureUnit))
            const data = generateYearlyLineData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )
            const lineRendered = convertToSpecialLine(data)
            setDataForLine(lineRendered)
          }
        }
        break
      case 'semestral':
        // -------------------------------------------------------------CORPORATIVO Y SEMESTRAL
        if (reachInChart === 'GLOBAL') {
          if (typeOfGraphic === 'line') {
            setGraphicOptions(lineSemestralOptions(motherDataset.measureUnit))
            const data = genCorpSemestralData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )
            const lineRendered = convertToSpecialLine(data)
            setDataForLine(lineRendered)
            // setDataForLine(data)
          }
        }
        // -------------------------------------------------------------LDN O INST Y SEMESTRAL
        if (reachInChart === 'AGGREGATE' || reachInChart === 'BSNS') {
          if (typeOfGraphic === 'line') {
            setGraphicOptions(lineSemestralOptions(motherDataset.measureUnit))
            const data = genLineSemestralData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )
            const lineRendered = convertToSpecialLine(data)
            setDataForLine(lineRendered)
            // setDataForLine(data)
          }
        }
        break
      case 'trimestral':
        // -------------------------------------------------------------CORPORATIVO Y TRIMESTRAL
        if (reachInChart === 'GLOBAL') {
          if (typeOfGraphic === 'line') {
            setGraphicOptions(lineSemestralOptions(motherDataset.measureUnit))
            const data = genCorpTrimestralData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )
            const lineRendered = convertToSpecialLine(data)
            setDataForLine(lineRendered)
            // setDataForLine(data)
          }
        }
        // ------------------------------------------------------------- LDN O INST Y TRIMESTRAL
        if (reachInChart === 'AGGREGATE' || reachInChart === 'BSNS') {
          if (typeOfGraphic === 'line') {
            setGraphicOptions(lineSemestralOptions(motherDataset.measureUnit))
            const data = genTrimestralLineData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )
            const lineRendered = convertToSpecialLine(data)
            setDataForLine(lineRendered)
            // setDataForLine(data)
          }
        }
        break
      case 'monthly':
        // ------------------------------------------------------------- CORPORATIVO Y MENSUAL
        setTimeUnit('month')
        if (reachInChart === 'GLOBAL') {
          if (typeOfGraphic === 'line') {
            setGraphicOptions(lineMonthlyOptions(motherDataset.measureUnit))

            const data = genCorpMonthlyData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )
            const lineRendered = convertToSpecialLine(data)
            setDataForLine(lineRendered)
          }
        }
        // ------------------------------------------------------------- LDN O INST Y MENSUAL
        if (reachInChart === 'AGGREGATE' || reachInChart === 'BSNS') {
          // función que genera los valores mensuales
          if (typeOfGraphic === 'line') {
            setGraphicOptions(lineMonthlyOptions(motherDataset.measureUnit))
            const data = genMonthlyLineData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )
            const lineRendered = convertToSpecialLine(data)
            setDataForLine(lineRendered)
          }
        }
        break
      case 'weekly':
        // ------------------------------------------------------------- CORPORATIVO Y SEMANAL
        setTimeUnit('week')
        if (reachInChart === 'GLOBAL') {
          if (typeOfGraphic === 'line') {
            setGraphicOptions(lineWeeklyOptions(motherDataset.measureUnit))
            const data = genCorpWeeklyData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )
            const lineRendered = convertToSpecialLine(data)
            setDataForLine(lineRendered)
          }
        }
        // ------------------------------------------------------------- LDN O INST Y SEMANAL
        if (reachInChart === 'AGGREGATE' || reachInChart === 'BSNS') {
          if (typeOfGraphic === 'line') {
            setGraphicOptions(lineWeeklyOptions(motherDataset.measureUnit))
            const data = genWeeklyData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )
            const lineRendered = convertToSpecialLine(data)
            setDataForLine(lineRendered)
          }
        }
        break
      case 'daily':
        // ------------------------------------------------------------- CORPORATIVO Y DIARIO
        setTimeUnit('day')
        if (reachInChart === 'GLOBAL') {
          if (typeOfGraphic === 'line') {
            setGraphicOptions(lineDailyOptions(motherDataset.measureUnit))

            const data = genCorpDailyData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )
            const lineRendered = convertToSpecialLine(data)
            setDataForLine(lineRendered)
          }
        }
        // ------------------------------------------------------------- LDN O INST Y DIARIO
        if (reachInChart === 'AGGREGATE' || reachInChart === 'BSNS') {
          if (typeOfGraphic === 'line') {
            setGraphicOptions(lineDailyOptions(motherDataset.measureUnit))
            const data = genWeeklyData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )
            const lineRendered = convertToSpecialLine(data)
            setDataForLine(lineRendered)
          }
        }
        break
      default:
        break
    }
  }

  return (
    <React.Fragment>
      {typeOfGraphic === 'bubble' && (
        <Bubble
          data={dataForBubble}
          width={300}
          height={500}
          options={graphicOptions}
        />
      )}
      {typeOfGraphic === 'line' && (
        <Line
          data={dataForLine}
          width={300}
          height={500}
          options={graphicOptions}
        />
      )}
    </React.Fragment>
  )
}

const areEqual = (prevProps, nextProps) => true

export default React.memo(NotCumulativeChart)
// export default NotCumulativeChart
