export const taskConstants = {
  FETCH_STARTED: 'TASK_FETCH_STARTED',
  FETCH_SUCCESS: 'TASK_FETCH_SUCCESS',
  FETCH_FAILURE: 'TASK_FETCH_FAILURE',

  CREATE_STARTED: 'TASK_CREATE_STARTED',
  CREATE_FAILURE: 'TASK_CREATE_FAILURE',
  CREATE_SUCCESS: 'TASK_CREATE_SUCCESS',

  UPDATE_STARTED: 'TASK_UPDATE_STARTED',
  UPDATE_SUCCESS: 'TASK_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'TASK_UPDATE_FAILURE',

  DELETE_STARTED: 'TASK_DELETE_STARTED',
  DELETE_SUCCESS: 'TASK_DELETE_SUCCESS',
  DELETE_FAILURE: 'TASK_DELETE_FAILURE',

  CHANGE_STATUS: 'TASK_CHANGE_STATUS',

  SET_TASK: 'TASK_SET',

  CREATE: 'TASK_CREATE',
  DELETE: 'TASK_DELETE',
  SET_TASK_UPDATE_FORM: 'TASK_SET_UPDATE_FORM',
  UPDATE: 'TASK_UPDATE'
}
