import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import logoTero from '../../assets/img/terocolor.png'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    zindex: 1050,
    display: 'flex',
    flexDirection: 'column',
    alignItems: props.centerVertically ? 'center' : 'flex-start',
    justifyContent: props.center ? 'center' : 'flex-start',
    height: props.height ? props.height : props.size,
    width: props.width ? props.width : props.size,
    backgroundColor: props.background
  }),
  circle: (props) => ({}),
  logo: (props) => ({
    marginTop: 80,
    width: 200,
    height: 'auto',
    ...props.logoStyle
  })
}))

/**
 * MaterialUI circle loading indicator.
 *
 * @version 1
 * @author [John Bidwell](https://github.com/johnbidwellb)
 * @prop {boolean} loading - Indica cuando mostrar el círculo. Default false
 * @prop {number | string} size - Tamaño del círculo. Default 40
 * @prop {number} thickness - Grosor del círculo. Default 5
 * @prop {string} color - Color del círculo. Opciones: [primary, secondary, inherit]
 * @prop {boolean} center - Centra horizontalmente el círculo. Default false
 * @prop {boolean} centerVertically - Centra verticalmente el círculo. Default false
 * @prop {number | string} height - Alto del contenedor. Default 40
 * @prop {number | string} width - Ancho del contenedor. Default 40
 * @prop {string} background - Color de fondo del indicador en RGB o RGBA. Default rgba(0,0,0,0)
 * @prop {boolean} showLogo - Indica si mostrar o no el logo de la organización
 * @prop {Component} logo - Logo a mostrar
 * @prop {object} logoStyle - CSS del logo
 */

const Progress = ({
  loading,
  size,
  thickness,
  color,
  showLogo,
  logo,
  logoStyle,
  center,
  centerVertically,
  height,
  width,
  background
}) => {
  const classes = useStyles({
    loading,
    size,
    thickness,
    color,
    showLogo,
    logo,
    logoStyle,
    center,
    centerVertically,
    height,
    width,
    background
  })

  return loading ? (
    <div className={classes.root}>
      <CircularProgress
        className={classes.circle}
        size={size}
        thickness={thickness}
        color={color}
      />
      {showLogo && <img src={logo} alt='tero' className={classes.logo} />}
    </div>
  ) : null
}

Progress.defaultProps = {
  loading: false,
  size: 40,
  thickness: 5,
  color: 'primary',
  center: false,
  centerVertically: false,
  height: 40,
  width: 40,
  background: 'rgba(0,0,0,0)',
  showLogo: false,
  logo: logoTero
}

Progress.propTypes = {
  /**
   * {Bool} - si true, muestra el indicador de carga
   */
  loading: PropTypes.bool,
  /**
   * {Number} - Tamaño del círculo
   */
  size: PropTypes.number,
  /**
   * {Number} - Grosor del círculo
   */
  thickness: PropTypes.number,
  /**
   * {String} - Color del círculo
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  /**
   * {Bool} - Centrar horizontalmente
   */
  center: PropTypes.bool,
  /**
   * {Bool} - Centrar verticalmente
   */
  centerVertically: PropTypes.bool,
  /**
   * {Number, String} - Altura del contenedor
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * {Number, String} - Ancho del contenedor
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * {String} - Color de fondo del indicador en RGB o RGBA
   */
  background: PropTypes.any,
  /**
   * {Bool} - Indica si mostrar o no el logo de la organización
   */
  showLogo: PropTypes.bool,
  /**
   * {Element} - Logo a mostrar
   */
  logo: PropTypes.any,
  /**
   * {Object} - CSS del logo
   */
  logoStyle: PropTypes.any
}
export default Progress
