export const userConstants = {
  SET_INITIAL_STATE: 'USER_SET_INITIAL_STATE',

  SET_USER: 'SET_USER',

  UPDATE_STARTED: 'USER_UPDATE_STARTED',
  UPDATE_FAILURE: 'USER_UPDATE_FAILURE',
  UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',

  INVITE_STARTED: 'USER_INVITE_STARTED',
  INVITE_SUCCESS: 'USER_INVITE_SUCCESS',
  INVITE_FAILURE: 'USER_INVITE_FAILURE',

  DELETE_STARTED: 'USER_DELETE_STARTED',
  DELETE_FAILURE: 'USER_DELETE_FAILURE',
  DELETE_SUCCESS: 'USER_DELETE_SUCCESS',

  UPLOAD_PROFILE_PICTURE_STARTED: 'UPLOAD_PROFILE_PICTURE_STARTED',
  UPLOAD_PROFILE_PICTURE_SUCCESS: 'UPLOAD_PROFILE_PICTURE_SUCCESS',
  UPLOAD_PROFILE_PICTURE_FAILURE: 'UPLOAD_PROFILE_PICTURE_FAILED',

  DIALOG: {
    OPEN: 'USER_DIALOG_OPEN',
    CLOSE: 'USER_DIALOG_CLOSE'
  },

  GET_ME_STARTED: 'USER_GET_ME_STARTED',
  GET_ME_SUCCESS: 'USER_GET_ME_SUCCESS',
  GET_ME_FAILURE: 'USER_GET_ME_FAILURE'
}
