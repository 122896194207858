import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { FormField } from 'boosted-materialui-forms'
import SubmitButton from '../UI/ButtonWithLoading'
import { useSelector, useDispatch } from 'react-redux'
import { reportActions } from '../../state/actions'
import TeroLogo from '../UI/TeroLogo'
import { reportConstants } from '../../state/types'

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(1) }
}))

const ContentTab = ({ report }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { initiatives } = useSelector((state) => state.initiatives)
  const { indicators } = useSelector((state) => state.indicators)
  const [initiativesSelect, setInitiativesSelect] = useState(initiatives)
  const [showContent, setShowContent] = useState(false)

  useEffect(() => {
    const tempArray = initiatives.map((initiative) => ({
      ...initiative,
      label: initiative.name,
      value: initiative.id
    }))
    setInitiativesSelect(tempArray)
    setFormData((prevData) => ({
      ...prevData,
      initiative: {
        ...prevData.initiative,
        options: tempArray
      }
    }))
  }, [initiatives])

  // const [indicators, setIndicators] = useState([])

  const handleChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target

    if (name === 'initiative') {
      setShowContent(false)
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        value: value,
        error: ''
      }
    }))
  }

  const [formData, setFormData] = useState({
    initiative: {
      element: 'select',
      value: '',
      options: initiativesSelect,
      config: {
        name: 'initiative',
        label: 'Iniciativa',
        fullWidth: true,
        margin: 'normal',
        size: 'normal'
      },
      onChange: handleChange,
      error: false
    },
    reportText: {
      value: '',
      config: {
        name: 'reportText',
        label: 'Texto del reporte de la iniciativa (modular)',
        fullWidth: true,
        multiline: true,
        rows: 4,
        maxRows: 4,
        size: 'normal',
        type: 'text'
      },
      onChange: handleChange
    },
    indicator: {
      element: 'multiselect',
      value: [],
      options: [],
      config: {
        name: 'indicator',
        label: 'Indicadores',
        fullWidth: true,
        margin: 'normal'
      },
      onChange: handleChange
    },
    position: {
      value: 0,
      config: {
        name: 'position',
        label: 'Posición',
        fullWidth: true,
        margin: 'normal',
        type: 'number',
        min: 0
      },
      onChange: handleChange
    }
  })

  /** Setea los indicadores para la iniciativa */
  useEffect(() => {
    let tempInitiative = report.initiatives.find(
      (initiative) => initiative.id === formData.initiative.value
    )
    if (tempInitiative) {
      const updateInitiativeContent = async () => {
        let tempIndicators = await indicators.map((indicator) => ({
          ...indicator,
          label: indicator.name,
          value: indicator.id
        }))
        let initiativeInReport = await report.content.initiatives.find(
          (initiative) => initiative.id === formData.initiative.value
        )
        await setFormData((prevData) => ({
          ...prevData,
          indicator: {
            ...prevData.indicator,
            value: initiativeInReport
              ? initiativeInReport.indicators.map(
                  (indicator) => indicator.id
                  // ({
                  //   value: indicator.id,
                  //   label: indicator.name
                  // })
                )
              : [],
            options: tempIndicators
          },
          reportText: {
            ...prevData.reportText,
            value: initiativeInReport?.reportText
          },
          position: {
            ...prevData.position,
            value: initiativeInReport?.position
          }
        }))
        await setShowContent(true)
      }
      updateInitiativeContent()
    } else {
      setShowContent(false)
    }
  }, [
    formData.initiative,
    initiatives,
    report.content.initiatives,
    indicators,
    report.initiatives
  ])

  /** Añade una iniciativa al reporte */
  const [loadingAddInitiative, setLoadingAddInitiative] = useState(false)
  const [errAddInitiative, setErrAddInitiative] = useState(false)
  const [okAddInitiative, setOkAddInitiative] = useState(false)
  const addInitiative = (event) => {
    event.preventDefault()
    let dataToSubmit = {
      report: {
        ...report,
        content: {
          ...report.content,
          initiatives: [
            ...report.content.initiatives,
            {
              id: formData.initiative.value,
              position:
                Math.max(
                  report.content.initiatives.map(
                    (initiative) => initiative.position
                  )
                ) + 1,
              indicators: [],
              reportText: ''
            }
          ]
        }
      }
    }
    delete dataToSubmit.report.updatedAt
    delete dataToSubmit.report.createdAt
    delete dataToSubmit.report.id

    const submit = async () => {
      setLoadingAddInitiative(true)
      setErrAddInitiative(false)
      setOkAddInitiative(false)
      await dispatch(reportActions.update(report.id, dataToSubmit))
        .then(() => {
          setOkAddInitiative(true)
          setTimeout(() => {
            setOkAddInitiative(false)
          }, 2000)
        })
        .catch((err) => {
          setErrAddInitiative(err)
        })
      setLoadingAddInitiative(false)
    }
    submit()
  }
  useEffect(() => {
    if (errAddInitiative) {
      const errors = errAddInitiative.data.errors
        ? errAddInitiative.data.errors.join(' ')
        : 'Ha ocurrido un error.'
      setFormData((prevState) => ({
        ...prevState,
        initiative: {
          ...prevState.initiative,
          error: errors
        }
      }))
    }
  }, [errAddInitiative])

  /** Actualiza una iniciativa */
  const [loadingupdateInitiative, setLoadingupdateInitiative] = useState(false)
  const [errupdateInitiative, setErrupdateInitiative] = useState(false)
  const [okupdateInitiative, setOkupdateInitiative] = useState(false)
  const updateInitiative = (event) => {
    event.preventDefault()
    const initiativesToUpdate = report.content.initiatives.map((initiative) => {
      if (initiative.id !== formData.initiative.value) {
        return initiative
      }
      return {
        ...initiative,
        reportText: formData.reportText.value,
        indicators: formData.indicator.value.map((id) => ({ id })),
        position: formData.position.value
      }
    })
    let dataToSubmit = {
      report: {
        ...report,
        content: {
          ...report.content,
          initiatives: initiativesToUpdate
        }
      }
    }
    delete dataToSubmit.report.updatedAt
    delete dataToSubmit.report.createdAt
    delete dataToSubmit.report.id

    const submit = async () => {
      setLoadingupdateInitiative(true)
      setErrupdateInitiative(false)
      setOkupdateInitiative(false)
      await dispatch(reportActions.update(report.id, dataToSubmit))
        .then(() => {
          setOkupdateInitiative(true)
          setTimeout(() => {
            setOkupdateInitiative(false)
            dispatch({
              type: reportConstants.GLOBAL_REPORT_DIALOG.CHANGE_TAB,
              payload: 2
            })
          }, 1000)
        })
        .catch((err) => {
          setErrupdateInitiative(err)
        })
      setLoadingupdateInitiative(false)
    }
    if (
      !report.initiatives.find(
        (initiative) => initiative.id === formData.initiative.value
      )
    ) {
      dispatch({
        type: reportConstants.GLOBAL_REPORT_DIALOG.CHANGE_TAB,
        payload: 2
      })
    }
    submit()
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1} alignItems='center' justifyContent='center'>
        <Grid item container spacing={2} alignItems='center'>
          <Grid item xs>
            <FormField {...formData.initiative} />
          </Grid>
          <Grid item>
            <SubmitButton
              text='Agregar'
              loading={loadingAddInitiative}
              submitOk={okAddInitiative}
              onClick={addInitiative}
              disabled={
                loadingAddInitiative ||
                !formData.initiative.value ||
                report.initiatives.find(
                  (initiative) => initiative.id === formData.initiative.value
                )
              }
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          alignItems='center'
          justifyContent='space-between'
        >
          {formData.initiative.value && showContent ? (
            // report.content.initiatives.find(
            //   (initiative) => initiative.id === formData.initiative.value
            // )
            <>
              <Grid item xs={12} md={6}>
                <FormField {...formData.reportText} />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                container
                direction='column'
                spacing={1}
              >
                <Grid item xs>
                  <FormField
                    {...formData.indicator}
                    value={formData.indicator.value}
                  />
                </Grid>
                <Grid item xs>
                  <FormField
                    {...formData.position}
                    vlaue={formData.position.value}
                  />
                </Grid>
              </Grid>
              {errupdateInitiative && (
                <Grid item xs={12}>
                  <Typography color='error'>Ha ocurrido un error</Typography>
                </Grid>
              )}
            </>
          ) : (
            <Grid
              item
              container
              direction='column'
              alignItems='center'
              justifyContent='center'
              style={{
                marginTop: 16,
                marginBottom: 16
              }}
            >
              <Grid item>
                <TeroLogo width={160} />
              </Grid>
              <Grid item>
                <Typography align='center'>Agregue una iniciativa</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <SubmitButton
            text={
              formData.initiative.value
                ? report.initiatives.find(
                    (initiative) => initiative.id === formData.initiative.value
                  )
                  ? 'Actualizar'
                  : 'Siguiente'
                : 'Siguiente'
            }
            loading={loadingupdateInitiative}
            submitOk={okupdateInitiative}
            disabled={loadingupdateInitiative}
            onClick={updateInitiative}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ContentTab
