import Axios from '../../axios'
import {
  initiativeActions,
  employeeActions,
  areaActions,
  indicatorActions,
  organizationActions,
  materialityActions,
  lineActions
} from './index'
import {
  initiativeConstants,
  indicatorConstants,
  authConstants,
  userConstants,
  employeeConstants,
  areaConstants,
  organizationConstants,
  uiConstants,
  materialityConstants,
  resourcesConstants
} from '../types'
import {
  uiActions
} from './ui.actions'
import {
  push
} from 'connected-react-router'
import {
  resourcesActions
} from './resources.actions'
import {
  enqueueSnackbar
} from './notification.actions'
import {
  userActions
} from './user.actions'

var jwt = require('jsonwebtoken')

function changeToken() {
  return (dispatch, getState) => {
    dispatch(uiActions.startLoading())
    let refreshToken = getState().authentication.refreshToken
    let mail = getState().user.user.mail
    let keepSession = getState().authentication.keepSession
    
    return Axios.post('/refreshToken', {
        mail: mail,
        refreshToken: refreshToken
      })
      .then((response) => {
        let token = JSON.stringify(response.data.token)        
        if (keepSession) {
          localStorage.setItem('token', token)          
        } else {
          sessionStorage.setItem('token', token)          
        }
        dispatch(success(token))
        // dispatch(fetchData())
        return dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        return dispatch(logout())
      })
  }

  function success(token) {
    return {
      type: authConstants.CHANGE_TOKEN_SUCCESS,
      payload: token
    }
  }
}

// const fetchData = () => {
//   const promise = new Promise((resolve, reject) => {

//   })
// }
function fetchData() {
  return async (dispatch, getState) => {
    dispatch(uiActions.startLoading())
    await dispatch(changeToken())
    let token = null
    let keepSession = getState().authentication.keepSession
    if (keepSession) {
      token = JSON.parse(localStorage.getItem('token'))      
    } else {
      token = JSON.parse(sessionStorage.getItem('token'))      
    }
    if (token) {
      const user = jwt.decode(token).user
      Axios.defaults.headers.common['authorization'] = token
      if (user.companyId) {
        dispatch(initiativeActions.fetchAll(user.companyId))
        dispatch(employeeActions.fetchActive(user.companyId))
        dispatch(employeeActions.fetchInactive(user.companyId))
        dispatch(areaActions.fetchAll(user.companyId))
        dispatch(indicatorActions.fetchAll(user.companyId))
        dispatch(organizationActions.fetch(user.companyId))
        dispatch(materialityActions.fetch(user.companyId))
        dispatch(resourcesActions.fetchOds())
        dispatch(resourcesActions.fetchPerspectives())
        dispatch(resourcesActions.fetchGriContents())
        dispatch(resourcesActions.fetchSectors())
        dispatch(resourcesActions.fetchCountries())
        dispatch(lineActions.fetchLines(user.companyId))
        dispatch(userActions.me())
      }
    } else {
      dispatch(logout())
    }
    dispatch(uiActions.endLoading())
  }
}

// TODO: push a misma ruta cuando un usuario no autenticado esté viendo un post o una organización
function login(data) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.post('/login', {
        ...data
      })
      .then((response) => {
        console.log(response);
        let token = response.data.token
        Axios.defaults.headers.common['authorization'] = token
        let userId = response.data.userId
        if (data.keepSession) {
          localStorage.setItem('token', JSON.stringify(token)) // DE FORMA INDEFINIDA
          localStorage.setItem('userId', JSON.stringify(userId))
        } else {
          sessionStorage.setItem('token', JSON.stringify(token)) // SOLO PARA LA PESTAÑA ACTUAL
          sessionStorage.setItem('userId', JSON.stringify(userId))
        }
        dispatch(success(response.data))

        // Desencriptar Token para guardar datos del usuario
        let user = jwt.decode(response.data.token).user        
        dispatch({
          type: userConstants.SET_USER,
          payload: user
        })

        dispatch(uiActions.endLoading())
        if (user.companyId) {
          dispatch(push('/app/indicators'))
        } else {
          dispatch(push('/app/registerOrganization'))
          // dispatch(resourcesActions.fetchSectors());
        }
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
      })
  }

  function start() {
    return {
      type: authConstants.LOGIN_STARTED
    }
  }

  function success(data) {
    return {
      type: authConstants.LOGIN_SUCCESS,
      payload: data
    }
  }

  function failure(error) {
    return {
      type: authConstants.LOGIN_FAILURE,
      payload: error
    }
  }
}

const cleanStores = () => {
  return (dispatch) => {
    // Clear store
    localStorage.clear()
    sessionStorage.clear()
    // Limpiar headers
    Axios.defaults.headers.common['authorization'] = null
    // Limpieza de reducers
    dispatch({
      type: initiativeConstants.SET_INITIAL_STATE
    })
    dispatch({
      type: areaConstants.SET_INITIAL_STATE
    })
    dispatch({
      type: indicatorConstants.SET_INITIAL_STATE
    })
    dispatch({
      type: organizationConstants.SET_INITIAL_STATE
    })
    dispatch({
      type: employeeConstants.SET_INITIAL_STATE
    })
    dispatch({
      type: materialityConstants.SET_INITIAL_STATE
    })
    dispatch({
      type: userConstants.SET_INITIAL_STATE
    })
    dispatch({
      type: uiConstants.SET_INITIAL_STATE
    })
    dispatch({
      type: resourcesConstants.SET_INITIAL_STATE
    })
    dispatch({
      type: authConstants.LOGOUT
    })
  }
}

const logout = () => {
  return (dispatch) => {
    dispatch(uiActions.startLoading())
    dispatch(cleanStores())
    window.localStorage.setItem('CREDENTIALS_FLUSH', Date.now().toString())
    window.localStorage.removeItem('CREDENTIALS_FLUSH')
    dispatch(uiActions.endLoading())
    // ir a Landing page
    dispatch(push('/'))
  }
}

function register(data) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.post('/register', {
        ...data
      })
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Usuario registrado exitosamente'
          })
        )
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'Ha fallado el registro'
          })
        )
      })
  }

  function start() {
    return {
      type: authConstants.REGISTER_REQUEST
    }
  }

  function success(data) {
    return {
      type: authConstants.REGISTER_SUCCESS,
      payload: {
        user: data
      }
    }
  }

  function failure(error) {
    return {
      type: authConstants.REGISTER_FAILURE,
      payload: error
    }
  }
}

const recoverPasswordSendMail = (mail) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return Axios.post('/recoverPassword', {
          ...mail
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }
}

const recoverPasswordSendPassword = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return Axios.post('/changePassword', {
          ...data
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }
}

const recoverPasswordCanRecover = (token) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return Axios.get(`/changePassword?token=${token}`)
        .then((response) => {
          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  }
}

export const authenticationActions = {
  login,
  logout,
  register,
  fetchData,
  changeToken,
  cleanStores,
  recoverPasswordSendMail,
  recoverPasswordSendPassword,
  recoverPasswordCanRecover
}