import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { notificationConstants } from "../../state/types";
import { Close as CloseIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
}));

let displayed = [];

const Notifier = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { snackbars } = useSelector((state) => state.notifications);

  const displayNotification = (id) => {
    displayed = [...displayed, id];
  };

  const removeNotification = (id) => {
    displayed = displayed.filter((key) => key !== id);
    dispatch({ type: notificationConstants.REMOVE_SNACKBAR, payload: id });
  };

  const setDismissed = (key) => {
    dispatch({ type: notificationConstants.DISMISS_SNACKBAR, payload: key });
  };

  useEffect(
    () => {
      snackbars.forEach(
        ({ key, message, variant, options = {}, dismissed = false }) => {
          if (dismissed) {
            closeSnackbar(key);
            return;
          }
          // Si ya está desplegada, no hace nada
          if (displayed.includes(key)) return;

          // Muestra las notificaciones
          enqueueSnackbar(message, {
            key,
            ...options,
            variant: variant,
            onClose: (event, reason, myKey) => {
              if (options.onClose) {
                options.onClose(event, reason, myKey);
              }
            },
            onExited: (event, myKey) => {
              dispatch({
                type: notificationConstants.REMOVE_SNACKBAR,
                payload: myKey,
              });
              removeNotification(myKey);
            },
            action: (myKey) => {
              return (
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.close}
                  onClick={() => setDismissed(myKey)}
                >
                  <CloseIcon />
                </IconButton>
              );
            },
          });
          // Guarda la notificación
          displayNotification(key);
        }
      );
    },
    // eslint-disable-next-line
    [snackbars, closeSnackbar, , enqueueSnackbar]
  );

  return null;
};

export default Notifier;
