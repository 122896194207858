import Axios from '../../axios'
import { graphViewConstants } from '../types'
import { uiActions } from './ui.actions'
import { enqueueSnackbar } from './notification.actions'

const fetch = (indicatorId) => {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.get(`/indicator-graph/${indicatorId}`)
      .then((response) => {
        dispatch(success(response.data.graphViews))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(uiActions.endLoading())
        dispatch(failure(error))
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: error.message
          })
        )
      })
  }
  function start() {
    return {
      type: graphViewConstants.GRAPH_VIEW_FETCH_STARTED
    }
  }

  function success(data) {
    // ojo especificar el payload
    return {
      type: graphViewConstants.GRAPH_VIEW_FETCH_SUCCESS,
      payload: data
    }
  }

  function failure(error) {
    return {
      type: graphViewConstants.GRAPH_VIEW_FETCH_FAILED,
      payload: error
    }
  }
}

const destroy = (id, indicatorId) => {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.delete(`/indicator-graph/${indicatorId}`, { data: { viewId: id } })
      .then((response) => {
        dispatch(success(id))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: response.data.message
          })
        )
      })
      .catch((error) => {
        dispatch(uiActions.endLoading())
        dispatch(failure(error))
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: error.message
          })
        )
      })
  }
  function start() {
    return {
      type: graphViewConstants.GRAPH_VIEW_DELETE_STARTED
    }
  }

  function success(id) {
    // ojo especificar el payload
    return {
      type: graphViewConstants.GRAPH_VIEW_DELETE_SUCCESS,
      payload: { id }
    }
  }

  function failure(error) {
    return {
      type: graphViewConstants.GRAPH_VIEW_DELETE_FAILED,
      payload: error
    }
  }
}

const create = (indicatorId, reach, period) => {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.post(`/indicator-graph/${indicatorId}`, {
      reachVisual: reach,
      periodVisual: period
    })
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
        if (response.status === 201) {
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Vista guardada existosamente'
            })
          )
        }
        if (response.status === 200) {
          dispatch(
            enqueueSnackbar({
              variant: 'warning',
              message:
                'Ya existe una vista con estas características para este indicador'
            })
          )
        }
      })
      .catch((error) => {
        dispatch(uiActions.endLoading())
        dispatch(failure(error))
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: error.message
          })
        )
      })
  }
  function start() {
    return {
      type: graphViewConstants.GRAPH_VIEW_CREATE_STARTED
    }
  }

  function success(data) {
    // ojo especificar el payload
    return {
      type: graphViewConstants.GRAPH_VIEW_CREATE_SUCCESS,
      payload: data.graphView
    }
  }

  function failure(error) {
    return {
      type: graphViewConstants.GRAPH_VIEW_CREATE_FAILED,
      payload: error
    }
  }
}

const update = (indicatorId, data) => {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.patch(`/indicator-graph/${indicatorId}`, {
      ...data
    })
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
        if (!response.data.graphView.selectedToInitiative) {
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Vista removida'
            })
          )
        } else {
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Vista añadida'
            })
          )
        }
      })
      .catch((error) => {
        dispatch(uiActions.endLoading())
        dispatch(failure(error))
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: error.message
          })
        )
      })
  }
  function start() {
    return {
      type: graphViewConstants.GRAPH_VIEW_UPDATE_STARTED
    }
  }

  function success(data) {
    // ojo especificar el payload
    return {
      type: graphViewConstants.GRAPH_VIEW_UPDATE_SUCCESS,
      payload: data.graphView
    }
  }

  function failure(error) {
    return {
      type: graphViewConstants.GRAPH_VIEW_UPDATE_FAILED,
      payload: error
    }
  }
}

export const graphViewsActions = {
  fetch,
  destroy,
  create,
  update
}
