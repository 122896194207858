import React, { useState, useEffect, Fragment } from 'react'
import FormField from '../UI/FormFields'
import { Grid, Card, CardContent, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { indicatorConstants } from '../../state/types'
import {
  validForm,
  updateFormData,
  generateReduxData
} from '../../helpers/form.helper'
import { useHandleError } from '../../hooks/useHandleError'
import { parseIndicatorDataToSubmit } from '../../helpers/indicators.helper'
import ButtonWithLoading from '../UI/ButtonWithLoading'
import ShowMoreText from 'react-show-more-text'
import { BlockRounded } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275
  },
  title: {
    fontSize: 14
  },
  textTail: {
    fontSize: 14,
    color: theme.palette.text.secondary
  },
  pos: {
    marginBottom: 12
  },
  typeStrong: {
    color: theme.palette.grey['700'],
    fontWeight: theme.typography.fontWeightBold
  },
  examples: {
    color: theme.palette.grey['400'],
    fontSize: '10',
    textTransform: 'allcaps',
    fontStyle: 'italic'
  },
  footerAtn: {
    fontSize: 14,
    color: theme.palette.secondary.dark,
    fontStyle: 'italic'
  }
}))

const TypeForm = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  // component states
  const [loading, setLoading] = useState(false)
  const [submitOk, setSubmitOk] = useState(false)
  const [error, setError] = useState(null)
  const [typeSelected, setTypeSelected] = useState(false)
  //redux states
  const indicatorInProgress = useSelector(
    (state) => state.indicators.indicatorInProgress
  )
  const data = indicatorInProgress.data
  const create = indicatorInProgress.create
  const indicatorValid = indicatorInProgress.indicatorValid
  // formData
  const [formData, setValues] = useState({
    type: {
      element: 'select',
      value: data.type ? data.type : '',
      config: {
        id: 'type',
        name: 'type',
        label: 'Alcance de registro',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true,
        select: true,
        defaultValue: 'GLOBAL',
        disabled: !create ? true : false
      },
      options: [
        { label: 'Nivel Corporativo', value: 'GLOBAL' },
        { label: 'Por línea de negocio', value: 'BSNS' },
        { label: 'Por instalación', value: 'AGGREGATE' }
      ],
      validation: {
        required: true
      },
      valid: data.type ? true : false,
      validationMessage: 'Tipo de indicador es requerido'
    }
  })
  console.log(data);
  // checking formData errors
  const [formDataWithErrors] = useHandleError(formData, error)
  useEffect(() => {
    setValues(formDataWithErrors)
  }, [formDataWithErrors])
  //onchange action
  const updateForm = (event) => {
    event.preventDefault()
    setTypeSelected(true)
    setValues(updateFormData(formData, event.target.name, event.target.value))
  }

  // este es un submit para almacenar en el estado Redux las selecciones del usuario
  const submitForm = (event) => {    
    event.preventDefault()
    const submit = async () => {
      setLoading(true)
      // setError(null)
      dispatch({
        type: indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.SET,
        payload: {
          indicatorValid: validForm(formData),
          data: generateReduxData(formData),
          tabValue: 3
        }
      })
      setLoading(false)
    }
    submit()
  }

  // show value of isExpanded for ShowMoreText
  const executeOnClick = (isExpanded) => {
    console.log(isExpanded)
  }

  return (
    <Fragment>
      <form>
        <Grid container spacing={1} direction='column'>
          {/* explicación */}
          <Grid item>
            {' '}
            <Card>
              <CardContent>
                <Typography
                  className={classes.title}
                  color='secondary'
                  gutterBottom
                >
                  A continuación selecciona el alcance de registro para el
                  indicador
                </Typography>

                <ShowMoreText
                  lines={1}
                  more='Leer más'
                  less='Leer menos'
                  onClick={executeOnClick}
                  expanded={true}
                  className={classes.textTail}
                  anchorClass={classes.title}
                >
                  {/* <Typography variant='body2' component='p' gutterBottom>
                    El tipo de registro se refiere a la correspondencia de los
                    valores que periódicamente se ingresan para este indicador.
                  </Typography> */}
                  <Typography variant='body2' component='p' gutterBottom>
                    <span className={classes.typeStrong}>
                      Nivel corporativo:
                    </span>{' '}
                    cuando el indicador abarca a toda la organización.
                    <span className={classes.examples}>
                      {' '}
                      ej: Porcentaje de mujeres.
                    </span>
                  </Typography>
                  <Typography variant='body2' component='p' gutterBottom>
                    <span className={classes.typeStrong}>
                      Por línea de negocio:
                    </span>{' '}
                    cuando las mediciones se asocian a una subdivisión de la
                    empresa.{' '}
                    <span className={classes.examples}>
                      {' '}
                      ej: Donaciones por filial.
                    </span>
                  </Typography>
                  <Typography variant='body2' component='p' gutterBottom>
                    <span className={classes.typeStrong}>Por instalación:</span>{' '}
                    cuando la medición se asocia a una o más instalaciones en
                    particular.{' '}
                    <span className={classes.examples}>
                      {' '}
                      ej: Consumo de agua por instalación.
                    </span>
                  </Typography>
                  <Typography
                    variant='body2'
                    component='p'
                    className={classes.footerAtn}
                    color='primary'
                    gutterBottom
                  >
                    Por favor ten en cuenta que el alcance de registro se asigna
                    al momento de crear el indicador y no puede ser cambiado
                    posteriormente.
                  </Typography>
                </ShowMoreText>
              </CardContent>
            </Card>
          </Grid>
          {/* input */}
          <Grid item xs={12} md={4}>
            <FormField
              id='type'
              formData={formData.type}
              change={() => updateForm}
            />
          </Grid>
          {/* submit btn */}
          <Grid container spacing={3}>
            <Grid item xs>
              <ButtonWithLoading
                text='Siguiente'
                loading={loading}
                submitOk={submitOk}
                onClick={submitForm}
                disabled={loading || !typeSelected}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  )
}

export default TypeForm
