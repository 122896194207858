import { createStore, applyMiddleware, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import reducers from "./reducers";
import thunk from 'redux-thunk'
// import {
//   areaActions,
//   initiativeActions,
//   indicatorActions,
//   organizationActions,
//   employeeActions,
//   materialityActions
// } from "./actions";

import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import createRootReducer from './state/reducers'

const initialState = {}

// * --- Middlewares del store --- *
export const history = createBrowserHistory()
const middleware = [thunk, routerMiddleware(history)]

// * -------- Enhancers del store --------
const enhancers = []

// * -------- Componer Middlewares y Enhancers --------
let composedEnhancers = null
if (process.env.NODE_ENV === 'development') {
  const composeEnhancers = composeWithDevTools({ trace: true })
  composedEnhancers = composeEnhancers(
    applyMiddleware(...middleware),
    ...enhancers
  )
} else {
  composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)
}

// * -------- Configuración del Persist --------
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router'],
  whitelist: ['user', 'authentication']
}
const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
)

export default function configureStore() {
  const store = createStore(persistedReducer, initialState, composedEnhancers)
  const persistor = persistStore(store)
  return { store, persistor }
}

// const store = createStore(
//   reducers,
//   process.env.NODE_ENV === "development"
//     ? composeWithDevTools(applyMiddleware(thunk))
//     : applyMiddleware(thunk)
// );

// const user = localStorage.getItem("user")
//   ? JSON.parse(localStorage.getItem("user")).user
//   : null;

// if (user) {
//   store.dispatch(initiativeActions.fetchAll(user.companyId));
//   store.dispatch(employeeActions.fetchActive(user.companyId));
//   store.dispatch(employeeActions.fetchInactive(user.companyId));
//   store.dispatch(areaActions.fetchAll(user.companyId));
//   store.dispatch(indicatorActions.fetchAll(user.companyId));
//   store.dispatch(organizationActions.fetch(user.companyId));
//   store.dispatch(materialityActions.fetch(user.companyId));
// }

// export default store;
