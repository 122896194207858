/**
 * Devuleve un número con puntos y comas, por ejemplo 1000.01 lo odevulve como 1.000,01
 * @param {Number} x número a transformar
 * @returns {String}
 */
export function beautifyNumber(x) {
  if (!x) return ''
  const numberParts = x.toString().split('.')

  if (isNaN(Number(numberParts[0]))) return x
  const intergerPart = Number(numberParts[0]).toString()
  const prevDecPart = numberParts.length > 1 ? numberParts[1].slice(0, 2) : null
  const decimalPart = numberParts.length > 1 ? prevDecPart : null
  const intWithDots = intergerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  let fullNumber = intWithDots
  if (decimalPart) {
    fullNumber = fullNumber + `,${decimalPart}`
  }
  if (decimalPart === '') {
    fullNumber = fullNumber + ','
  }

  return fullNumber
}

/**
 * Devuleve un número de javascript desde un string, por ejemplo 1.000,01 lo odevulve como 1000.01
 * @param {String} x número a transformar
 * @returns {Number}s.elper
 */
export function uglifyNumber(x) {
  if (!x) return ''
  const numberParts = String(x).split(',')
  const intPart = numberParts[0].split('.').join('')
  const decPart = numberParts.length > 1 ? numberParts[1] : null
  let fullNumber = intPart
  if (decPart) {
    // console.log('si')
    fullNumber = fullNumber + `.${decPart}`
  }

  if (decPart === '') {
    fullNumber = fullNumber + '.'
  }

  return fullNumber
}

export function finalBeautify(val) {
  let temp = val

  if (String(val).endsWith(',')) {
    temp = `${temp}0`
  }
  // console.log(temp)
  return temp
}

export function numberFieldChange(value) {
  value = uglifyNumber(value)
  value = beautifyNumber(value)
  return value
}
