import React from 'react'
import ReactDOM from 'react-dom'
import './App.css'
import { RenderRoutes, ROUTES } from './routes'
import { Provider } from 'react-redux' // Redux
import configureStore, { history } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { SnackbarProvider } from 'notistack'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from '@material-ui/styles'
import { lightTheme } from './themes'
import Notifier from './components/UI/Notifier'
import JavascriptTimeAgo from 'javascript-time-ago'
import es from 'javascript-time-ago/locale/es'
import en from 'javascript-time-ago/locale/en'
import ScrollToTop from './components/UI/ScrollToTop'

// Initialize the desired locales for javascript-time-ago.
JavascriptTimeAgo.locale(es)
JavascriptTimeAgo.locale(en)

const { store, persistor } = configureStore()

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={lightTheme}>
        <SnackbarProvider maxSnack={3}>
          <ConnectedRouter history={history}>
            <ScrollToTop />
            <Notifier />
            <RenderRoutes routes={ROUTES} />
            {/* <Routes /> */}
          </ConnectedRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
)

ReactDOM.render(<App />, document.getElementById('root'))
