import React, { useState } from 'react'

import {
  Edit as EditIcon,
  DeleteOutline as DeleteIcon,
  MoreVert as MoreVertIcon,
  Block as BlockIcon,
  Check as CheckIcon
} from '@material-ui/icons'
import {
  Avatar,
  Grid,
  Typography,
  IconButton,
  Checkbox,
  useMediaQuery,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { employeeActions } from '../../state/actions'
import { useDispatch } from 'react-redux'
import { employeeConstants } from '../../state/types'
import Can from '../../auth/Can'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: '100%'
  },
  avatar: {
    margin: 10,
    width: 30,
    height: 30
  },
  menuItem: { marginTop: theme.spacing(1) },
  menuIcon: {
    marginRight: theme.spacing(1)
  }
}))

const setEmployeeForReducer = (employee) => {
  let employeeRoles = []
  if (employee.roleAdmin) {
    employeeRoles.push('roleAdmin')
  }
  if (employee.roleLeader) {
    employeeRoles.push('roleLeader')
  }
  if (employee.roleUser) {
    employeeRoles.push('roleUser')
  }
  if (employee.roleManageIndicators) {
    employeeRoles.push('roleManageIndicators')
  }
  if (employee.roleManageInitiatives) {
    employeeRoles.push('roleManageInitiatives')
  }
  employee = {
    id: employee.id,
    name: employee.name,
    lastName: employee.lastName,
    mail: employee.mail,
    jobTitle: employee.jobTitle,
    roles: employeeRoles
  }
  return employee
}

const Employee = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { employee } = props
  const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  // eslint-disable-next-line
  const [openAlert, setOpenAlert] = useState(false)
  const [openDeleteUserAlert, setOpenDeleteUserAlert] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)

  const handleOpenDialogForm = () => {
    dispatch({
      type: employeeConstants.DIALOG.OPEN,
      payload: setEmployeeForReducer(employee)
    })
  }

  const options = [
    {
      text: 'Editar',
      action: () => handleOpenDialogForm(),
      disabled: false,
      icon: <EditIcon className={classes.menuIcon} />
    },
    {
      text: employee.isActive ? 'Deshabilitar' : 'Habilitar',
      action: () => setOpenAlert(true),
      disabled: false,
      icon: employee.isActive ? (
        <BlockIcon className={classes.menuIcon} />
      ) : (
        <CheckIcon className={classes.menuIcon} />
      )
    },
    {
      text: 'Eliminar',
      action: () => {
        setOpenDeleteUserAlert(true)
      },
      disabled: false,
      icon: <DeleteIcon className={classes.menuIcon} />
    }
  ]

  return (
    <div className={classes.root}>
      <Grid
        item
        xs
        container
        spacing={2}
        justifyContent='flex-start'
        alignItems='center'
        wrap='nowrap'
      >
        <Grid item xs={2}>
          {employee.isActive ? (
            <Avatar
              alt={employee.fullName}
              className={classes.avatar}
              src={employee.profilePicture}
            />
          ) : (
            <Can
              perform='users:manage'
              yes={() => (
                <Checkbox color='primary' onChange={() => setOpenAlert(true)} />
              )}
            />
          )}
        </Grid>
        <Grid
          item
          xs
          container
          direction={matches ? 'column' : 'row'}
          spacing={1}
          alignItems='center'
          justifyContent={matches ? 'center' : 'flex-start'}
        >
          <Grid item xs md={6}>
            <Typography
              variant='body1'
              align={matches ? 'center' : 'left'}
              style={{ fontWeight: 600 }}
            >
              {/* {employee.name} */}
              {employee.fullName}
            </Typography>
          </Grid>
          <Grid item xs md={6}>
            <Typography variant='body2' align={matches ? 'center' : 'center'}>
              {employee.jobTitle}
            </Typography>
          </Grid>
        </Grid>
        <Can
          perform='users:manage'
          yes={() => (
            <Grid item xs={1}>
              <IconButton
                onClick={(event) => setAnchorEl(event.currentTarget)}
                aria-label='more'
                aria-controls='long-menu'
                aria-haspopup='true'
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                open={openMenu}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option}
                    onClick={() => {
                      option.action()
                      setAnchorEl(null)
                    }}
                    className={classes.menuItem}
                    disabled={option.disabled}
                  >
                    {option.icon}
                    {option.text}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          )}
        />
      </Grid>
      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{`${
          employee.isActive ? 'Deshabilitar' : 'Habilitar'
        } usuario`}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {`¿Confirma que desea ${
              employee.isActive ? 'deshabilitar' : 'habilitar'
            } este usuario?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlert(false)} color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              dispatch(employeeActions.updateStatus(employee))
              setOpenAlert(false)
            }}
            color='primary'
            autoFocus
          >
            {employee.isActive ? 'Deshabilitar' : 'Habilitar'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de confirmación de eliminación */}
      <Dialog
        open={openDeleteUserAlert}
        onClose={() => setOpenDeleteUserAlert(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Eliminar usuario</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            ¿Confirma que desea eliminar este usuario de la organización?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteUserAlert(false)} color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              employee.isActive
                ? dispatch(employeeActions.deleteActive(employee.id))
                : dispatch(employeeActions.deleteInactive(employee.id))
              setOpenDeleteUserAlert(false)
            }}
            color='primary'
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Employee
