import 'chartjs-adapter-date-fns'
import { es } from 'date-fns/locale'

// ajuste de la línea de tiempo para mostrar en tooltip el mes al que corresponde
// según cálculo de YearMonth (ver datasets-generator y dates-calculations)
const monthDict = {
  ene: 'Enero',
  feb: 'Febrero',
  mar: 'Marzo',
  abr: 'Abril',
  may: 'Mayo',
  jun: 'Junio',
  jul: 'Julio',
  ago: 'Agosto',
  sep: 'Septiembre',
  oct: 'Octubre',
  nov: 'Noviembre',
  dic: 'Diciembre'
}

// en las opciones para tooltips, title pasa un array de longitud 1
// en cambio para label, es un conunto de objetos de todos los puntos en el gráfico

// para referencia de customización de footer en tooltip
const footer = (tooltipItems) => {
  // console.log(tooltipItems)
  let sum = 0
  let cleanedTooltips = []
  tooltipItems.forEach((tooltipItem) => {
    cleanedTooltips.push(tooltipItem.label.split(',')[1])
  })
  return cleanedTooltips
}
const setYearlyLineTitle = (tooltipItem) => {
  // console.log(tooltipItem)
  let label = tooltipItem[0].label.split(',')[1]
  return label
}

const setBubbleTitle = (tooltipItem) => {
  let label = tooltipItem[0].label
  let value = tooltipItem[0].formattedValue.split(',')[3]
  return `${label} : ${value}`
}

const setBubbleLabel = (tooltipItem) => {
  return ''
}

const setAnualTitle = (tooltipItems) => {
  // console.log(tooltipItems)
  // return tooltipItems[0].label.split(',')[1]
  return ''
}

const setMensualTitle = (tooltipItems) => {
  const itemsArr = tooltipItems[0].label.split(',')
  const onlyMonth = itemsArr[0].split(' ')[0]
  const onlyYear = itemsArr[1]
  const cleaned = `${monthDict[onlyMonth]},${onlyYear}`
  return cleaned
}

const setWeeklytitle = (tooltipItems) => {
  // console.log(tooltipItems)
  const dayAndMonth = tooltipItems[0].label.split(',')[0]
  const year = tooltipItems[0].label.split(',')[1]
  return `${dayAndMonth},${year}`
}

// para referencia de customización de cada una de las labels para x,y
const setIndividual = (tooltipItems) => {
  for (let prop in tooltipItems) {
    // console.log(prop)
    // console.log(tooltipItems.dataIndex)
    return `${tooltipItems.dataset.label}: ${tooltipItems.parsed.y}`
  }
  return tooltipItems
}

// ---- lineal anual
export const lineYearlyOptions = (indicatorMeasure) => {
  return {
    // acá
    // onHover: function (e, el) {
    //   e.chart.css('cursor', e[0] ? 'pointer' : 'default')
    // },
    responsive: true,
    maintainAspectRatio: false,
    spanGaps: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'year'
        },

        adapters: {
          date: {
            locale: es
          }
        }
      },
      y: {
        title: {
          display: true,
          text: indicatorMeasure
        }
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'center',
        fullWidth: true
      },
      tooltip: {
        callbacks: {
          // footer: footer,
          title: setYearlyLineTitle
        }
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        },
        pan: {
          enabled: true
        }
      }
    }
  }
}

// ---- lineal mensual
export const lineMonthlyOptions = (indicatorMeasure) => {
  return {
    // acá
    // onHover: function (e, el) {
    //   e.chart.css('cursor', e[0] ? 'pointer' : 'default')
    // },
    spanGaps: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month'
        },

        adapters: {
          date: {
            locale: es
          }
        }
      },
      y: {
        title: {
          display: true,
          text: indicatorMeasure
        }
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'center',
        fullWidth: true
      },
      tooltip: {
        callbacks: {
          // footer: footer,
          title: setMensualTitle
        }
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        },
        pan: {
          enabled: true
        }
      }
    }
  }
}

// ---- lineal semanal
export const lineWeeklyOptions = (indicatorMeasure) => {
  return {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'week'
        },

        adapters: {
          date: {
            locale: es
          }
        }
      },
      y: {
        title: {
          display: true,
          text: indicatorMeasure
        }
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'center',
        fullWidth: true
      },
      tooltip: {
        callbacks: {
          // footer: footer,
          title: setWeeklytitle
        }
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        },
        pan: {
          enabled: true
        }
      }
    }
  }
}

// ---- lineal semanal y diario
export const lineDailyOptions = (indicatorMeasure) => {
  return {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day'
        },

        adapters: {
          date: {
            locale: es
          }
        }
      },
      y: {
        title: {
          display: true,
          text: indicatorMeasure
        }
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'center',
        fullWidth: true
      },
      tooltip: {
        callbacks: {
          // footer: footer,
          title: setWeeklytitle
        }
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        },
        pan: {
          enabled: true
        }
      }
    }
  }
}

// --- barras anual
export const barYearlyOptions = (indicatorMeasure) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'year'
        },
        adapters: {
          date: {
            locale: es
          }
        }
      },
      y: {
        title: {
          display: true,
          text: indicatorMeasure
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom'
      },
      tooltip: {
        callbacks: {
          // footer: footer,
          // label: setIndividual,
          title: setAnualTitle
        }
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        },
        pan: {
          enabled: true
        }
      }
    }
  }
}

// --- barras semestral
export const barSemestralOptions = (indicatorMeasure) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: true,
          text: indicatorMeasure
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom'
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        },
        pan: {
          enabled: true
        }
      }
    }
  }
}

// líneas semestrales
export const lineSemestralOptions = (indicatorMeasure) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    spanGaps: true,
    scales: {
      y: {
        title: {
          display: true,
          text: indicatorMeasure
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom'
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        },
        pan: {
          enabled: true
        }
      }
    }
  }
}

export const barOptions = (indicatorMeasure) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'year'
        },
        adapters: {
          date: {
            locale: es
          }
        }
      },
      y: {
        title: {
          display: true,
          text: indicatorMeasure
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom'
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        },
        pan: {
          enabled: true
        }
      }
    }
  }
}

export const barAccumOptions = (indicatorMeasure) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: true,
          text: indicatorMeasure
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom'
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        },
        pan: {
          enabled: true
        }
      }
    }
  }
}

export const lineTotalOptions = (indicatorMeasure) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: true,
          text: indicatorMeasure
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom'
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        },
        pan: {
          enabled: true
        }
      }
    }
  }
}

export const bubbleOptions = (indicatorMeasure, timeUnit) => {
  return {
    // onHover: function (e, el) {
    //   e.chart.css('cursor', e[0] ? 'pointer' : 'default')
    // },
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: timeUnit
        },
        adapters: {
          date: {
            locale: es
          }
        }
      },
      y: {
        title: {
          display: true,
          text: indicatorMeasure
        }
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'center',
        fullWidth: true
      },
      tooltip: {
        // mode: 'single',
        callbacks: {
          // footer: footer,
          title: setBubbleTitle,
          label: setBubbleLabel
        }
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        },
        pan: {
          enabled: true
        }
      }
    }
  }
}

export const sweetOptions = (indicatorMeasure, timeUnit) => {
  return {
    // onHover: function (e, el) {
    //   e.chart.css('cursor', e[0] ? 'pointer' : 'default')
    // },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        },
        pan: {
          enabled: true
        }
      }
    }
  }
}

export const categoricalOptions = (indicatorMeasure) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category'
      },
      y: {
        title: {
          display: true,
          text: indicatorMeasure
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom'
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        },
        pan: {
          enabled: true
        }
      }
    }
  }
}
