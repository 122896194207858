import Axios from '../../axios'
import { userConstants } from '../types'
import { uiActions } from './ui.actions'
// import { push } from "connected-react-router";
import { enqueueSnackbar } from './notification.actions'

const getMeStarted = () => ({ type: userConstants.GET_ME_STARTED })
const getMeSuccess = (data) => ({
  type: userConstants.GET_ME_SUCCESS,
  payload: data.user
})
const getMeFailure = (error) => ({
  type: userConstants.GET_ME_FAILURE,
  payload: error
})

const me = () => {
  return (dispatch) => {
    dispatch(getMeStarted())
    Axios.get('/me')
      .then((response) => {
        dispatch(getMeSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getMeFailure(error.response))
      })
  }
}

const updateStarted = () => ({
  type: userConstants.UPDATE_STARTED
})
const updateSuccess = (user) => ({
  type: userConstants.UPDATE_SUCCESS,
  payload: { user }
})
const updateFailure = (error) => ({
  type: userConstants.UPDATE_FAILURE,
  payload: { error }
})

const update = (id, user) => {
  return (dispatch) => {
    dispatch(updateStarted())
    dispatch(uiActions.startLoading())
    Axios.patch(
      `/users/${id}`,
      {
        user: user
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then((response) => {
        dispatch(updateSuccess(response.data.user[0]))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Usuario actualizado correctamente'
          })
        )
      })
      .catch((error) => {
        dispatch(updateFailure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido actualizar el usuario'
          })
        )
      })
  }
}

function invite(data) {
  // TODO: testear
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.post('/invitation', data)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Usuario invitado exitosamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido invitar al usuario'
          })
        )
      })
  }

  function start() {
    return {
      type: userConstants.INVITE_STARTED
    }
  }

  function success(data) {
    return {
      type: userConstants.INVITE_SUCCESS,
      payload: data.user
    }
  }

  function failure(error) {
    return {
      type: userConstants.INVITE_FAILURE,
      payload: error
    }
  }
}

function updateCompany(companyId, userId) {
  return (dispatch) => {
    Axios.patch(`/users/${userId}`, { user: { companyId: companyId } }).then(
      (response) => {
        dispatch({
          type: userConstants.UPDATE_SUCCESS,
          payload: { user: response.data.user[0] }
        })
      }
    )
  }
}

export const userActions = {
  invite,
  updateCompany,
  update,
  me
}
