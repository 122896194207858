export const socialConstants = {
  PROFILE: {
    SOCIAL_GET_PROFILE_STARTED: "SOCIAL_GET_PROFILE_STARTED",
    SOCIAL_GET_PROFILE_SUCCESS: "SOCIAL_GET_PROFILE_SUCCESS",
    SOCIAL_GET_PROFILE_FAILURE: "SOCIAL_GET_PROFILE_FAILURE",

    SOCIAL_PROFILE_FOLLOW_STARTED: "SOCIAL_PROFILE_FOLLOW_STARTED",
    SOCIAL_PROFILE_FOLLOW_SUCCESS: "SOCIAL_PROFILE_FOLLOW_SUCCESS",
    SOCIAL_PROFILE_FOLLOW_FAILURE: "SOCIAL_PROFILE_FOLLOW_FAILURE",
    SOCIAL_PROFILE_UNFOLLOW_STARTED: "SOCIAL_PROFILE_UNFOLLOW_STARTED",
    SOCIAL_PROFILE_UNFOLLOW_SUCCESS: "SOCIAL_PROFILE_UNFOLLOW_SUCCESS",
    SOCIAL_PROFILE_UNFOLLOW_FAILURE: "SOCIAL_PROFILE_UNFOLLOW_FAILURE",

    SOCIAL_PROFILE_FOLLOW_HASHTAG: "SOCIAL_PROFILE_FOLLOW_HASHTAG",
    SOCIAL_PROFILE_UNFOLLOW_HASHTAG: "SOCIAL_PROFILE_UNFOLLOW_HASHTAG",
  },
};
