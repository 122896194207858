import React, { useEffect, useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import NoContent from '../components/UI/NoContent'
import { Typography, Fab, Container,Button,Grid, Avatar } from '@material-ui/core'
import Progress from '../components/UI/Progress'
import Can from '../auth/Can'
import { Add as AddIcon } from '@material-ui/icons'
import ShowReports from '../components/Reports/ShowReports'
import Dialog from '../components/UI/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { reportActions, uiActions } from '../state/actions'
import GlobalReportsDialogTabs from '../components/Reports/GlobalReportsDialogTabs'
import { reportConstants } from '../state/types'
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
// import { FormField } from 'boosted-materialui-forms'
import { Link } from 'react-router-dom'
import { SketchPicker } from 'react-color';
import FormField from '../components/UI/FormFields'
import Axios from '../axios'
import ButtonWithLoading from '../components/UI/ButtonWithLoading'
import { enqueueSnackbar } from '../state/actions/notification.actions'
// "/Users/anibalurrea/Desktop/Inosoft/Tero/tero-frontend-master/src/state/actions/notification.actions"


const useStyles = makeStyles((theme) => ({
  root: {},
  fab: { position: 'fixed', bottom: theme.spacing(2), right: theme.spacing(2) }
}))

const Paso1 = () => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const { reports } = useSelector((state) => state.reports)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    // const [openCreateReport, setOpenCreateReport] = useState(false)
    const { open: openDialog } = useSelector(
      (state) => state.reports.globalReportDialog
    )

    const report = JSON.parse(sessionStorage.getItem('report'))
    const [selectedFile, setSelectedFile] = useState();
    const [selectedColorPdf, setSelectedColorPdf] = useState('');
    const [selectedColorTabla, setSelectedColorTabla] = useState('');
    const [selectedColorGrafico, setSelectedColorGrafico] = useState('');
    const [selectedColorTitulos, setSelectedColorTitulos] = useState('');
    const [template, setTemplate] = useState('');
    const { user } = useSelector((state) => state.user)
    // console.log(value);
  
    useEffect(() => {
      document.title = 'Reportes - Paso 1'
      console.log(report)
      if(report.colorLetra != null){
        setSelectedColorPdf(report.colorLetra)
      } else {
        setSelectedColorPdf('#fff')
      }

      if(report.colorTabla != null){
        setSelectedColorTabla(report.colorTabla)
      } else {
        setSelectedColorTabla('#fff')
      }

      if(report.colorGrafico != null){
        setSelectedColorGrafico(report.colorGrafico)
      } else {
        setSelectedColorGrafico('#fff')
      }

      if(report.colorTitulo != null){
        setSelectedColorTitulos(report.colorTitulo)
      } else {
        setSelectedColorTitulos('#fff')
      }

      // Axios.get(`/reporttemplates/${report.companyId}`)
      Axios.get(`/customreports/template/get/${report.companyId}`)
        .then((response) => {

            updateData('managerId', 'options', [
              clearOption,
              ...response.data.reports.map((employee) => ({
                ...employee,
                label: employee.name,
                value: employee
              }))
            ])        

          })
          .catch((err) => {
            console.log(err.response);
          })

    }, [])

    const test = async () => {

      // console.log(selectedFile
        // console.log(selectedColorPdf.hex)
        // console.log(selectedColorTabla.hex)
        // console.log(selectedColorGrafico.hex)
        // console.log(selectedColorTitulos.hex)
        // console.log(selectedColorTitulos)

        report.colorGrafico = selectedColorGrafico.hex == undefined ? selectedColorTitulos : selectedColorGrafico.hex
        report.colorLetra = selectedColorPdf.hex == undefined ? selectedColorPdf : selectedColorPdf.hex
        report.colorTabla = selectedColorTabla.hex == undefined ? selectedColorTabla : selectedColorTabla.hex
        report.colorTitulo = selectedColorTitulos.hex == undefined ? selectedColorTitulos : selectedColorTitulos.hex

        // report.colorGrafico = selectedColorGrafico.hex
        // report.colorLetra = selectedColorPdf.hex
        // report.colorTabla = selectedColorTabla.hex
        // report.colorTitulo = selectedColorTitulos.hex

        sessionStorage.setItem('report', JSON.stringify(report))

      const formData = new FormData();
    
      formData.append("colorLetra", selectedColorPdf.hex == undefined ? selectedColorPdf : selectedColorPdf.hex);
      formData.append("colorGrafico", selectedColorGrafico.hex == undefined ? selectedColorTitulos : selectedColorGrafico.hex);      
      formData.append("colorTabla", selectedColorTabla.hex == undefined ? selectedColorTabla : selectedColorTabla.hex);
      formData.append("colorTitulo", selectedColorTitulos.hex == undefined ? selectedColorTitulos : selectedColorTitulos.hex);
      formData.append("logo", selectedFile);
      formData.append("url", report.logoReporte);
      formData.append("aux", selectedFile == undefined ? 1 : 0);

      Axios.post(`/customreports/saveColors/${report.id}`, formData)
        .then((response) => {
          console.log(response)
          if(response.data.status == 1){
            report.logoReporte = response.data.report.list_init_repo.logoReporte
            sessionStorage.setItem('report', JSON.stringify(report))
            dispatch(
              enqueueSnackbar({
                variant: 'success',
                message: 'Se guardo correctamente'
              })
            ) 

          }
            // console.log(report);
          })
          .catch((err) => {
            console.log(err.response);
          })

    }

    const updateField = async (event) => {

      if (confirm('Al cargar una plantilla, perderas todo lo que ya tienes guardado en este reporte. ¿Deseas continuar?')) {
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Espera un momento, se esta cargando la plantilla...'
          })
        )      
        setTimeout(() => {
          dispatch(uiActions.startLoading())
        }, 1500)  
        console.log(event.target.value);

        const aux = event.target.value
        aux.esTemplate = 0
        aux.id = report.id
        aux.userId = user.id
        var a = aux.dateStart;
        var b = a.split("T");
        console.log(b[0]);
        aux.dateStart = b[0];

        var aa = aux.dateFinish;
        var bb = aa.split("T");
        aux.dateFinish = bb[0];
        console.log(aux)

        setSelectedColorPdf(aux.colorLetra)
        setSelectedColorTabla(aux.colorTabla)
        setSelectedColorGrafico(aux.colorGrafico)
        setSelectedColorTitulos(aux.colorTitulo)

        sessionStorage.setItem('report', JSON.stringify(aux))

        setValues((prev) => {
          return {
            ...prev,
            [event.target.name]: {
              ...prev[event.target.name],
              value: event.target.value
            }
          }
        })

        testPaso1(aux)
      } else {
        // Do nothing!
        console.log('Plantilla no cargada');
        // alert("NO")
      }

      
    }

    const testPaso1 = (aux) => {      
      
      console.log(aux)
      //   report.colorGrafico = selectedColorGrafico.hex == undefined ? selectedColorTitulos : selectedColorGrafico.hex
      //   report.colorLetra = selectedColorPdf.hex == undefined ? selectedColorPdf : selectedColorPdf.hex
      //   report.colorTabla = selectedColorTabla.hex == undefined ? selectedColorTabla : selectedColorTabla.hex
      //   report.colorTitulo = selectedColorTitulos.hex == undefined ? selectedColorTitulos : selectedColorTitulos.hex

      //   sessionStorage.setItem('report', JSON.stringify(report))

      const formData = new FormData();
    
      formData.append("colorLetra", aux.colorLetra);
      formData.append("colorGrafico", aux.colorGrafico);      
      formData.append("colorTabla", aux.colorTabla);
      formData.append("colorTitulo", aux.colorTitulo);
      formData.append("logo", '');
      formData.append("url", aux.logoReporte);
      formData.append("aux", 1);

      Axios.post(`/customreports/saveColors/${report.id}`, formData)
        .then((response) => {
          console.log(response)
          if(response.data.status == 1){
            // report.logoReporte = response.data.report.list_init_repo.logoReporte
            // sessionStorage.setItem('report', JSON.stringify(report))
            testPaso2(aux)
          }
            // console.log(report);
          })
          .catch((err) => {
            console.log(err.response);
          })

    }

    const testPaso2 = (aux) => {
      console.log(aux)
      const dataToSubmit = {
        report : {
          nombreEmpresa : aux.nombreEmpresa,
          ubiSede : aux.ubicacionSede,
          actividad : aux.actividad,
          marca : aux.marca,
          productos : aux.productos,
          servicios : aux.servicios,
          ubiOper : aux.ubicacionOper,
          propiedades : aux.propiedades,
          formaJuridica : aux.formaJuridica,
          mercadoServicios : aux.mercadoServicios,
          userId : user.id
        }
      }

      Axios.post(`/customreports/stepTwo/${report.id}`, dataToSubmit)
        .then((response) => {
          console.log(response);
          testPaso3(aux)
        })
        .catch(() => {
          
        })

    }

    const testPaso3 = (aux) => {
      console.log(aux)
      const dataToSubmit = {
        report : {
          name : aux.name,
          description : aux.description,
          dateStart : aux.dateStart,
          dateFinish : aux.dateFinish,
          companyId : report.companyId,
          materialityId : 127,
          userId : user.id,
          esTemplate: 0
        }
      }

      Axios.patch(`/customreports/${report.id}`, dataToSubmit)
        .then((response) => {
          console.log(response);
          testPaso4(aux)
          // dispatch(uiActions.endLoading())
          // dispatch(
          //   enqueueSnackbar({
          //     variant: 'success',
          //     message: 'Espera un momento, se esta cargando la plantilla...'
          //   })
          // )

          // setTimeout(() => {
          //   window.location.replace("reportes")
          // }, 1500)          
        })
        .catch(() => {
          
        })

    }

    const testPaso4 = (aux) => {
      console.log(aux)

      for (let i = 0; i < aux.reportInitiative.length; i++) {
        
        console.log(aux.reportInitiative[i].initiativeId);

        const dataToSubmit = {
          report : {
            initiativeId : aux.reportInitiative[i].initiativeId,
            reportId : aux.id
          }
        }

        Axios.post(`customreports/createReportInitiative/`, dataToSubmit)
        .then((response) => {
            console.log(response);
            if(i == aux.reportInitiative.length - 1){
              dispatch(uiActions.endLoading())

              dispatch(
                enqueueSnackbar({
                  variant: 'success',
                  message: 'Plantilla cargada'
                })
              )

              setTimeout(() => {
                window.location.replace("reportes")
              }, 1000)   
            }
            // getData();
          })
          .catch((err) => {
            console.log(err.response);
          })            
        
      }

    }

    const changeColorPdf = (color) => {
      // console.log(report);   
      // console.log(color);
      console.log("LETRAS")
      setSelectedColorPdf(color)
      // report.colorLetra = color.hex;
      // sessionStorage.setItem('report', JSON.stringify(report))
      // console.log(report);
    }
    
    const changeColorTabla = (color) => {     
      // console.log(report); 
      // console.log(color);
      console.log("TABLA")
      setSelectedColorTabla(color)
      // report.colorTabla = color.hex;
      // sessionStorage.setItem('report', JSON.stringify(report))
      // console.log(report);
    }

    const changeColorGrafico = (color) => {
      // console.log(report); 
      // console.log(color);
      console.log("GRAFICO")
      setSelectedColorGrafico(color)
      // report.colorGrafico = color.hex;
      // sessionStorage.setItem('report', JSON.stringify(report))
      // console.log(report);
    }

    const changeColorTitulos = (color) => {
      // console.log(report); 
      // console.log(color);
      console.log("TITULOS")
      setSelectedColorTitulos(color)
      // report.colorTitulo = color.hex;
      // sessionStorage.setItem('report', JSON.stringify(report))
      // console.log(report);
    }
  
    const changeFile = (event) => {
      console.log(event.target.files[0]);
      setSelectedFile(event.target.files[0]);
      // setIsSelected(true);
    };

    const updateData = (field, prop, value) => {
      console.log(field);
      console.log(value);
      setValues((prev) => ({
        ...prev,
        [field]: {
          ...prev[field],
          [prop]: value
        }
      }))
    }

    const clearOption = { label: 'Limpiar Filtro', value: '' }

    const sel = []

    const [formData, setValues] = useState({
      managerId: {
        element: 'select',
        value: '',
        options: [],
        config: {
            id: 'manager',
            name: 'managerId',
            label: 'Selecciona una Plantilla (opcional)',
            placeholder: 'Selecciona una Plantilla (opcional)',
            variant: 'outlined',
            margin: 'normal',
            fullWidth: true,
            select: true
        },
        validation: {
          required: true
        },
      }
    })

    return (
        <div className={classes.root}>
            <Fragment>
              <Container>
                  <br>
                  </br>

                  {/* <center><h3>Paso 1 - Información general del reporte</h3> */}
                  <center><h3>Paso 1 - Crea un nuevo reporte</h3>                  
                    <h4>Crea un nuevo reporte a partir de un template (plantilla) o seleccionando los temas ASG y contenidos que necesites. Además, puedes solicitar validación de los contenidos por parte de otros usuarios.</h4>
                  </center>

                  <br>
                  </br>
                  <Grid container alignItems="center" display="flex" justifyContent="center" style={{width:"auto"}} direction="column">
                      <Grid container spacing={2} width={500}>

                      {/* <Grid item xs={12}>
                                <center>
                                  <h5>Selecciona un template predefinido</h5>
                                </center>
                        </Grid> */}
                      {report.esTemplate == 0 ? (
                        <Grid item xs={12}>
                                <center> 
                                <FormField
                                  id='managerId'
                                  formData={formData.managerId}
                                  change={(event) => updateField}
                                />
                                
                                </center>
                        </Grid>
                      ) : '' }                        

                        {report.logoReporte != null ? (
                        <>
                          <Grid item xs={6}>
                                <center>
                                  <h5>Selecciona un logo para el reporte PDF</h5>
                                </center>
                          </Grid>
                          <Grid item xs={6}>
                                <center>
                                  <h5>Vista previa de logo cargado</h5>
                                </center>
                          </Grid>
                          <Grid item xs={6}>
                            <center> 
                              <input
                                type="file"
                                name="file"
                                onChange={changeFile}
                              />
                            </center>
                          </Grid>
                          <Grid item xs={6}>
                            <center> 
                            <Avatar src={report.logoReporte}>
                                  Logo
                                </Avatar>
                            </center>
                          </Grid>
                        </>
                        ) : (
                          <>
                          <Grid item xs={12}>
                                <center>
                                  <h5>Selecciona un logo para el reporte PDF</h5>
                                </center>
                          </Grid>
                            <Grid item xs={12}>
                              <center> 
                                <input
                                  type="file"
                                  name="file"
                                  onChange={changeFile}
                                />
                                {/* <Avatar src={report.logoReporte}>
                                  Logo
                                </Avatar> */}
                              </center>
                            </Grid>
                          </>
                          ) }

                        
                        <Grid item xs={6}>
                                <center>
                                  <h5>Selecciona un color para las letras del reporte PDF</h5>
                                </center>
                        </Grid>
                        <Grid item xs={6}>
                                <center>
                                  <h5>Selecciona un color para las tablas del reporte PDF</h5>
                                </center>
                        </Grid>
                        <Grid item xs={6}>
                                <center> 
                                  <SketchPicker 
                                    color={selectedColorPdf}
                                    onChangeComplete={ changeColorPdf }
                                  /> 
                                </center>
                        </Grid>
                        <Grid item xs={6}>
                                <center> 
                                <SketchPicker 
                                    color={selectedColorTabla}
                                    onChangeComplete={ changeColorTabla }
                                  /> 
                                </center>
                        </Grid>
                        <Grid item xs={6}>
                                <center>
                                  <h5>Selecciona un color para los graficos del reporte PDF</h5>
                                </center>
                        </Grid>
                        <Grid item xs={6}>
                                <center>
                                  <h5>Selecciona un color para los titulos del reporte PDF</h5>
                                </center>
                        </Grid>
                        <Grid item xs={6}>
                                <center> 
                                <SketchPicker 
                                    color={selectedColorGrafico}
                                    onChangeComplete={ changeColorGrafico }
                                  /> 
                                </center>
                        </Grid>
                        <Grid item xs={6}>
                                <center> 
                                <SketchPicker 
                                    color={selectedColorTitulos}
                                    onChangeComplete={ changeColorTitulos }
                                  /> 
                                </center>
                        </Grid>
                        <Grid item xs={12}>
                                <center> 
                                <ButtonWithLoading
                                  text='Guardar'
                                  onClick={test}
                                  fullWidth
                                />
                                </center>
                        </Grid>
                      </Grid>
                  </Grid>

                  
                  {/* <Button><i className="fas fa-file-word"></i></Button> */}                  

              </Container>  
                <br>
                </br>
            </Fragment>
        </div>
    )
}

export default Paso1