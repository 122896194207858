import React, { useEffect, useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import NoContent from '../components/UI/NoContent'
import { Typography, Fab, Container,Button,Grid,Box } from '@material-ui/core'
import Progress from '../components/UI/Progress'
import Can from '../auth/Can'
import ShowReports from '../components/Reports/ShowReports'
import Dialog from '../components/UI/Dialog'
import { useDispatch, useSelector,connect } from 'react-redux'
import { reportActions } from '../state/actions'
import GlobalReportsDialogTabs from '../components/Reports/GlobalReportsDialogTabs'
import FormField from '../components/UI/FormFields'
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {
  validForm,
  updateFormData,
  generateReduxData
} from '../helpers/form.helper'
import ButtonWithLoading from '../components/UI/ButtonWithLoading'
import { enqueueSnackbar, indicatorActions } from '../state/actions'
// import ButtonWithLoading from '../UI/ButtonWithLoading'
/* import DesktopDatePicker from '@mui/lab/DesktopDatePicker'; */

const Paso2 = (props) => {

  const dispatch = useDispatch();
  const report = JSON.parse(sessionStorage.getItem('report'))

  const { user } = useSelector((state) => state.user)

  useEffect(() => {
    document.title = 'Reportes - Paso 2'

    // if(report.nombreEmpresa.length > 0){
    //   formData.name.value = report.nombreEmpresa;
    // }
    
    console.log(report);
  }, [])

  const handleChangeDate = (field) => (date) => {
    setValues(updateFormData(formData, field, date))
  }

  const updateForm = (event) => {
    console.log(event.target.name);
    event.preventDefault()
    setValues(updateFormData(formData, event.target.name, event.target.value))    

    if(event.target.name == 'name'){
      report.nombreEmpresa = event.target.value;
      sessionStorage.setItem('report', JSON.stringify(report))
    } else if(event.target.name == 'ubicacion'){
      report.ubicacionSede = event.target.value;
      sessionStorage.setItem('report', JSON.stringify(report))
    } else if(event.target.name == 'actividades'){
      report.actividad = event.target.value;
      sessionStorage.setItem('report', JSON.stringify(report))
    } else if(event.target.name == 'marcas'){
      report.marca = event.target.value;
      sessionStorage.setItem('report', JSON.stringify(report))
    } else if(event.target.name == 'productos'){
      report.productos = event.target.value;
      sessionStorage.setItem('report', JSON.stringify(report))
    } else if(event.target.name == 'servicios'){
      report.servicios = event.target.value;
      sessionStorage.setItem('report', JSON.stringify(report))
    } else if(event.target.name == 'operaciones'){
      report.ubicacionOper = event.target.value;
      sessionStorage.setItem('report', JSON.stringify(report))
    } else if(event.target.name == 'propiedades'){
      report.propiedades = event.target.value;
      sessionStorage.setItem('report', JSON.stringify(report))
    } else if(event.target.name == 'forma'){
      report.formaJuridica = event.target.value;
      sessionStorage.setItem('report', JSON.stringify(report))
    } else if(event.target.name == 'mercados'){
      report.mercadoServicios = event.target.value;
      sessionStorage.setItem('report', JSON.stringify(report))
    } else {
      console.log("no name");
    }

  }

  

  const test = () => {
    console.log(formData);

    console.log(formData.name.value);

    const dataToSubmit = {
      report : {
        nombreEmpresa : formData.name.value,
        ubiSede : formData.ubicacion.value,
        actividad : formData.actividades.value,
        marca : formData.marcas.value,
        productos : formData.productos.value,
        servicios : formData.servicios.value,
        ubiOper : formData.operaciones.value,
        propiedades : formData.propiedades.value,
        formaJuridica : formData.forma.value,
        mercadoServicios : formData.mercados.value,
        userId : user.id
      }
    }

    dispatch(indicatorActions.updateReportEmpresa(dataToSubmit, report.id))
    

  //   {
  //     "report": 
  //     { 
  //         "nombreEmpresa": "name",
  //         "ubiSede": "ubi",
  //         "actividad": "activ",
  //         "marca": "marc",
  //         "productos": "prod",
  //         "servicios": "serv",
  //         "ubiOper": "ubiOp",
  //         "propiedades": "prop",
  //         "formaJuridica": "formJur",
  //         "mercadoServicios": "mercserv"
  //     }
  // }

    // dispatch(
    //   enqueueSnackbar({
    //     variant: 'success',
    //     message: 'Se guardo correctamente la presentación de la empresa'
    //   })
    // )
  }

  const [formData, setValues] = useState({
    name: {
      element: 'input',
      value: report.nombreEmpresa == null ? '' : report.nombreEmpresa,
      config: {
        id: 'name',
        name: 'name',
        label: 'Nombre empresa',
        type: 'text',
        placeholder: 'Nombre empresa',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: true
      },
    },
    ubicacion: {
      element: 'input',
      value: report.ubicacionSede == null ? '' : report.ubicacionSede,
      config: {
        id: 'ubicacion',
        name: 'ubicacion',
        label: 'Ubicación de la sede',
        type: 'text',
        placeholder: 'Ubicación de la sede',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: true
      },
    },
    actividades: {
      element: 'input',
      value: report.actividad == null ? '' : report.actividad,
      config: {
        id: 'actividades',
        name: 'actividades',
        label: 'Actividades',
        type: 'text',
        placeholder: 'Actividades',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: true
      },
    },
    marcas: {
      element: 'input',
      value: report.marca == null ? '' : report.marca,
      config: {
        id: 'marcas',
        name: 'marcas',
        label: 'Marcas',
        type: 'text',
        placeholder: 'Marcas',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: true
      },
    },
    productos: {
      element: 'input',
      value: report.productos == null ? '' : report.productos,
      config: {
        id: 'productos',
        name: 'productos',
        label: 'Productos',
        type: 'text',
        placeholder: 'Productos',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: true
      },
    },
    servicios: {
      element: 'input',
      value: report.servicios == null ? '' : report.servicios,
      config: {
        id: 'servicios',
        name: 'servicios',
        label: 'Servicios',
        type: 'text',
        placeholder: 'Servicios',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: true
      },
    },
    operaciones: {
      element: 'input',
      value: report.ubicacionOper == null ? '' : report.ubicacionOper,
      config: {
        id: 'operaciones',
        name: 'operaciones',
        label: 'Ubicaciones de las operaciones',
        type: 'text',
        placeholder: 'Ubicaciones de las operaciones',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: true
      },
    },
    propiedades: {
      element: 'input',
      value: report.propiedades == null ? '' : report.propiedades,
      config: {
        id: 'propiedades',
        name: 'propiedades',
        label: 'Propiedades',
        type: 'text',
        placeholder: 'Propiedades',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: true
      },
    },
    forma: {
      element: 'input',
      value: report.formaJuridica == null ? '' : report.formaJuridica,
      config: {
        id: 'forma',
        name: 'forma',
        label: 'Forma juridica',
        type: 'text',
        placeholder: 'Forma juridica',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: true
      },
    },
    mercados: {
      element: 'input',
      value: report.mercadoServicios == null ? '' : report.mercadoServicios,
      config: {
        id: 'mercados',
        name: 'mercados',
        label: 'Mercados servicios',
        type: 'text',
        placeholder: 'Mercados servicios',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: true
      },
    },
  })

  const activeEmployees =
    props.activeEmployees.length > 0 ? (
      <>
        {props.activeEmployees.map((employee) => {
          // console.log(employee)
        
         
        })}
        
      </>
    ) : (
      ''
    )

    return (
        <Container>
          <br>
          </br>
          <center><h3>Paso 2 - Presentación de la empresa</h3>
            <h4>Esta sección corresponde a la presentación de la empresa.</h4></center>
            <br>
            </br>
            <form>
            <Grid container alignItems="center" display="flex" justifyContent="center" style={{width:"auto"}} direction="column">
              <Grid container spacing={2} width={500}>
                <Grid item xs={6}>
                <FormField
                    id={'name'}
                    formData={formData.name}
                    change={() => updateForm}
                  />
                </Grid>
                <Grid item xs={6}>
                <FormField
                    id={'ubicacion'}
                    formData={formData.ubicacion}
                    change={() => updateForm}
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* nombre */}
                  <FormField
                    id={'actividades'}
                    formData={formData.actividades}
                    change={() => updateForm}
                  />
                </Grid>
                <Grid item xs={6}>
                <FormField
                    id={'marcas'}
                    formData={formData.marcas}
                    change={() => updateForm}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormField
                      id={'productos'}
                      formData={formData.productos}
                      change={() => updateForm}
                    />
                </Grid>
                <Grid item xs={6}>
                  <FormField
                      id={'servicios'}
                      formData={formData.servicios}
                      change={() => updateForm}
                    />
                </Grid>
                <Grid item xs={6}>
                  <FormField
                        id={'operaciones'}
                        formData={formData.operaciones}
                        change={() => updateForm}
                      />
                </Grid>
                <Grid item xs={6}>
                  <FormField
                        id={'propiedades'}
                        formData={formData.propiedades}
                        change={() => updateForm}
                      />
                </Grid>
                <Grid item xs={6}>
                  <FormField
                        id={'forma'}
                        formData={formData.forma}
                        change={() => updateForm}
                      />
                </Grid>
                <Grid item xs={6}>
                  <FormField
                        id={'mercados'}
                        formData={formData.mercados}
                        change={() => updateForm}
                      />
                </Grid>
                <Grid item xs={12}>
                <ButtonWithLoading
                text='Guardar'
                onClick={test}
                fullWidth
              />
                </Grid>
                </Grid>
              </Grid>

              {/* <div>
                <ButtonWithLoading
                text='Agregar'
                fullWidth
                onClick={test}
                />
            </div> */}
              <br>
            </br>
              
            </form>
      </Container>
    )
}

function mapStateToProps(state) {
  return {
    activeEmployees: state.employees.active,
    inactiveEmployees: state.employees.inactives
  }
}

export default connect(mapStateToProps)(Paso2)
