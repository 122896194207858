export const postConstants = {
  NEW_POST_DIALOG: {
    OPEN: "NEW_POST_DIALOG_OPEN",
    CLOSE: "NEW_POST_DIALOG_CLOSE",
  },

  FETCH_STARTED: "POST_FETCH_STARTED",
  FETCH_FAILURE: "POST_FETCH_FAILURE",
  FETCH_SUCCESS: "POST_FETCH_SUCCESS",

  FETCH_MORE_STARTED: "POST_FETCH_MORE_STARTED",
  FETCH_MORE_FAILURE: "POST_FETCH_MORE_FAILURE",
  FETCH_MORE_SUCCESS: "POST_FETCH_MORE_SUCCESS",

  FETCH_ONE_STARTED: "POST_FETCH_ONE_STARTED",
  FETCH_ONE_FAILURE: "POST_FETCH_ONE_FAILURE",
  FETCH_ONE_SUCCESS: "POST_FETCH_ONE_SUCCESS",

  CREATE_STARTED: "POST_CREATE_STARTED",
  CREATE_SUCCESS: "POST_CREATE_SUCCESS",
  CREATE_FAILURE: "POST_CREATE_FAILURE",

  UPDATE_STARTED: "POST_UPDATE_STARTED",
  UPDATE_SUCCESS: "POST_UPDATE_SUCCESS",
  UPDATE_FAILURE: "POST_UPDATE_FAILURE",

  DELETE_STARTED: "POST_DELETE_STARTED",
  DELETE_SUCCESS: "POST_DEELETE_SUCCESS",
  DELETE_FAILURE: "POST_DELETE_FAILURE",

  COMMENT_CREATE_STARTED: "POST_COMMENT_CREATE_STARTED",
  COMMENT_CREATE_SUCCESS: "POST_COMMENT_CREATE_SUCCESS",
  COMMENT_CREATE_FAILURE: "POST_COMMENT_CREATE_FAILURE",

  COMMENT_DELETE_STARTED: "POST_COMMENT_DELETE_STARTED",
  COMMENT_DELETE_SUCCESS: "POST_COMMENT_DELETE_SUCCESS",
  COMMENT_DELETE_FAILURE: "POST_COMMENT_DELETE_FAILURE",

  COMMENT_UPDATE_STARTED: "POST_COMMENT_UPDATE_STARTED",
  COMMENT_UPDATE_SUCCESS: "POST_COMMENT_UPDATE_SUCCESS",
  COMMENT_UPDATE_FAILURE: "POST_COMMENT_UPDATE_FAILURE",

  COMMENT_FETCH_STARTED: "POST_COMMENT_FETCH_STARTED",
  COMMENT_FETCH_SUCCESS: "POST_COMMENT_FETCH_SUCCESS",
  COMMENT_FETCH_FAILURE: "POST_COMMENT_FETCH_FAILURE",

  ADD_LIKE_SUCCESS: "POST_ADD_LIKE_SUCCESS",
  ADD_LIKE_FAILURE: "POST_ADD_LIKE_FAILURE",
  REMOVE_LIKE_SUCCESS: "POST_REMOVE_LIKE_SUCCESS",
  REMOVE_LIKE_FAILURE: "POST_REMOVE_LIKE_FAILURE",

  PROFILE_FOLLOW_STARTED: "POST_PROFILE_FOLLOW_STARTED",
  PROFILE_FOLLOW_SUCCESS: "POST_PROFILE_FOLLOW_SUCCESS",
  PROFILE_FOLLOW_FAILURE: "POST_PROFILE_FOLLOW_FAILURE",
  PROFILE_UNFOLLOW_STARTED: "POST_PROFILE_UNFOLLOW_STARTED",
  PROFILE_UNFOLLOW_SUCCESS: "POST_PROFILE_UNFOLLOW_SUCCESS",
  PROFILE_UNFOLLOW_FAILURE: "POST_PROFILE_UNFOLLOW_FAILURE",

  FETCH_LIKES_SUCCESS: "POST_FETCH_LIKES_SUCCESS",
};
