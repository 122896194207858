import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ShowReport from './ShowReport'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {},
  reportContainer: {},
  topMargin: { marginTop: theme.spacing(5) }
}))

/**
 * Despliega los reportes
 */

const ShowReports = ({ reports }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {typeof reports !== 'string' &&
        reports.map((report, index) => (
          <div
            key={index}
            className={clsx({
              [classes.topMargin]: index !== 0
            })}
          >
            <ShowReport report={report} />
          </div>
        ))}
    </div>
  )
}

export default ShowReports
