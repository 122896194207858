import { uiConstants } from "../types";

function startLoading() {
  return {
    type: uiConstants.START_LOADING
  };
}

function endLoading() {
  return {
    type: uiConstants.END_LOADING
  };
}

function showSnackbar(data) {
  return {
    type: uiConstants.SHOW_SNACKBAR,
    payload: { ...data, key: `${new Date().getTime()}` }
  };
}

function hideSnackbar(key) {
  return {
    type: uiConstants.HIDE_SNACKBAR,
    payload: key
  };
}

export const uiActions = {
  startLoading,
  endLoading,
  showSnackbar,
  hideSnackbar
};

// export default uiActions;
