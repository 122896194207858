import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { FormField, useFormValidator } from 'boosted-materialui-forms'
import SubmitButton from '../UI/ButtonWithLoading'
import { useDispatch } from 'react-redux'
import { reportActions } from '../../state/actions/report.actions'
import { reportConstants } from '../../state/types'

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(1) }
}))

const ReportTab = ({ create, report }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleChange = (event, options) => {
    event.preventDefault()
    console.log(event.target)
    console.log(options)
    const { name, value } = event.target
    const { valid } = options
    setFormData((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], value: value, valid: valid }
    }))
  }

  const handleChangeDate = (name) => (date) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], value: date }
    }))
  }

  const [formData, setFormData] = useState({
    name: {
      value: report?.name,
      config: {
        name: 'name',
        label: 'Nombre',
        fullWidth: true,
        margin: 'normal',
        required: true,
        size: 'normal'
      },
      onChange: (event, options) => handleChange(event, options),
      validations: {
        required: { message: 'Debe ingresar nombre del reporte' }
      },
      valid: report ? true : false
    },
    materiality: {
      element: 'select',
      value: report?.content?.materiality ? true : 'false',
      config: {
        name: 'materiality',
        label: 'Análisis de materialidad',
        fullWidth: true,
        margin: 'normal',
        size: 'normal'
      },
      options: [
        { label: 'Mostrar', value: true },
        { label: 'Ocultar', value: false }
      ],
      onChange: (event, options) => handleChange(event, options),
      valid: true
    },
    start: {
      element: 'date',
      value: report ? report.start : new Date(),
      config: {
        name: 'start',
        label: 'Fecha de inicio',
        fullWidth: true,
        margin: 'normal',
        required: true
      },
      onChange: handleChangeDate('start'),
      validations: { required: { message: 'Debe ingresar fecha de inicio' } },
      valid: true
    },
    finish: {
      element: 'date',
      value: report ? report.finish : new Date(),
      config: {
        name: 'endDate',
        label: 'Fecha de término',
        fullWidth: true,
        margin: 'normal',
        required: true
      },
      onChange: handleChangeDate('finish'),
      validations: { required: { message: 'Debe ingresar fecha de término' } },
      valid: true
    },
    ceoWords: {
      value: report?.ceoWords,
      config: {
        name: 'ceoWords',
        label: 'Palabras iniciales',
        multiline: true,
        rows: 4,
        maxRows: 4,
        fullWidth: true,
        margin: 'normal'
      },
      onChange: (event, options) => handleChange(event, options),
      valid: true
    }
  })

  const [formIsValid] = useFormValidator(formData)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [submitOk, setSubmitOk] = useState(false)
  const handleSubmit = (event) => {
    event.preventDefault()
    const dataToSubmit = {
      report: {
        ceoWords: formData.ceoWords.value,
        name: formData.name.value,
        start: formData.start.value,
        finish: formData.finish.value,
        content: {
          materiality: formData.materiality.value ? true : false
        }
      }
    }
    const submit = async () => {
      setLoading(true)
      setError(false)
      setSubmitOk(false)
      create && !report?.id
        ? await dispatch(reportActions.create(dataToSubmit))
            .then(() => {
              setLoading(false)
              setSubmitOk(true)
              setTimeout(() => {
                dispatch({
                  type: reportConstants.GLOBAL_REPORT_DIALOG.CHANGE_TAB,
                  payload: 1
                })
              }, 1000)
            })
            .catch(() => {
              setError(true)
              setLoading(false)
            })
        : await dispatch(reportActions.update(report.id, dataToSubmit))
            .then(() => {
              setLoading(false)
              setSubmitOk(true)
              setTimeout(() => {
                dispatch({
                  type: reportConstants.GLOBAL_REPORT_DIALOG.CHANGE_TAB,
                  payload: 1
                })
              }, 1000)
            })
            .catch(() => {
              setError(true)
              setLoading(false)
            })
    }
    submit()
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction='row'
        spacing={1}
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item xs={12} md={6}>
          <FormField {...formData.name} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormField {...formData.materiality} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormField {...formData.start} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormField {...formData.finish} />
        </Grid>
        <Grid item xs={12}>
          <FormField {...formData.ceoWords} />
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography color='error'>Ha ocurrido un error</Typography>
            <Typography color='error'>
              Debes tener un análisis de materialidad activo
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} container justifyContent='center'>
          <SubmitButton
            disabled={!formIsValid || loading}
            text={create && !report?.id ? 'Crear' : 'Editar'}
            onClick={handleSubmit}
            loading={loading}
            submitOk={submitOk}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ReportTab
