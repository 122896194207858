import Axios from "../../axios";
import { uiActions } from "./index";
import { postConstants } from "../types";
import { enqueueSnackbar } from "./notification.actions";
/**
 * Fetch posts
 * @param {ParamDataTypeHere} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */
const fetchStarted = () => ({ type: postConstants.FETCH_STARTED });
const fetchSuccess = (posts) => ({
  type: postConstants.FETCH_SUCCESS,
  payload: posts,
});
const fetchFailure = (error) => ({
  type: postConstants.FETCH_FAILURE,
  payload: error,
});
const fetch = (organizationId = null, loggedIn = null) => {
  return (dispatch) => {
    dispatch(fetchStarted());
    // dispatch(uiActions.startLoading());
    Axios.get(
      organizationId
        ? loggedIn
          ? `/companies/${organizationId}/posts`
          : `/companies/${organizationId}/posts/public`
        : "/posts"
    )
      .then((response) => {
        dispatch(fetchSuccess(response.data.posts));
        // dispatch(uiActions.endLoading());
      })
      .catch((error) => {
        dispatch(fetchFailure(error.response));
        // dispatch(uiActions.endLoading());
        dispatch(
          enqueueSnackbar({
            variant: "error",
            message: "No se ha podido obtener los posts",
          })
        );
      });
  };
};

/**
 * Fetch more posts
 * @param {Integer} page - Indica qué página de posts debo hacer fetch
 */
const fetchMoreSuccess = (posts) => ({
  type: postConstants.FETCH_MORE_SUCCESS,
  payload: posts,
});
const fetchMoreFailure = (error) => ({
  type: postConstants.FETCH_MORE_FAILURE,
  payload: error,
});
const fetchMore = (organizationId = null, page = null, loggedIn = null) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return Axios.get(
        organizationId
          ? loggedIn
            ? `/companies/${organizationId}/posts?page=${page}`
            : `/companies/${organizationId}/posts/public?page=${page}`
          : `/posts?page=${page}`
      )
        .then((response) => {
          dispatch(fetchMoreSuccess(response.data.posts));
          resolve(response.data.posts);
        })
        .catch((error) => {
          dispatch(fetchMoreFailure(error.response));
          dispatch(
            enqueueSnackbar({
              variant: "error",
              message: "No se ha podido obtener los posts",
            })
          );
          reject();
        });
    });
  };
};

/**
 * Fetch a un solo post.
 * @param {Integer} id - Id del post a buscar
 */

const fetchOneStarted = () => ({ type: postConstants.FETCH_ONE_STARTED });
const fetchOneSuccess = (post) => ({
  type: postConstants.FETCH_ONE_SUCCESS,
  payload: post,
});
const fetchOneFailure = (error) => ({
  type: postConstants.FETCH_ONE_FAILURE,
  payload: error,
});
const fetchOne = (id, loggedIn = null) => {
  return (dispatch) => {
    dispatch(fetchOneStarted());
    dispatch(uiActions.startLoading());
    Axios.get(loggedIn ? `/posts/${id}` : `/posts/${id}/public`)
      .then((response) => {
        dispatch(fetchOneSuccess(response.data.post));
        dispatch(uiActions.endLoading());
      })
      .catch((error) => {
        dispatch(fetchOneFailure(error.response));
        dispatch(uiActions.endLoading());
        dispatch(
          enqueueSnackbar({
            variant: "error",
            message: "No se ha podido obtener el post",
          })
        );
      });
  };
};

/**
 * Método para crear un post.
 * @endpoint /posts
 * @param {Object} data - Contiene el objeto con la información del post
 * @body - { text, status, externalLink, initiativesId }
 * @attribute text - el cuerpo del post,
 * @attribute status - PUBLIC o PRIVATE, por dafault es PUBLIC,
 * @attribute externalLink - es el link al que se le saca la metadata, es nulleable
 * @attribute initiativesId - un arreglo de ids de las iniciativas que se van a postear
 */

const createStarted = () => ({ type: postConstants.CREATE_STARTED });
const createSuccess = (response) => ({
  type: postConstants.CREATE_SUCCESS,
  payload: response.post,
});
const createFailure = (error) => ({
  type: postConstants.CREATE_FAILURE,
  payload: error,
});

const create = (data) => {
  return (dispatch) => {
    dispatch(createStarted());
    // dispatch(uiActions.startLoading());
    Axios.post("/posts", data)
      .then((response) => {
        dispatch(createSuccess(response.data));
        dispatch(uiActions.endLoading());
      })
      .catch((error) => {
        dispatch(createFailure(error.response));
        // dispatch(uiActions.endLoading());
        dispatch(
          enqueueSnackbar({
            variant: "error",
            message: "No se ha podido crear la publicación",
          })
        );
      });
  };
};

// const update = data => {};

// const destroy = data => {};

/**
 * Obtiene todos los comentarios.
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {ParamDataTypeHere} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */

// TODO:

/**
 * Añade un nuevo comentario a la publicación.
 * @param {Object} data - Objeto que contiene el texto con el cuerpo del comenario.
 * @params {Integer} postId - ID del post al cual pertenece el comentario
 * @body {text}
 * @attribute text - Cuerpo del comentario
 */

const createCommentStarted = () => ({
  type: postConstants.COMMENT_CREATE_STARTED,
});
const createCommentSuccess = (comment) => ({
  type: postConstants.COMMENT_CREATE_SUCCESS,
  payload: comment,
});
const createCommentFailure = (error) => ({
  type: postConstants.COMMENT_CREATE_FAILURE,
  payload: error,
});
const createComment = (data, postId) => {
  return (dispatch) => {
    dispatch(createCommentStarted());
    return new Promise((resolve, reject) => {
      return Axios.post(`/posts/${postId}/comments`, data)
        .then((response) => {
          dispatch(createCommentSuccess(response.data.comment));
          resolve(response.data.comment);
        })
        .catch((error) => {
          dispatch(createCommentFailure(error.response));
          reject(error);
        });
    });
  };
};

/**
 * Elimina un comentario de un post
 * @param {Integer} commentId - ID del comentario a eliminar
 * @param {Integer} postId - ID del post que pertenece el comentario
 */

const destroyCommentStarted = () => ({
  type: postConstants.COMMENT_DELETE_STARTED,
});
const destroyCommentSuccess = (commentId, postId) => ({
  type: postConstants.COMMENT_DELETE_SUCCESS,
  payload: { commentId, postId },
});
const destroyCommentFailure = (error) => ({
  type: postConstants.COMMENT_DELETE_FAILURE,
  payload: error,
});
const destroyComment = (commentId, postId) => {
  return (dispatch) => {
    dispatch(destroyCommentStarted());
    Axios.delete(`/posts/${postId}/comments/${commentId}`)
      .then((response) => {
        dispatch(destroyCommentSuccess(commentId, postId));
      })
      .catch((error) => {
        dispatch(destroyCommentFailure(error.response));
      });
  };
};

/**
 * Actualiza un comentario.
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {ParamDataTypeHere} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */

const editCommentStarted = () => ({
  type: postConstants.COMMENT_UPDATE_STARTED,
});
const editCommentSuccess = (data) => ({
  type: postConstants.COMMENT_UPDATE_SUCCESS,
  payload: data.comment,
});
const editCommentFailure = (error) => ({
  type: postConstants.COMMENT_UPDATE_FAILURE,
  payload: error,
});

const editComment = (data, postId, commentId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(editCommentStarted());
      Axios.patch(`/posts/${postId}/comments/${commentId}`, data)
        .then((response) => {
          dispatch(editCommentSuccess(response.data));
          resolve(response.data.comment);
        })
        .catch((error) => {
          dispatch(editCommentFailure(error.response));
          reject(error.response);
        });
    });
  };
};
/**
 * Fetch de todos los comentarios de un post.
 * @param {Integer} postId - ID del post a obtener los comentarios
 */

const fetchCommentsStarted = () => ({
  type: postConstants.COMMENT_FETCH_STARTED,
});
const fetchCommentsSuccess = (postId, comments) => ({
  type: postConstants.COMMENT_FETCH_SUCCESS,
  payload: { postId, comments },
});
const fetchCommentFailure = (postId, error) => ({
  type: postConstants.COMMENT_FETCH_FAILURE,
  payload: { postId, error },
});
const fetchComments = (postId, page, loggedIn) => {
  return (dispatch) => {
    dispatch(fetchCommentsStarted());
    return new Promise((resolve, reject) => {
      return Axios.get(
        loggedIn
          ? `posts/${postId}/comments?page=${page}`
          : `posts/${postId}/comments/public?page=${page}`
      )
        .then((response) => {
          dispatch(fetchCommentsSuccess(postId, response.data));
          resolve(response.data.comments);
        })
        .catch((error) => {
          dispatch(fetchCommentFailure(error.response));
          reject(false);
        });
    });
  };
};

/**
 * Añade like a un post.
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {ParamDataTypeHere} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */

const addLikeSuccess = (data) => ({
  type: postConstants.ADD_LIKE_SUCCESS,
  payload: data,
});
const addLikeFailure = (postId, error) => ({
  type: postConstants.ADD_LIKE_FAILURE,
  payload: { postId, error },
});

const addLike = (postId) => {
  return (dispatch) => {
    Axios.post(`/posts/${postId}/like`)
      .then((response) => {
        dispatch(addLikeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(addLikeFailure(postId, error.response));
      });
  };
};

/**
 * Remueve un like.
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {ParamDataTypeHere} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */

const removeLikeSuccess = (data, postId, userId) => ({
  type: postConstants.REMOVE_LIKE_SUCCESS,
  payload: { data, postId, userId },
});
const removeLikeFailure = (postId, userId, error) => ({
  type: postConstants.REMOVE_LIKE_FAILURE,
  payload: { postId, userId, error },
});

const removeLike = (postId, userId) => {
  return (dispatch) => {
    Axios.delete(`/posts/${postId}/like`)
      .then((response) => {
        dispatch(removeLikeSuccess(response.data, postId, userId));
      })
      .catch((error) => {
        dispatch(removeLikeFailure(postId, userId, error.response));
      });
  };
};
/**
 * FETCH de likes de un posts
 * @summary Obtiene los likes de un post dada una página
 * @param {ParamDataTypeHere} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */

const fetchLikesSuccess = (postId, likes) => ({
  type: postConstants.FETCH_LIKES_SUCCESS,
  payload: { postId, likes },
});

const fetchLikes = (postId, page) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return Axios.get(`/posts/${postId}/like?page=${page}`)
        .then((response) => {
          dispatch(fetchLikesSuccess(postId, response.data.likes));
          resolve(response.data.likes);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  };
};

/**
 * Follow a una organización.
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {ParamDataTypeHere} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */
const followStarted = () => ({
  type: postConstants.PROFILE_FOLLOW_STARTED,
});
const followSuccess = (organizationId) => ({
  type: postConstants.PROFILE_FOLLOW_SUCCESS,
  payload: organizationId,
});
const followFailure = (error) => ({
  type: postConstants.PROFILE_FOLLOW_FAILURE,
  payload: error,
});
const follow = (organizationId) => {
  return (dispatch) => {
    dispatch(followStarted());
    Axios.post(`/companies/${organizationId}/follow`)
      .then((response) => {
        dispatch(followSuccess(response.data));
      })
      .catch((error) => {
        dispatch(followFailure(error.response));
        dispatch(
          enqueueSnackbar({
            variant: "error",
            message: "No se ha podido seguir a la organización",
          })
        );
      });
  };
};

/**
 * unfollow a una organización.
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {ParamDataTypeHere} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */

const unfollowStarted = () => ({
  type: postConstants.PROFILE_UNFOLLOW_STARTED,
});
const unfollowSuccess = (organizationId, userId) => ({
  type: postConstants.PROFILE_UNFOLLOW_SUCCESS,
  payload: { organizationId, userId },
});
const unfollowFailure = (error) => ({
  type: postConstants.PROFILE_UNFOLLOW_FAILURE,
  payload: error,
});

const unfollow = (organizationId, userId) => {
  return (dispatch) => {
    dispatch(unfollowStarted());
    Axios.delete(`/companies/${organizationId}/follow`)
      .then((response) => {
        dispatch(unfollowSuccess(organizationId, userId));
      })
      .catch((error) => {
        dispatch(unfollowFailure(error.response));
        dispatch(
          enqueueSnackbar({
            variant: "error",
            message: "No se ha podido dejar de seguir a la organización",
          })
        );
      });
  };
};

/**
 * Obtiene la metadata de un link
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {String} url - url a obtener metadata
 */

const getMetadata = (url) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post("/fetchmeta", { url: url })
        .then((response) => {
          resolve(response.data);
        })
        .then((error) => {
          reject(error);
        });
    });
  };
};

export const postActions = {
  fetch,
  fetchMore,
  fetchOne,
  create,
  createComment,
  destroyComment,
  editComment,
  addLike,
  removeLike,
  fetchComments,
  follow,
  unfollow,
  getMetadata,
  fetchLikes,
};
