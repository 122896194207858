import { socialConstants } from "../types";

const initialState = {
  profile: {
    loading: false,
    error: null,
    type: "organization",
    followLoading: false,
    followError: null,
    data: {
      organization: null,
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case socialConstants.PROFILE.SOCIAL_GET_PROFILE_STARTED:
      return {
        ...state,
        profile: { ...state.profile, loading: true, error: null },
      };
    case socialConstants.PROFILE.SOCIAL_GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: false,
          error: null,
          data: { ...state.profile.data, organization: action.payload },
        },
      };
    case socialConstants.PROFILE.SOCIAL_GET_PROFILE_FAILURE:
      return {
        ...state,
        profile: { ...state.profile, loading: false, error: action.payload },
      };
    case socialConstants.PROFILE.SOCIAL_PROFILE_FOLLOW_STARTED:
      return {
        ...state,
        profile: {
          ...state.profile,
          followLoading: true,
          followError: null,
        },
      };
    case socialConstants.PROFILE.SOCIAL_PROFILE_FOLLOW_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          followLoading: false,
          followError: null,
          data: {
            ...state.profile.data,
            organization: {
              ...state.profile.data.organization,
              alreadyFollow: true,
              followers: [
                ...state.profile.data.organization.followers,
                action.payload,
              ],
            },
          },
        },
      };
    case socialConstants.PROFILE.SOCIAL_PROFILE_FOLLOW_FAILURE:
      return {
        ...state,
        profile: {
          ...state.profile,
          followLoading: false,
          followError: action.payload,
        },
      };
    case socialConstants.PROFILE.SOCIAL_PROFILE_UNFOLLOW_STARTED:
      return {
        ...state,
        profile: {
          ...state.profile,
          followLoading: true,
          followError: null,
        },
      };
    case socialConstants.PROFILE.SOCIAL_PROFILE_UNFOLLOW_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          followLoading: false,
          followError: null,
          data: {
            ...state.profile.data,
            organization: {
              ...state.profile.data.organization,
              alreadyFollow: false,
              followers: state.profile.data.organization.followers.filter(
                (follower) => follower.userId !== action.payload.userId
              ),
            },
          },
        },
      };
    case socialConstants.PROFILE.SOCIAL_PROFILE_UNFOLLOW_FAILURE:
      return {
        ...state,
        profile: {
          ...state.profile,
          followLoading: false,
          followError: action.payload,
        },
      };
    // case socialConstants.PROFILE.SOCIAL_GET_PROFILE_POSTS_STARTED:
    //   return {
    //     ...state,
    //     profile: { ...state.profile, loading: true, error: null }
    //   };
    // case socialConstants.PROFILE.SOCIAL_GET_PROFILE_POSTS_SUCCESS:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       loading: false,
    //       error: null,
    //       data: { ...state.profile.data, posts: action.payload }
    //     }
    //   };
    // case socialConstants.PROFILE.SOCIAL_GET_PROFILE_POSTS_FAILURE:
    //   return {
    //     ...state,
    //     profile: { ...state.profile, loading: false, error: action.payload }
    //   };
    default: {
      return { ...state };
    }
  }
}
