import { graphViewConstants } from '../types'

const initialState = {
  views: [],
  loading: false,
  error: null
}

export default function (state = {}, action) {
  switch (action.type) {
    case graphViewConstants.SET_INITIAL_STATE:
      return initialState
    // FETCH RELATED
    case graphViewConstants.GRAPH_VIEW_FETCH_STARTED:
      return { ...state, loading: true, error: null }
    case graphViewConstants.GRAPH_VIEW_FETCH_SUCCESS:
      return {
        views: action.payload,
        loading: false,
        error: null
      }
    case graphViewConstants.GRAPH_VIEW_FETCH_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    // CREATE RELATED
    case graphViewConstants.GRAPH_VIEW_CREATE_STARTED:
      return { ...state, loading: true, error: null }
    case graphViewConstants.GRAPH_VIEW_CREATE_SUCCESS:
      const newView = action.payload
      const currentViews = [...state.views]
      const existsAlready = currentViews.findIndex(
        (view) => view.id === action.payload.id
      )
      let updSingularViews
      if (existsAlready !== -1) {
        updSingularViews = currentViews
      } else {
        updSingularViews = [...currentViews, newView]
      }
      return {
        ...state,
        views: updSingularViews,
        loading: false
      }
    case graphViewConstants.GRAPH_VIEW_CREATE_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    // UPDATE RELATED
    case graphViewConstants.GRAPH_VIEW_UPDATE_STARTED:
      return { ...state, loading: true, error: null }
    case graphViewConstants.GRAPH_VIEW_UPDATE_SUCCESS:
      const updtView = action.payload
      const crtOtherViews = [...state.views].filter(
        (view) => view.id !== updtView.id
      )
      const updtViews = [...crtOtherViews, updtView]
      return {
        ...state,
        views: updtViews,
        loading: false,
        error: null
      }
    case graphViewConstants.GRAPH_VIEW_UPDATE_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    // DELETE RELATED
    case graphViewConstants.GRAPH_VIEW_DELETE_STARTED:
      return { ...state, loading: true, error: null }
    case graphViewConstants.GRAPH_VIEW_DELETE_SUCCESS:
      const updatedViews = state.views.filter(
        (view) => view.id !== action.payload.id
      )
      return { ...state, views: updatedViews, loading: false, error: null }
    case graphViewConstants.GRAPH_VIEW_DELETE_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    default:
      return state
  }
}
