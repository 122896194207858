export const indicatorConstants = {
  SET_INITIAL_STATE: 'INDICATOR_SET_INITIAL_STATE',

  FETCH_STARTED: 'INDICATOR_FETCH_STARTED',
  FETCH_SUCCESS: 'INDICATOR_FETCH_SUCCESS',
  FETCH_FAILURE: 'INDICATOR_FETCH_FAILURE',

  CREATE: 'INDICATOR_CREATE',

  UPDATE: 'INDICATOR_UPDATE',

  DELETE_STARTED: 'INDICATOR_DELETE_STARTED',
  DELETE_SUCCESS: 'INDICATOR_DELETE_SUCCESS',
  DELETE_FAILURE: 'INDICATOR_DELETE_FAILURE',

  CREATE_LOG: 'INDICATOR_CREATE_LOG',
  DELETE_LOG: 'INDICATOR_DELETE_LOG',
  UPDATE_LOG: 'INDICATOR_UPDATE_LOG',

  CREATE_LOG_STARTED: 'INDICATOR_CREATE_LOG_STARTED',
  CREATE_LOG_FAILURE: 'INDICATOR_CREATE_LOG_FAILURE',
  CREATE_LOG_SUCCESS: 'INDICATOR_CREATE_LOG_SUCCESS',

  DELETE_LOG_STARTED: 'INDICATOR_DELETE_LOG_STARTED',
  DELETE_LOG_SUCCESS: 'INDICATOR_DELETE_LOG_SUCCESS',
  DELETE_LOG_FAILURE: 'INDICATOR_DELETE_LOG_FAILURE',

  UPDATE_LOG_STARTED: 'INDICATOR_UPDATE_LOG_STARTED',
  UPDATE_LOG_SUCCESS: 'INDICATOR_UPDATE_LOG_SUCCESS',
  UPDATE_LOG_FAILURE: 'INDICATOR_UPDATE_LOG_FAILURE',

  CREATE_INDICATOR_DIALOG: {
    OPEN: 'CREATE_INDICATOR_DIALOG_OPEN',
    CLOSE: 'CREATE_INDICATOR_DIALOG_CLOSE',
    SET: 'CREATE_INDICATOR_DIALOG_SET',
    CLEAR: 'CREATE_INDICATOR_DIALOG_CLEAR'
  },

  ADD_AREA_STARTED: 'INDICATOR_ADD_AREA_STARTED',
  ADD_AREA_SUCCESS: 'INDICATOR_ADD_AREA_SUCCESS',
  ADD_AREA_FAILURE: 'INDICATOR_ADD_AREA_FAILURE',

  SET_BRANCHES: 'INDICATOR_SET_BRANCHES',
  SET_LINES: 'INDICATOR_SET_LINES',

  // Guardar la data en BBDD una vez se complete el formulario de creación de indicadores
  INDICATOR_IN_PROGRESS: {
    SAVE_DATA_START: 'INDICATOR_IN_PROGRESS_SAVE_DATA_START',
    SAVE_DATA_SUCCESS: 'INDICATOR_IN_PROGRESS_SAVE_DATA_SUCCESS',
    SAVE_DATA_FAILED: 'INDICATOR_IN_PROGRESS_SAVE_DATA_FAILED'
  },
  ///

  INDICATOR_IN_PROGRESS_DIALOG: {
    OPEN: 'INDICATOR_IN_PROGRESS_DIALOG_OPEN',
    CLOSE: 'INDICATOR_IN_PROGRESS_DIALOG_CLOSE',
    SET: 'INDICATOR_IN_PROGRESS_DIALOG_SET',
    CLEAR: 'INDICATOR_IN_PROGRESS_DIALOG_CLEAR',
    ADD_CONTENT: 'ADD_CONTENT_TO_STATE',
    SET_BRANCHES: 'INDICATOR_IN_PROGRESS_BRANCHES_SET',
    SET_LINES: 'INDICATOR_IN_PROGRESS_DIALOG.SET_LINES',
    CREATE: {
      OPEN: 'NEW_INDICATOR_TO_PROCESS_SET_DIALOG'
    }
  },

  FETCH_GRI_SPECIFIC_CONTENT: {
    SUCCESS: 'FETCH_GRI_CONTENT_SUCCESS',
    FAILED: 'FETCH_GRI_CONTENT_FAILED'
  },

  COMPOUND_INDICATORS: {
    FETCH_ALL_START: 'COMPOUND_INDICATORS_FETCH_ALL_START',
    FETCH_ALL_SUCCESS: 'COMPOUND_INDICATORS_FETCH_ALL_SUCCESS',
    FETCH_ALL_FAILED: 'COMPOUND_INDICATORS_FETCH_ALL_FAILED',
    FETCH_ONE_START: 'COMPOUND_INDICATORS_FETCH_ONE_START',
    FETCH_ONE_SUCCESS: 'COMPOUND_INDICATORS_FETCH_ONE_SUCCESS',
    FETCH_ONE_FAILED: 'COMPOUND_INDICATORS_FETCH_ONE_FAILED',
    DELETE_START: 'COMPOUND_INDICATOR_IN_PROGRESS_DELETE_START',
    DELETE_SUCCESS: 'COMPOUND_INDICATOR_IN_PROGRESS_DELETE_SUCCESS',
    DELETE_FAILED: 'COMPOUND_INDICATOR_IN_PROGRESS_DELETE_FAILED'
  },

  COMPOUND_INDICATOR_IN_PROGRESS: {
    SAVE_DATA_START: 'COMPOUND_INDICATOR_IN_PROGRESS_SAVE_DATA_START',
    SAVE_DATA_SUCCESS: 'COMPOUND_INDICATOR_IN_PROGRESS_SAVE_DATA_SUCCESS',
    SAVE_DATA_FAILED: 'COMPOUND_INDICATOR_IN_PROGRESS_SAVE_DATA_FAILED',
    UPDATE_START: 'COMPOUND_INDICATOR_IN_PROGRESS_UPDATE_START',
    UPDATE_SUCCESS: 'COMPOUND_INDICATOR_IN_PROGRESS_UPDATE_SUCCESS',
    UPDATE_FAILED: 'COMPOUND_INDICATOR_IN_PROGRESS_UPDATE_FAILED'
  },

  COMPOUND_INDICATOR_IN_PROGRESS_DIALOG: {
    OPEN: 'COMPOUND_INDICATOR_IN_PROGRESS_DIALOG_OPEN',
    CLOSE: 'COMPOUND_INDICATOR_IN_PROGRESS_DIALOG_CLOSE',
    SET: 'COMPOUND_INDICATOR_IN_PROGRESS_DIALOG_SET',
    CLEAR: 'COMPOUND_INDICATOR_IN_PROGRESS_DIALOG_CLEAR'
  }
}
