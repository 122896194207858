import { combineReducers } from 'redux'
import initiatives from './initiatives.reducer'
import employees from './employees.reducer'
import ui from './ui.reducer'
import areas from './areas.reducer'
import indicators from './indicators.reducer'
import organization from './organization.reducer'
import materialities from './materiality.reducer'
import user from './user.reducer'
import resources from './resources.reducer'
import { authentication } from './authentication.reducer'
import { connectRouter } from 'connected-react-router'
import notifications from './notifications.reducer'
import posts from './posts.reducer'
import social from './social.reducer'
import search from './search.reducer'
import reports from './reports.reducer'
import tour from './tour.reducer'
import linesOfBsns from './lines.reducer'
import graphViews from './graphViews.reducer'

export default (history) =>
  combineReducers({
    authentication,
    initiatives,
    areas,
    ui,
    indicators,
    organization,
    employees,
    materialities,
    user,
    resources,
    notifications,
    posts,
    social,
    search,
    reports,
    tour,
    linesOfBsns,
    graphViews,
    router: connectRouter(history)
  })
