export const uiConstants = {
  SET_INITIAL_STATE: 'UI_SET_INITIAL_STATE',

  START_LOADING: 'START_LOADING',
  END_LOADING: 'END_LOADING',

  SHOW_SNACKBAR: 'SHOW_SNACKBAR',
  HIDE_SNACKBAR: 'HIDE_SNACKBAR',

  SHOW_DRAWER: 'SHOW_DRAWER',
  HIDE_DRAWER: 'HIDE_DRAWER',
  SET_DRAWER: 'SET_DRAWER'
}
