import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
// import { indicatorActions } from '../../state/actions'
import { indicatorConstants } from '../../state/types'
import InputSearchFilter from '../UI/InputSearchFilter'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 700,
    backgroundColor: theme.palette.background.paper
  },
  formControl: { width: '100%' },
  chips: { display: 'flex', flexWrap: 'wrap' },
  chip: { margin: 2 },
  branch: {},
  list: { width: '100%' },
  inputSearch: {
    marginLeft: '13%'
  },
  filters: {
    marginTop: '3%',
    marginBottom: '1%'
    // maxWidth: '70%'
  }
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const BranchesForm = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  // Redux states
  const indicator = useSelector(
    (state) => state.indicators.indicatorInProgress.data
  )
  const { indicators } = useSelector((state) => state.indicators)
  const { branches } = useSelector((state) => state.organization.organization)
  const { lines } = useSelector((state) => state.linesOfBsns)

  // Local states

  const [submitBranchesLoading, setSubmitBranchesLoading] = useState(false)
  const [branchesIds, setBranchesIds] = useState(
    indicator.branchesIds ? indicator.branchesIds : []
  )
  const [checked, setChecked] = useState(
    branches.filter((branch) =>
      branchesIds.find((idTemp) => idTemp === branch.id)
    )
  )
  const [checkedIds, setCheckedIds] = useState([])
  useEffect(() => {
    setCheckedIds(checked.map((ele) => ele.id))
    setBranchesIds(checkedIds)
  }, [checked])

  const [filteredBranches, setFilteredBranches] = useState(branches)

  // Seleccionar la LDN a la que pertenece la instalación
  const findMyLine = (branchId) => {
    let found
    if (lines.length > 0) {
      lines.map((line) =>
        line.branches.map((ele) => {
          if (ele.id === branchId) {
            found = line.name
          }
        })
      )

      if (found === undefined) {
        return 'no asociada a línea de negocio'
      }
      return found
    } else {
      return '---'
    }
  }

  // toggle list handler
  const handleToggle = (branch) => () => {
    const currentIndex = checked.indexOf(branch)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(branch)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  // submission handler
  const submitForm = (event) => {
    event.preventDefault()
    const submit = async () => {
      setSubmitBranchesLoading(true)
      // setError(null)
      dispatch({
        type: indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.SET,
        payload: {
          tabValue: 4,
          branchesIds: checkedIds
        }
      })
      setSubmitBranchesLoading(false)
    }
    submit()
  }

  const resultHandler = (result) => {
    setFilteredBranches(result)
  }

  return (
    <Grid container direction='column' align='center' justifyContent='center'>
      <InputSearchFilter
        placeholder='Instalación, línea de negocio o dirección'
        handleResult={resultHandler}
        data={branches}
        className={classes.inputSearch}
      />
      <Grid item>
        <List className={classes.root}>
          {filteredBranches.map((branch, idx) => {
            const labelId = `checkbox-list-label-${branch.name}`
            return (
              <ListItem
                key={branch.name}
                role={undefined}
                dense
                button
                onClick={handleToggle(branch)}
              >
                <ListItemText
                  id={labelId}
                  primary={`${branch.name} / ${findMyLine(branch.id)}`}
                  secondary={`${branch.direction}, ${branch.country}`}
                />
                <ListItemIcon>
                  <Checkbox
                    edge='start'
                    checked={checked.indexOf(branch) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                {/* <ListItemSecondaryAction>
                  <IconButton edge='end' aria-label='comments'>
                    <CommentIcon />
                  </IconButton>
                </ListItemSecondaryAction> */}
              </ListItem>
            )
          })}
        </List>
        <Grid item>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            onClick={submitForm}
            disabled={submitBranchesLoading}
          >
            {submitBranchesLoading ? 'Siguiente' : 'Siguiente'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BranchesForm
