import React, {
  useEffect,
  useCallback,
  Suspense,
  lazy,
  useState,
  useRef
} from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { authenticationActions } from './state/actions'
import { useStorageListener } from './hooks/useStorageListener'
import Progress from './components/UI/Progress'
import Paso1 from './containers/Paso1'
import Paso2 from './containers/Paso2'
import Paso3 from './containers/Paso3'
import Paso4 from './containers/Paso4'
import Paso5 from './containers/Paso5'
import Organigrama from './containers/Organigrama'
const Layout = lazy(() => import('./HOC/Layout'))

const LandingPage = lazy(() => import('./containers/LandingPage'))
const CorporateLanding = lazy(() => import('./containers/CorporateLanding'))
const Contacto = lazy(() => import('./containers/Contacto'))
const AgendarReunion = lazy(() => import('./containers/AgendarReunion'))
const ContactoPartners = lazy(() => import('./containers/ContactoPartners'))
const DescargarMaterial = lazy(() => import('./containers/DescargarMaterial'))
const ContactConversion = lazy(() =>
  import('./components/LandingPage/conversionPages/ContactConversion')
)
const BookingConversion = lazy(() =>
  import('./components/LandingPage/conversionPages/BookingConversion')
)
const PartnersConversion = lazy(() =>
  import('./components/LandingPage/conversionPages/PartnersConversion')
)

const NotAuthorized = lazy(() => import('./components/Error/NotAuthorized'))
const NotFound = lazy(() => import('./components/Error/NotFound'))
const Login = lazy(() => import('./components/auth/Login'))
const Register = lazy(() => import('./components/auth/Register'))
const Logout = lazy(() => import('./components/auth/Logout'))
const RecoverPassword = lazy(() => import('./components/auth/RecoverPassword'))
const RecoverPasswordEmail = lazy(() =>
  import('./components/auth/RecoverPasswordEmail')
)
const Materiality = lazy(() =>
  import('./components/MaterialityMatrix/Materiality')
)
const MaterialityPoll = lazy(() => import('./containers/MaterialityPoll'))
const MaterialityPublicPoll = lazy(() =>
  import('./containers/MaterialityPublicPoll')
)
const Materialities = lazy(() => import('./containers/Materiality'))
const Profile = lazy(() => import('./containers/Profile'))
const Indicators = lazy(() => import('./containers/Indicators'))
const Initiatives = lazy(() => import('./containers/Initiatives'))
const Organization = lazy(() => import('./containers/Organization'))
const Feed = lazy(() => import('./containers/Feed'))
const Reports = lazy(() => import('./containers/Reports'))
const Reportes = lazy(() => import('./containers/Reportes'))
const Formulario = lazy(() => import('./containers/Formulario'))
const ReportsTemplate = lazy(() => import('./containers/ReportsTemplate'))
const ReportsRevisores = lazy(() => import('./containers/ReportsRevisores'))
const ListRevisores = lazy(() => import('./containers/ListRevisores'))

export const ROUTES = [
  {
    path: '/',
    key: 'ROOT',
    exact: true,
    component: () => <LandingPage />
  },

  {
    path: '/nosotros',
    key: 'CORPORATE',
    exact: true,
    component: () => <CorporateLanding />
  },
  {
    path: '/contacto',
    key: 'CONTACT',
    exact: true,
    component: () => <Contacto />
  },
  {
    path: '/booking',
    key: 'BOOKING',
    exact: true,
    component: () => <AgendarReunion />
  },
  {
    path: '/partnering',
    key: 'PARTNERING',
    exact: true,
    component: () => <ContactoPartners />
  },
  {
    path: '/thankyou-contact',
    key: 'THANKYOU_CONTACT',
    exact: true,
    component: () => <ContactConversion />
  },
  {
    path: '/thankyou-booking',
    key: 'THANKYOU_BOOKING',
    exact: true,
    component: () => <BookingConversion />
  },
  {
    path: '/thankyou-partners',
    key: 'THANKYOU_PARTNERS',
    exact: true,
    component: () => <PartnersConversion />
  },
  {
    path: '/download-resources',
    key: 'DOWNLOAD-RESOURCES',
    exact: true,
    component: () => <DescargarMaterial />
  },
  {
    path: '/signin',
    key: 'SIGNIN',
    exact: true,
    component: () => <Login />
  },
  {
    path: '/register',
    key: 'REGISTER',
    exact: true,
    component: () => <Register />
  },
  {
    path: '/recoverPassword',
    key: 'RECOVER_PASSWORD',
    exact: true,
    component: () => <RecoverPasswordEmail />
  },
  {
    path: '/recover_password',
    key: 'CHANGE_PASSWORD',
    exact: false,
    component: (props) => {
      return <RecoverPassword {...props} />
    }
  },
  {
    path: '/poll/public/:token',
    key: 'MATERIALITYPUBLICPOLL',
    exact: true,
    component: (props) => {
      return <MaterialityPublicPoll {...props} />
    }
  },
  {
    path: '/poll/:token',
    key: 'MATERIALITYPOLL',
    exact: false,
    component: (props) => {
      return <MaterialityPoll {...props} />
    }
  },

  {
    path: '/posts/:id',
    key: 'POST',
    exact: false,
    component: (props) => (
      <Layout>
        <Feed {...props} />
      </Layout>
    )
  },
  {
    path: '/organization/:id',
    key: 'ORGANIZATION_PROFILE',
    exact: false,
    component: (props) => (
      <Layout>
        <Feed {...props} />
      </Layout>
    )
  },
  {
    path: '/app',
    key: 'APP',
    exact: false,
    component: (props) => {
      if (localStorage.getItem('token') || sessionStorage.getItem('token')) {
        return <RenderRoutes {...props} />
      }
      return <NotAuthorized {...props} />
    },
    routes: [
      {
        path: '/app/dashboard',
        key: 'DASHBOARD',
        exact: true,
        component: (props) => (
          <Layout>
            <Organization {...props} />
          </Layout>
        )
      },
      {
        path: '/app/registerOrganization',
        key: 'REGISTERORGANIZATION',
        exact: true,
        component: (props) => (
          <Layout>
            <Organization {...props} />
          </Layout>
        )
      },
      {
        path: '/app/initiatives',
        key: 'INITIATIVES',
        exact: true,
        component: (props) => (
          <Layout>
            <Initiatives {...props} />
          </Layout>
        )
      },
      {
        path: '/app/indicators',
        key: 'INDICATORS',
        exact: true,
        component: (props) => (
          <Layout>
            <Indicators {...props} />
          </Layout>
        )
      },
      {
        path: '/app/organization',
        key: 'ORGANIZATION',
        exact: true,
        component: (props) => (
          <Layout>
            <Organization {...props} />
          </Layout>
        )
      },
      {
        path: '/app/profile',
        key: 'PROFILE',
        exact: true,
        component: (props) => (
          <Layout>
            <Profile {...props} />
          </Layout>
        )
      },
      {
        path: '/app/materialities',
        key: 'MATERIALITIES',
        exact: true,
        component: (props) => (
          <Layout>
            <Materialities {...props} />
          </Layout>
        )
      },
      {
        path: '/app/materialities/:id',
        key: 'MATERIALITY',
        exact: true,
        component: (props) => (
          <Layout>
            <Materiality {...props} />
          </Layout>
        )
      },
      {
        path: '/app/feed',
        key: 'FEED',
        exact: true,
        component: (props) => (
          <Layout>
            <Feed {...props} />{' '}
          </Layout>
        )
      },
      {
        path: '/app/organization/:id',
        key: 'ORGANIZATION_PROFILE',
        exact: true,
        component: (props) => (
          <Layout>
            <Feed {...props} />
          </Layout>
        )
      },
      {
        path: '/app/posts/:id',
        key: 'SINGLE_POST',
        exact: true,
        component: (props) => (
          <Layout>
            <Feed {...props} />
          </Layout>
        )
      },
      {
        path: '/app/reports',
        key: 'REPORTS',
        exact: true,
        component: (props) => (
          <Layout>
            <Reports />
          </Layout>
        )
      },
      {
        path: '/app/reportsTemplate',
        key: 'REPORTSTEMPLATE',
        exact: true,
        component: (props) => (
          <Layout>
            <ReportsTemplate />
          </Layout>
        )
      },
      {
        path: '/app/reportsRevisores',
        key: 'REPORTSREVISORES',
        exact: true,
        component: (props) => (
          <Layout>
            <ReportsRevisores />
          </Layout>
        )
      },
      {
        path: '/app/ListRevisores',
        key: 'LISTREVISORES',
        exact: true,
        component: (props) => (
          <Layout>
            <ListRevisores />
          </Layout>
        )
      },
      {
        path: '/app/logout',
        key: 'LOGOUT',
        exact: true,
        component: () => <Logout />
      },
      {
        path: '/app/reportes',
        key: 'REPORTES',
        exact: true,
        component: (props) => (
          <Layout>
            <Reportes />
          </Layout>)
      },
      {
        path: '/app/formulario',
        key: 'FORMULARIO',
        exact: true,
        component: () => <Formulario />
      },
      {
        path: '/app/organigrama',
        key: 'ORGANIGRAMA',
        exact: true,
        component: () => <Layout><Organigrama /></Layout>
      },
      {
        path: '/app/paso1',
        key: 'PASO1',
        exact: true,
        component: (props) => (
          <Layout>
            <Paso1 />
          </Layout>)
      },
      {
        path: '/app/paso2',
        key: 'PASO2',
        exact: true,
        component: (props) => (
          <Layout>
            <Paso2 />
          </Layout>)
      },
      {
        path: '/app/paso3',
        key: 'PASO3',
        exact: true,
        component: (props) => (
          <Layout>
            <Paso3 />
          </Layout>)
      },
      {
        path: '/app/paso4',
        key: 'PASO4',
        exact: true,
        component: (props) => (
          <Layout>
            <Paso4 />
          </Layout>)
      },
      {
        path: '/app/paso5',
        key: 'PASO5',
        exact: true,
        component: (props) => (
          <Layout>
            <Paso5 />
          </Layout>)
      }
    
    ]
  }
]

/**
 * Render a route with potential sub routes
 * https://reacttraining.com/react-router/web/example/route-config
 */
function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  )
}

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */
export function RenderRoutes({ routes }) {
  const dispatch = useDispatch()
  const loggedIn = useSelector((state) => state.authentication.loggedIn)
  const { user } = useSelector((state) => state.user)
  const token = localStorage.getItem('token')
    ? JSON.parse(localStorage.getItem('token'))
    : JSON.parse(sessionStorage.getItem('token'))
  const history = useHistory()

  // Dispara un evento de storage cada vez que se abre una página
  useEffect(() => {
    window.localStorage.setItem(
      'REQUESTING_SHARED_CREDENTIALS',
      Date.now().toString()
    )
    window.localStorage.removeItem('REQUESTING_SHARED_CREDENTIALS')
  })

  const storageCallback = useCallback(
    (event) => {
      const credentials = JSON.parse(window.sessionStorage.getItem('token'))
      // Pide compartir el token
      if (event.key === 'REQUESTING_SHARED_CREDENTIALS' && credentials) {
        window.localStorage.setItem(
          'CREDENTIALS_SHARING',
          window.sessionStorage.getItem('token')
        )
        window.localStorage.removeItem('CREDENTIALS_SHARING')
      }
      // Comparte el token
      if (event.key === 'CREDENTIALS_SHARING' && !credentials) {
        window.sessionStorage.setItem('token', event.newValue)
      }
      // Cierra sesión en todas las pestañas o ventanas
      if (event.key === 'CREDENTIALS_FLUSH' && credentials) {
        window.sessionStorage.removeItem('token')
        setTimeout(() => {
          dispatch(authenticationActions.logout())
        }, [2000])
      }
    },
    // eslint-disable-next-line
    []
  )
  useStorageListener(storageCallback)

  useEffect(
    () => {
      if (loggedIn && user.companyId && token) {
        dispatch(authenticationActions.fetchData())
      } else if (!loggedIn && token) {
        localStorage.removeItem('token')
        sessionStorage.removeItem('token')
        history.push('/signin')
      } else if (!token && loggedIn) {
        dispatch(authenticationActions.cleanStores())
      }
    },
    // eslint-disable-next-line
    [loggedIn, history, dispatch]
  )

  // carga selectiva de chatbot Hubspot

  // const chatbotRef = useRef()
  // const [scriptLoaded, setScriptLoaded] = useState(false)

  // const loadChatBot = () => {
  //   const script = document.createElement('script')
  //   script.id = 'chatbot-tero-load'
  //   script.src = 'https://js.hs-scripts.com/7918219.js'
  //   script.async = true
  //   script.onload = () => {
  //     setScriptLoaded(true)
  //     chatbotRef.current.appendChild(script)
  //   }
  //   document.body.appendChild(script)
  // }

  // const unloadChatBot = () => {
  //   const element = document.getElementsByClassName('widget')
  //   const element = document.getElementsByTagName('body')[0]
  //   console.log('chatBotRef', chatbotRef)
  //   chatbotRef.current.removeChild()
  //   document.body.removeChild(document.querySelector('#chatbot-tero-load'))
  // }

  // useEffect(() => {
  //   if (!history.location.pathname.includes('/app')) {
  //     loadChatBot()
  //   } else {
  //     unloadChatBot()
  //   }
  // })

  // const onConversationsAPIReady = () =>{
  //   console.log(
  //     `HubSpot Conversations API: ${window.HubSpotConversations.widget}`
  //   )
  // }

  // if (window.HubSpotConversations) {
  //   onConversationsAPIReady()
  //   window.HubSpotConversations.widget.refresh()
  // } else {

  //   window.hsConversationsOnReady = [onConversationsAPIReady]
  // }

  // final carga selectiva Hubspot

  return (
    <Suspense
      fallback={
        <>
          <Progress
            loading={true}
            center
            centerVertically
            size={80}
            height={window.innerHeight}
            width={window.innerWidth}
            showLogo={true}
          />
        </>
      }
    >
      <Switch>
        {routes.map((route, i) => {
          return <RouteWithSubRoutes key={route.key} {...route} />
        })}
        <Route component={() => <NotFound />} />
      </Switch>
      {/* <div ref={chatbotRef}></div> */}
    </Suspense>
  )
}

/*
    Credits to https://medium.com/better-programming/react-router-architecture-thats-simple-scalable-and-protected-da896827f946
*/
