import { uiConstants } from "../types";

const initialState = {
  loading: false,
  drawer: false
};

export const ui = (state = initialState, action) => {
  switch (action.type) {
    /*--- SET initial state ---*/
    case uiConstants.SET_INITIAL_STATE:
      return initialState;
    /*--- SET initial state ---*/

    case uiConstants.START_LOADING:
      return { ...state, loading: true };
    case uiConstants.END_LOADING:
      return { ...state, loading: false };

    // Drawer para landingpage
    case uiConstants.SHOW_DRAWER:
      return {
        ...state,
        drawer: true
      };
    case uiConstants.HIDE_DRAWER:
      return {
        ...state,
        drawer: false
      };
    case uiConstants.SET_DRAWER:
      return {
        ...state,
        drawer: !state.drawer
      };
    default:
      return { ...state };
  }
};
export default ui;
