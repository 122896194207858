import Axios from '../../axios'
import { searchTypes } from '../types'

const search = (query, page = 1) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.get(`/search?name=${query}&page=${page}`)
        .then((result) => {
          dispatch({
            type: searchTypes.NEW,
            payload: { query, results: result.data }
          })
          resolve(result.data)
        })
        .catch((error) => {
          reject()
        })
    })
  }
}

export const searchActions = {
  search
}
