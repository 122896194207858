import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography, IconButton, Grid } from '@material-ui/core'
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from '@material-ui/icons'
import SubmitButton from '../UI/ButtonWithLoading'
import { useDispatch } from 'react-redux'
import { reportConstants } from '../../state/types'
import { reportActions } from '../../state/actions'

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(2), maxWidth: '100%' },
  row: { width: '100%' },
  paper: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    maxWidth: '100%'
  }
}))

const MIN_POSITION = 1
const MAX_POSITION = 3

/**
 * Componente para mostrar cada sección del informe
 */
const Section = ({ element, increasePosition, decreasePosition }) => {
  const classes = useStyles()
  const { title, position } = element

  return (
    <Paper variant='outlined' classes={{ root: classes.paper }}>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item xs>
          <Typography>{title}</Typography>
        </Grid>
        <Grid item>
          {position !== MAX_POSITION && (
            <IconButton onClick={() => increasePosition()}>
              <KeyboardArrowDownIcon />
            </IconButton>
          )}
          {position !== MIN_POSITION && (
            <IconButton onClick={() => decreasePosition()}>
              <KeyboardArrowUpIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

const StructureTab = ({ report }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [structureOrder, setStructureOrder] = useState([
    {
      type: 'initiatives',
      position: report.structureOrder.find(
        (element) => element.type === 'initiatives'
      )?.position,
      title: 'Iniciativas'
    },
    {
      type: 'indicatorsTable',
      position: report.structureOrder.find(
        (element) => element.type === 'indicatorsTable'
      )?.position,
      title: 'Tabla de indicadores'
    },
    {
      type: 'materiality',
      position: report.structureOrder.find(
        (element) => element.type === 'materiality'
      )?.position,
      title: 'Análisis de materialidad'
    }
  ])

  const decreasePosition = (currentPosition) => {
    const tempStructureOrder = structureOrder.map((element) => {
      if (element.position === currentPosition) {
        return { ...element, position: element.position - 1 }
      } else if (element.position === currentPosition - 1) {
        return { ...element, position: element.position + 1 }
      }
      return element
    })

    setStructureOrder(tempStructureOrder)
  }

  const increasePosition = (currentPosition) => {
    const tempStructureOrder = structureOrder.map((element) => {
      if (element.position === currentPosition) {
        return { ...element, position: element.position + 1 }
      } else if (element.position === currentPosition + 1) {
        return { ...element, position: element.position - 1 }
      }
      return element
    })

    setStructureOrder(tempStructureOrder)
  }

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [submitOk, setSubmitOk] = useState(false)
  const handleSubmit = (event) => {
    event.preventDefault()
    const dataToSubmit = {
      report: {
        structureOrder: structureOrder.map((element) => ({
          type: element.type,
          position: element.position
        }))
      }
    }
    const submit = async () => {
      setLoading(true)
      setError(false)
      setSubmitOk(false)
      await dispatch(reportActions.update(report.id, dataToSubmit))
        .then(() => {
          setLoading(false)
          setSubmitOk(true)
          setTimeout(() => {
            dispatch({
              type: reportConstants.GLOBAL_REPORT_DIALOG.CHANGE_TAB,
              payload: 3
            })
          }, 1000)
        })
        .catch(() => {
          setError(true)
          setLoading(false)
        })
    }
    submit()
  }

  return (
    <div className={classes.root}>
      <Grid container direction='column' spacing={2} alignItems='flex-start'>
        {structureOrder
          .sort((current, next) => {
            if (current.position > next.position) {
              return 1
            }
            if (current.position < next.position) {
              return -1
            }
            return 0
          })
          .map((element) => (
            <Grid key={element.type} item xs={12} className={classes.row}>
              <Section
                element={element}
                decreasePosition={() => decreasePosition(element.position)}
                increasePosition={() => increasePosition(element.position)}
              />
            </Grid>
          ))}
        {error && (
          <Grid item xs>
            <Typography color='error'>Ha ocurrido un error</Typography>
          </Grid>
        )}

        <Grid item xs={12} container justifyContent='center'>
          <SubmitButton
            disabled={loading}
            text='Actualizar / Siguiente'
            loading={loading}
            submitOk={submitOk}
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default StructureTab
