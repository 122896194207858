import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import 'moment/locale/es'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Switch from '@material-ui/core/Switch'
import DeleteIcon from '@material-ui/icons/Delete'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import IconButton from '@material-ui/core/IconButton'
import { graphViewsActions } from '../../state/actions/graphViews.actions'
// import EditIcon from '@material-ui/icons/Edit'

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: '0.8em',
    backgroundColor: 'rgb(65, 178, 197)',
    color: theme.palette.common.white,
    borderRadius: 9,
    maxWidth: 200,
    border: 0,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 3, 1)
  }
}))(Tooltip)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 600
  },
  container: {
    paddingLeft: '6%'
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  }
}))

const dictionary = {
  AGGREGATE: 'instalaciones',
  BSNS: 'líneas de negocio',
  GLOBAL: 'corporativo',
  yearly: 'anual',
  semestral: 'semestral',
  trimestral: 'trimestral',
  monthly: 'mensual',
  weekly: 'semanal',
  daily: 'diario'
}
const colorsArr = ['#00C3CA', '#6efeff', '#666', '#6492ad', '#003a52', '#fff']
const assignColor = (pV) => {
  switch (pV) {
    case 'yearly':
      return colorsArr[0]
    case 'semestral':
      return colorsArr[1]
    case 'trimestral':
      return colorsArr[2]
    case 'monthly':
      return colorsArr[3]
    case 'weekly':
      return colorsArr[4]
    case 'daily':
      return colorsArr[5]
    default:
      return colorsArr[4]
  }
}

const GraphViewList = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const vvs = useSelector((state) =>
    state.graphViews.views ? state.graphViews.views : []
  )
  const [selectedToReport, setSelectedToReport] = useState(
    vvs.filter((view) => view.selectedToInitiative)
  )

  useEffect(() => {
    if (vvs.length > 0) {
      let theSelected = vvs.filter((view) => view.selectedToInitiative)
      let indexSelected = theSelected.map((view) => view.id)
      setSelectedToReport(indexSelected)
    }
  }, [vvs])

  const deleteView = (viewId, indiId) => {
    dispatch(graphViewsActions.destroy(viewId, indiId))
  }

  const addViewToReport = (indiId, id) => {
    // despachar acción para actualizar la vista
    let targetView = vvs.find((view) => view.id === id)
    delete targetView.createdAt
    delete targetView.updatedAt
    let data = {
      ...targetView,
      selectedToReport: !targetView.selectedToInitiative
    }

    dispatch(graphViewsActions.update(indiId, data))
  }
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={6}>
        <Typography variant='h6' className={classes.title}>
          Vistas guardadas
        </Typography>
        {vvs.length > 0 ? (
          <div className={classes.demo}>
            <List dense>
              {vvs.map((view, idx) => (
                <ListItem key={`${view.createdAt}-${idx}`}>
                  <ListItemAvatar>
                    <Avatar style={{ color: assignColor(view.periodVisual) }}>
                      <InsertChartIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${dictionary[view.reachVisual]}/${
                      dictionary[view.periodVisual]
                    }`}
                    secondary={`creada el ${moment(view.createdAt).format(
                      'DD MMM YYYY'
                    )}`}
                  />
                  <ListItemSecondaryAction>
                    {/* <IconButton edge='end' aria-label='edit'>
                    <EditIcon />
                  </IconButton> */}
                    <IconButton
                      edge='end'
                      aria-label='delete'
                      onClick={() => {
                        deleteView(view.id, props.indicatorId)
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <HtmlTooltip
                      title={
                        <div>
                          Este switch te permite incluir la vista en el reporte
                          de iniciativas
                        </div>
                      }
                    >
                      <Switch
                        edge='end'
                        onChange={() =>
                          addViewToReport(props.indicatorId, view.id)
                        }
                        checked={selectedToReport.indexOf(view.id) !== -1}
                        inputProps={{
                          'aria-labelledby':
                            'switch-list-label-select-to-report'
                        }}
                      />
                    </HtmlTooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        ) : (
          <p>No existen vistas guardadas para este indicador</p>
        )}
      </Grid>
    </Grid>
  )
}

export default GraphViewList
