import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { beautifyNumber } from '../../helpers/numbers.helper'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10
  },
  number: {
    color: '#26cad3',
    fontWeight: 400,
    fontSize: `3rem`,
    fontFamily: 'Helvetica',
    textTransform: 'none'
  },
  numberSmall: {
    color: '#26cad3',
    fontWeight: 400,
    fontSize: `2rem`,
    fontFamily: 'Helvetica',
    textTransform: 'none'
  },
  measure: {
    color: '#888',
    fontWeight: 300,
    fontSize: `1rem`,
    fontFamily: 'Helvetica',
    textTransform: 'none',
    marginLeft: theme.spacing(1)
  }
}))

const IndicatorStatus = ({ indicator }) => {
  const classes = useStyles()
  const [progress, setProgress] = useState(0)

  useEffect(
    () => {
      setProgress(indicator.currentStatus)
      // setProgress(indicator.accumulated)
    },
    // eslint-disable-next-line
    [indicator]
  )
  const [sortedLogs, setSortedLogs] = useState(
    indicator.logs.sort((a, b) => b.updatedAt - a.updatedAt)
  )

  const [lastLogValue, setLastLogValue] = useState(
    sortedLogs.length > 0 ? sortedLogs[0].value : 0
  )

  const [lastLogValueLength, setLastLogValueLength] = useState(
    sortedLogs.length > 0 ? String(sortedLogs[0].value).length : '0'
  )
  // console.log('lastLogValue', lastLogValue, indicator.name)
  const [isCumulative, setIsCumulative] = useState(indicator.cumulative)
  const measureLabel = (isCltv, measureUnit) => {
    switch (isCltv) {
      case null:
        return `${measureUnit}`
      case true:
        return `${measureUnit}`
      case false:
        return `${measureUnit} promedio`
      default:
        return `${measureUnit}`
    }
  }
  return (
    <div className={classes.root}>
      <Tooltip title='Último registro'>
        <Typography variant='h2' className={lastLogValueLength > 4 ? classes.numberSmall : classes.number}>
          {lastLogValue !== 0 ? beautifyNumber(Number(lastLogValue)) : 0}
        </Typography>
      </Tooltip>
      <Typography className={classes.measure}>
        {/* {measureLabel(isCumulative, indicator.measure)} */}
        {/* {`${isCumulative}`} */}
        {indicator.measure}
      </Typography>
    </div>
  )
}

export default IndicatorStatus
