import Axios from '../../axios'
import {
  resourcesConstants
} from '../types'
import {
  uiActions
} from './ui.actions'
import {
  enqueueSnackbar
} from './notification.actions'

const fetchCountries = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.get('/countries-regions')
        .then((response) => {
          dispatch({
            type: resourcesConstants.COUNTRIES_FETCH,
            payload: response.data
          })
          resolve(response.data)
        })
        .catch((error) => {
          //   dispatch();
          reject(error.response)
        })
    })
  }
}

function fetchOds() {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.get('/ods')
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido obtener los ODS'
          })
        )
      })
  }

  function start() {
    return {
      type: resourcesConstants.ODS_FETCH_STARTED
    }
  }
  // come here to fix ods appeareances
  function success(data) {
    data.ods.forEach((o) => {
      o['label'] = o.name
      o['value'] = o.id
    })
    return {
      type: resourcesConstants.ODS_FETCH_SUCCESS,
      payload: data.ods
    }
  }

  function failure(error) {
    return {
      type: resourcesConstants.ODS_FETCH_FAILURE,
      payload: error
    }
  }
}

function fetchPerspectives() {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.get('/perspectives')
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido obtener las dimensiones'
          })
        )
      })
  }

  function start() {
    return {
      type: resourcesConstants.PERSPECTIVE_FETCH_STARTED
    }
  }

  function success(data) {
    return {
      type: resourcesConstants.PERSPECTIVE_FETCH_SUCCESS,
      payload: data.perspectives
    }
  }

  function failure(error) {
    return {
      type: resourcesConstants.PERSPECTIVE_FETCH_FAILURE,
      payload: error
    }
  }
}

// fetch all contents

function fetchGriContents() {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.get('/gri_contents')
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se han podido obtener los contenidos GRI'
          })
        )
      })
  }

  function start() {
    return {
      type: resourcesConstants.GRI_CONTENTS_STARTED
    }
  }

  function success(data) {
    return {
      type: resourcesConstants.GRI_CONTENTS_SUCCESS,
      payload: data.griContents
    }
  }

  function failure(error) {
    return {
      type: resourcesConstants.GRI_CONTENTS_FAILED,
      payload: error
    }
  }
}


function fetchSectors() {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.get('/sectors')
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido obtener los sectores de industría'
          })
        )
      })

    function start() {
      return {
        type: resourcesConstants.SECTOR_FETCH_STARTED
      }
    }

    function success(data) {
      return {
        type: resourcesConstants.SECTOR_FETCH_SUCCESS,
        payload: data.sectors
      }
    }

    function failure(error) {
      return {
        type: resourcesConstants.SECTOR_FETCH_FAILURE,
        payload: error
      }
    }
  }
}


export const resourcesActions = {
  fetchOds,
  fetchPerspectives,
  fetchSectors,
  fetchCountries,
  fetchGriContents
}