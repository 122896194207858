import ConsoleLog from '../../debugging/ConsoleLog'
import {
  materialityConstants
} from '../types'

const initialMaterialityData = {
  loading: false,
  error: null,
  valid: false,
  name: '',
  description: '',
  odsId: ''
}

const initialMaterialityModal = {
  open: false,
  loading: false,
  error: null,
  id: 0,
  interests: [],
  interestData: initialMaterialityData
}

const initialInterestModal = {
  open: false,
  valid: false,
  loading: false,
  error: null,
  data: {
    id: null,
    name: '',
    description: '',
    odsId: null,
    sourceId: null,
    ods: {
      id: null
    }
  }
}

const initialInterestGroupModal = {
  open: false,
  valid: false,
  create: true,
  loading: false,
  error: null,
  materialityId: null,
  deleteGroupLink: false,
  data: {
    type: 'EXTERNAL',
    name: '',
    description: '',
    companyId: '',
    members: [],
    newMembers: [],
    removeMembers: [],
    csvMails: []
  }
}

const initialReportInProgress = {
  introduction: '',
  description: '',
  conclusion: '',
  graph1zoom: 0,
  graph2zoom: 0,
  graph3zoom: 0,
}

const updateMultipleInterests = {
  loading: false,
  error: null,
  errors: []
}

const initialPoll = {
  materialityId: null,
  memberId: null,
  interestGroupId: null,
  type: null,
  email: null,
  canVote: null,
  alredyVote: null,
  answers: []
}

const materialityTabs = {
  currentTab: 0
}

const initialState = {
  materialities: [],
  theMateriality: null,
  loading: false,
  error: null,
  materialityModal: initialMaterialityModal,
  interestModal: initialInterestModal,
  interestGroupModal: initialInterestGroupModal,
  reportInProgress: initialReportInProgress,
  poll: initialPoll,
  changeStatusDialogOpen: false,
  updateMultipleInterests: updateMultipleInterests,
  materialityTabs: materialityTabs,
  // { groupId: 0, token: 'fakeToken' }
  linksToGroups: [],
  materialityAnswersCount: [],
  totalCounts: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    /*--- SET initial state ---*/
    case materialityConstants.SET_INITIAL_STATE:
      return initialState
    /*--- SET initial state ---*/

    // FETCH actions
    case materialityConstants.FETCH_STARTED:
      return {
        ...state, loading: true, error: null
      }
    case materialityConstants.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        materialities: action.payload.materialities
      }
    case materialityConstants.FETCH_FAILURE:
      return {
        ...state, loading: false, error: action.payload
      }

    /*---- FETCH THE ONE ---*/
    case materialityConstants.FETCH_THEONE_STARTED:
      return {
        ...state, loading: true, error: null
      }

    case materialityConstants.FETCH_THEONE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        theMateriality: action.payload.materiality
      }
    case materialityConstants.FETCH_THEONE_FAILURE:
      return {
        ...state, loading: false, error: action.payload
      }

    /*---- FETCH THE ONE ---*/

    /*--- CRUD materialities ---*/

    // DELETE actions
    case materialityConstants.DELETE_STARTED:
      return {
        ...state, loading: true, error: null
      }
    case materialityConstants.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        materialities: state.materialities.filter(
          (materiality) => materiality.id !== action.payload.id
        )
      }
    case materialityConstants.DELETE_FAILURE:
      return {
        ...state, loading: false, error: action.payload.error
      }

    // CREATE action
    case materialityConstants.CREATE_STARTED:
      return {
        ...state,
        materialityModal: {
          ...state.materialityModal,
          loading: true,
          error: null
        }
      }
    case materialityConstants.CREATE_SUCCESS:
      return {
        ...state,
        materialityModal: initialMaterialityModal,
        materialities: [...state.materialities, action.payload.materiality],
        materialityTotalCount: 0
      }
    case materialityConstants.CREATE_FAILURE:
      return {
        ...state,
        materialityModal: {
          ...state.materialityModal,
          loading: false,
          error: action.payload.error
        }
      }
    /*--- CRUD materialities ---*/

    /*--- MODALS ---*/
    case materialityConstants.CREATE_MATERIALITY_MODAL.OPEN: {
      return {
        ...state,
        materialityModal: {
          ...state.materialityModal,
          open: true
        }
      }
    }
    case materialityConstants.CREATE_MATERIALITY_MODAL.CLOSE: {
      return {
        ...state,
        materialityModal: initialMaterialityModal
      }
    }
    case materialityConstants.CREATE_MATERIALITY_MODAL.SET: {
      return {
        ...state,
        materialityModal: {
          ...state.materialityModal,
          interestData: {
            ...state.materialityModal.interestData,
            ...action.payload.interestData,
            valid: action.payload.valid !== undefined ?
              action.payload.valid : state.materialityModal.valid
          }
        }
      }
    }
    case materialityConstants.CREATE_MATERIALITY_MODAL.CLEAR: {
      return {
        ...state,
        materialityModal: {
          ...state.materialityModal,
          ...initialInterestModal,
          open: true
        }
      }
    }

    case materialityConstants.INTEREST_MODAL.OPEN: {
      return {
        ...state,
        interestModal: {
          ...initialInterestModal,
          open: true,
          data: {
            ...initialInterestModal.data,
            ...action.payload
          }
        }
        // interestModalOpen: true,
        // interestModal: action.payload || initialInterestModal
      }
    }
    case materialityConstants.INTEREST_MODAL.CLOSE: {
      return {
        ...state,
        // interestModalOpen: false,
        interestModal: initialInterestModal
      }
    }
    case materialityConstants.INTEREST_MODAL.SET: {
      return {
        ...state,
        interestModal: {
          ...state.interestModal,
          valid: action.payload.valid !== undefined ?
            action.payload.valid : state.interestModal.valid,
          data: action.payload.data ? {
            ...state.interestModal.data,
            ...action.payload.data
          } : state.interestModal.data
        }
        // interestModal: { ...state.interestModal, ...action.payload }
      }
    }
    case materialityConstants.INTEREST_MODAL.CLEAR: {
      return {
        ...state,
        interestModal: initialInterestModal
      }
    }

    case materialityConstants.INTEREST_GROUP_MODAL.OPEN:
      return {
        ...state,
        interestGroupModal: {
          ...state.interestGroupModal,
          materialityId: action.payload.materialityId ?
            action.payload.materialityId : state.interestGroupModal.materialityId,
          open: true,
          create: action.payload.create !== undefined ? action.payload.create : true,
          data: {
            ...initialInterestGroupModal.data,
            ...action.payload.data
          }
        }
      }
    case materialityConstants.INTEREST_GROUP_MODAL.CLOSE:
      return {
        ...state,
        interestGroupModal: initialInterestGroupModal
      }
    case materialityConstants.INTEREST_GROUP_MODAL.SET:
      return {
        ...state,
        interestGroupModal: {
          ...state.interestGroupModal,
          valid: action.payload.valid !== undefined ?
            action.payload.valid : state.interestGroupModal.valid,
          data: action.payload.data ? {
            ...state.interestGroupModal.data,
            ...action.payload.data
          } : state.interestGroupModal.data
        }
      }
    case materialityConstants.INTEREST_GROUP_MODAL.SET_MEMBER:
      return {
        ...state,
        interestGroupModal: {
          ...state.interestGroupModal,
          data: {
            ...state.interestGroupModal.data,
            members: state.interestGroupModal.create ? [...state.interestGroupModal.data.members, action.payload] : state.interestGroupModal.data.members,
            newMembers: !state.interestGroupModal.create ? [...state.interestGroupModal.data.newMembers, action.payload] : state.interestGroupModal.data.newMembers
          }
        }
      }
    case materialityConstants.INTEREST_GROUP_MODAL.DELETE_MEMBER:
      const isDeletingAGroupLink = action.payload.email.includes('@teropoll.com')
      return {
        ...state,
        interestGroupModal: {
          ...state.interestGroupModal,
          deleteGroupLink: isDeletingAGroupLink,
          data: {
            ...state.interestGroupModal.data,
            members: state.interestGroupModal.data.members.filter(
              (member) => member.email !== action.payload.email
            ),
            newMembers: state.interestGroupModal.data.newMembers.filter(
              (member) => member.email !== action.payload.email
            ),
            removeMembers: !state.interestGroupModal.create ? [...state.interestGroupModal.data.removeMembers, action.payload] : state.interestGroupModal.data.removeMembers
          }
        }
      }
    case materialityConstants.INTEREST_GROUP_MODAL.READD_MEMBER:
      return {
        ...state,
        interestGroupModal: {
          ...state.interestGroupModal,
          data: {
            ...state.interestGroupModal.data,
            members: [...state.interestGroupModal.data.members, action.payload],
            newMembers: [
              ...state.interestGroupModal.data.newMembers,
              action.payload
            ],
            removeMembers: state.interestGroupModal.data.removeMembers.filter(
              (member) => member.email !== action.payload.email
            )
          }
        }
      }
    case materialityConstants.INTEREST_GROUP_MODAL.CLEAR:
      return {
        ...state,
        interestGroupModal: initialInterestGroupModal
      }

    /*--- OPEN POLLS ---*/
    case materialityConstants.INTEREST_GROUP_MODAL.SET_MASSIVE_LINK:
      return {
        ...state,
        interestGroupModal: {
          ...state.interestGroupModal,
          data: {
            ...state.interestGroupModal.data,
            members: state.interestGroupModal.create ? [...state.interestGroupModal.data.members, action.payload] : state.interestGroupModal.data.members,
            newMembers: !state.interestGroupModal.create ? [...state.interestGroupModal.data.newMembers, action.payload] : state.interestGroupModal.data.newMembers
          }
        }
      }
    case materialityConstants.INTEREST_GROUP_MODAL.REMOVE_MASSIVE_LINK:
      return {
        ...state,
        interestGroupModal: {
          ...state.interestGroupModal,
          data: {
            ...state.interestGroupModal.data,
            members: state.interestGroupModal.data.members.filter(
              (member) => member.email !== action.payload.email
            ),
            newMembers: state.interestGroupModal.data.newMembers.filter(
              (member) => member.email !== action.payload.email
            ),
            removeMembers: !state.interestGroupModal.create ? [...state.interestGroupModal.data.removeMembers, action.payload] : state.interestGroupModal.data.removeMembers
          }
        }
      }

    case materialityConstants.HARVEST_POLL_LINKS_START:
      return {
        ...state
      }
    case materialityConstants.HARVEST_POLL_LINKS_SUCCESS:
      const filtered = [...state.linksToGroups].filter(
        (ele) => ele.groupId !== action.payload.groupId
      )
      return {
        ...state,
        linksToGroups: [...filtered, action.payload]
      }

    case materialityConstants.HARVEST_POLL_LINKS_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.payload
      }
    case materialityConstants.CLEAR_ERROR:
      return {
        ...state,
        error: null
      }

    /*--- END HARVESTING POLLS---*/

    /*--- FETCH POLLS COUNT ---*/
    case materialityConstants.FETCH_ANSWERS_COUNT_START:
      return {
        ...state
      }
    case materialityConstants.FETCH_ANSWERS_COUNT_SUCCESS:
      let filterTheCount = state.totalCounts.filter(
        (ele) => ele.matId !== action.payload.totalCounts.matId
      )
      return {
        ...state,
        materialityAnswersCount: action.payload.materialityCount,
        totalCounts: [...filterTheCount, action.payload.totalCounts]
      }

    case materialityConstants.FETCH_ANSWERS_COUNT_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.payload
      }

    /*--- END OF FETCH POLLS COUNT ---*/

    /*--- MODALS ---*/

    /*--- EDIT INTEREST ---*/
    case materialityConstants.EDIT_INTEREST_STARTED:
      return {
        ...state,
        interestModal: {
          ...state.interestModal,
          loading: true,
          error: null
        }
      }
    // reference marker 2
    case materialityConstants.EDIT_INTEREST_SUCCESS:
      const withNewContentInterests = state.theMateriality.withScores.interests
        .map((interest) => {
          return interest.id !== action.payload.interestId ? interest : action.payload.data
        })
      return {
        ...state,
        interestModal: {
          ...state.interestModal,
          loading: true,
          error: null,
          open: false
        },
        theMateriality: {
          ...state.theMateriality,
          withScores: {
            ...state.theMateriality.withScores,
            interests: [...withNewContentInterests]
          }
        }


        // materialities: state.materialities.map((materiality) => {
        //   if (materiality.id !== action.payload.materialityId) {
        //     return materiality
        //   }
        //   return {
        //     ...materiality,
        //     interests: materiality.interests.map((interest) => {
        //       if (interest.id !== action.payload.interestId) {
        //         return interest
        //       }
        //       return { ...interest, ...action.payload.data }
        //     })
        //   }
        // })
      }
    case materialityConstants.EDIT_INTEREST_FAILURE:
      return {
        ...state,
        interestModal: {
          ...state.interestModal,
          loading: true,
          error: action.payload
        }
        // loading: false,
        // error: action.payload
      }

    /*--- ADD Interest ---*/
    case materialityConstants.ADD_INTEREST_STARTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    // reference marker 1
    case materialityConstants.ADD_INTEREST_SUCCESS:
      const interestsUpdated = action.payload.interest
      return {
        ...state,
        loading: false,
        error: null,
        modalOpen: false,
        theMateriality: {
          ...state.theMateriality,
          withScores: {
            ...state.theMateriality.withScores,
            interests: [...state.theMateriality.withScores.interests, interestsUpdated]
          }
        }
        // materialities: state.materialities.map((materiality) => {
        //   if (materiality.id !== action.payload.materialityId) {
        //     return materiality
        //   }
        //   return {
        //     ...materiality,
        //     interests: [...materiality.interests, action.payload.interest]
        //   }
        // })
      }
    case materialityConstants.ADD_INTEREST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    /*--- ADD Interest ---*/

    /*--- ADD group ---*/
    // developer at work
    case materialityConstants.ADD_INTEREST_GROUP_STARTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    // marker 1
    case materialityConstants.ADD_INTEREST_GROUP_SUCCESS:
      const groupUpdated = action.payload.group
      return {
        ...state,
        loading: false,
        error: null,
        interestGroupModal: initialInterestGroupModal,
        theMateriality: {
          ...state.theMateriality,
          withInterests: {
            ...state.theMateriality.withInterests,
            interestGroups: [...state.theMateriality.withInterests.interestGroups, groupUpdated]
          }
        }
        // materialities: {
        // ...state.materialities,
        // materialities: state.materialities.map((materiality) => {
        //   if (materiality.id !== action.payload.materialityId) {
        //     return materiality
        //   }
        //   return {
        //     ...materiality,
        //     interestGroups: [
        //       ...materiality.interestGroups,
        //       action.payload.group
        //     ]
        //   }
        // })
        // }
      }

    case materialityConstants.ADD_INTEREST_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    /*--- ADD group ---*/

    /*--- DELETE GROUP ---*/
    case materialityConstants.DELETE_INTEREST_GROUP_STARTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    // marker 2
    case materialityConstants.DELETE_INTEREST_GROUP_SUCCESS:
      const minusOneGroup = state.theMateriality.withInterests.interestGroups.filter((group) => group.id !== action.payload.groupId)
      return {
        ...state,
        loading: false,
        error: null,
        theMateriality: {
          ...state.theMateriality,
          withInterests: {
            ...state.theMateriality.withInterests,
            interestGroups: minusOneGroup
          }
        }
      }
    // return {
    //   ...state,
    //   loading: false,
    //   materialities: state.materialities.map((materiality) => {
    //     if (materiality.id !== action.payload.materialityId) {
    //       return materiality
    //     }
    //     return {
    //       ...materiality,
    //       interestGroups: materiality.interestGroups.filter(
    //         (group) => group.id !== action.payload.groupId
    //       )
    //     }
    //   })
    // }


    case materialityConstants.DELETE_INTEREST_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    /*--- DELETE GROUP ---*/

    /*--- EDIT GROUP ---*/
    case materialityConstants.EDIT_INTEREST_GROUP_STARTED:
      return {
        ...state,
        interestGroupModal: {
          ...state.interestGroupModal,
          loading: true,
          error: null
        }
      }
    // marker 3
    case materialityConstants.EDIT_INTEREST_GROUP_SUCCESS:
      const removeAlsoTheGroupLink = state.interestGroupModal.deleteGroupLink
      let updatedLinks = state.linksToGroups
      if (removeAlsoTheGroupLink) {
        updatedLinks = updatedLinks.filter(ele => ele.groupId !== action.payload.id)
      }
      const withEditedGroup = state.theMateriality.withInterests.interestGroups.map((group) => {
        return group.id !== action.payload.id ? group : action.payload
      })
      return {
        ...state,
        interestGroupModal: initialInterestGroupModal,
        linksToGroups: [...updatedLinks],
        theMateriality: {
          ...state.theMateriality,
          withInterests: {
            ...state.theMateriality.withInterests,
            interestGroups: [...withEditedGroup]
          }
        }
      }

    // materialities: state.materialities.map((materiality) => {
    //   if (materiality.id !== action.payload.materialityId) {
    //     return materiality
    //   }
    //   return {
    //     ...materiality,
    //     interestGroups: materiality.interestGroups.map((group) => {
    //       if (group.id !== action.payload.id) {
    //         return group
    //       }
    //       return action.payload
    //     })
    //   }
    // })

    // linksToGroups: action.payload.listRecord

    case materialityConstants.EDIT_INTEREST_GROUP_FAILURE:
      return {
        ...state,
        interestGroupModal: {
          ...state.interestGroupModal,
          loading: false,
          error: action.payload
        }
      }
    /*--- EDIT GROUP ---*/

    /*--- MATERIALITY POLL ---*/
    case materialityConstants.SET_MATERIALITY_POLL:
      return {
        ...state,
        poll: action.payload
      }

    case materialityConstants.SET_SCORE:
      return {
        ...state,
        poll: {
          ...state.poll,
          answers: state.poll.answers.map((interest) => {
            if (interest.interestId !== action.payload.interestId) {
              return interest
            }
            return {
              ...interest,
              score: action.payload.score
            }
          })
        }
      }

    case materialityConstants.SEND_MATERIALITY_POLL_STARTED:
      return {
        ...state,
        loading: true
      }
    case materialityConstants.SEND_MATERIALITY_POLL_SUCCESS:
      return {
        ...state,
        loading: false,
        poll: {
          ...state.poll,
          voteSuccess: true,
          ...initialPoll
        }
      }
    case materialityConstants.SEND_MATERIALITY_POLL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case materialityConstants.GET_POLL_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case materialityConstants.GET_POLL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        poll: {
          ...action.payload.data,
          token: action.payload.token
        }
      }
    case materialityConstants.GET_POLL_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case materialityConstants.GET_PUBLIC_POLL_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case materialityConstants.GET_PUBLIC_POLL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        poll: {
          ...action.payload.data
        }
      }
    case materialityConstants.GET_PUBLIC_POLL_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    /*--- MATERIALITY POLL ---*/

    /*--- UPDATE STATUS MATERIALITY ---*/
    case materialityConstants.UPDATE_STATUS_STARTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case materialityConstants.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        changeStatusDialogOpen: false,
        materialities: state.materialities.map((materiality) => {
          if (materiality.id !== action.payload.id) return materiality
          return action.payload
        })
      }
    case materialityConstants.UPDATE_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    /*--- UPDATE STATUS MATERIALITY ---*/

    /*--- UPDATE MODAL MATERIALITY STATUS ---*/
    case materialityConstants.UPDATE_STATUS_DIALOG:
      return {
        ...state,
        changeStatusDialogOpen: action.payload.open
      }
    /*--- UPDATE MODAL MATERIALITY STATUS ---*/

    /*--- UPDATE MULTIPLE INTERESTS ---*/
    case materialityConstants.EDIT_MULTIPLE_STARTED:
      return {
        ...state,
        updateMultipleInterests: {
          ...state.updateMultipleInterests,
          loading: true,
          error: null,
          errors: []
        }
      }

    case materialityConstants.EDIT_MULTIPLE_SUCCESS:
      return {
        ...state,
        materialities: state.materialities.map((materiality) => {
          if (materiality.id !== action.payload.materialityId) {
            return materiality
          }
          return {
            ...materiality,
            interests: materiality.interests.map((interest) => {
              let find = action.payload.successResponses.find(
                (response) => response.id === interest.id
              )
              if (find) {
                return find
              }
              return interest
            })
          }
        }),
        updateMultipleInterests: {
          ...state.updateMultipleInterests,
          loading: false,
          error: null,
          errors: []
        }
      }

    case materialityConstants.EDIT_MULTIPLE_FAILURE:
      return {
        ...state,
        materialities: state.materialities.map((materiality) => {
          if (materiality.id !== action.payload.materialityId) {
            return materiality
          }
          return {
            ...materiality,
            interests: materiality.interests.map((interest) => {
              let find = action.payload.successResponses.find(
                (response) => response.id === interest.id
              )
              if (find) {
                return find
              }
              return interest
            })
          }
        }),
        updateMultipleInterests: {
          ...state.updateMultipleInterests,
          loading: false,
          error: true,
          errors: action.payload.failureResponses
        }
      }
    /*--- UPDATE MULTIPLE INTERESTS ---*/

    // !Editar desde acá para usar la nueva metodología

    case materialityConstants.ON_CREATE_STARTED:
      return {
        ...state,
        newMateriality: {
          interests: [{
            id: 0,
            name: ''
          },
          {
            id: 1,
            name: ''
          },
          {
            id: 2,
            name: ''
          },
          {
            id: 3,
            name: ''
          },
          {
            id: 4,
            name: ''
          }
          ]
        }
      }

    // const initialMaterialityModal = {
    //   loading: false,
    //   error: null,
    //   id: "",
    //   valid: false,
    //   interests: [],
    //   interestData: initialMaterialityData
    // };
    // case materialityConstants.ON_CREATE_ADD_INTEREST_STARTED:
    case materialityConstants.ON_CREATE_ADD_INTEREST:
      return {
        ...state,
        materialityModal: {
          ...state.materialityModal,
          interests: [
            ...state.materialityModal.interests,
            {
              ...action.payload.interest,
              id: state.materialityModal.id
            }
          ],
          interestData: initialMaterialityData,
          id: state.materialityModal.id + 1
        }
      }
    // case materialityConstants.ON_CREATE_ADD_INTEREST_FAILURE:
    case materialityConstants.ON_CREATE_UPDATE_INTEREST:
      return {
        ...state,
        materialityModal: {
          ...state.materialityModal,
          interests: state.materialityModal.interests.map((interest) => {
            if (interest.id !== action.payload.interest.id) {
              return interest
            }
            return {
              ...action.payload.interest
            }
          })
        }
      }
    case materialityConstants.ON_CREATE_DELETE_INTEREST:
      return {
        ...state,
        materialityModal: {
          ...state.materialityModal,
          interests: state.materialityModal.interests.filter((interest) => {
            return interest.id !== action.payload.id
          })
        }
      }

    case materialityConstants.DELETE_INTEREST_STARTED:
      return {
        ...state,
        deleting: true
      }
    // reference marker 3
    case materialityConstants.DELETE_INTEREST_SUCCESS:
      delete state.deleting
      const minusOneInterests = state.theMateriality.withScores.interests.filter((interest) => interest.id !== action.payload.interestId)
      return {
        ...state,
        theMateriality: {
          ...state.theMateriality,
          withScores: {
            ...state.theMateriality.withScores,
            interests: minusOneInterests
          }
        }
      }
    // return {
    //   ...state,
    //   materialities: state.materialities.map((materiality) => {
    //     if (materiality.id !== action.payload.materialityId) {
    //       return materiality
    //     }
    //     return {
    //       ...materiality,
    //       interests: materiality.interests.filter(
    //         (interest) => interest.id !== action.payload.interestId
    //       )
    //     }
    //   })
    // }
    case materialityConstants.DELETE_INTEREST_FAILURE:
      delete state.deleting
      return {
        ...state
      }

    case materialityConstants.UPDATE_STARTED:
      return {
        ...state
      }
    case materialityConstants.UPDATE_SUCCESS:
      return {
        ...state,
        materialities: state.materialities.map((materiality) => {
          if (materiality.id !== action.payload.data.id) {
            return materiality
          }
          return action.payload.data
        })
      }
    case materialityConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }

    case materialityConstants.FETCH_SOURCES:
      action.payload.push({
        name: 'Otra',
        id: 0,
        odsId: null,
        description: ''
      })
      return {
        ...state,
        sources: action.payload
          // .push({ name: "Otra", id: null })
          .map((source) => ({
            ...source,
            label: source.name,
            value: source.id,
            interests: source.interests &&
              source.interests.map((interest) => ({
                ...interest,
                label: interest.name,
                value: interest.name
              }))
          }))
      }
    case materialityConstants.SEND_POLL_TO_GROUP:
      const igsAfterSend = state.theMateriality.withInterests.interestGroups.map(group => {
        if (group.id !== action.payload.interestGroupId) {
          return group
        }
        return {
          ...group,
          emailCount: group.emailCount + 1
        }
      })
      return {
        ...state,
        theMateriality: {
          ...state.theMateriality,
          withInterests: {
            ...state.theMateriality.withInterests,
            canAddMaterialityIssues: false,
            status: 'VALIDATION',
            interestGroups: [...igsAfterSend]
          }

        }
        // materialities: state.materialities.map((materiality) => {
        //   if (materiality.id !== action.payload.materialityId) {
        //     return materiality
        //   }
        //   return {
        //     ...materiality,
        //     canAddMaterialityIssues: false,
        //     status: 'VALIDATION',
        //     interestGroups: materiality.interestGroups.map((group) => {
        //       if (group.id !== action.payload.interestGroupId) {
        //         return group
        //       }
        //       return {
        //         ...group,
        //         emailCount: group.emailCount + 1
        //       }
        //     })
        //   }
        // })
      }
    case materialityConstants.SEND_POLL_TO_ALL_GROUPS:
      return {
        ...state,
        materialities: state.materialities.map((materiality) => {
          if (materiality.id !== action.payload.materialityId) {
            return materiality
          }
          return {
            ...materiality,
            canAddMaterialityIssues: false,
            status: 'VALIDATION',
            interestGroups: materiality.interestGroups.map((group) => {
              return {
                ...group,
                emailCount: group.emailCount + 1
              }
            })
          }
        }),
        theMateriality: {
          ...state.theMateriality,
          withInterests: {
            ...state.theMateriality.withInterests,
            canAddMaterialityIssues: false,
            status: 'VALIDATION',
            interestGroups: state.theMateriality.withInterests.interestGroups.map((group) => {
              return {
                ...group,
                emailCount: group.emailCount + 1
              }
            })

          }
        }
      }
    case materialityConstants.CHANGE_TAB:
      return {
        ...state,
        materialityTabs: {
          ...state.materialityTabs,
          currentTab: action.payload.currentTab
        }
      }
    case materialityConstants.CLOSE_MATERIALITY:
      return {
        ...state,
        materialities: state.materialities.map((materiality) => {
          if (materiality.id !== action.payload.materialityId) {
            return materiality
          }
          return action.payload.materiality
        })
      }

    /**
     * Añade un listado de mails a un grupo de interés en el dialog de editar grupo de interés
     */

    case materialityConstants.ADD_CSV_MAILS:
      return {
        ...state,
        interestGroupModal: {
          ...state.interestGroupModal,
          data: {
            ...state.interestGroupModal.data,
            ...action.payload
          }
        }
      }

    /**
     * Permite actualizar un grupo de interés
     */

    case materialityConstants.UPDATE_INTEREST_GROUP:
      const groupsWithTheupdate = state.theMateriality.withInterests.interestGroups.map(group => {
        if (group.id !== action.payload.interestGroupId) {
          return group
        }
        return {
          ...group,
          ...action.payload.data
        }
      })
      return {
        ...state,
        theMateriality: {
          ...state.theMateriality,
          withInterests: {
            ...state.theMateriality.withInterests,
            interestGroups: [...groupsWithTheupdate]
          }
        }
      }

    // return {
    //   ...state,
    //   materialities: state.materialities.map((materiality) => {
    //     if (materiality.id !== action.payload.materialityId) {
    //       return materiality
    //     }
    //     return {
    //       ...materiality,
    //       interestGroups: materiality.interestGroups.map((group) => {
    //         if (group.id !== action.payload.interestGroupId) {
    //           return group
    //         }
    //         return { ...group, ...action.payload.data }
    //       })
    //     }
    //   })
    // }
    /*--- MATERIALITY REPORTS ---*/

    case materialityConstants.REPORTS.CREATE:
      return {
        ...state,
        theMateriality: {
          ...state.theMateriality,
          withReports: {
            ...state.theMateriality.withReports,
            report: action.payload.report
          }
        }
      }

    case materialityConstants.REPORTS.IN_PREP.FETCH:
      return {
        ...state,
        reportInProgress: { ...state.reportInProgress, ...action.payload.report }
      }

    case materialityConstants.REPORTS.UPDATE:
      return {
        ...state,
        theMateriality: {
          ...state.theMateriality,
          withReports: {
            ...state.theMateriality.withReports,
            report: {
              ...action.payload.report,
            }
          }
        }
      }

    case materialityConstants.REPORTS.SAVE:
      return {
        ...state,
        materialities: state.materialities.map((materiality) => {
          if (materiality.id !== action.payload.materialityId) {
            return materiality
          }
          return {
            ...state.theMateriality.withReports,
            withReports: {
              report: {
                ...state.theMateriality.withReports.report,
                snapshots: [
                  ...state.theMateriality.withReports.report.snapshots,
                  action.payload.snapshot
                ]
              }
            }
          }
        })
      }
    case materialityConstants.REPORTS.DELETE_SNAPSHOT:
      // return {
      //   ...state,
      //   globalReportDialog: {
      //     ...state.globalReportDialog,
      //     report: {
      //       ...state.globalReportDialog.report,
      //       snapshots: state.globalReportDialog.report.snapshots.filter(
      //         (snapshot) => snapshot.id !== action.payload.snapshotId
      //       )

      //     }
      //   }
      // }
      return {
        ...state
      }
    /*--- MATERIALITY REPORTS ---*/
    default:
      return state
  }
}