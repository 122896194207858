import { notificationConstants } from '../types'
import Axios from '../../axios'
import { authenticationActions } from './authentication.actions'

export const enqueueSnackbar = (notification) => ({
  type: notificationConstants.ENQUEUE_SNACKBAR,
  payload: { notification }
})

export const removeSnackbar = (key) => ({
  type: notificationConstants.REMOVE_SNACKBAR,
  payload: { key }
})

/**
 * Obtiene las notificaciones del feed de usuario
 */

const fetchStarted = () => ({
  type: notificationConstants.FETCH_STARTED
})
const fetchSuccess = (notifications) => ({
  type: notificationConstants.FETCH_SUCCESS,
  payload: notifications
})
const fetchFailure = (error) => ({
  type: notificationConstants.FETCH_FAILURE,
  payload: error
})
export const fetch = () => {
  return (dispatch) => {
    dispatch(fetchStarted())
    Axios.get('/notifications')
      .then((response) => {
        dispatch(fetchSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchFailure(error.response))
        dispatch(authenticationActions.changeToken())
      })
  }
}

/**
 * Obtiene nuevas notificaciones del feed de usuario
 */

// const fetchMoreStarted = () => ({
//   type: notificationConstants.FETCH_STARTED,
// });
const fetchMoreSuccess = (notifications) => ({
  type: notificationConstants.FETCH_MORE_SUCCESS,
  payload: notifications
})
const fetchMoreFailure = (error) => ({
  type: notificationConstants.FETCH_MORE_FAILURE,
  payload: error
})
export const fetchMore = (page) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return Axios.get(`/notifications?page=${page}`)
        .then((response) => {
          dispatch(fetchMoreSuccess(response.data.oldNotifications))
          resolve(response.data.oldNotifications)
        })
        .catch((error) => {
          dispatch(fetchMoreFailure(error.response))
          reject(error.response)
        })
    })
  }
}
/**
 * Actualiza el estado de lectura de una notificacion
 * @param {Array} ids - Array con los ids de las notificaciones a actualizar
 */

const setReadStarted = () => ({ type: notificationConstants.SET_READ_STARTED })
const setReadSuccess = (data, ids) => ({
  type: notificationConstants.SET_READ_SUCCESS,
  payload: data.notifications
})
const setReadFailure = (error) => ({
  type: notificationConstants.SET_READ_FAILURE,
  payload: error
})
export const setRead = (ids) => {
  return (dispatch) => {
    dispatch(setReadStarted())
    Axios.patch('/notifications', { notificationIds: ids })
      .then((response) => {
        dispatch(setReadSuccess(response.data, ids))
      })
      .catch((error) => {
        dispatch(setReadFailure(error.response))
      })
  }
}

/**
 * Añade nuevas notificaciones provenientes del socket.
 * @param {Object} notification - Nueva notificación al usuario
 */

// const notificationText = (type) => {
//   switch (type) {
//     case "NEW_FOL_POST":
//       return `ha realizado una nueva publicación`;
//     case "NEW_ORG_POST":
//       return `ha realizado una nueva publicación`;
//     case "NEW_COMMENT":
//       return `ha realizado un comentario en tu publicación`;
//     case "NEW_LIKE":
//       return `le ha dado like a tu publicación`;
//     default:
//       return "";
//   }
// };

// const notificationLink = (type, post, comment, like) => {
//   switch (type) {
//     case "NEW_FOL_POST":
//       return `/app/posts/${post.id}`;
//     case "NEW_ORG_POST":
//       return `/app/posts/${post.id}`;
//     case "NEW_COMMENT":
//       return `/app/posts/${comment.postId}`;
//     case "NEW_LIKE":
//       return `/app/posts/${like.postId}`;
//     default:
//       return "";
//   }
// };

const newNotification = (notification) => {
  return (dispatch) => {
    dispatch({ type: notificationConstants.NEW, payload: notification })
  }
}

export const notificationActions = {
  fetch,
  newNotification,
  setRead,
  fetchMore
}
