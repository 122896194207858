import { format, parse } from 'date-fns'
import { es } from 'date-fns/esm/locale'
import isSameDay from 'date-fns/isSameDay'
import {
  indiOneColor,
  indiTwoColor,
  relationLineColor,
  corporatePallete
} from './colors-generator'
import {
  giveMeYearAndMonth,
  tellMeWhichYearSemester,
  tellMeWhichYearTrimester,
  whichNumberOfWeek,
  areInTheSameWeek
} from './dates-calculations'

const monthsArr = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sept',
  'Oct',
  'Nov',
  'Dic'
]

const assignCumulative = (value) => {
  if (value === null) {
    return true
  } else {
    return value
  }
}

const humanizeMonth = (ymPair) => {
  const year = ymPair.split('-')[0]
  const month = ymPair.split('-')[1]
  const word = monthsArr[parseInt(month) - 1]
  return `${word} ${year}`
}

const datalabelsObj = (division, itemIn) => {
  return {
    color: '#fff',
    anchor: 'end',
    align: 'top',
    clip: true,
    rotation: 270,
    // offset: 2,
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: 3,
    padding: 5,
    marginBottom: 3,
    formatter: function (point, other) {
      if (point.hasOwnProperty('y')) {
        if (point.y === 0) {
          return null
        } else {
          if (Number.isInteger(point.y)) {
            return `${division}: ${point.y}`
          } else {
            return `${division}: ${point.y.toFixed(2)}`
          }
        }
      }
      if (point === 0) {
        return null
      } else {
        if (Number.isInteger(point)) {
          return `${division}: ${point}`
        } else {
          return `${division}: ${point.toFixed(2)}`
        }
      }
    },
    font: {
      size: 9
    }
  }
}

//// "cernido" por divisiones
export const generateLogsPerDivision = (
  indicator,
  orgLines,
  orgBranches,
  targetPeriod
) => {
  if (indicator.logs.length === 0) {
    return []
  }
  const logs = indicator.logs
  // objetos a exportar para indicadores acumulativos
  let logsPerLines = {}
  let logsPerBranches = {}
  let logsCorporate = { corporativo: [] }
  // objetos a exportar para indicadores no acumulativos
  let logsPerLinesNotAc = {}
  let logsPerBranchesNotAc = {}
  let logsCorporateNotAc = { corporativo: [] }
  // clasificación de logs por Instalaciones, LDN o corporativo (esto depende del alcance del indicador (indicator.type))
  for (let log of logs) {
    if (log.lineId !== null) {
      for (let line of orgLines) {
        if (line.id === log.lineId) {
          if (logsPerLines.hasOwnProperty(line.id)) {
            logsPerLines[line.id].push({ date: log.date, value: log.value })
          } else {
            logsPerLines[line.id] = []
            logsPerLines[line.id].push({ date: log.date, value: log.value })
          }
        }
      }
    }
    if (log.branchId !== null) {
      for (let branch of orgBranches) {
        if (branch.id === log.branchId) {
          if (logsPerBranches.hasOwnProperty(branch.id)) {
            logsPerBranches[branch.id].push({
              date: log.date,
              value: log.value
            })
          } else {
            logsPerBranches[branch.id] = []
            logsPerBranches[branch.id].push({
              date: log.date,
              value: log.value
            })
          }
        }
      }
    }
    if (!log.branchId && !log.lineId) {
      logsCorporate['corporativo'].push({ date: log.date, value: log.value })
    }
  }

  // en caso de que el indicador sea no acumulativo (indicator.cumulative),
  // preparar la data con el último valor por período por división
  if (!assignCumulative(indicator.cumulative)) {
    // ------- no acumulativos encontrando los últimos valores de registro para cada año
    if (targetPeriod === 'yearly') {
      // para cada una de las instalaciones en el objeto que contiene instalacion:logs
      for (let branch in logsPerBranches) {
        // extraemos los años presentes
        let years = logsPerBranches[branch].map((log) =>
          new Date(log.date).getFullYear()
        )
        years = years.filter((item, i, ar) => ar.indexOf(item) === i)

        // recorremos el array de años y por cada año
        years.forEach((year) => {
          // recorremos el objeto logsPerBranches según la instalación actual
          const logsInYear = logsPerBranches[branch].filter(
            (log) => new Date(log.date).getFullYear() === year
          )
          const orderedYears = logsInYear.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
          // el valor más reciente es el de interés
          const targetLog = orderedYears[orderedYears.length - 1]
          if (logsPerBranchesNotAc.hasOwnProperty(branch)) {
            logsPerBranchesNotAc[branch].push(targetLog)
          } else {
            logsPerBranchesNotAc[branch] = []
            logsPerBranchesNotAc[branch].push(targetLog)
          }
        })
      }
      for (let line in logsPerLines) {
        // extraemos los años presentes
        let years = logsPerLines[line].map((log) =>
          new Date(log.date).getFullYear()
        )
        years = years.filter((item, i, ar) => ar.indexOf(item) === i)

        // recorremos el array de años y por cada año
        years.forEach((year) => {
          // recorremos el objeto logsPerBranches según la instalación actual
          const logsInYear = logsPerLines[line].filter(
            (log) => new Date(log.date).getFullYear() === year
          )
          const orderedYears = logsInYear.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
          // el valor más reciente es el de interés
          const targetLog = orderedYears[orderedYears.length - 1]
          if (logsPerBranchesNotAc.hasOwnProperty(line)) {
            logsPerLinesNotAc[line].push(targetLog)
          } else {
            logsPerLinesNotAc[line] = []
            logsPerLinesNotAc[line].push(targetLog)
          }
        })
      }

      let years = logsCorporate['corporativo'].map((log) =>
        new Date(log.date).getFullYear()
      )
      years.forEach((year) => {
        // recorremos el objeto logsPerBranches según la instalación actual
        const logsInYear = logsCorporate['corporativo'].filter(
          (log) => new Date(log.date).getFullYear() === year
        )
        const orderedYears = logsInYear.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        )
        // el valor más reciente es el de interés
        const targetLog = orderedYears[orderedYears.length - 1]
        logsCorporateNotAc['corporativo'].push(targetLog)
      })
    }
    // ------- no acumulativos encontrando los últimos valores de registro para semestres

    if (targetPeriod === 'semestral') {
      // para cada división, encontrar a qué período pertenece cada log
      for (let branch in logsPerBranches) {
        let semesters = logsPerBranches[branch].map((log) =>
          tellMeWhichYearSemester(log.date)
        )
        const uniqueSemesters = semesters.filter(
          (item, i, ar) => ar.indexOf(item) === i
        )
        uniqueSemesters.forEach((sem) => {
          const logsInSemester = logsPerBranches[branch].filter((log) => {
            return tellMeWhichYearSemester(new Date(log.date)) === sem
          })
          const orderedLogs = logsInSemester.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
          const targetLog = orderedLogs[orderedLogs.length - 1]
          if (logsPerBranchesNotAc.hasOwnProperty(branch)) {
            logsPerBranchesNotAc[branch].push(targetLog)
          } else {
            logsPerBranchesNotAc[branch] = []
            logsPerBranchesNotAc[branch].push(targetLog)
          }
        })
      }
      for (let line in logsPerLines) {
        let semesters = logsPerLines[line].map((log) =>
          tellMeWhichYearSemester(log.date)
        )
        const uniqueSemesters = semesters.filter(
          (item, i, ar) => ar.indexOf(item) === i
        )
        uniqueSemesters.forEach((semester) => {
          // recorremos el objeto logsPerBranches según la instalación actual
          const logsInSemester = logsPerLines[line].filter(
            (log) => tellMeWhichYearSemester(new Date(log.date)) === semester
          )
          const orderedSemesters = logsInSemester.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
          // el valor más reciente es el de interés
          const targetLog = orderedSemesters[orderedSemesters.length - 1]
          if (logsPerLinesNotAc.hasOwnProperty(line)) {
            logsPerLinesNotAc[line].push(targetLog)
          } else {
            logsPerLinesNotAc[line] = []
            logsPerLinesNotAc[line].push(targetLog)
          }
        })
      }
      let semesters = logsCorporate['corporativo'].map((log) =>
        tellMeWhichYearSemester(new Date(log.date))
      )
      semesters.forEach((semester) => {
        // recorremos el objeto logsPerBranches según la instalación actual
        const logsInSemester = logsCorporate['corporativo'].filter(
          (log) => tellMeWhichYearSemester(new Date(log.date)) === semester
        )
        const orderedSemesters = logsInSemester.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        )
        // el valor más reciente es el de interés
        const targetLog = orderedSemesters[orderedSemesters.length - 1]
        logsCorporateNotAc['corporativo'].push(targetLog)
      })
    }
    // ------- no acumulativos encontrando los últimos valores de registro para trimestres
    if (targetPeriod === 'trimestral') {
      // para cada división, encontrar a qué período pertenece cada log
      // aca
      for (let branch in logsPerBranches) {
        let trimesters = logsPerBranches[branch].map((log) =>
          tellMeWhichYearTrimester(log.date)
        )
        const uniqueTrimesters = trimesters.filter(
          (item, i, ar) => ar.indexOf(item) === i
        )
        uniqueTrimesters.forEach((trime) => {
          const logsInTrimester = logsPerBranches[branch].filter((log) => {
            return tellMeWhichYearTrimester(new Date(log.date)) === trime
          })
          const orderedLogs = logsInTrimester.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
          const targetLog = orderedLogs[orderedLogs.length - 1]
          if (logsPerBranchesNotAc.hasOwnProperty(branch)) {
            logsPerBranchesNotAc[branch].push(targetLog)
          } else {
            logsPerBranchesNotAc[branch] = []
            logsPerBranchesNotAc[branch].push(targetLog)
          }
        })
      }

      for (let line in logsPerLines) {
        let trimesters = logsPerLines[line].map((log) =>
          tellMeWhichYearTrimester(log.date)
        )
        const uniqueTrimesters = trimesters.filter(
          (item, i, ar) => ar.indexOf(item) === i
        )
        uniqueTrimesters.forEach((trimester) => {
          // recorremos el objeto logsPerBranches según la instalación actual
          const logsInTrimester = logsPerLines[line].filter(
            (log) => tellMeWhichYearTrimester(new Date(log.date)) === trimester
          )
          const orderedLogsInTrimester = logsInTrimester.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
          // el valor más reciente es el de interés
          const targetLog =
            orderedLogsInTrimester[orderedLogsInTrimester.length - 1]
          if (logsPerLinesNotAc.hasOwnProperty(line)) {
            logsPerLinesNotAc[line].push(targetLog)
          } else {
            logsPerLinesNotAc[line] = []
            logsPerLinesNotAc[line].push(targetLog)
          }
        })
      }
      // corporativos no acumulativos por trimestre
      let trimesters = logsCorporate['corporativo'].map((log) =>
        tellMeWhichYearTrimester(new Date(log.date))
      )
      trimesters.forEach((trimester) => {
        // recorremos el objeto logsPerBranches según la instalación actual
        const logsInTrimester = logsCorporate['corporativo'].filter(
          (log) => tellMeWhichYearTrimester(new Date(log.date)) === trimester
        )
        const orderedTrimesters = logsInTrimester.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        )
        // el valor más reciente es el de interés
        const targetLog = orderedTrimesters[orderedTrimesters.length - 1]
        logsCorporateNotAc['corporativo'].push(targetLog)
      })
    }
    // ------- no acumulativos encontrando los últimos valores de registro mensual

    if (targetPeriod === 'monthly') {
      for (let branch in logsPerBranches) {
        let yMonths = logsPerBranches[branch].map((log) =>
          giveMeYearAndMonth(log.date)
        )
        let uniqueYearsMonths = yMonths.filter(
          (item, i, ar) => ar.indexOf(item) === i
        )
        uniqueYearsMonths.forEach((YMPair) => {
          const logsInYearMonth = logsPerBranches[branch].filter((log) => {
            return giveMeYearAndMonth(new Date(log.date)) === YMPair
          })
          const orderedLogs = logsInYearMonth.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
          const targetLog = orderedLogs[orderedLogs.length - 1]
          if (logsPerBranchesNotAc.hasOwnProperty(branch)) {
            logsPerBranchesNotAc[branch].push(targetLog)
          } else {
            logsPerBranchesNotAc[branch] = []
            logsPerBranchesNotAc[branch].push(targetLog)
          }
        })
      }

      for (let line in logsPerLines) {
        let yMonths = logsPerLines[line].map((log) =>
          giveMeYearAndMonth(log.date)
        )
        let uniqueYearsMonths = yMonths.filter(
          (item, i, ar) => ar.indexOf(item) === i
        )
        uniqueYearsMonths.forEach((YMPair) => {
          const logsInYearMonth = logsPerLines[line].filter((log) => {
            return giveMeYearAndMonth(new Date(log.date)) === YMPair
          })
          const orderedLogs = logsInYearMonth.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
          const targetLog = orderedLogs[orderedLogs.length - 1]
          if (logsPerLinesNotAc.hasOwnProperty(branch)) {
            logsPerLinesNotAc[line].push(targetLog)
          } else {
            logsPerLinesNotAc[line] = []
            logsPerLinesNotAc[line].push(targetLog)
          }
        })
      }
      // corporativo mensual no acumulativo
      let yMonths = logsCorporate['corporativo'].map((log) =>
        giveMeYearAndMonth(new Date(log.date))
      )
      yMonths.forEach((YMPair) => {
        // recorremos el objeto logsPerBranches según la instalación actual
        const logsInYearMonth = logsCorporate['corporativo'].filter(
          (log) => giveMeYearAndMonth(new Date(log.date)) === YMPair
        )
        const orderedMonthYears = logsInYearMonth.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        )
        // el valor más reciente es el de interés
        const targetLog = orderedMonthYears[orderedMonthYears.length - 1]
        logsCorporateNotAc['corporativo'].push(targetLog)
      })
    }

    // ------- no acumulativos encontrando los últimos valores de registro semanal

    if (targetPeriod === 'weekly') {
      for (let branch in logsPerBranches) {
        let weeksIdentifiers = logsPerBranches[branch].map((log) =>
          whichNumberOfWeek(new Date(log.date))
        )

        weeksIdentifiers.forEach((week) => {
          const logsInYearWeek = logsPerBranches[branch].filter((log) => {
            return areInTheSameWeek(
              new Date(week.beginOfWeek),
              new Date(log.date)
            )
          })

          if (logsInYearWeek.length > 0) {
            const orderedLogs = logsInYearWeek.sort(
              (a, b) => new Date(a.date) - new Date(b.date)
            )
            const targetLog = orderedLogs[orderedLogs.length - 1]
            if (logsPerBranchesNotAc.hasOwnProperty(branch)) {
              logsPerBranchesNotAc[branch].push(targetLog)
            } else {
              logsPerBranchesNotAc[branch] = []
              logsPerBranchesNotAc[branch].push(targetLog)
            }
          }
        })
      }

      for (let line in logsPerLines) {
        let weeksIdentifiers = logsPerLines[line].map((log) =>
          whichNumberOfWeek(new Date(log.date))
        )
        weeksIdentifiers.forEach((week) => {
          const logsInYearWeek = logsPerLines[line].filter((log) => {
            return areInTheSameWeek(
              new Date(week.beginOfWeek),
              new Date(log.date)
            )
          })
          if (logsInYearWeek.length > 0) {
            const orderedLogs = logsInYearWeek.sort(
              (a, b) => new Date(a.date) - new Date(b.date)
            )
            const targetLog = orderedLogs[orderedLogs.length - 1]
            if (logsPerLinesNotAc.hasOwnProperty(line)) {
              logsPerLinesNotAc[line].push(targetLog)
            } else {
              logsPerLinesNotAc[line] = []
              logsPerLinesNotAc[line].push(targetLog)
            }
          }
        })
      }
      // corporativo semanal no acumulativo
      let weeksIdentifiers = logsCorporate['corporativo'].map((log) =>
        whichNumberOfWeek(new Date(log.date))
      )

      weeksIdentifiers.forEach((week) => {
        const logsInYearWeek = logsCorporate['corporativo'].filter((log) =>
          areInTheSameWeek(new Date(week.beginOfWeek), new Date(log.date))
        )
        if (logsInYearWeek.length > 0) {
          const orderedLogs = logsInYearWeek.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
          // el valor más reciente es el de interés
          const targetLog = orderedLogs[orderedLogs.length - 1]
          logsCorporateNotAc['corporativo'].push(targetLog)
        }
      })
    }

    if (targetPeriod === 'daily') {
      for (let branch in logsPerBranches) {
        let datesPresent = logsPerBranches[branch].map(
          (log) => new Date(log.date)
        )

        datesPresent.forEach((day) => {
          const logsInDate = logsPerBranches[branch].filter((log) =>
            isSameDay(new Date(log.date), day)
          )
          if (logsInDate.length > 0) {
            const orderedLogs = logsInDate.sort(
              (a, b) => new Date(a.date) - new Date(b.date)
            )
            const targetLog = orderedLogs[orderedLogs.length - 1]
            if (logsPerBranchesNotAc.hasOwnProperty(branch)) {
              logsPerBranchesNotAc[branch].push(targetLog)
            } else {
              logsPerBranchesNotAc[branch] = []
              logsPerBranchesNotAc[branch].push(targetLog)
            }
          }
        })
      }

      for (let line in logsPerLines) {
        let datesPresent = logsPerLines[line].map((log) => new Date(log.date))

        datesPresent.forEach((day) => {
          const logsInDate = logsPerLines[line].filter((log) =>
            isSameDay(new Date(log.date), day)
          )
          if (logsInDate.length > 0) {
            const orderedLogs = logsInDate.sort(
              (a, b) => new Date(a.date) - new Date(b.date)
            )
            const targetLog = orderedLogs[orderedLogs.length - 1]
            if (logsPerLinesNotAc.hasOwnProperty(branch)) {
              logsPerBranchesNotAc[line].push(targetLog)
            } else {
              logsPerLinesNotAc[line] = []
              logsPerLinesNotAc[line].push(targetLog)
            }
          }
        })
      }
      // corporativo diario no acumulativo

      const logsInDay = logsCorporate['corporativo'].filter((log) =>
        isSameDay(new Date(log.date), day)
      )
      if (logsInDay.length > 0) {
        const orderedLogs = logsInDay.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        )
        // el valor más reciente es el de interés
        const targetLog = orderedLogs[orderedLogs.length - 1]
        logsCorporateNotAc['corporativo'].push(targetLog)
      }
    }
    // limpiamos los objetos relativos a la clasificación inicial que correspondería a indicadores acumulativos
    // en el próximo call del stack se chequea cuál key-value pair se toma para continuar los cálculos
    logsPerLines = {}
    logsPerBranches = {}
    logsCorporate = {}
  }

  return {
    logsCorporate,
    logsCorporateNotAc,
    logsPerLines,
    logsPerLinesNotAc,
    logsPerBranches,
    logsPerBranchesNotAc
  }
}

//////////////////// clasificación por alcance

export const generateBranchesDS = (
  classifiedLogs,
  orgBranches,
  whichIndicator
) => {
  // la única opción para generar alcance por instalaciones es que ambos indicadores tengan alcance por instalaciones
  // esto es filtrado desde el componente CompoundIndicatorConfig, función compatibleReaches
  const branchesLogs = {}
  const targetBranchClassifiedLogs =
    Object.keys(classifiedLogs.logsPerBranches).length > 0
      ? classifiedLogs.logsPerBranches
      : classifiedLogs.logsPerBranchesNotAc
  ///////////////////////////////
  for (let branchId in targetBranchClassifiedLogs) {
    const targetBranch = orgBranches.find(
      (branch) => branch.id === parseInt(branchId)
    )
    if (targetBranch) {
      branchesLogs[`${targetBranch.name}`] =
        targetBranchClassifiedLogs[branchId]
    }
  }
  return branchesLogs
}

export const generateLinesDS = (classifiedLogs, orgLines) => {
  // existen logs por LDN? trabajar con esa data
  // asignar a las LDN los registros de instalaciones que pertenezcan a esa LDN
  const linesLogs = {}
  const targetLineClassifiedLogs =
    Object.keys(classifiedLogs.logsPerLines).length > 0
      ? classifiedLogs.logsPerLines
      : classifiedLogs.logsPerLinesNotAc
  const targetBranchClassifiedLogs =
    Object.keys(classifiedLogs.logsPerBranches).length > 0
      ? classifiedLogs.logsPerBranches
      : classifiedLogs.logsPerBranchesNotAc

  const sintheticLines = orgLines.map((line) => {
    return { id: line.id, name: line.name, branches: line.branches }
  })

  // En caso de que no existan logs por LDN => entonces trabajar con la data de instalaciones y reclasificar por LDN
  if (Object.keys(targetLineClassifiedLogs).length === 0) {
    for (let branchIndex in targetBranchClassifiedLogs) {
      for (let line in sintheticLines) {
        const found = sintheticLines[line].branches.find(
          (ele) => ele.id === parseInt(branchIndex)
        )
        if (found) {
          if (linesLogs.hasOwnProperty(sintheticLines[line].name)) {
            linesLogs[sintheticLines[line].name] = [
              ...linesLogs[sintheticLines[line].name],
              ...targetBranchClassifiedLogs[branchIndex]
            ]
          } else {
            linesLogs[sintheticLines[line].name] =
              targetBranchClassifiedLogs[branchIndex]
          }
        }
      }
    }
  } else {
    for (let lineIndex in targetLineClassifiedLogs) {
      let foundIt = sintheticLines.find(
        (line) => parseInt(lineIndex) === line.id
      )
      if (foundIt) {
        if (linesLogs.hasOwnProperty(foundIt.name)) {
          linesLogs[foundIt.name] = [
            ...linesLogs[foundIt.name],
            ...targetLineClassifiedLogs[lineIndex]
          ]
        } else {
          linesLogs[foundIt.name] = targetLineClassifiedLogs[lineIndex]
        }
      }
    }
  }
  return linesLogs
}

export const generateCorporateDS = (classifiedLogs) => {
  // agrupar todos los logs en un solo objeto de la forma {corporativo:[{date, value}, {date, value}...]}
  let output = { corporativo: [] }
  for (let classif in classifiedLogs) {
    for (let divisionId in classifiedLogs[classif]) {
      classifiedLogs[classif][divisionId].forEach((log) =>
        output['corporativo'].push(log)
      )
    }
  }
  return output
}

// Clasificación por períodos
/**
 *
 * @param {*} data dataset
 * @param {*} whichIndicator primer o segundo indicador en la relación
 * @param {*} indicator entidad de indicador con todas sus propiedades
 * @param {*} vsData dataset del otro indicador en la relación
 * @returns
 */
export const classifyByYear = (
  data,
  whichIndicator,
  indicator,
  vsData,
  mustHomologate
) => {
  // se consideran las fechas de todos los registros para homogeneizar la comparación
  const dates = []
  for (let division in data) {
    data[division].forEach((entry) => dates.push(entry.date))
  }
  for (let division in vsData) {
    vsData[division].forEach((entry) => dates.push(entry.date))
  }
  const yearsPresent = dates.map((date) => {
    return date.split('T')[0].split('-')[0]
  })
  const uniqueYears = yearsPresent.filter(
    (item, i, ar) => ar.indexOf(item) === i
  )

  const divisionsAndPeriods = {}
  // asignamos tantas propiedades como divisiones existan en la data
  for (let division of Object.keys(data)) {
    divisionsAndPeriods[`${division}`] = {}
  }
  // asignamos tantos años como existan en cada division
  for (let year of uniqueYears) {
    for (let division in divisionsAndPeriods) {
      divisionsAndPeriods[division][`${year}`] = []
    }
  }

  // asignamos los valores de cada division en el array correspondiente a división/período
  for (let division in data) {
    for (let year in uniqueYears) {
      for (let log in data[division]) {
        if (
          new Date(data[division][log].date).getFullYear() ===
          parseInt(uniqueYears[year])
        ) {
          divisionsAndPeriods[`${division}`][uniqueYears[year]].push(
            data[division][log].value
          )
        }
      }
    }
  }

  for (let division in divisionsAndPeriods) {
    Object.keys(divisionsAndPeriods[division]).forEach((year) => {
      divisionsAndPeriods[division][year] = divisionsAndPeriods[division][
        year
      ].reduce((acc, curr) => curr + acc, 0)
    })
  }
  // Preparamos el dataset
  let output = {
    datasets: [],
    labels: uniqueYears
  }
  Object.keys(divisionsAndPeriods).forEach((division, index) => {
    const datasetElement = {}
    datasetElement.label = `${indicator.name}/${division}`
    datasetElement.data = []
    datasetElement.type = 'bar'
    datasetElement.backgroundColor =
      whichIndicator === 'indiOne' ? indiOneColor : indiTwoColor
    datasetElement.borderColor =
      whichIndicator === 'indiOne' ? indiOneColor : indiTwoColor
    datasetElement.yAxisID =
      whichIndicator === 'indiOne' ? 'y' : mustHomologate ? 'y' : 'y1'
    for (let year in divisionsAndPeriods[division]) {
      const itemInData = {}
      itemInData.x = new Date(year, 0)
      itemInData.y = divisionsAndPeriods[division][year]
      datasetElement.datalabels = datalabelsObj(division, itemInData)
      datasetElement.data.push(itemInData)
    }
    output.datasets.push(datasetElement)
  })
  return output
}

export const classifyBySemester = (
  data,
  whichIndicator,
  indicator,
  vsData,
  mustHomologate
) => {
  const dates = []
  for (let division in data) {
    data[division].forEach((entry) => dates.push(entry.date))
  }
  for (let division in vsData) {
    vsData[division].forEach((entry) => dates.push(entry.date))
  }
  const semestersPresent = dates.map((date) => {
    return tellMeWhichYearSemester(new Date(date))
  })
  let uniqueSemesters = semestersPresent.filter(
    (item, i, ar) => ar.indexOf(item) === i
  )
  uniqueSemesters = uniqueSemesters.reverse()
  const divisionsAndPeriods = {}
  for (let division of Object.keys(data)) {
    divisionsAndPeriods[`${division}`] = {}
  }
  for (let semester of uniqueSemesters) {
    for (let division in divisionsAndPeriods) {
      divisionsAndPeriods[division][`${semester}`] = []
    }
  }

  // asignamos los valores de cada division en el array correspondiente a división/período
  for (let division in data) {
    for (let semester in uniqueSemesters) {
      for (let log in data[division]) {
        if (
          tellMeWhichYearSemester(new Date(data[division][log].date)) ===
          uniqueSemesters[semester]
        ) {
          divisionsAndPeriods[`${division}`][uniqueSemesters[semester]].push(
            data[division][log].value
          )
        }
      }
    }
  }

  for (let division in divisionsAndPeriods) {
    Object.keys(divisionsAndPeriods[division]).forEach((semester) => {
      divisionsAndPeriods[division][semester] = divisionsAndPeriods[division][
        semester
      ].reduce((acc, curr) => curr + acc, 0)
    })
  }
  // Preparamos el dataset
  let output = {
    datasets: [],
    labels: uniqueSemesters
  }
  Object.keys(divisionsAndPeriods).forEach((division, index) => {
    const datasetElement = {}
    datasetElement.label = `${indicator.name}/${division}`
    datasetElement.data = []
    datasetElement.type = 'bar'
    datasetElement.backgroundColor =
      whichIndicator === 'indiOne' ? indiOneColor : indiTwoColor
    datasetElement.borderColor =
      whichIndicator === 'indiOne' ? indiOneColor : indiTwoColor
    datasetElement.yAxisID =
      whichIndicator === 'indiOne' ? 'y' : mustHomologate ? 'y' : 'y1'
    for (let semester in divisionsAndPeriods[division]) {
      const itemInData = divisionsAndPeriods[division][semester]
      datasetElement.datalabels = datalabelsObj(division, itemInData)
      datasetElement.data.push(itemInData)
    }
    output.datasets.push(datasetElement)
  })
  return output
}

export const classifyByTrimester = (
  data,
  whichIndicator,
  indicator,
  vsData,
  mustHomologate
) => {
  const dates = []
  for (let division in data) {
    data[division].forEach((entry) => dates.push(entry.date))
  }
  for (let division in vsData) {
    vsData[division].forEach((entry) => dates.push(entry.date))
  }
  const trimestersPresent = dates.map((date) => {
    return tellMeWhichYearTrimester(new Date(date))
  })
  let uniqueTrimesters = trimestersPresent.filter(
    (item, i, ar) => ar.indexOf(item) === i
  )
  uniqueTrimesters = uniqueTrimesters.reverse()

  const divisionsAndPeriods = {}
  for (let division of Object.keys(data)) {
    divisionsAndPeriods[`${division}`] = {}
  }
  for (let trimester of uniqueTrimesters) {
    for (let division in divisionsAndPeriods) {
      divisionsAndPeriods[division][`${trimester}`] = []
    }
  }
  // asignamos los valores de cada division en el array correspondiente a división/período
  for (let division in data) {
    for (let trimester in uniqueTrimesters) {
      for (let log in data[division]) {
        if (
          tellMeWhichYearTrimester(new Date(data[division][log].date)) ===
          uniqueTrimesters[trimester]
        ) {
          divisionsAndPeriods[`${division}`][uniqueTrimesters[trimester]].push(
            data[division][log].value
          )
        }
      }
    }
  }

  for (let division in divisionsAndPeriods) {
    Object.keys(divisionsAndPeriods[division]).forEach((semester) => {
      divisionsAndPeriods[division][semester] = divisionsAndPeriods[division][
        semester
      ].reduce((acc, curr) => curr + acc, 0)
    })
  }
  // Preparamos el dataset
  let output = {
    datasets: [],
    labels: uniqueTrimesters
  }

  Object.keys(divisionsAndPeriods).forEach((division, index) => {
    const datasetElement = {}
    datasetElement.label = `${indicator.name}/${division}`
    datasetElement.data = []
    datasetElement.type = 'bar'
    datasetElement.backgroundColor =
      whichIndicator === 'indiOne' ? indiOneColor : indiTwoColor
    datasetElement.borderColor =
      whichIndicator === 'indiOne' ? indiOneColor : indiTwoColor
    datasetElement.yAxisID =
      whichIndicator === 'indiOne' ? 'y' : mustHomologate ? 'y' : 'y1'
    for (let trimester in divisionsAndPeriods[division]) {
      const itemInData = divisionsAndPeriods[division][trimester]
      datasetElement.datalabels = datalabelsObj(division, itemInData)
      datasetElement.data.push(itemInData)
    }
    output.datasets.push(datasetElement)
  })
  return output
}

export const classifyByMonth = (
  data,
  whichIndicator,
  indicator,
  vsData,
  mustHomologate
) => {
  const dates = []
  for (let division in data) {
    data[division].forEach((entry) => dates.push(entry.date))
  }
  for (let division in vsData) {
    vsData[division].forEach((entry) => dates.push(entry.date))
  }
  const yMonthsPresent = dates.map((date) => {
    return giveMeYearAndMonth(new Date(date))
  })

  let uniqueYMPairs = yMonthsPresent.filter(
    (item, i, ar) => ar.indexOf(item) === i
  )
  uniqueYMPairs = uniqueYMPairs.reverse()

  const divisionsAndPeriods = {}
  for (let division of Object.keys(data)) {
    divisionsAndPeriods[`${division}`] = {}
  }
  for (let ymPair of uniqueYMPairs) {
    for (let division in divisionsAndPeriods) {
      divisionsAndPeriods[division][`${ymPair}`] = []
    }
  }
  // asignamos los valores de cada division en el array correspondiente a división/período
  for (let division in data) {
    uniqueYMPairs.forEach((ymPair) => {
      for (let log in data[division]) {
        if (giveMeYearAndMonth(new Date(data[division][log].date)) === ymPair) {
          divisionsAndPeriods[`${division}`][ymPair].push(
            data[division][log].value
          )
        }
      }
    })
  }

  for (let division in divisionsAndPeriods) {
    Object.keys(divisionsAndPeriods[division]).forEach((yMPair) => {
      divisionsAndPeriods[division][yMPair] = divisionsAndPeriods[division][
        yMPair
      ].reduce((acc, curr) => curr + acc, 0)
    })
  }
  // Preparamos el dataset
  let output = {
    datasets: [],
    labels: uniqueYMPairs.map((ymPair) => humanizeMonth(ymPair))
  }

  Object.keys(divisionsAndPeriods).forEach((division, index) => {
    const datasetElement = {}
    datasetElement.label = `${indicator.name}/${division}`
    datasetElement.data = []
    datasetElement.type = 'bar'
    datasetElement.backgroundColor =
      whichIndicator === 'indiOne' ? indiOneColor : indiTwoColor
    datasetElement.borderColor =
      whichIndicator === 'indiOne' ? indiOneColor : indiTwoColor
    datasetElement.yAxisID =
      whichIndicator === 'indiOne' ? 'y' : mustHomologate ? 'y' : 'y1'
    for (let ymPair in divisionsAndPeriods[division]) {
      const itemInData = divisionsAndPeriods[division][ymPair]
      datasetElement.data.push(itemInData)
      datasetElement.datalabels = datalabelsObj(division, itemInData)
    }
    output.datasets.push(datasetElement)
  })
  return output
}

export const classifyByWeek = (
  data,
  whichIndicator,
  indicator,
  vsData,
  mustHomologate
) => {
  const dates = []
  for (let division in data) {
    data[division].forEach((entry) => dates.push(entry.date))
  }
  for (let division in vsData) {
    vsData[division].forEach((entry) => dates.push(entry.date))
  }
  // dado que puede existir registro para una misma fecha pero diferentes hh:mm:ss
  // e interesa los registros por fecha para clasificar por semana
  // se procesan las fechas de la siguiente manera
  const datesWithoutTime = dates.map((ele) => {
    const wt = ele.split('T')[0]
    const splitted = {
      year: wt.split('-')[0],
      month: parseInt(wt.split('-')[1]) - 1,
      day: wt.split('-')[2]
    }
    const rt = new Date(splitted.year, splitted.month, splitted.day, 0)
    return rt.toISOString()
  })
  const uniqueDatesPresent = datesWithoutTime
    .filter((item, i, ar) => ar.indexOf(item) === i)
    .sort((a, b) => new Date(a) - new Date(b))
  // a qué semana y año corresponde cada fecha
  const weeksIdentifiers = uniqueDatesPresent.map((date) =>
    whichNumberOfWeek(new Date(date))
  )

  // clasificación de divisiones y períodos
  const divisionsAndPeriods = {}
  for (let division of Object.keys(data)) {
    divisionsAndPeriods[`${division}`] = {}
  }
  for (let week of weeksIdentifiers) {
    for (let division in divisionsAndPeriods) {
      divisionsAndPeriods[division][
        `${week.targetYear}-${week.weekNumber}-${week.beginOfWeek}`
      ] = []
    }
  }

  // asignamos los valores de cada division en el array correspondiente a división/período
  for (let division in data) {
    for (let week in weeksIdentifiers) {
      for (let log in data[division]) {
        if (
          areInTheSameWeek(
            weeksIdentifiers[week].beginOfWeek,
            new Date(data[division][log].date)
          )
        ) {
          divisionsAndPeriods[`${division}`][
            `${weeksIdentifiers[week].targetYear}-${weeksIdentifiers[week].weekNumber}-${weeksIdentifiers[week].beginOfWeek}`
          ].push(data[division][log].value)
        }
      }
    }
  }

  for (let division in divisionsAndPeriods) {
    Object.keys(divisionsAndPeriods[division]).forEach((week) => {
      divisionsAndPeriods[division][week] = divisionsAndPeriods[division][
        week
      ].reduce((acc, curr) => curr + acc, 0)
    })
  }

  // Preparamos el dataset
  let output = {
    datasets: [],
    labels: weeksIdentifiers
      .map((ele) => ele.beginOfWeek)
      .map((d) => `Sem ${format(d, 'dd/MM/yyyy', { locale: es })}`)
  }

  Object.keys(divisionsAndPeriods).forEach((division, index) => {
    const datasetElement = {}
    datasetElement.label = `${indicator.name}/${division}`
    datasetElement.data = []
    datasetElement.type = 'bar'
    datasetElement.backgroundColor =
      whichIndicator === 'indiOne' ? indiOneColor : indiTwoColor
    datasetElement.borderColor =
      whichIndicator === 'indiOne' ? indiOneColor : indiTwoColor
    datasetElement.yAxisID =
      whichIndicator === 'indiOne' ? 'y' : mustHomologate ? 'y' : 'y1'
    for (let date in divisionsAndPeriods[division]) {
      const itemInData = divisionsAndPeriods[division][date]
      datasetElement.data.push(itemInData)
      datasetElement.datalabels = datalabelsObj(division, itemInData)
    }
    output.datasets.push(datasetElement)
  })
  return output
}

export const classifyByDay = (
  data,
  whichIndicator,
  indicator,
  vsData,
  mustHomologate
) => {
  const dates = []
  for (let division in data) {
    data[division].forEach((entry) => dates.push(entry.date))
  }
  for (let division in vsData) {
    vsData[division].forEach((entry) => dates.push(entry.date))
  }

  const datesWithoutTime = dates.map((ele) => {
    const wt = ele.split('T')[0]
    const splitted = {
      year: wt.split('-')[0],
      month: parseInt(wt.split('-')[1]) - 1,
      day: wt.split('-')[2]
    }
    const rt = new Date(splitted.year, splitted.month, splitted.day, 0)
    return rt.toISOString()
  })
  const uniqueDatesPresent = datesWithoutTime
    .filter((item, i, ar) => ar.indexOf(item) === i)
    .sort((a, b) => new Date(a) - new Date(b))
  // clasificación de divisiones y períodos
  const divisionsAndPeriods = {}
  for (let division of Object.keys(data)) {
    divisionsAndPeriods[`${division}`] = {}
  }
  uniqueDatesPresent.forEach((date) => {
    for (let division in divisionsAndPeriods) {
      divisionsAndPeriods[division][date] = []
    }
  })

  // asignamos los valores de cada division en el array correspondiente a división/período
  for (let division in data) {
    uniqueDatesPresent.forEach((date) => {
      for (let log in data[division]) {
        if (isSameDay(new Date(date), new Date(data[division][log].date))) {
          divisionsAndPeriods[division][date].push(data[division][log].value)
        }
      }
    })
  }

  for (let division in divisionsAndPeriods) {
    Object.keys(divisionsAndPeriods[division]).forEach((day) => {
      divisionsAndPeriods[division][day] = divisionsAndPeriods[division][
        day
      ].reduce((acc, curr) => curr + acc, 0)
    })
  }
  let output = {
    datasets: [],
    labels: uniqueDatesPresent.map((d) =>
      format(new Date(d), 'dd/MM/yyyy', { locale: es })
    )
  }
  Object.keys(divisionsAndPeriods).forEach((division, index) => {
    const datasetElement = {}
    datasetElement.label = `${indicator.name}/${division}`
    datasetElement.data = []
    datasetElement.type = 'bar'
    datasetElement.backgroundColor =
      whichIndicator === 'indiOne' ? indiOneColor : indiTwoColor
    datasetElement.borderColor =
      whichIndicator === 'indiOne' ? indiOneColor : indiTwoColor
    datasetElement.yAxisID =
      whichIndicator === 'indiOne' ? 'y' : mustHomologate ? 'y' : 'y1'
    for (let date in divisionsAndPeriods[division]) {
      const itemInData = divisionsAndPeriods[division][date]
      datasetElement.data.push(itemInData)
      datasetElement.datalabels = datalabelsObj(division, itemInData)
    }
    output.datasets.push(datasetElement)
  })
  return output
}

// Creación de la línea gráfica para la relación indicador 1 / indicador 2

export const generateRelationLines = (divisionsArr, divisionsDataArr) => {
  const referenceGraphLinesArrs = []
  divisionsArr.forEach((division, index) => {
    referenceGraphLinesArrs.push({
      xAxisID: 'x',
      yAxisID: 'y2',
      type: 'line',
      borderColor: corporatePallete[index],
      label: `Relación a:b en ${division}`,
      data: [],
      datalabels: {
        color: '#fff',
        align: (2 + index + 1) % 2 === 0 ? 'top' : 'bottom',
        anchor: 'top',
        backgroundColor: corporatePallete[index],
        borderRadius: 3,
        padding: 5,
        marginBottom: 3,
        font: {
          weight: 'bold',
          size: 12
        },
        formatter: function (value, context) {
          return value.y.toFixed(2)
        }
      }
    })
  })
  const referenceLinesWithData = []
  for (let division in divisionsDataArr) {
    let found = referenceGraphLinesArrs.find((gLine) =>
      gLine.label.includes(division)
    )
    const lineWithData = { ...found, data: divisionsDataArr[division] }
    referenceLinesWithData.push(lineWithData)
  }
  return referenceLinesWithData
}

// referencia del output para este tipo de gráficos
// const reference = {
//   data: {
//     datasets: [
//       {
//         label: 'Relación a:b',
//         data: [
//           {
//             x: new Date(2019, 0),
//             y: 0
//           },
//           {
//             x: new Date(2020, 0),
//             y: 223
//           },
//           {
//             x: new Date(2021, 0),
//             y: 334
//           },
//           {
//             x: new Date(2022, 0),
//             y: 345
//           }
//         ],
//         type: 'line',
//         borderColor: '#FC7A1E'
//       },
//       {
//         label: 'Línea 1',
//         data: [
//           { x: new Date(2019, 0), y: 334 },
//           { x: new Date(2020, 0), y: 334 },
//           { x: new Date(2021, 0), y: 334 },
//           { x: new Date(2022, 0), y: 334 }
//         ],
//         type: 'bar',
//         backgroundColor: 'rgba(0, 187, 255, 0.55)',
//         borderColor: '#00BBFF'
//       },
//       {
//         label: 'Línea 2',
//         data: [
//           { x: new Date(2019, 0), y: 320 },
//           { x: new Date(2020, 0), y: 233 },
//           { x: new Date(2021, 0), y: 176 },
//           { x: new Date(2022, 0), y: 233 }
//         ],
//         type: 'bar',
//         backgroundColor: 'rgba(0, 187, 33, 0.55)',
//         borderColor: '#00BB55'
//       }
//     ],
//     labels: [
//       '2021-06-01T04:00:00.000Z',
//       '2021-07-01T04:00:00.000Z',
//       '2021-08-01T04:00:00.000Z',
//       '2021-09-01T04:00:00.000Z'
//     ]
//   },
//   options: {
//     spanGaps: true,
//     maintainAspectRatio: false,
//     scales: {
//       x: {
//         type: 'time',
//         time: {
//           unit: 'year'
//         },
//         adapters: {
//           date: {
//             locale: {
//               code: 'es',
//               formatLong: {},
//               localize: {},
//               match: {},
//               options: {
//                 weekStartsOn: 1,
//                 firstWeekContainsDate: 1
//               }
//             }
//           }
//         }
//       },
//       y: {
//         title: {
//           display: true,
//           text: 're'
//         }
//       }
//     },
//     responsive: true,
//     plugins: {
//       legend: {
//         position: 'bottom',
//         align: 'center',
//         fullWidth: true
//       },
//       tooltip: {
//         callbacks: {}
//       },
//       zoom: {
//         zoom: {
//           wheel: {
//             enabled: true
//           },
//           pinch: {
//             enabled: true
//           },
//           mode: 'xy'
//         },
//         pan: {
//           enabled: true
//         }
//       }
//     }
//   }
// }
