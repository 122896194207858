import React, { useState, useEffect } from 'react'
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  Input,
  Checkbox,
  MenuItem,
  List,
  ListItemIcon,
  Avatar,
  ListItemAvatar,
  ListItemText,
  ListItem
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
// import { indicatorActions } from '../../state/actions'
import { indicatorConstants } from '../../state/types'
import { Business as BusinessIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 700,
    backgroundColor: theme.palette.background.paper
  },
  formControl: { width: '100%' },
  chips: { display: 'flex', flexWrap: 'wrap' },
  chip: { margin: 2 },
  branch: {},
  list: { width: '100%' }
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const BusinessForm = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  // Redux states
  const indicator = useSelector(
    (state) => state.indicators.indicatorInProgress.data
  )
  const { indicators } = useSelector((state) => state.indicators)
  const { lines } = useSelector((state) => state.linesOfBsns)

  // Local states

  const [submitLinesLoading, setSubmitLinesLoading] = useState(false)
  const [linesIds, setLinesIds] = useState(
    indicator.linesIds ? indicator.linesIds : []
  )
  const [checked, setChecked] = useState(
    lines.filter((line) => linesIds.find((idTemp) => idTemp === line.id))
  )
  const [checkedIds, setCheckedIds] = useState([])
  useEffect(() => {
    setCheckedIds(checked.map((ele) => ele.id))
    setLinesIds(checkedIds)
  }, [checked])

  // toggle list handler
  const handleToggle = (line) => () => {
    const currentIndex = checked.indexOf(line)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(line)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  // submission handler
  const submitForm = (event) => {
    event.preventDefault()
    const submit = async () => {
      setSubmitLinesLoading(true)
      // setError(null)
      dispatch({
        type: indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.SET,
        payload: {
          tabValue: 4,
          linesIds: checkedIds
        }
      })
      setSubmitLinesLoading(false)
    }
    submit()
  }

  return (
    <Grid container direction='column' align='center' justifyContent='center'>
      <Grid item xs>
        <List className={classes.root}>
          {lines.map((line, idx) => {
            const labelId = `checkbox-list-label-${line.name}`
            return (
              <ListItem
                key={line.name}
                role={undefined}
                dense
                button
                onClick={handleToggle(line)}
              >
                <ListItemText
                  id={labelId}
                  primary={line.name}
                  // secondary={`Linea de negocios: ${findMyLine(branch.id)}`}
                />
                <ListItemIcon>
                  <Checkbox
                    edge='start'
                    checked={checked.indexOf(line) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                {/* <ListItemSecondaryAction>
                  <IconButton edge='end' aria-label='comments'>
                    <CommentIcon />
                  </IconButton>
                </ListItemSecondaryAction> */}
              </ListItem>
            )
          })}
        </List>
      </Grid>
      <Grid item>
        <Button
          fullWidth
          variant='contained'
          color='primary'
          onClick={submitForm}
          disabled={submitLinesLoading}
        >
          {submitLinesLoading ? 'Siguiente' : 'Siguiente'}
        </Button>
      </Grid>
    </Grid>
  )
}

export default BusinessForm
