import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import TeroLogo from '../../assets/img/terocolor.png'
import { Typography, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {},
  img: {
    width: theme.spacing(30),
    marginTop: theme.spacing(4)
  }
}))

/**
 * Componente que despliega un mensaje indicando que no hay elementos para mostrar
 * @param {text} message - Mensaje a mostrar
 * @param {text} title - Título del mensaje a mostrar
 */

const NoContent = ({ title, message, clickHere }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        direction='column'
        spacing={2}
      >
        <Grid item>
          <img src={TeroLogo} alt='Tero logo' className={classes.img} />
        </Grid>
        {title && (
          <Grid item>
            <Typography variant='boy1'>{title}</Typography>
          </Grid>
        )}
        {message && (
          <Grid item>
            <Typography variant='body2'>
              {message} {clickHere}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

NoContent.defaultProps = {
  title: null,
  message: null,
  clickHere: null
}

NoContent.propTypes = {
  /**
   * {String} - Mensaje a mostrar
   */
  message: PropTypes.string,
  /**
   * {String} - Título del mensaje a mostrar
   */
  title: PropTypes.string,
  /**
   * {element} - Texto y acción a ejecutar en 'click aquí'
   */
  clickHere: PropTypes.element
}

export default NoContent
