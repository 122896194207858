import React, { useEffect, useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import NoContent from '../components/UI/NoContent'
import { Typography, Fab, Container,Button,Grid } from '@material-ui/core'
import Progress from '../components/UI/Progress'
import Can from '../auth/Can'
import ShowReports from '../components/Reports/ShowReports'
import Dialog from '../components/UI/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { reportActions, indicatorActions } from '../state/actions'
import { reportConstants } from '../state/types'
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import FormField from '../components/UI/FormFields'
import TextField from "@material-ui/core/TextField";
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {
  validForm,
  updateFormData,
  generateReduxData
} from '../helpers/form.helper'
import ButtonWithLoading from '../components/UI/ButtonWithLoading'

const Paso3 = () => {

  const dispatch = useDispatch();
    // const report = JSON.parse(sessionStorage.getItem('reportPaso3'))
    const { user } = useSelector((state) => state.user)
    // console.log(user)
    const report = JSON.parse(sessionStorage.getItem('report'))
    // const reportData = JSON.parse(sessionStorage.getItem('report'))

    const arrAlcance = [{value:"NULL", nombre:"Todas"},
                        {value:"AGGREGATE", nombre:"Por instalación"},
                        {value:"BSNS", nombre:"Líneas de negocio"},
                        {value:"GLOBAL", nombre:"Nivel Corporativo"}]

    const arrPeriodo = [{value:"yearly", nombre:"Anual"},
                        {value:"semestral", nombre:"Semestral"},
                        {value:"trimestral", nombre:"Trimestral"},
                        {value:"monthly", nombre:"Mensual"},
                        {value:"daily", nombre:"Diario"}]

    const clearOption = { label: 'Limpiar Filtro', value: '' }

    useEffect(() => {
        document.title = 'Reportes - Paso 2'
        console.log(report);

        // updateData('managerId', 'options', [
        //   clearOption,
        //   ...arrAlcance.map((employee) => ({
        //     ...employee,
        //     label: employee.nombre,
        //     value: employee.value
        //   }))
        // ])

        // updateData('managerId1', 'options', [
        //   clearOption,
        //   ...arrPeriodo.map((employee) => ({
        //     ...employee,
        //     label: employee.nombre,
        //     value: employee.value
        //   }))
        // ])   
        
        // console.log()
      }, [])

      const handleChangeDate = (field) => (date) => {
        console.log(new Date(date.toDate()).toLocaleString("es-CL").split(" "));
        setValues(updateFormData(formData, field, date))
      }

      const handleChangeDate1 = (event) => {
        console.log(event.target.value);
        // console.log(new Date(date.toDate()).toLocaleString("es-CL").split(" "));
        // setValues(updateFormData(formData, field, date))
      }

      const updateData = (field, prop, value) => {
        console.log(field);
        console.log(value);
        setValues((prev) => ({
          ...prev,
          [field]: {
            ...prev[field],
            [prop]: value
          }
        }))
      }
    
      const updateForm = (event) => {
        console.log(event.target.name);
        console.log(event.target.value);
        event.preventDefault()
        setValues(updateFormData(formData, event.target.name, event.target.value))

        // analisis: null
        // descripcion: null
        // fechaIni: null
        // fechaTermi: null
        // nombre: null

        if(event.target.name == 'name'){
          report.name = event.target.value;
          sessionStorage.setItem('report', JSON.stringify(report))
        } else if(event.target.name == 'dateInicio'){
          report.dateStart = event.target.value;
          sessionStorage.setItem('report', JSON.stringify(report))
        } else if(event.target.name == 'dateTermino'){
          report.dateFinish = event.target.value;
          sessionStorage.setItem('report', JSON.stringify(report))
        } else if(event.target.name == 'palabras'){
          report.description = event.target.value;
          sessionStorage.setItem('report', JSON.stringify(report))
        } else if(event.target.name == 'managerId'){
          report.alcance = event.target.value;
          sessionStorage.setItem('report', JSON.stringify(report))
        } else if(event.target.name == 'managerId1'){
          report.frecuency = event.target.value;
          sessionStorage.setItem('report', JSON.stringify(report))
        } else {
          console.log("no name");
        }

      }

      const test = () => {
        // event.preventDefault()
        // setValues(updateFormData(formData, event.target.name, event.target.value))

        // console.log("test");
        // console.log(formData.name.value);
        // console.log(formData.analisis.value);
        // console.log(formData.dateInicio.value);
        // console.log(formData.dateTermino.value);
        // console.log(formData.palabras.value);

        const dataToSubmit = {
          report : {
            name : formData.name.value,
            description : formData.palabras.value,
            dateStart : formData.dateInicio.value,
            dateFinish : formData.dateTermino.value,
            companyId : report.companyId,
            materialityId : 127,
            userId : user.id,
            esTemplate: 0
            // frecuency: formData.managerId1.value,
            // alcance: formData.managerId.value
          }
        }
        console.log(dataToSubmit)
        dispatch(indicatorActions.updateReportPaso3(dataToSubmit, report.id))

      }

      const sel = [
        { label: '1', value: '1' },
        { label: '2', value: '2' }
      ]
    
      const [formData, setValues] = useState({
        name: {
          element: 'input',
          value: report.name == 'name' ? '' : report.name,
          config: {
            id: 'name',
            name: 'name',
            label: 'Nombre',
            type: 'text',
            placeholder: 'Nombre',
            variant: 'outlined',
            margin: 'normal',
            fullWidth: true
          },
          validation: {
            required: true
          },
        },
        analisis: {
            element: 'select',
            value: '',
            options: sel,
            config: {
                id: 'analisis',
                name: 'analisis',
                label: 'Análisis de materialidad',
                placeholder: 'Análisis de materialidad',
                variant: 'outlined',
                margin: 'normal',
                fullWidth: true,
                select: true
            },
            validation: {
              required: true
            },
          },
          dateInicio: {
            // value: '' ? '' : moment(),
            value: report.dateStart == null ? '2021-01-31' : report.dateStart,
            validation: {
              required: true
            },
            },
        
        dateTermino: {
          // value: '' ? '' : moment().add(1, 'days'),
          value: report.dateFinish == null ? '2021-02-01' : report.dateFinish,
          validation: {
            required: true
          },
        },
        palabras: {
          element: 'input',
          value: report.description == 'description' ? '' : report.description,
          config: {
            id: 'palabras',
            name: 'palabras',
            label: 'Descripción',
            type: 'text',
            placeholder: 'Descripción',
            variant: 'outlined',
            margin: 'normal',
            fullWidth: true
          },
          validation: {
            required: true
          },
        },
        managerId: {
          element: 'select',
          value: report.alcance == null ? '' : report.alcance,
          options: [],
          config: {
              id: 'manager',
              name: 'managerId',
              label: 'Selecciona un Alcance',
              placeholder: 'Selecciona un Alcance',
              variant: 'outlined',
              margin: 'normal',
              fullWidth: true,
              select: true
          },
          validation: {
            required: true
          },
        },
        managerId1: {
          element: 'select',
          value: report.frecuency == null ? '' : report.frecuency,
          options: [],
          config: {
              id: 'manager',
              name: 'managerId1',
              label: 'Selecciona un Periodo',
              placeholder: 'Selecciona un Periodo',
              variant: 'outlined',
              margin: 'normal',
              fullWidth: true,
              select: true
          },
          validation: {
            required: true
          },
        }
      })

    return (
        <Container>
            <br>
        </br>
            <center><h3>Paso 2 - Información general del reporte</h3>
                <h4>Esta sección permite ingresar la información general de tu reporte.</h4></center>
                <br>
                </br>
            <form>
                <Grid container alignItems="center" display="flex" justifyContent="center" style={{width:"auto"}} direction="column">
                    <Grid container spacing={2} width={450}>
                        <Grid item xs={12}>
                            <FormField
                                id={'name'}
                                formData={formData.name}
                                change={() => updateForm}
                            />
                        </Grid>

                        <Grid item xs={6}>

                        <TextField fullWidth id='dateInicio' name='dateInicio' label="Fecha de Inicio" 
                                   type="date" defaultValue="2021-01-01" sx={{width: 220}} variant="outlined" 
                                   value={formData.dateInicio.value} onChange={updateForm}>                  
                        </TextField>
                        </Grid>
                        <Grid item xs={6}>

                        <TextField fullWidth id='dateTermino' name='dateTermino' label="Fecha de Termino" 
                                   type="date" defaultValue="2021-01-01" sx={{width: 220}} variant="outlined" 
                                   value={formData.dateTermino.value} onChange={updateForm}>                  
                        </TextField>
                        </Grid>
                        {/* <Grid item xs={6}>
                                <center> 
                                <FormField
                                  id='managerId'
                                  formData={formData.managerId}
                                  change={() => updateForm}
                                />
                                
                                </center>
                        </Grid>
                        <Grid item xs={6}>
                                <center> 
                                <FormField
                                  id='managerId1'
                                  formData={formData.managerId1}
                                  change={() => updateForm}
                                />
                                
                                </center>
                        </Grid> */}
                        {/* <Grid item xs={12}>
                                <center> 
                                  <Select value='grapefruit' fullWidth="true">
                                    <MenuItem value="grapefruit">Grapefruit</MenuItem>
                                    <MenuItem value="lime">Lime</MenuItem>
                                  </Select>
                                </center>
                        </Grid>  */}
                        <Grid item xs={12}>
                            <FormField
                                id={'palabras'}
                                formData={formData.palabras}
                                change={() => updateForm}
                            />
                        </Grid>
                        <Grid item xs={12}>
                          <ButtonWithLoading
                          text='Guardar'
                          onClick={test}
                          fullWidth
                        />
                        </Grid>
                        </Grid>
                </Grid>
            </form>
                <br>
        </br>
        </Container>
    )
}

export default Paso3
