import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Dialog,
  Typography,
  IconButton,
  DialogActions,
  withWidth,
  useTheme
} from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// import ConsoleLog from '../../debugging/ConsoleLog'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    zIndex: 10
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'inherit'
  }
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, titlebackground } = props
  const theme = useTheme()
  return (
    <MuiDialogTitle
      disableTypography
      style={{
        background: titlebackground
          ? theme.palette.gradientTopBottom.main
          : '#fff'
      }}
    >
      {onClose ? (
        <IconButton
          aria-label='Close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography
        variant='h6'
        align={titlebackground ? 'center' : 'left'}
        style={{ color: titlebackground ? '#fff' : '#000' }}
      >
        {children}
      </Typography>
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const CustomDialog = (props) => {
  const {
    width,
    onClose,
    selectedValue,
    dialogcontentdividers,
    titlebackground,
    dispatch,
    ...other
  } = props
  const [fullScreen, setFullScreen] = useState(false)
  useEffect(() => {
    if (width === 'sm' || width === 'xs') {
      setFullScreen(true)
    } else {
      setFullScreen(false)
    }
  }, [width])

  function handleClose() {
    onClose(selectedValue)
  }

  return (
    <>
      {/* <ConsoleLog>{typeof props.dialogtitle}</ConsoleLog> */}
      <Dialog
        maxWidth={props.maxWidth}
        onClose={handleClose}
        aria-labelledby={
          typeof props.dialogtitle === 'string' ? props.dialogtitle : ''
        }
        fullWidth
        fullScreen={fullScreen}
        {...other}
      >
        {props.dialogtitle ? (
          <DialogTitle
            id='simple-dialog-title'
            onClose={handleClose}
            titlebackground={titlebackground}
          >
            {props.dialogtitle}
          </DialogTitle>
        ) : fullScreen ? (
          <DialogTitle id='simple-dialog-title' onClose={handleClose} />
        ) : null}
        <DialogContent dividers={dialogcontentdividers}>
          {props.dialogcontent}
        </DialogContent>
        {props.dialogactions ? (
          <DialogActions>{props.dialogactions}</DialogActions>
        ) : null}
      </Dialog>
    </>
  )
}

Dialog.propTypes = {
  dialogtitle: PropTypes.string,
  dialogcontent: PropTypes.element
}

export default withWidth()(connect(null, null)(CustomDialog))
