import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Logo from '../../assets/img/terocolor.png'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {},
  logo: (props) => ({
    width: props.width,
    height: props.height ? props.height : 'auto'
  })
}))

/**
 * Logo de Tero
 */

const TeroLogo = ({ width, height }) => {
  const classes = useStyles({ width, height })

  return (
    <div className={classes.root}>
      <img src={Logo} alt='Tero' className={classes.logo} />
    </div>
  )
}

TeroLogo.defaultProps = {
  width: 128
}

TeroLogo.propTypes = {
  /**
   * Ancho de la imagen
   */
  width: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  /**
   * Ancho de la imagen
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default TeroLogo
