import { notificationConstants } from '../types'
import { v4 as uuidv4 } from 'uuid'

const initialState = {
  nextSnackbar: -1, // Da la key para la notificación
  snackbars: [], // Arreglo que almacena las notificaciones
  notifications: { newNotifications: [], oldNotifications: [] },
  fetching: false,
  errorFetching: null,
  setReadSuccess: null
}
export default function (state = initialState, action) {
  switch (action.type) {
    // snackbars para notificaciones del sistema
    case notificationConstants.ENQUEUE_SNACKBAR:
      const uuid = uuidv4()
      return {
        ...state,
        snackbars: [
          ...state.snackbars,
          { ...action.payload.notification, key: uuid }
        ]
      }
    case notificationConstants.REMOVE_SNACKBAR:
      return {
        ...state,
        snackbars: state.snackbars.filter(
          (notification) => notification.key !== action.payload
        )
      }
    case notificationConstants.DISMISS_SNACKBAR:
      return {
        ...state,
        snackbars: state.snackbars.map((notification) => {
          if (notification.key !== action.payload) {
            return notification
          }
          return {
            ...notification,
            dismissed: true
          }
        })
      }
    // Notificaciones para feed
    case notificationConstants.FETCH_STARTED:
      return { ...state, fetching: true, errorFetching: null }
    case notificationConstants.FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        errorFetching: null,
        notifications: action.payload
      }
    case notificationConstants.FETCH_FAILURE:
      return { ...state, fetching: false, errorFetching: action.payload }

    case notificationConstants.FETCH_MORE_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          oldNotifications: [
            ...state.notifications.oldNotifications,
            ...action.payload
          ]
        }
      }
    case notificationConstants.FETCH_MORE_FAILURE:
      return { ...state }

    case notificationConstants.NEW:
      if (
        state.notifications.newNotifications.find(
          (notification) => notification.id === action.payload.id
        )
      ) {
        return state
      }
      return {
        ...state,
        notifications: {
          ...state.notifications,
          newNotifications: [
            { ...action.payload, open: true },
            ...state.notifications.newNotifications
          ]
        }
      }
    case notificationConstants.CLOSE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          newNotifications: state.notifications.newNotifications.map(
            (notification) => {
              if (notification.id !== action.payload) {
                return notification
              }
              return {
                ...notification,
                open: false
              }
            }
          )
        }
      }
    case notificationConstants.SET_READ_STARTED:
      return {
        ...state,
        setReadSuccess: null
      }
    case notificationConstants.SET_READ_SUCCESS:
      return {
        ...state,
        setReadSuccess: true,
        notifications: {
          ...state.notifications,
          newNotifications: state.notifications.newNotifications.map(
            (notification) => {
              const replaceNotification = action.payload.find(
                (updatedNotification) =>
                  updatedNotification.id === notification.id
              )
              if (replaceNotification) {
                return replaceNotification
              }
              return notification
            }
          ),
          oldNotifications: state.notifications.oldNotifications.map(
            (notification) => {
              const replaceNotification = action.payload.find(
                (updatedNotification) =>
                  updatedNotification.id === notification.id
              )
              if (replaceNotification) {
                return replaceNotification
              }
              return notification
            }
          )
        }
      }
    case notificationConstants.SET_READ_FAILURE:
      return {
        ...state,
        setReadSuccess: false
      }
    default:
      return state
  }
}
