import { areaConstants } from '../types'

const initialState = {
  loading: false,
  error: null,
  areas: []
}

export default function (state = {}, action) {
  switch (action.type) {
    /*--- SET initial state ---*/
    case areaConstants.SET_INITIAL_STATE:
      return initialState
    /*--- SET initial state ---*/

    /*--- FETCH ---*/
    case areaConstants.FETCH_STARTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case areaConstants.FETCH_SUCCESS:
      action.payload.forEach((area) => {
        area['label'] = area.name
        area['value'] = area.id
      })
      return {
        ...state,
        loading: false,
        error: null,
        areas: action.payload
      }
    case areaConstants.FETCH_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    /*--- FETCH ---*/

    case areaConstants.DELETE_STARTED:
      return { areas: state.areas, deleting: true }
    case areaConstants.DELETE_SUCCESS:
      let areas = state.areas.filter((area) => area.id !== action.payload.id)
      return { areas: areas }
    case areaConstants.DELETE_FAILURE:
      return { areas: state.areas, errorDeleting: true }

    /**
     * Actualiza un área
     */
    case areaConstants.UPDATE:
      return {
        ...state,
        areas: state.areas.map((area) => {
          if (area.id !== action.payload.areaId) {
            return area
          }
          return {
            ...area,
            ...action.payload.data,
            label: action.payload.data.name,
            value: action.payload.data.id
          }
        })
      }
    /**
     * Crea un área
     */
    case areaConstants.CREATE:
      return {
        ...state,
        areas: [
          ...state.areas,
          {
            ...action.payload,
            label: action.payload.name,
            value: action.payload.id
          }
        ]
      }

    default:
      return state
  }
}
