import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  AccordionActions,
  Grid,
  IconButton,
  Button,
  Chip
} from '@material-ui/core'
import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  TrendingUp as TrendingUpIcon,
  Timeline as TimelineIcon,
  BlurOff as BlurOffIcon,
  GetApp as GetAppIcon,
  ErrorOutline as ErrorOutlineIcon
} from '@material-ui/icons'
import Dialog from '../UI/Dialog'
import ButtonWithLoading from '../UI/ButtonWithLoading'
import { reportActions } from '../../state/actions'
import { useDispatch } from 'react-redux'
import { reportConstants } from '../../state/types'
import Progress from '../UI/Progress'

const useStyles = makeStyles((theme) => ({
  root: {},
  icons: { fontSize: 32 },
  structureElementContainer: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    border: '1px solid #000'
  }
}))

const parseStructureName = (key) => {
  switch (key) {
    case 'initiatives':
      return 'Iniciativas'
    case 'materiality':
      return 'Análisis de materialidad'
    case 'indicatorsTable':
      return 'Indicadores'
    default:
      break
  }
}

/**
 * Despliega un reporte
 */

const ShowReport = ({ report }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [errorDelete, setErrorDelete] = useState(false)
  const [deleteOk, setDeleteOk] = useState(false)

  const handleEdit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch({
      type: reportConstants.GLOBAL_REPORT_DIALOG.OPEN,
      payload: { create: false, report: report }
    })
  }

  const handleDelete = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setShowDeleteDialog(true)
  }

  const submitDelete = (event) => {
    event.preventDefault()
    const submit = async () => {
      setLoadingDelete(true)
      setDeleteOk(false)
      setErrorDelete(false)
      await dispatch(reportActions.destroy(report.id))
        .then(() => {
          setDeleteOk(true)
          setTimeout(() => {
            setDeleteOk(false)
            setShowDeleteDialog(false)
          }, 2000)
        })
        .catch(() => setErrorDelete(true))
      setLoadingDelete(false)
    }
    submit()
  }

  const [loadingDownload, setLoadingDownload] = useState(false)
  // const [downloadOk, setDownloadOk] = useState(false)
  const [downloadError, setDownloadError] = useState(false)
  const download = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const submit = async () => {
      setLoadingDownload(true)
      // setDownloadOk(false)
      setDownloadError(false)
      await dispatch(reportActions.download(report.id))
        .then((response) => {
          setLoadingDownload(false)
          // setDownloadOk(true)
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${report.name}.pdf`)
          document.body.appendChild(link)
          link.click()
          // setTimeout(() => {
          //   setDownloadOk(false)
          // }, 1000)
        })
        .catch((err) => {
          setDownloadError(true)
          setLoadingDownload(false)
          setTimeout(() => {
            setDownloadError(false)
          }, 1000)
        })
    }
    submit()
  }

  const reportDetails = (
    <>
      <Grid
        container
        direction='row'
        spacing={5}
        justifyContent='space-around'
        alignItems='flex-start'
      >
        <Grid item xs={12} md={6} container direction='column' spacing={5}>
          <Typography variant='h6'>Estructura</Typography>
          {report.structureOrder.map((order) => (
            <Grid item xs key={order.position}>
              <div className={classes.structureElementContainer}>
                <Typography align='center'>
                  {parseStructureName(order.type)}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} md={6} container direction='column' spacing={5}>
          {report.ceoWords && (
            <Grid
              item
              container
              alignItems='center'
              justifyContent='flex-start'
              spacing={2}
            >
              <Grid item>
                <VisibilityIcon className={classes.icons} />
              </Grid>
              <Grid item xs container>
                <Typography variant='h6'>Palabras iniciales</Typography>
                <Typography
                  variant='subtitle1'
                  paragraph
                  style={{ width: '100%' }}
                >
                  {report.ceoWords.length > 247
                    ? `${report.ceoWords.slice(0, 247)}...`
                    : report.ceoWords}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid
            item
            container
            alignItems='center'
            justifyContent='flex-start'
            spacing={2}
          >
            <Grid item>
              <BlurOffIcon className={classes.icons} />
            </Grid>
            <Grid item xs container direction='column'>
              <Typography variant='h6'>Análisis de materialidad</Typography>
              <Typography variant='subtitle1' paragraph>
                {report.content.materiality ? 'Visible' : 'Oculta'}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems='center'
            justifyContent='flex-start'
            spacing={2}
          >
            <Grid item>
              <TimelineIcon className={classes.icons} />
            </Grid>
            <Grid item xs container>
              <Typography variant='h6'>Iniciativas</Typography>
              <Grid item container spacing={1}>
                {report.initiatives.map((initiative) => (
                  <Grid item>
                    <Chip
                      variant='outlined'
                      color='primary'
                      label={initiative.name}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems='center'
            justifyContent='flex-start'
            spacing={2}
          >
            <Grid item>
              <TrendingUpIcon className={classes.icons} />
            </Grid>
            <Grid item xs container>
              <Typography variant='h6'>Indicadores</Typography>
              <Grid item container spacing={1}>
                {report.indicators.map((indicator) => (
                  <Grid item>
                    <Chip
                      variant='outlined'
                      color='primary'
                      label={indicator.name}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
        >
          <Grid
            container
            alignItems='center'
            justifyContent='space-between'
            spacing={1}
          >
            <Grid item xs={10}>
              <Typography>{report.name}</Typography>
            </Grid>
            <Grid item xs={2} container alignItems='center' spacing={1}>
              {downloadError && <ErrorOutlineIcon />}
              {loadingDownload && (
                <Progress loading={loadingDownload} size={24} thickness={4} />
              )}
              {!downloadError && !loadingDownload && (
                <IconButton onClick={download}>
                  <GetAppIcon />
                </IconButton>
              )}

              <IconButton onClick={handleEdit}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>{reportDetails}</AccordionDetails>
        <AccordionActions></AccordionActions>
      </Accordion>
      {showDeleteDialog && (
        <Dialog
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          dialogtitle='¿Confirma que desea eliminar este reporte?'
          dialogcontent={
            <>
              <Typography paragraph>
                Esta acción no puede ser revertida
              </Typography>
              {errorDelete && (
                <Typography color='error'>Ha ocurrido un error</Typography>
              )}
            </>
          }
          dialogactions={
            <>
              <Button
                color='primary'
                onClick={() => setShowDeleteDialog(false)}
              >
                Cancelar
              </Button>
              <ButtonWithLoading
                autoFocus
                onClick={submitDelete}
                text='Eliminar'
                loading={loadingDelete}
                submitOk={deleteOk}
              />
            </>
          }
        />
      )}
    </div>
  )
}

export default ShowReport
