import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SubmitButton from '../UI/ButtonWithLoading'
import { FormField } from 'boosted-materialui-forms'
import {
  Grid,
  Paper,
  IconButton,
  Typography,
  CircularProgress
} from '@material-ui/core'
import {
  Delete as DeleteIcon,
  DescriptionOutlined as DescriptionIcon,
  GetApp as GetAppIcon
} from '@material-ui/icons'
import { reportActions } from '../../state/actions'
import { useDispatch } from 'react-redux'
const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(2) },
  paper: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    maxWidth: '100%'
  },
  downloadIconWrapper: {
    position: 'relative'
  },
  icon: {
    fontSize: 24
  },
  downloadIcon: {
    position: 'absolute',
    right: 8,
    bottom: 4,
    fontSize: 16
  }
}))

const DownloadTab = ({ report }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleChange = (event) => {
    event.preventDefault()
    const { value } = event.target
    setSaveReportData((prevData) => ({
      ...prevData,
      value: value,
      error: ''
    }))
  }

  const [saveReportData, setSaveReportData] = useState({
    value: '',
    config: {
      name: 'save',
      label: 'Nombre copia',
      fullWidth: true,
      size: 'normal'
    },
    onChange: handleChange
  })

  const [loading, setLoading] = useState(false)
  const [submitOk, setSubmitOk] = useState(false)
  const saveReport = (event) => {
    event.preventDefault()
    const dataToSubmit = {
      snapshot: {
        name: saveReportData.value
      }
    }
    const submit = async () => {
      setLoading(true)
      setSubmitOk(false)
      await dispatch(reportActions.save(report.id, dataToSubmit))
        .then(() => {
          setSubmitOk(true)
          setLoading(false)
          setSaveReportData((prevState) => ({ ...prevState, value: '' }))
          setTimeout(() => {
            setSubmitOk(false)
          }, 1000)
        })
        .catch((err) => {
          setSaveReportData((prevState) => ({
            ...prevState,
            error: err?.errors?.errorLabel
              ? `${err?.errors?.label}: ${err?.errors?.errorLabel} `
              : 'Ha ocurrido un error'
          }))
          setLoading(false)
        })
    }
    submit()
  }

  const [loadingDownload, setLoadingDownload] = useState(false)
  const [downloadOk, setDownloadOk] = useState(false)
  const [downloadError, setDownloadError] = useState(false)
  const download = (event) => {
    event.preventDefault()
    const submit = async () => {
      setLoadingDownload(true)
      setDownloadOk(false)
      setDownloadError(false)
      await dispatch(reportActions.download(report.id))
        .then((response) => {
          setLoadingDownload(false)
          setDownloadOk(true)
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${report.name}.pdf`)
          document.body.appendChild(link)
          link.click()
          setTimeout(() => {
            setDownloadOk(false)
          }, 1000)
        })
        .catch((err) => {
          setDownloadError(
            err?.errors?.errorLabel
              ? `${err?.errors?.label}: ${err?.errors?.errorLabel} `
              : 'Ha ocurrido un error'
          )
          setLoadingDownload(false)
        })
    }
    submit()
  }

  const [snapshotToDelete, setSnapshotToDelete] = useState(null)
  const [loadingDeleteSnapshot, setLoadingDeleteSnapshot] = useState(false)
  const handleDeleteSnapshot = (event, snapshotId) => {
    event.preventDefault()
    setSnapshotToDelete(snapshotId)
    const destroy = async () => {
      try {
        await setLoadingDeleteSnapshot(true)
        await dispatch(reportActions.deleteSnapshot(report.id, snapshotId))
        setLoadingDeleteSnapshot(false)
      } catch (err) {
        setLoadingDeleteSnapshot(false)
      }
    }
    destroy()
  }
  return (
    <div className={classes.root}>
      <Grid container direction='column' justifyContent='center' spacing={2}>
        <Grid item container alignItems='center' spacing={2}>
          <Grid item xs>
            <FormField {...saveReportData} />
          </Grid>
          <Grid item>
            <SubmitButton
              text='Guardar'
              onClick={saveReport}
              loading={loading}
              submitOk={submitOk}
              disabled={!saveReportData.value}
            />
          </Grid>
        </Grid>
        <Grid item xs container spacing={2} direction='column'>
          {report.snapshots.map((snapshot, index) => (
            <Grid item xs key={snapshot.id}>
              <Paper variant='outlined' classes={{ root: classes.paper }}>
                <Grid
                  container
                  spacing={2}
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item xs>
                    <Typography>{snapshot.name}</Typography>
                  </Grid>
                  <Grid item>
                    <a
                      href={snapshot.url}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <IconButton className={classes.downloadIconWrapper}>
                        <DescriptionIcon className={classes.documentIcon} />
                        <GetAppIcon className={classes.downloadIcon} />
                      </IconButton>
                    </a>
                    <IconButton>
                      {loadingDeleteSnapshot &&
                      snapshot.id === snapshotToDelete ? (
                        <CircularProgress size={24} />
                      ) : (
                        <DeleteIcon
                          className={classes.icon}
                          onClick={(event) =>
                            handleDeleteSnapshot(event, snapshot.id)
                          }
                        />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
        {downloadError && (
          <Grid item xs={12}>
            <Typography color='error'>{downloadError}</Typography>
          </Grid>
        )}
        <Grid item xs={12} container justifyContent='center'>
          <SubmitButton
            text='Descargar último reporte'
            loading={loadingDownload}
            submitOk={downloadOk}
            onClick={download}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default DownloadTab
