const rules = {
  // Admin del sistema
  roleAdmin: {},
  // Lider de sostenibilidad
  roleLeader: {
    static: [
      'initiatives:create',
      'initiatives:update',
      'initiatives:delete',
      'indicators:create',
      'indicators:update',
      'indicators:delete',
      'materialities:create',
      'materialities:update',
      'materialities:delete',
      'interestGroups:create',
      'interestGroups:update',
      'interestGroups:delete',
      'interestGroupsPoll:send',
      'interestGroupsMailing:edit',
      'materialityIssues:manage',
      'materialityIssues:create',
      'materialityIssues:update',
      'materialityIssues:delete',
      'indicatorLogs:manage',
      'reports:create',
      'users:manage'
    ],
    dynamic: {
      'organizationFeed:follow': ({
        userOrganizationId,
        organizationFeedId
      }) => {
        return userOrganizationId !== organizationFeedId
      }
    }
  },
  // Encargado de iniciativas
  roleManageInitiatives: {
    static: ['initiatives:create', 'initiatives:update', 'initiatives:delete']
  },
  // Encargado de indicadores
  roleManageIndicators: {
    static: ['indicators:create', 'indicators:update', 'indicators:delete']
  },
  // Usuario común
  roleUser: {
    dynamic: {
      'organizationFeed:follow': ({
        userOrganizationId,
        organizationFeedId
      }) => {
        return userOrganizationId !== organizationFeedId
      },
      'indicatorLogs:manage': ({ managerId, userId }) => {
        return managerId === userId
      }
    }
  }
}

export default rules
