import { socialConstants } from "../types";
import Axios from "../../axios";
import { enqueueSnackbar } from "./notification.actions";

/**
 * Obtiene el perfil de la organización a mostrar
 * @param {Integer} organizationId - ID de la organización a mostrar
 */

const fetchOrganizationStarted = () => ({
  type: socialConstants.PROFILE.SOCIAL_GET_PROFILE_STARTED,
});
const fetchOrganizationSuccess = (data) => ({
  type: socialConstants.PROFILE.SOCIAL_GET_PROFILE_SUCCESS,
  payload: data,
});
const fetchOrganizationFailure = (error) => ({
  type: socialConstants.PROFILE.SOCIAL_GET_PROFILE_FAILURE,
  payload: error,
});

const fetchOrganization = (organizationId) => {
  return (dispatch) => {
    dispatch(fetchOrganizationStarted());
    Axios.get(`/companies/${organizationId}/profile`)
      .then((response) => {
        dispatch(fetchOrganizationSuccess(response.data.organization));
      })
      .catch((error) => {
        dispatch(fetchOrganizationFailure(error.response));
      });
  };
};

/**
 * Follow a una organización.
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {ParamDataTypeHere} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */
const followStarted = () => ({
  type: socialConstants.PROFILE.SOCIAL_PROFILE_FOLLOW_STARTED,
});
const followSuccess = (organizationId) => ({
  type: socialConstants.PROFILE.SOCIAL_PROFILE_FOLLOW_SUCCESS,
  payload: organizationId,
});
const followFailure = (error) => ({
  type: socialConstants.PROFILE.SOCIAL_PROFILE_FOLLOW_FAILURE,
  payload: error,
});
const follow = (organizationId) => {
  return (dispatch) => {
    dispatch(followStarted());
    Axios.post(`/companies/${organizationId}/follow`)
      .then((response) => {
        dispatch(followSuccess(response.data));
      })
      .catch((error) => {
        dispatch(followFailure(error.response));
        dispatch(
          enqueueSnackbar({
            variant: "error",
            message: "No se ha podido seguir a la organización",
          })
        );
      });
  };
};

/**
 * unfollow a una organización.
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {ParamDataTypeHere} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */

const unfollowStarted = () => ({
  type: socialConstants.PROFILE.SOCIAL_PROFILE_UNFOLLOW_STARTED,
});
const unfollowSuccess = (organizationId, userId) => ({
  type: socialConstants.PROFILE.SOCIAL_PROFILE_UNFOLLOW_SUCCESS,
  payload: { organizationId, userId },
});
const unfollowFailure = (error) => ({
  type: socialConstants.PROFILE.SOCIAL_PROFILE_UNFOLLOW_FAILURE,
  payload: error,
});

const unfollow = (organizationId, userId) => {
  return (dispatch) => {
    dispatch(unfollowStarted());
    Axios.delete(`/companies/${organizationId}/follow`)
      .then((response) => {
        dispatch(unfollowSuccess(response.data, userId));
      })
      .catch((error) => {
        dispatch(unfollowFailure(error.response));
        dispatch(
          enqueueSnackbar({
            variant: "error",
            message: "No se ha podido dejar de seguir a la organización",
          })
        );
      });
  };
};
/**
 * Obtiene recomendaciones de hashtags.
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {ParamDataTypeHere} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */

const recommendedHashtags = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.get("/hashtags/recommendations")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  };
};
/**
 * Añade un hashtag a seguir
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {ParamDataTypeHere} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */

const followHashtag = (hashtag) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post("/hashtags/follow", {
        hashtag: {
          text: hashtag,
        },
      })
        .then((response) => {
          dispatch({
            type: socialConstants.PROFILE.SOCIAL_PROFILE_FOLLOW_HASHTAG,
            payload: response.data.hashtag,
          });
          resolve(response.data.hashtag);
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              variant: "error",
              message: "No se ha podido seguir el hashtag",
            })
          );
          reject(error.response);
        });
    });
  };
};

/**
 * Deja de seguir un hashtag
*/

const unfollowHashtag = (hashtag) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.delete(`/hashtags/follow/${hashtag.hashtagText.split("#")[1]}`)
        .then((response) => {
          dispatch({
            type: socialConstants.PROFILE.SOCIAL_PROFILE_UNFOLLOW_HASHTAG,
            payload: hashtag,
          });
          resolve(true);
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              variant: "error",
              message: "No se ha podido dejar de seguir el hashtag",
            })
          );
          resolve(false);
        });
    });
  };
};

export const socialActions = {
  fetchOrganization,
  follow,
  unfollow,
  recommendedHashtags,
  followHashtag,
  unfollowHashtag,
};
