import Axios from '../../axios'
import { materialityConstants } from '../types'
import { uiActions } from './ui.actions'
import { enqueueSnackbar } from './notification.actions'

function fetch(organizationId) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())

    Axios.get(`/companies/${organizationId}/materialities`)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido obtener las matrices de materialidad'
          })
        )
      })
  }

  function start() {
    return {
      type: materialityConstants.FETCH_STARTED
    }
  }
  function success(data) {
    return {
      type: materialityConstants.FETCH_SUCCESS,
      payload: data
    }
  }

  function failure(error) {
    return {
      type: materialityConstants.FETCH_FAILURE,
      payload: error
    }
  }
}

function fetchTheMateriality(cId, mId) {
  const companyId = cId
  const materialityId = mId
  return async (dispatch) => {
    try {
      dispatch(start())
      const response = await Axios.get(
        `/companies/${companyId}/materialities/${materialityId}`
      )
      dispatch(success(response.data))
      dispatch(uiActions.endLoading())
    } catch (error) {
      dispatch(failure(error.response))
      dispatch(uiActions.endLoading())
    }

    function start() {
      return {
        type: materialityConstants.FETCH_THEONE_STARTED
      }
    }
    function success(data) {
      return {
        type: materialityConstants.FETCH_THEONE_SUCCESS,
        payload: data
      }
    }

    function failure(error) {
      return {
        type: materialityConstants.FETCH_THEONE_FAILURE,
        payload: error
      }
    }
  }
}

function harvestPollLinks(groupId) {
  return async (dispatch) => {
    try {
      dispatch(uiActions.startLoading())
      const response = await Axios.post(`/poll/harvestLinks`, { groupId })
      dispatch(success(response.data))
      dispatch(uiActions.endLoading())
    } catch (error) {
      dispatch(failure(error.response))
      dispatch(uiActions.endLoading())
      dispatch(clearError())
    }
    // dispatch(
    //   enqueueSnackbar({
    //     variant: 'error',
    //     message:
    //       'Ha ocurrido un error obteniendo los links de encuestas abiertas'
    //   })
    // )

    function start() {
      return {
        type: materialityConstants.HARVEST_POLL_LINKS_START
      }
    }
    function success(data) {
      return {
        type: materialityConstants.HARVEST_POLL_LINKS_SUCCESS,
        payload: data
      }
    }
    function failure(error) {
      return {
        type: materialityConstants.HARVEST_POLL_LINKS_FAILURE,
        payload: error
      }
    }
    function clearError() {
      return {
        type: materialityConstants.CLEAR_ERROR
      }
    }
  }
}

function fetchPollAnswersCount(id) {
  return async (dispatch) => {
    try {
      dispatch(start())
      dispatch(uiActions.startLoading())
      const response = await Axios.get(`/poll/count/${id}`)
      dispatch(success(response.data))
      dispatch(uiActions.endLoading())
    } catch (error) {
      dispatch(failure(error.response))
      dispatch(uiActions.endLoading())
    }
    // dispatch(
    //   enqueueSnackbar({
    //     variant: 'error',
    //     message:
    //       'Ha ocurrido un error obteniendo los links de encuestas abiertas'
    //   })
    // )

    function start() {
      return {
        type: materialityConstants.FETCH_ANSWERS_COUNT_START
      }
    }
    function success(data) {
      return {
        type: materialityConstants.FETCH_ANSWERS_COUNT_SUCCESS,
        payload: data
      }
    }
    function failure(error) {
      console.log(error)
      return {
        type: materialityConstants.FETCH_ANSWERS_COUNT_FAILURE,
        payload: error
      }
    }
  }
}

const create = (organizationId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/companies/${organizationId}/materialities`, { ...data })
        .then((response) => {
          dispatch({
            type: materialityConstants.CREATE_SUCCESS,
            payload: { materiality: response.data.materiality }
          })
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Matriz creada exitosamente'
            })
          )
          resolve(response.data.materiality)
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido crear la matriz de materialidad'
            })
          )
          reject(error.response)
        })
    })
  }
}

function _delete(organizationId, materialityId) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.delete(`/companies/${organizationId}/materialities/${materialityId}`)
      .then((response) => {
        dispatch(success(materialityId))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Matriz de materialidad eliminada exitosamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido eliminar la matriz de materialidad'
          })
        )
      })
  }

  function start() {
    return {
      type: materialityConstants.DELETE_STARTED
    }
  }

  function success(id) {
    return {
      type: materialityConstants.DELETE_SUCCESS,
      payload: { id }
    }
  }

  function failure(error) {
    return {
      type: materialityConstants.DELETE_FAILURE,
      payload: { error }
    }
  }
}

function onCreate() {
  return {
    type: materialityConstants.ON_CREATE_STARTED
  }
}

function onCreateAddInterest(interest) {
  return {
    type: materialityConstants.ON_CREATE_ADD_INTEREST,
    payload: {
      interest: interest
    }
  }
}

function onCreateUpdateInterest(interest) {
  return {
    type: materialityConstants.ON_CREATE_UPDATE_INTEREST,
    payload: { interest: interest }
  }
}

function onCreateDeleteInterest(id) {
  return {
    type: materialityConstants.ON_CREATE_DELETE_INTEREST,
    payload: { id: id }
  }
}

function addInterest(materialityId, interest) {
  /*
   * * Esta acción sirve para añadir temas materiales a un análisis de materialidad
   * * El JSON de interest tiene la forma { name: 'Lorem ipsum' }
   */

  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.post(`/materialities/${materialityId}/interests`, { ...interest })
      .then((response) => {
        dispatch(success(materialityId, response.data))
        dispatch(uiActions.endLoading())
        dispatch({ type: materialityConstants.INTEREST_MODAL.CLOSE })
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Tema de materialidad añadido'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido añadir el tema material'
          })
        )
      })
  }

  function start() {
    return {
      type: materialityConstants.ADD_INTEREST_STARTED
    }
  }

  function success(materialityId, interest) {
    return {
      type: materialityConstants.ADD_INTEREST_SUCCESS,
      payload: {
        materialityId: materialityId,
        interest: interest
      }
    }
  }

  function failure(error) {
    return {
      type: materialityConstants.ADD_INTEREST_FAILURE,
      paylaod: { error }
    }
  }
}

/**
 * Permite eliminar un tema material del análisis de materialidad
 */

const removeInterest = (materialityId, interestId) => {

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(uiActions.startLoading())
      Axios.delete(`/materialities/${materialityId}/interests/${interestId}`)
        .then(() => {
          dispatch({
            type: materialityConstants.DELETE_INTEREST_SUCCESS,
            payload: {
              materialityId: materialityId,
              interestId: interestId
            }
          })
          dispatch(uiActions.endLoading())
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Interés eliminado exitosamente'
            })
          )
          resolve()
        })
        .catch((error) => {
          dispatch(uiActions.endLoading())
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido eliminar el interés'
            })
          )
          reject()
        })
    })
  }
}

/**
 * @params {integer} materialityId - If of materiality where interest belongs
 * @param  {integer} materialityId - Id of materiality where interest belongs
 * @param  {integer} interestId - ID of interest to update
 * @param  {object} data - Object that contains data to update
 */
function editInterest(materialityId, interestId, data) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    return Axios.patch(
      `/materialities/${materialityId}/interests/${interestId}`,
      {
        ...data
      }
    )
      .then((response) => {
        dispatch(success(response.data.interest))
        dispatch(uiActions.endLoading())
        dispatch({ type: materialityConstants.INTEREST_MODAL.CLOSE })
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Tema material actualizado'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido actualizar el tema material'
          })
        )
      })
  }

  function start() {
    return {
      type: materialityConstants.EDIT_INTEREST_STARTED
    }
  }

  function success(data) {
    return {
      type: materialityConstants.EDIT_INTEREST_SUCCESS,
      payload: {
        data: data,
        materialityId: materialityId,
        interestId: interestId
      }
    }
  }

  function failure(error) {
    return {
      type: materialityConstants.EDIT_INTEREST_FAILURE,
      payload: {
        error
      }
    }
  }
}
// developer at work
function createInterestGroup(materialityId, data) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.post(`/materialities/${materialityId}/interest_groups`, data)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Grupo de interés creado exitosamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido crear el grupo de interés'
          })
        )
      })
  }

  function start() {
    return {
      type: materialityConstants.ADD_INTEREST_GROUP_STARTED
    }
  }

  function success(data) {
    return {
      type: materialityConstants.ADD_INTEREST_GROUP_SUCCESS,
      payload: {
        materialityId: materialityId,
        group: data.interestGroup,
        listRecord: data.listRecord
      }
    }
  }

  function failure(error) {
    return {
      type: materialityConstants.ADD_INTEREST_GROUP_FAILURE,
      payload: error
    }
  }
}

function deleteInterestGroup(materialityId, groupId) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.delete(`/materialities/${materialityId}/interest_groups/${groupId}`)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Grupo de interés eliminado exitosamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido eliminar el grupo de interés'
          })
        )
      })

    function start() {
      return {
        type: materialityConstants.DELETE_INTEREST_GROUP_STARTED
      }
    }

    function success() {
      return {
        type: materialityConstants.DELETE_INTEREST_GROUP_SUCCESS,
        payload: { materialityId, groupId }
      }
    }

    function failure(error) {
      return {
        type: materialityConstants.DELETE_INTEREST_GROUP_FAILURE,
        payload: error
      }
    }
  }
}

function updateInterestGroup(materialityId, groupId, data) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.patch(
      `/materialities/${materialityId}/interest_groups/${groupId}`,
      data
    )
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Grupo de interés actualizado correctamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido actualizar el grupo de interés'
          })
        )
      })

    function start() {
      return {
        type: materialityConstants.EDIT_INTEREST_GROUP_STARTED
      }
    }

    function success(data) {
      return {
        type: materialityConstants.EDIT_INTEREST_GROUP_SUCCESS,
        payload: data.interestGroup
      }
    }

    function failure(error) {
      return {
        type: materialityConstants.DELETE_INTEREST_FAILURE,
        payload: error
      }
    }
  }
}

function updateMaterialityStatus(organizationId, materialityId, newStatus) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.patch(`/companies/${organizationId}/materialities/${materialityId}`, {
      status: newStatus
    })
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Estado actualizado'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido actualizar el estado'
          })
        )
      })

    function start() {
      return {
        type: materialityConstants.UPDATE_STATUS_STARTED
      }
    }

    function success(data) {
      return {
        type: materialityConstants.UPDATE_STATUS_SUCCESS,
        payload: data.materiality
      }
    }

    function failure(error) {
      return {
        type: materialityConstants.UPDATE_STATUS_FAILURE,
        payload: error
      }
    }
  }
}

/**
 * Dado un token, obtiene los temas materiales que debe responder el usuario
 * @param {String} token - Token a enviar al backend para validad si el usuario puede responder la encuesta y obtener los temas materiales
 */
function getPollData(token) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.get(`/poll/${token}`)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
      })

    function start() {
      return {
        type: materialityConstants.GET_POLL_DATA_STARTED
      }
    }

    function success(data) {
      return {
        type: materialityConstants.GET_POLL_DATA_SUCCESS,
        payload: { data, token }
      }
    }

    function failure(error) {
      return {
        type: materialityConstants.GET_POLL_DATA_FAILURE,
        payload: error
      }
    }
  }
}

/**
 * Dado un id de análisis de materialidad, obtiene los temas materiales que debe responder el usuario
 * @param {String} materialityId - No protegido por token - acceso a todo el que tenga el link de la encuesta
 * @param {String} interestGroupId - No protegido por token - acceso a todo el que tenga el link de la encuesta
 */
function getPublicPollData(token) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.get(`/poll/public/${token}`)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
      })

    function start() {
      return {
        type: materialityConstants.GET_PUBLIC_POLL_DATA_STARTED
      }
    }

    function success(data) {
      return {
        type: materialityConstants.GET_PUBLIC_POLL_DATA_SUCCESS,
        payload: { data }
      }
    }

    function failure(error) {
      return {
        type: materialityConstants.GET_PUBLIC_POLL_DATA_FAILURE,
        payload: error
      }
    }
  }
}
/**
 * Envía las evaluaciones de la encuesta del grupo interno
 * @param {Number} materialityId - ID del análisis de materialidad
 * @param {Number} interestGroupId - ID del grupo de interés al cuál está asociado el usuario
 * @param {Object} data - Objeto que contiene el email, tipo y respuestas del usuario
 */
function sendMaterialityPoll(materialityId, interestGroupId, data) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.post(
      `/materialities/${materialityId}/interestGroup/${interestGroupId}/interestScore`,
      data
    )
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
      })

    function start() {
      return {
        type: materialityConstants.SEND_MATERIALITY_POLL_STARTED
      }
    }

    function success(data) {
      return {
        type: materialityConstants.SEND_MATERIALITY_POLL_SUCCESS,
        payload: data
      }
    }

    function failure(error) {
      return {
        type: materialityConstants.SEND_MATERIALITY_POLL_FAILURE,
        payload: error
      }
    }
  }
}

/**
 * Envía las evaluaciones de la encuesta respondidas desde link público
 * @param {Number} materialityId - ID del análisis de materialidad
 * @param {Number} interestGroupId - ID del grupo de interés al cuál está asociado el usuario
 * @param {Object} data - tipo y respuestas del usuario
 */
function sendMaterialityPublicPoll(materialityId, interestGroupId, data) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.post(
      `/materialities/${materialityId}/interestGroup/${interestGroupId}/publicInterestScore`,
      data
    )
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
      })

    function start() {
      return {
        type: materialityConstants.SEND_MATERIALITY_POLL_STARTED
      }
    }

    function success(data) {
      return {
        type: materialityConstants.SEND_MATERIALITY_POLL_SUCCESS,
        payload: data
      }
    }

    function failure(error) {
      return {
        type: materialityConstants.SEND_MATERIALITY_POLL_FAILURE,
        payload: error
      }
    }
  }
}

const updateStarted = () => ({ type: materialityConstants.UPDATE_STARTED })
const updateSuccess = (data) => ({
  type: materialityConstants.UPDATE_SUCCESS,
  payload: { data }
})
const updateFailure = (error) => ({
  type: materialityConstants.UPDATE_FAILURE,
  payload: { error }
})

const update = (organizationId, materialityId, data) => {
  return (dispatch) => {
    dispatch(uiActions.startLoading())
    dispatch(updateStarted())
    Axios.patch(
      `/companies/${organizationId}/materialities/${materialityId}`,
      data
    )
      .then((response) => {
        dispatch(updateSuccess(response.data.materiality))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Análisis de materialidad actualizado correctamente'
          })
        )
      })
      .catch((error) => {
        dispatch(updateFailure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'no se ha podido actualizar el análisis de materialidad'
          })
        )
      })
  }
}

// TODO: Pasar esta función a promesa
function setInterestsToSend(materialityId, send, notSend) {
  return (dispatch) => {
    let updateOk = true
    let successResponses = []
    let failureResponses = []
    dispatch(start())
    dispatch(uiActions.startLoading())
    send.forEach((interestId) => {
      Axios.patch(`/materialities/${materialityId}/interests/${interestId}`, {
        sendExternal: true
      })
        .then((response) => {
          updateOk = updateOk && true
          successResponses.push(response.data.updatedInterest)
        })
        .catch((error) => {
          updateOk = false
          failureResponses.push(interestId)
        })
    })
    notSend.forEach((interestId) => {
      Axios.patch(`/materialities/${materialityId}/interests/${interestId}`, {
        sendExternal: false
      })
        .then((response) => {
          updateOk = updateOk && true
          successResponses.push(response.data.updatedInterest)
        })
        .catch((error) => {
          updateOk = false
          failureResponses.push(interestId)
        })
    })
    if (updateOk) {
      dispatch(success(materialityId, successResponses))
      dispatch(
        enqueueSnackbar({
          variant: 'success',
          message: 'Temas de materialidad actualizados correctamente'
        })
      )
    } else {
      dispatch(failure(materialityId, successResponses, failureResponses))
      dispatch(
        enqueueSnackbar({
          variant: 'error',
          message: 'No se ha podido actualizar correctamente'
        })
      )
    }
    dispatch(uiActions.endLoading())
  }

  function start() {
    return {
      type: materialityConstants.EDIT_MULTIPLE_STARTED
    }
  }

  function success(materialityId, successResponses) {
    return {
      type: materialityConstants.EDIT_MULTIPLE_SUCCESS,
      payload: { materialityId, successResponses }
    }
  }

  function failure(materialityId, successResponses, failureResponses) {
    return {
      type: materialityConstants.EDIT_MULTIPLE_FAILURE,
      payload: { materialityId, successResponses, failureResponses }
    }
  }
}

/**
 * Actualiza un grupo de interés
 */

const updateInterestGroupV2 = (materialityId, interestGroupId, data) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/materialities/${materialityId}/interest_groups/${interestGroupId}`,
      data
    )
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Grupo de interés actualizado correctamente'
          })
        )
        dispatch({
          type: materialityConstants.UPDATE_INTEREST_GROUP,
          payload: {
            materialityId,
            interestGroupId,
            data: response.data.interestGroup
          }
        })
        resolve()
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'no se ha podido actualizar el grupo de interés'
          })
        )
        reject(err)
      })
  })
}

/**
 * Obtiene las fuentes de temas materiales
 */

const fetchInterestSources = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.get('/interest-sources')
        .then((response) => {
          dispatch({
            type: materialityConstants.FETCH_SOURCES,
            payload: response.data.sources
          })

          resolve()
        })
        .catch((error) => {
          reject()
        })
    })
  }
}

/**
 * Permite enviar la encuesta de materialidad a un único grupos de interés
 */

const sendPollToGroup = (materialityId, interestGroupId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.get(
        `/materialities/${materialityId}/interest_groups/${interestGroupId}/send-poll`
      )
        .then((response) => {
          dispatch({
            type: materialityConstants.SEND_POLL_TO_GROUP,
            payload: { materialityId, interestGroupId }
          })
          resolve()
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido enviar la encuesta'
            })
          )
          reject()
        })
    })
  }
}

/**
 * Permite enviar la encuesta de materialidad a todos los grupos de interés
 */

const sendPollToAllGroups = (companyId, materialityId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.get(
        `/companies/${companyId}/materialities/${materialityId}/send-poll`
      )
        .then((response) => {
          dispatch({
            type: materialityConstants.SEND_POLL_TO_ALL_GROUPS,
            payload: { materialityId }
          })
          resolve()
        })
        .catch((error) => {
          reject()
        })
    })
  }
}

/**
 * Cierra un análisis de materialidad
 */

const closeMateriality = (organizationId, materialityId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.patch(
        `/companies/${organizationId}/materialities/${materialityId}`,
        { status: 'ACTUAL' }
      )
        .then((response) => {
          dispatch({
            type: materialityConstants.CLOSE_MATERIALITY,
            payload: { materiality: response.data, materialityId }
          })
          resolve()
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'no se ha podido realizar esta operación'
            })
          )
          reject(error)
        })
    })
  }
}

// Reportes de materialidad

const fetchReportInPrep = (materialityId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.get(`/materialities/${materialityId}/report`)
        .then((response) => {
          dispatch({
            type: materialityConstants.REPORTS.IN_PREP.FETCH,
            payload: {
              materialityId: materialityId,
              report: response.data.report
            }
          })
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }
}

const createMReport = (materialityId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/materialities/${materialityId}/report`, { report: data })
        .then((response) => {
          dispatch({
            type: materialityConstants.REPORTS.CREATE,
            payload: {
              materialityId: materialityId,
              report: response.data.report
            }
          })
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }
}

const updateMReport = (materialityId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.patch(`/materialities/${materialityId}/report`, { report: data })
        .then((response) => {
          dispatch({
            type: materialityConstants.REPORTS.UPDATE,
            payload: {
              materialityId: materialityId,
              report: response.data.report
            }
          })
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }
}

const saveMReport = (materialityId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/materialities/${materialityId}/report/save`, data)
      .then((response) => {
        dispatch({
          type: materialityConstants.REPORTS.SAVE,
          payload: { materialityId, snapshot: response.data.snapshot }
        })
        resolve()
      })
      .catch((err) => {
        reject(err.response.data)
      })
  })
}

const downloadMReport = (materialityId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/materialities/${materialityId}/report/download`, {
      responseType: 'arraybuffer'
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: err.response?.data?.errors?.errorLabel
              ? `${err.response?.data?.errors?.label}: ${err?.response?.data?.errors?.errorLabel} `
              : 'Ha ocurrido un error'
          })
        )
        reject(err.response.data)
      })
  })
}
//
const deleteMRSnapshot = (reportId, snapshotId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/materialities/${reportId}/snapshots/${snapshotId}`)
      .then(() => {
        dispatch({
          type: materialityConstants.REPORTS.DELETE_SNAPSHOT,
          payload: { reportId, snapshotId }
        })
        resolve()
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido eliminar el snapshot'
          })
        )
        reject(err.response)
      })
  })
}

export const materialityActions = {
  fetch,
  create,
  _delete,
  onCreate,
  onCreateAddInterest,
  onCreateUpdateInterest,
  onCreateDeleteInterest,
  addInterest,
  removeInterest,
  editInterest,
  createInterestGroup,
  deleteInterestGroup,
  updateInterestGroup,
  sendMaterialityPoll,
  sendMaterialityPublicPoll,
  updateMaterialityStatus,
  getPollData,
  getPublicPollData,
  setInterestsToSend,
  update,
  fetchInterestSources,
  sendPollToGroup,
  sendPollToAllGroups,
  closeMateriality,
  updateInterestGroupV2,
  harvestPollLinks,
  fetchPollAnswersCount,
  fetchTheMateriality,
  createMReport,
  fetchReportInPrep,
  updateMReport,
  saveMReport,
  downloadMReport,
  deleteMRSnapshot
}
