import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  FormControl,
  Input,
  Chip,
  Select,
  InputLabel,
  MenuItem,
  Popover,
  Divider,
  Fab
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Linechart from './LineChart'
import { useSelector } from 'react-redux'
import LogIndicator from './LogIndicator'
import Can from '../../auth/Can'
import { accumulated, totalForPeriod } from '../../helpers/logs-calculations'
import IndicatorCharts from './IndicatorCharts'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    paddingBottom: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  menuButton: {
    maxWidth: 200,
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    backgroundColor: '#26cad3',
    color: '#fff'
  },
  menuItem: {
    maxWidth: 300,
    padding: theme.spacing(2)
  }
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const getPossibleBranches = (filters) => {
  let branches = filters.branch.options
  // let value = filters.branch.value;
  let value = filters.branch.value
  if (!filters.country.value.length && !filters.region.value.length) {
    return { branches, value }
  }
  if (filters.country.value.length) {
    branches = branches.filter((branch) =>
      filters.country.value.find((country) => country === branch.country)
    )
    value = value.filter((tempValue) =>
      branches.find((tempBranch) => tempBranch.id === tempValue.id)
    )
  }
  if (filters.region.value.length) {
    branches = branches.filter((branch) =>
      filters.region.value.find((region) => region === branch.region)
    )
    value = value.filter((tempValue) =>
      branches.find((tempBranch) => tempBranch.id === tempValue.id)
    )
  }
  return { branches, value }
}

/**
 * Este componente muestra el gráfico con las distintas sucursales donde se muestra el avance del indicador
 */

const Logs = ({ indicator, organization, subindicator }) => {
  // console.log('log en props', log)
  // console.log('indicator en LogIndicator', indicator)
  // console.log(indicator);
  // console.log(subindicator);
  const classes = useStyles()

  const { user } = useSelector((state) => state.user)
  const { countries: countriesDB } = useSelector((state) => state.resources)

  //   Obtención de instalaciones
  const [branches] = useState(indicator.branches)
  const [lines] = useState(indicator.lines)

  const [menuAnchor, setMenuAnchor] = useState(null)
  const openMenu = (event) => setMenuAnchor(event.currentTarget)
  const closeMenu = (event) => setMenuAnchor(null)
  const isOpen = Boolean(menuAnchor)

  return (
    <div className={classes.root}>
      <Grid container direction='column'>
        <Grid item>
          <IndicatorCharts indicator={indicator} />
        </Grid>
        <Grid item></Grid>
        {indicator.subc == null || indicator.subc.length < 1 ? (
        <Grid container direction='column' spacing={2}>
          <Can
            perform='indicatorLogs:manage'
            data={{
              managerId: indicator.managerId,
              userId: user.id
            }}
            yes={() => (
              <Fab
                size='small'
                variant='extended'
                onClick={openMenu}
                className={classes.menuButton}
              >
                <AddIcon />
                Añadir registro
              </Fab>
            )}
          />
          <Popover
            open={isOpen}
            anchorEl={menuAnchor}
            onClose={closeMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <Grid item className={classes.menuItem}>
              <LogIndicator indicator={indicator} close={closeMenu} />
            </Grid>
          </Popover>
        </Grid>
        ): null}
      </Grid>
    </div>
  )
}

export default Logs
