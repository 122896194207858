import { tourConstants } from '../types'

const TOUR_STEPS = [
  {
    target: '.tour_feed',
    content:
      'En este espacio puedes interactuar con otros usuarios de Tero, compartir experiencias y recibir feedback.',
    disableBeacon: true
  },
  {
    target: '.tour_organization',
    content:
      'En esta sección podrás ver y editar el perfil de tu organización, ordenarla por áreas e invitar a tu equipo a desarrollar una organización más sostenible'
  },
  {
    target: '.tour_materialities',
    content:
      'El análisis de Materialidad es el punto de partida en el camino de una estrategia de sostenibilidad. Permite identificar los focos más relevantes, los mayores riesgos y las oportunidades más relevantes en términos ambientales, sociales y económicos, es decir, definir cuáles son tus temas materiales, considerando la visión del negocio y también la de tus públicos de interés.'
  },
  {
    target: '.tour_initiatives',
    content:
      'En esta sección podrás registrar y monitorear el avance de los proyectos e iniciativas de sostenibilidad de la organización. Puedes hacer seguimiento y reportar avances.'
  },
  {
    target: '.tour_indicators',
    content:
      'En esta sección podrás crear y gestionar indicadores de sostenibilidad para medir y respaldar las iniciativas de tu organización y su impacto a largo plazo.'
  },
  {
    target: '.tour_reports',
    content: 'En esta sección puedes gestionar reportes globales o modulares'
  }
]

const INITIAL_STATE = {
  debug: true,
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
  styles: {
    options: {
      arrowColor: '#fff',
      backgroundColor: '#fff',
      overlayColor: 'rgba(0, 0, 0, 0.4)',
      primaryColor: '#35647e',
      textColor: '#003a52',
      width: 400,
      zIndex: 3200
    }
  }
}
export const tourReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // start the tour
    case tourConstants.TOUR_START:
      return { ...state, run: true, stepIndex: 0 }
    // Reset to 0th step
    case tourConstants.TOUR_RESET:
      return { ...state, stepIndex: 0 }
    // Stop the tour
    case tourConstants.TOUR_STOP:
      return { ...state, run: false }
    // Update the steps for next / back button click
    case tourConstants.TOUR_NEXT_OR_PREV:
      return { ...state, ...action.payload }
    // Restart the tour - reset go to 1st step, restart create new tour
    case tourConstants.TOUR_RESTART:
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date()
      }
    default:
      return state
  }
}

export default tourReducer
