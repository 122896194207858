import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { InputLabel, FormControl, Select, Button } from '@material-ui/core'
import MouseIcon from '@material-ui/icons/Mouse'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Bar, Line, Chart, Bubble } from 'react-chartjs-2'
import BarChartMemo from './BarChartMemo'
import BarChartCorpMemo from './BarChartCorpMemo'
import NotCumulativeChart from './NotCumulativeChart'
import MonthlyCombinedChart from './MonthlyCombinedChart'
import ExtendedChart from './ExtendedChart'
import 'chartjs-adapter-date-fns'
import zoomPlugin from 'chartjs-plugin-zoom'
Chart.register(zoomPlugin)
// import { es } from 'date-fns/locale'
import { createMotherDataset } from '../../helpers/logs-calculations4'
import {
  genMonthlyLineData,
  generateYearlyBarData,
  genSemestralBarData,
  genTrimestralBarData,
  genTotalBarData,
  genWeeklyData,
  genCorpAccData,
  genCorpSemestralData,
  genCorpMonthlyData,
  genCorpTrimestralData,
  genCorpWeeklyData,
  genCorpDailyData,
  genBubbleChart
} from '../../helpers/datasets-generator'
import {
  lineMonthlyOptions,
  barYearlyOptions,
  barSemestralOptions,
  barOptions,
  lineWeeklyOptions,
  lineDailyOptions,
  barAccumOptions
} from '../../helpers/charts-options'
import { graphViewsActions } from '../../state/actions/graphViews.actions'
import GraphViewList from './GraphViewList'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1)
    // minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  selectWrapper: {
    width: '20%'
  },
  wrapper: {
    // height: '100vh',
    // backgroundColor: 'red'
  },
  formControlWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: '#fff'
    // boxShadow: '-5px 6px 96px -18px rgba(0,0,0,0.56);'
  },
  graphicWrapper: {
    margin: '1.5%',
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: theme.shadows[15]
    // boxShadow: '-5px 6px 96px -18px rgba(0,0,0,0.56);'
  },
  mouseActions: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  tooltipContent: {
    // fontFamily: 'Roboto',
    // backgroundColor: 'rgba(0,0,0,0.5)',
    background:
      'linear-gradient(to bottom right, rgba(65, 178, 197, 0.5) 0%, rgba(36, 86, 119, 0.5) 100%)',
    color: theme.palette.common.white,
    borderRadius: 9,
    maxWidth: 500,
    height: '40%',
    // border: '2px solid #000',
    border: 0,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  button: {
    backgroundColor: '#26cad3',
    color: '#fff'
  },
  graphicViewsWrapper: {
    display: 'flex',
    minHeight: 50,
    flexFlow: 'row wrap'
  }
}))

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: '0.8em',
    background:
      'linear-gradient(to bottom right, rgba(65, 178, 197, 0.5) 0%, rgba(36, 86, 119, 0.9) 100%)',
    // color: theme.palette.grey['700'],
    color: theme.palette.common.white,
    borderRadius: 9,
    maxWidth: 200,
    border: 0,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 3, 1)
  }
}))(Tooltip)

const IndicatorCharts = (props) => {
  console.log(props)
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(graphViewsActions.fetch(indicator.id))
  }, [indicator])

  const findThePeriod = (frequencyType) => {
    switch (frequencyType) {
      case 'year':
        return 'yearly'
      case 'week':
        return 'weekly'
      case 'month':
        return 'monthly'
      case 'year':
        return 'yearly'
      case 'week':
        return 'weekly'
      case 'day':
        return 'daily'
      default:
        return 'yearly'
    }
  }

  const { indicators } = useSelector((state) => state.indicators)
  const { indicator } = props
  // const { logs } = indicators.find((indi) => indi.id === indicator.id)
  const { logs } = props.indicator.logs
  console.log(logs)

  // opciones de alcance de acuerdo al tipo de indicador

  const availableReaches = (indicatorType) => {
    switch (indicatorType) {
      case 'AGGREGATE':
        return [
          <option value='AGGREGATE'>Por instalación</option>,
          <option value='BSNS'>Líneas de negocio</option>,
          <option value='GLOBAL'>Nivel Corporativo</option>
        ]
      case 'BSNS':
        return [
          <option value='BSNS'>Líneas de negocio</option>,
          <option value='GLOBAL'>Nivel Corporativo</option>
        ]
      case 'GLOBAL':
        return <option value='GLOBAL'>Nivel Corporativo</option>

      default:
        break
    }
  }

  // alcance de la visualización -- determinada por el alcance del indicador --- no es seleccionable
  const [reachVisualization, setReachVisualization] = useState(indicator.type)

  // período de la visualización --- determinada por la frecuencia de medición del indicador -- seleccionable
  const [periodVisualization, setPeriodVisualization] = useState(
    findThePeriod(indicator.frecuencyType)
  )

  const [motherDataset, setMotherDataset] = useState(
    createMotherDataset(indicator)
  )

  const [cumulative, setCumulative] = useState(
    indicator.cumulative === true ? true : false
  )
  const [cumulativeIsNull, setCumulativeIsNull] = useState(
    indicator.cumulative === null
  )

  const [isPercentage, setIsPercentage] = useState(
    indicator.measure === '%' ? true : false
  )

  const [typeOfGraphic, setTypeOfGraphic] = useState(1)

  const checkTheCumulative = (cumulative, cumulativeIsNull, isPercentage) => {
    // fallback de null para aquellos indicadores creados previamente
    // cuyo campo cumulative es asignado a null con la migración del nuevo campo cumulative
    // en este caso, si el usuario decide editar ¿es acumulativo? a No, se cargan los gráficos no acumulativos
    if (isPercentage) {
      return false
    }
    if (cumulative || cumulativeIsNull) {
      return true
    }
    if (!cumulative) {
      return false
    }
  }
  // función que chequea si el alcance seleccionado no es el alcance "nativo" del indicador (indicator.type)
  const isItExpandedReach = (indi, reach) => {
    return indi.type !== reach
  }

  // Función para guardar vista del indicador+características del gráfico

  const saveView = (indiId, rVisual, pVisual) => {
    dispatch(graphViewsActions.create(indiId, rVisual, pVisual))
  }

  useEffect(() => {
    setMotherDataset(createMotherDataset(indicator))
  }, [typeOfGraphic, logs])

  useEffect(() => {
    // si el alcance es el nativo al del indicador
    if (!isItExpandedReach(indicator, reachVisualization)) {
      if (
        (reachVisualization === 'AGGREGATE' || reachVisualization === 'BSNS') &&
        checkTheCumulative(cumulative, cumulativeIsNull, isPercentage)
      ) {
        if (periodVisualization === 'accumulated') {
          setTypeOfGraphic(8)
        }
        if (periodVisualization === 'yearly') {
          setTypeOfGraphic(1)
        }
        if (periodVisualization === 'semestral') {
          setTypeOfGraphic(2)
        }
        if (periodVisualization === 'trimestral') {
          setTypeOfGraphic(3)
        }
        if (periodVisualization === 'monthly') {
          setTypeOfGraphic(4)
        }
        if (periodVisualization === 'weekly') {
          setTypeOfGraphic(5)
        }
        if (periodVisualization === 'daily') {
          setTypeOfGraphic(6)
        }
      }
      if (
        (reachVisualization === 'AGGREGATE' || reachVisualization === 'BSNS') &&
        !checkTheCumulative(cumulative, cumulativeIsNull, isPercentage)
      ) {
        setTypeOfGraphic(16)
      }
      if (
        reachVisualization === 'GLOBAL' &&
        checkTheCumulative(cumulative, cumulativeIsNull, isPercentage)
      ) {
        if (periodVisualization === 'accumulated') {
          setTypeOfGraphic(9)
        }
        if (periodVisualization === 'yearly') {
          setTypeOfGraphic(10)
        }
        if (periodVisualization === 'semestral') {
          setTypeOfGraphic(11)
        }
        if (periodVisualization === 'trimestral') {
          setTypeOfGraphic(12)
        }
        if (periodVisualization === 'monthly') {
          setTypeOfGraphic(13)
        }
        if (periodVisualization === 'weekly') {
          setTypeOfGraphic(14)
        }
        if (periodVisualization === 'daily') {
          setTypeOfGraphic(15)
        }
      }
      if (
        reachVisualization === 'GLOBAL' &&
        !checkTheCumulative(cumulative, cumulativeIsNull, isPercentage)
      ) {
        setTypeOfGraphic(16)
      }
    } else {
      setTypeOfGraphic(17)
    }
  }, [reachVisualization, periodVisualization])

  const renderGraphic = () => {
    switch (typeOfGraphic) {
      // barras | instalaciones => anual
      case 1:
        return <BarChartMemo indicator={indicator} />
      // barras | instalaciones => semestral
      case 2:
        return (
          <Bar
            data={genSemestralBarData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )}
            width={300}
            height={500}
            options={barSemestralOptions(motherDataset.measureUnit)}
          />
        )
      // barras | instalaciones => trimestral
      case 3:
        return (
          <Bar
            data={genTrimestralBarData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )}
            width={300}
            height={500}
            options={barSemestralOptions(motherDataset.measureUnit)}
          />
        )
      // lineas | instalaciones => mensual
      // probando acá gráficos combinados
      case 4:
        return (
          // <Line
          //   data={genMonthlyLineData(
          //     motherDataset,
          //     motherDataset.measureUnit,
          //     motherDataset.cumulativeness
          //   )}
          //   width={300}
          //   height={500}
          //   options={lineMonthlyOptions(motherDataset.measureUnit)}
          // />
          <MonthlyCombinedChart
            reach={reachVisualization}
            period={periodVisualization}
            data={genMonthlyLineData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )}
            options={lineMonthlyOptions(motherDataset.measureUnit)}
          />
        )

      // lineas | instalaciones => semanal
      case 5:
        return (
          <Line
            data={genWeeklyData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )}
            width={300}
            height={500}
            options={lineWeeklyOptions(motherDataset.measureUnit)}
          />
        )
      // lineas | instalaciones => diario
      case 6:
        return (
          <Line
            data={genWeeklyData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )}
            width={300}
            height={500}
            options={lineDailyOptions(motherDataset.measureUnit)}
          />
        )

      // lineas | instalaciones => acumulado
      case 8:
        return (
          <Bar
            data={genTotalBarData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )}
            width={300}
            height={500}
            options={barAccumOptions(motherDataset.measureUnit)}
          />
        )
      // barras | corporativo => acumulado
      case 9:
        return (
          <Bar
            data={genCorpAccData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )}
            width={300}
            height={500}
            options={barAccumOptions(motherDataset.measureUnit)}
          />
        )
      // barras | corporativo => anual 10
      case 10:
        return <BarChartCorpMemo indicator={indicator} />
      // barras | corporativo => semestral 11
      case 11:
        return (
          <Bar
            data={genCorpSemestralData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )}
            width={300}
            height={500}
            options={barSemestralOptions(motherDataset.measureUnit)}
          />
        )
      // barras | corporativo => trimestral 12
      case 12:
        return (
          <Bar
            data={genCorpTrimestralData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )}
            width={300}
            height={500}
            options={barSemestralOptions(motherDataset.measureUnit)}
          />
        )
      // barras | corporativo => mensual 13
      case 13:
        return (
          <Line
            data={genCorpMonthlyData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )}
            width={300}
            height={500}
            options={lineMonthlyOptions(motherDataset.measureUnit)}
          />
        )
      // barras | corporativo => semanal 14
      case 14:
        return (
          <Line
            data={genCorpWeeklyData(
              motherDataset,
              motherDataset.measureUnit,
              motherDataset.cumulativeness
            )}
            width={300}
            height={500}
            options={lineWeeklyOptions(motherDataset.measureUnit)}
          />
        )
      // barras | corporativo => diario 15
      case 15:
        return (
          <Line
            data={genCorpDailyData(motherDataset)}
            width={300}
            height={500}
            options={lineDailyOptions(motherDataset.measureUnit)}
          />
        )
      // no acumulativo
      case 16:
        return (
          <NotCumulativeChart
            key={Math.random()}
            indicator={indicator}
            reach={reachVisualization}
            period={periodVisualization}
            // tOfG='bubble'
            tOfG='line'
            // tOfG={periodVisualization === 'accumulated' ? 'sweet' : 'line'}
          />
        )
      case 17:
        return (
          <ExtendedChart
            key={Math.random()}
            indicator={indicator}
            reach={reachVisualization}
            period={periodVisualization}
          />
        )
      default:
        return
    }
  }

  //Selección del alcance
  const handleReachVisualization = (event) => {
    setReachVisualization(event.target.value)
  }

  // Selección del período
  const handlePeriodVisualization = (event) => {
    setPeriodVisualization(event.target.value)
  }

  return (
    <div className={classes.wrapper}>
      {/* Selection container */}

      <div className={classes.formWrapper}>
        <div className={classes.formControlWrapper}>
          <FormControl className={classes.selectWrapper}>
            <InputLabel htmlFor='alcancekind-native-simple'>Alcance</InputLabel>
            <Select
              native
              value={reachVisualization}
              onChange={handleReachVisualization}
              inputProps={{
                name: 'alcancekind',
                id: 'alcancekind-native-simple'
              }}
            >
              <option aria-label='None' value='' />
              {availableReaches(indicator.type)}
            </Select>
          </FormControl>

          <FormControl className={classes.selectWrapper}>
            <InputLabel htmlFor='periodkind-native-simple'>Período</InputLabel>
            <Select
              native
              value={periodVisualization}
              onChange={handlePeriodVisualization}
              inputProps={{
                name: 'periodkind',
                id: 'periodkind-native-simple'
              }}
            >
              <option aria-label='None' value='' />
              {/* <option value='accumulated'>Total</option> */}
              <option value='yearly'>Anual</option>
              <option value='semestral'>Semestral</option>
              <option value='trimestral'>Trimestral</option>
              <option value='monthly'>Mensual</option>
              <option value='weekly'>Semanal</option>
              {/* <option
                value='weekly'
                disabled={indicator.frecuencyType !== 'week'}
              >
                Semanal
              </option> */}
              <option value='daily'>Diario</option>
              {/* <option
                value='daily'
                disabled={indicator.frecuencyType !== 'day'}
              >
                Diaria
              </option> */}
            </Select>
          </FormControl>
          <HtmlTooltip
            title={
              <div>
                Puedes guardar esta vista del gráfico para usarla en el reporte
                de iniciativas.{' '}
              </div>
            }
          >
            <Button
              onClick={() =>
                saveView(indicator.id, reachVisualization, periodVisualization)
              }
              type='submit'
              variant='contained'
              className={classes.button}
            >
              Guardar vista
            </Button>
          </HtmlTooltip>
          <div className={classes.mouseActions}>
            <HtmlTooltip
              title={
                <div>
                  Acerca y aleja el gráfico con la rueda del mouse, desplaza
                  horizontalmente haciendo click al arrastrar sobre la imagen
                </div>
              }
            >
              <MouseIcon color='secondary' />
            </HtmlTooltip>
          </div>
        </div>
      </div>
      <div className={classes.graphicWrapper}>{renderGraphic()}</div>
      <GraphViewList indicatorId={indicator.id} />
    </div>
  )
}

export default React.memo(IndicatorCharts)
// export default IndicatorCharts
