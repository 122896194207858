import { validate } from '../components/UI/validations'
import { formatter } from './format'
//
// Comprueba si todos los campos de un formulario son válidos o no
export const validForm = (formData) => {
  let isValid = true
  for (let key in formData) {
    isValid = formData[key].valid && isValid
  }
  return isValid
}

// Actualiza el valor del formulario
export const updateFormData = (formData, target, value) => {
  const newFormData = {
    ...formData
  }
  const newElement = {
    ...formData[target]
  }
  if (newElement.formatter) {
    newElement.value = formatter(newElement, value)
  } else {
    newElement.value = value
  }

  let validData = validate(newElement)

  // if (target === "confirmPassword" && target !== "password") {
  if (
    target === 'confirmPassword' ||
    (target === 'password' && formData.confirmPassword)
  ) {
    let setValidPasswords
    if (comparePasswords(target, value, formData)) {
      newElement.valid = true
      newElement.validationMessage = ''
      newElement.hasError = false
      if (target === 'password') {
        setValidPasswords = {
          ...formData['confirmPassword']
        }
        setValidPasswords.valid = true
        setValidPasswords.validationMessage = ''
        setValidPasswords.hasError = false
        newFormData['confirmPassword'] = setValidPasswords
      } else {
        setValidPasswords = {
          ...formData['password']
        }
        setValidPasswords.valid = true
        setValidPasswords.validationMessage = ''
        setValidPasswords.hasError = false
        newFormData['password'] = setValidPasswords
      }
    } else {
      if (target === 'confirmPassword') {
        newElement.valid = false
        newElement.validationMessage = 'Las contraseñas deben coincidir'
        newElement.hasError = true
      } else {
        const newConfirmPassword = {
          ...formData.confirmPassword
        }
        newConfirmPassword.valid = false
        newConfirmPassword.validationMessage = 'Las contraseñas deben coincidir'
        newConfirmPassword.hasError = true
        newFormData.confirmPassword = newConfirmPassword
      }
    }
    // }
  } else {
    newElement.valid = validData[0]
    newElement.validationMessage = validData[1]
    newElement.hasError = !validData[0]
  }

  // newElement.valid = validData[0];
  // newElement.validationMessage = validData[1];
  // newElement.hasError = !validData[0];

  newFormData[target] = newElement

  return newFormData
}

// Genera la data a guardar en redux en base al formData
// Lo que guarda es un par key:value
export const generateReduxData = (formData) => {
  let data = {}
  Object.keys(formData).forEach((key, index) => {
    data[key] = formData[key].value
  })
  return data
}

export const updateSubperspectivesOptions = (
  formData,
  subperspectives,
  subperspective,
  perspectiveId
) => {
  const newFormData = {
    ...formData
  }
  const newElement = {
    ...formData.subperspectiveId
  }
  newElement.options = subperspectives
  newElement.value = subperspective
    ? subperspective.perspective.id === perspectiveId &&
      formData.subperspectiveId.value === subperspective.id
      ? subperspective.id
      : ''
    : formData.subperspectiveId.value

  let validData = validate(newElement)
  newElement.valid = validData[0]
  newElement.validationMessage = validData[1]
  newElement.hasError = !validData[0]
  newFormData.subperspectiveId = newElement

  // console.log(newFormData);

  return newFormData
}

export const updateGRIContentOptions = (
  formData,
  specificContents,
  content
) => {
  const newFormData = {
    ...formData
  }
  const newElement = {
    ...formData.contentId
  }
  newElement.options = specificContents
  newElement.value = content
    ? content.subperspectiveId === formData.subperspectiveId.value &&
      formData.contentId.value === content.id
      ? content.id
      : ''
    : formData.contentId.value
  let validData = validate(newElement)
  newElement.valid = validData[0]
  newElement.validationMessage = validData[1]
  newElement.hasError = !validData[0]
  newFormData.contentId = newElement
  return newFormData
}

export const comparePasswords = (target, value, formData) => {
  if (target === 'password' && formData.confirmPassword.value === value) {
    return true
  } else if (
    target === 'confirmPassword' &&
    formData.password.value === value
  ) {
    return true
  }
  return false
}
