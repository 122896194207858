import React, { Fragment } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275
  },
  title: {
    fontSize: 14
  },
  textTail: {
    fontSize: 14,
    color: theme.palette.text.secondary
  },
  pos: {
    marginBottom: 12
  },
  nativeOverride: {
    root: {
      '& label.Mui-focused': {
        color: 'white'
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'yellow'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white'
        },
        '&:hover fieldset': {
          borderColor: 'white'
        },
        '&.Mui-focused fieldset': {
          borderColor: 'yellow'
        }
      }
    }
  }
  // notchedOutline: {
  //   borderBottom: '3px solid green',
  //   borderColor: 'yellow !important'
  // }
}))

const SelectNative = ({ formData, change, inputProps }) => {
  const classes = useStyles()
  const showError = () => {
    const hasError = formData.validation && !formData.valid
    // if (hasError) {
    //   fieldError = true
    // } else {
    //   fieldError = false
    // }
    let errorMessage = (
      <div className='error_label'>
        {hasError ? formData.validationMessage : null}
      </div>
    )
    return errorMessage
  }

  const renderTemplate = () => {
    let formTemplate = null

    const options = formData.options
    // override one of the following:
    // root,colorSecondary,focused,disabled,
    // adornedStart,adornedEnd,error,marginDense,
    // multiline,notchedOutline,input,inputMarginDense,
    // inputMultiline,inputAdornedStart,inputAdornedEnd.
    formTemplate = (
      <Fragment>
        <TextField
          {...formData.config}
          variant='standard'
          InputProps={{
            classes: {
              formControl: classes.notchedOutline
            }
          }}
          value={formData.value}
          onChange={change()}
          error={formData.hasError}
          // inputProps={inputProps || formData.config.inputProps}
        >
          {options &&
            options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={formData.config.disabled}
              >
                {option.label}
              </MenuItem>
            ))}
        </TextField>

        {formData.hasError ? showError() : null}
      </Fragment>
    )

    return formTemplate
  }

  return <Fragment>{renderTemplate()}</Fragment>
}

export default SelectNative
