import rules from './rules'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

/**
 * Función que checkea si el usuario cumple los permisos o no
 */

const check = (rules, roles, action, data) => {
  let allow = false
  roles.some((role) => {
    const permissions = rules[role]

    // Esto pasa cuando no se encuentra el rol
    if (!permissions) {
      return false
    }

    const staticPermissions = permissions.static

    // Si encuentra el permiso
    if (staticPermissions && staticPermissions.includes(action)) {
      allow = true
      return true
    }

    // Si no lo busca dentro de los permisos dinámicos
    const dynamicPermissions = permissions.dynamic

    if (dynamicPermissions) {
      const permissionCondition = dynamicPermissions[action]

      // Si no encuentra el permiso dinámico
      if (!permissionCondition) {
        return false
      }

      // Retorna la evaluación de si cumple o no los permisos
      allow = permissionCondition(data)
      console.log(action, allow)
      return allow
    }

    return false
  })

  return allow
}

const Can = ({ perform, data, yes, no }) => {
  const { user } = useSelector((state) => state.user)
  let rolesObject = {
    roleAdmin: user.roleAdmin,
    roleLeader: user.roleLeader,
    roleUser: user.roleUser,
    roleManageIndicators: user.roleManageIndicators,
    roleManageInitiatives: user.roleManageInitiatives
  }
  const roles = Object.keys(rolesObject).filter((key) => rolesObject[key])
  return check(rules, roles, perform, data) ? yes() : no()
}

Can.defaultProps = {
  perform: '',
  data: null,
  yes: () => null,
  no: () => null
}

Can.propTypes = {
  /**
   * {String} - Indica la acción a realizar.
   */
  perform: PropTypes.string.isRequired,
  /**
   * {object} - Se usa en el caso de validaciones dinámicas.
   */
  data: PropTypes.object,
  /**
   * {element} - Acción a realizar en caso de tener los permisos.
   */
  yes: PropTypes.func,
  /**
   * {element} - Acción a realizar en caso de no tener los permisos..
   */
  no: PropTypes.func
}

export default Can
