import { userConstants } from '../types'

const editUserDialog = {
  open: false,
  valid: false,
  error: null,
  loading: false,
  data: {
    name: '',
    lastName: '',
    email: '',
    profilePicture: '',
    password: '',
    confirmPassword: '',
    jobTitle: ''
  }
}

const initialState = {
  loading: false,
  error: null,
  // user: token ? jwt.decode(token).user : null
  user: {
    id: null,
    companyId: null
  },
  editUserDialog: editUserDialog
}

export default function user(state = initialState, action) {
  switch (action.type) {
    /*--- SET initial state ---*/
    case userConstants.SET_INITIAL_STATE:
      return initialState
    /*--- SET initial state ---*/

    /*--- SETEAR USER DESPUÉS DE LOGIN ---*/
    case userConstants.SET_USER:
      return {
        ...state,
        user: action.payload
      }
    /*--- SETEAR USER DESPUÉS DE LOGIN ---*/

    /*--- UPDATE ---*/
    case userConstants.UPDATE_STARTED:
      return {
        ...state,
        editUserDialog: {
          ...state.editUserDialog,
          loading: true,
          error: null
        }
      }
    case userConstants.UPDATE_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        editUserDialog: editUserDialog
      }
    case userConstants.UPDATE_FAILURE:
      return {
        ...state,
        editUserDialog: {
          ...state.editUserDialog,
          loading: false,
          error: action.payload.error
        }
      }
    /*--- UPDATE ---*/

    /* --- UPDATE DIALOG ---*/
    case userConstants.DIALOG.OPEN:
      return {
        ...state,
        editUserDialog: {
          ...state.editUserDialog,
          open: true,
          data: action.payload.user
        }
      }
    case userConstants.DIALOG.CLOSE:
      return {
        ...state,
        editUserDialog: editUserDialog
      }
    /* --- UPDATE DIALOG ---*/

    /*--- GET ME ---*/
    case userConstants.GET_ME_STARTED:
      return { ...state, loading: true, error: null }
    case userConstants.GET_ME_SUCCESS:
      return { ...state, loading: false, error: null, user: action.payload }
    case userConstants.GET_ME_FAILURE:
      return { ...state, loading: false, error: action.payload }
    /*--- GET ME ---*/

    default:
      return state
  }
}
