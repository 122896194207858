import {
  resourcesConstants
} from '../types'

const initialState = {
  loading: false,
  error: null,
  ods: [],
  perspectives: [],
  griContents: [],
  sectors: [],
  countries: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    /*--- SET initial state ---*/
    case resourcesConstants.SET_INITIAL_STATE:
      return initialState
      /*--- SET initial state ---*/

      /*--- Fetch ODS ---*/
    case resourcesConstants.ODS_FETCH_STARTED:
      return {
        ...state,
        loading: true,
          error: null
      }
      case resourcesConstants.ODS_FETCH_SUCCESS:
        return {
          ...state,
          loading: false,
            error: null,
            ods: action.payload
        }
        case resourcesConstants.ODS_FETCH_FAILURE:
          return {
            ...state,
            loading: false,
              error: action.payload
          }
          /*--- Fetch ODS ---*/

          /*--- Fetch perspectives ---*/
          case resourcesConstants.PERSPECTIVE_FETCH_STARTED:
            return {
              ...state,
              loading: true,
                error: null
            }
            case resourcesConstants.PERSPECTIVE_FETCH_SUCCESS:
              action.payload.forEach((perspective) => {
                perspective['label'] = perspective.name
                perspective['value'] = perspective.id
                perspective.subperspectives.forEach((subperspective) => {
                  subperspective['label'] = subperspective.name
                  subperspective['value'] = subperspective.id
                })
              })
              return {
                ...state,
                loading: false,
                  error: null,
                  perspectives: action.payload
              }
              case resourcesConstants.PERSPECTIVE_FETCH_FAILURE:
                return {
                  ...state,
                  loading: false,
                    error: action.payload
                }
                /*--- Fetch perspectives ---*/


                /*--- Fetch GRI contents start ---*/
                case resourcesConstants.GRI_CONTENTS_STARTED:
                  return {
                    ...state,
                    loading: true,
                      error: null
                  }
                  case resourcesConstants.GRI_CONTENTS_SUCCESS:
                    action.payload.forEach((content) => {
                      content['label'] = content.name
                      content['value'] = content.id
                    })
                    return {
                      ...state,
                      loading: false,
                        error: null,
                        griContents: action.payload
                    }
                    case resourcesConstants.GRI_CONTENTS_FAILED:
                      return {
                        ...state,
                        loading: false,
                          error: action.payload
                      }
                      /*--- Fetch GRI contents end ---*/


                      /*--- Fetch sectors ---*/
                      case resourcesConstants.SECTOR_FETCH_STARTED:
                        return {
                          ...state,
                          loading: true,
                            error: null
                        }
                        case resourcesConstants.SECTOR_FETCH_SUCCESS:
                          action.payload.forEach((sector) => {
                            sector['label'] = sector.name
                            sector['value'] = sector.id
                            sector.industryCategories.forEach((category) => {
                              category['label'] = category.name
                              category['value'] = category.id
                              category.industries.forEach((industry) => {
                                industry['label'] = industry.name
                                industry['value'] = industry.id
                              })
                            })
                          })
                          return {
                            ...state,
                            loading: false,
                              error: null,
                              sectors: action.payload
                          }
                          case resourcesConstants.SECTOR_FETCH_FAILURE:
                            return {
                              ...state,
                              loading: false,
                                error: action.payload
                            }

                            case resourcesConstants.COUNTRIES_FETCH:
                              action.payload.forEach((country) => {
                                country['label'] = country.name
                                country['value'] = country.name
                                country.regions.forEach((region) => {
                                  region['label'] = region.name
                                  region['value'] = region.name
                                })
                              })
                              return {
                                ...state,
                                countries: action.payload
                              }

                              default:
                                return state
  }
}