import React, { useEffect, useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import NoContent from '../components/UI/NoContent'
import { Typography, Fab, Container,Button,Grid } from '@material-ui/core'
import Progress from '../components/UI/Progress'
import Can from '../auth/Can'
import { Add as AddIcon } from '@material-ui/icons'
import { Remove as RemoveIcon } from '@material-ui/icons'
import ShowReports from '../components/Reports/ShowReports'
import Dialog from '../components/UI/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { reportActions } from '../state/actions'
import GlobalReportsDialogTabs from '../components/Reports/GlobalReportsDialogTabs'
import { reportConstants } from '../state/types'
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { FormField } from 'boosted-materialui-forms'
import { Link } from 'react-router-dom'
import TextField from "@material-ui/core/TextField";
import Axios from '../axios';
import Paso5 from './Paso5'
import { TableContainer } from '@mui/material';
import CustomDialog from '../components/UI/Dialog'
// /Users/anibalurrea/Desktop/Inosoft/Tero/tero-frontend-master/src/components/UI/Dialog 
  
    

const Paso4 = () => {

  const columns = [
    //Por cada {} es una columna donde name es el titulo del header
    //Y options sirve para crear filtros personalizados etc.
    {
      name: "Temática",
      options: {}
    },
    {
        name: "Acción",
        // AQUI DEBE HABER UN ESTADO SI TIENE INFORMACION GUARDADA O NO, PARA SABER QUE ICONO MOSTRAR
        options: {customBodyRender: (value,tableMeta,updateValue) => {
          // console.log(value);
          return <div>
            <button onClick={() => goIniciativa(value)}>
              {/* {selectedIndex == value.id ? <RemoveIcon></RemoveIcon> : <AddIcon></AddIcon>} */}
              <AddIcon></AddIcon>
            </button>
            </div>
        }}
        // options: {}
    },
    
  ]; 

  const [noMatchData, setnoMatchData] = useState('Cargando ...');

  const data = [
    ["Adaptación al teletrabajo", ""],
    ["Bienestar", ""],
    ["Ahorro energético", ""],
    ["Carbono neutral 2050", ""],
    ];

    const options = {
      search: "enabled",
      searchOpen: true,
      filter: false,
      viewColumns: false,   
      selectableRows: 'none',
      responsive: "standard",
      selectToolbarPlacement: "none",
      print: false,
      download: false,
      textLabels: {
        pagination: {
          next: "Siguiente",
          previous: "Anterior",
          rowsPerPage: "Filas por página:",
          displayRows: "de" // 1-10 of 30
        },
        body: {
          noMatch: noMatchData,
        }      
      }
    };

    const report = JSON.parse(sessionStorage.getItem('report'))
    const [openDoc, setOpenDoc] = useState(false)

    const [reportIndicators, setReportIndicators] = useState([]);

    const [data1, setData] = useState([]);

    const [dataIni, setDataIni] = useState([]);

    const [selectedIndex, setSelectedIndex] = useState()

    useEffect(() => {
        document.title = 'Reportes - Paso 3'
        // console.log(report);
        getData();
        // if(report.initiatives.length < 1){          
          

        // } else {

        //   report.initiatives.map((us, i) => {
        //           // console.log(us)
  
        //           setData(arr => [...arr,[us.name,i
        //             // <div>
        //             //     <button onClick={() => goIniciativa(us, i)}>
        //             //       {/* <RemoveIcon></RemoveIcon> */}
        //             //       {selectedIndex == i ? <RemoveIcon></RemoveIcon> : <AddIcon></AddIcon>}
        //             //     </button>
        //             // </div>
        //             ]]) 

        //         })

        // }        

      }, [])

      const getData = () => {

       

        Axios.get(`initiatives/company/${report.companyId}/report/${report.id}`)
          .then((response) => {
              console.log(response.data.initiatives);
              report.initiatives = response.data.initiatives;
              sessionStorage.setItem('report', JSON.stringify(report))
              setData([])
              response.data.initiatives.map((us, i) => {
                // console.log(us)
                  if(us.reportInitiative.length < 1){
                    setData(arr => [...arr,[us.name,us.id]]) 
                  }                  
                  setnoMatchData('No se encontraron resultados');
              })

            })
            .catch((err) => {
              console.log(err.response);
              setnoMatchData('No se encontraron resultados');
            })

      }

      const goIniciativa = (id) => {    
        
        setnoMatchData('Actualizando ...');
        setData([])      

        const dataToSubmit = {
          report : {
            initiativeId : id,
            reportId : report.id
          }
        }

        Axios.post(`customreports/createReportInitiative/`, dataToSubmit)
          .then((response) => {
              console.log(response.data);
              getData();
            })
            .catch((err) => {
              console.log(err.response);
            })        


        // Axios.get(`initiatives/company/${report.companyId}/`)
        //   .then((response) => {
        //       console.log(response.data.initiatives);
        //       report.initiatives = response.data.initiatives;
        //       sessionStorage.setItem('report', JSON.stringify(report))
        //       response.data.initiatives.map((us, i) => {
        //         // console.log(us)

        //           setData(arr => [...arr,[us.name,us
        //                                 // <div>
        //                                 //     <button onClick={() => goIniciativa(us, i)}>
        //                                 //       {/* <RemoveIcon></RemoveIcon> */}                                              
        //                                 //       {selectedIndex == i ? <RemoveIcon></RemoveIcon> : <AddIcon></AddIcon>}
        //                                 //     </button>
        //                                 // </div>
        //                                 ]]) 

        //       })

        //     })
        //     .catch((err) => {
        //       console.log(err.response);
        //     })



        // setReportIndicators(a);
        // setOpenDoc(true);
      }

    let theme = createTheme();
    theme = responsiveFontSizes(theme);

    return (
        
        <Container>
            <br>
             </br>
                <center><h3>Paso 3 - Selección de iniciativas/temáticas</h3>
                <h4>Esta sección permite seleccionar las tematicas de tu reporte.</h4></center>
                <br>
                </br>
                {/* <div style={{display: 'inline-flex',flexDirection: 'row', alignItems: 'flex-end', width: '100%', justifyContent: 'flex-end', marginBottom: '10px'}}>
                    <Button variant='contained' style={{color: 'blue'}}>Agregar</Button>
                </div> */}
                <ThemeProvider theme={theme}>
                    <MUIDataTable
                        data={data1}
                        columns={columns}
                        options={options} />
                </ThemeProvider>
                <br>
                </br>

                {/* <CustomDialog
                  maxWidth='md'
                  // dialogtitle={`Documentación`}
                  // dialogcontent={<Documentacion/>}
                  dialogcontent={<Paso5 dataIndicators={reportIndicators}/>}
                  open={openDoc}                  
                  // close={() => setOpenDoc(!openDoc)}
                  onClose={() => setOpenDoc(!openDoc)}
                  /> */}

                 <CustomDialog
                  maxWidth='md'
                  // dialogtitle={`Nuevo registro para ${indicator.name}`}
                  dialogcontent={
                    <Paso5
                    dataIndicators={reportIndicators}
                      close={() => setOpenDoc(false)}
                    />
                  }
                  open={openDoc}
                  onClose={() => setOpenDoc(!openDoc)}
                />

        </Container>
        
        
        
    )
    
}

export default Paso4
