import { postConstants } from "../types/post.types";

const newPostDialog = {
  open: false,
  loading: false,
  error: null,
};

const commentsActions = {
  fetching: false,
  fetched: false,
  errorFetching: null,
  created: false,
  creating: false,
  errorCreating: null,
  deleting: false,
  errorDeleting: null,
  editing: false,
  edited: true,
  errorEditing: null,
};
const initialState = {
  loading: false,
  error: null,
  loadingFollow: false,
  posts: [],
  newPostDialog,
  commentsActions,
};

export default function (state = initialState, action) {
  switch (action.type) {
    /*--- DIALOG EN FEED ---*/
    case postConstants.NEW_POST_DIALOG.OPEN:
      return {
        ...state,
        newPostDialog: {
          ...state.newPostDialog,
          open: !state.newPostDialog.open,
          ...action.payload,
        },
      };
    case postConstants.NEW_POST_DIALOG.CLOSE:
      return { ...state, newPostDialog: newPostDialog };
    /*--- DIALOG EN FEED ---*/

    /*--- FETCH ---*/
    case postConstants.FETCH_STARTED:
      return { ...state, posts: [], loading: true };
    case postConstants.FETCH_FAILURE:
      return { ...state, error: action.payload, posts: [], loading: false };
    case postConstants.FETCH_SUCCESS:
      return { ...state, posts: action.payload, loading: false };
    /*--- FETCH ---*/

    /*--- FETCH ---*/
    case postConstants.FETCH_MORE_FAILURE:
      return { ...state, error: action.payload };
    case postConstants.FETCH_MORE_SUCCESS:
      const postsToAdd = action.payload.filter((post) => {
        if (!state.posts.find((currentPost) => currentPost.id === post.id)) {
          return post;
        }
        return false;
      });
      return { ...state, posts: [...state.posts, ...postsToAdd] };
    /*--- FETCH ---*/

    /*--- FETCH ONE ---*/
    case postConstants.FETCH_ONE_STARTED:
      return { ...state, loading: true, error: null, posts: [] };
    case postConstants.FETCH_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        posts: [action.payload], //TODO: Cambiarlo cuando esté la consulta para hacer fetch a todos los posts
      };
    case postConstants.FETCH_ONE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    /*--- FETCH ONE ---*/

    /* CREAR POST ---*/
    case postConstants.CREATE_STARTED:
      return {
        ...state,
        newPostDialog: { ...state.newPostDialog, loading: true },
      };
    case postConstants.CREATE_SUCCESS:
      return {
        ...state,
        posts: [
          { ...action.payload, commentCount: 0, likeCount: 0 },
          ...state.posts,
        ],
        newPostDialog: newPostDialog,
      };
    case postConstants.CREATE_FAILURE:
      return {
        ...state,
        newPostDialog: {
          ...state.newPostDialog,
          newPostDialog: {
            ...state.newPostDialog,
            loading: false,
            error: action.payload,
          },
        },
      };
    /* CREAR POST ---*/

    /* ACTUALIZAR POST ---*/
    case postConstants.UPDATE_STARTED:
      return { ...state };
    case postConstants.UPDATE_SUCCESS:
      return { ...state };
    case postConstants.UPDATE_FAILURE:
      return { ...state };
    /* ACTUALIZAR POST ---*/

    /* ELIMINAR POST ---*/
    case postConstants.DELETE_STARTED:
      return { ...state };
    case postConstants.DELETE_SUCCESS:
      return { ...state };
    case postConstants.DELETE_FAILURE:
      return { ...state };
    /* ELIMINAR POST ---*/

    /*--- CREAR COMENTARIO ---*/
    case postConstants.COMMENT_CREATE_STARTED:
      return {
        ...state,
        commentsActions: {
          ...state.commentsActions,
          created: false,
          creating: true,
          errorCreating: null,
        },
      };
    case postConstants.COMMENT_CREATE_SUCCESS:
      return {
        ...state,
        loadingNewComment: false,
        posts: state.posts.map((post) => {
          if (post.id !== action.payload.postId) {
            return post;
          }
          return {
            ...post,
            comments: [...post.comments, action.payload],
            commentCount: post.commentCount + 1,
          };
        }),
        commentsActions: {
          ...state.commentsActions,
          created: true,
          creating: false,
          errorCreating: null,
        },
      };
    case postConstants.COMMENT_CREATE_FAILURE:
      return {
        ...state,
        commentsActions: {
          ...state.commentsActions,
          created: false,
          creating: false,
          errorCreating: action.payload,
        },
      };
    /*--- CREAR COMENTARIO ---*/

    /*--- EDITAR COMENTARIO ---*/
    case postConstants.COMMENT_UPDATE_SUCCESS:
      return {
        ...state,
        posts: state.posts.map((post) => {
          if (post.id !== action.payload.postId) {
            return post;
          }
          return {
            ...post,
            comments: post.comments.map((comment) => {
              if (comment.id !== action.payload.id) {
                return comment;
              }
              return {
                ...comment,
                ...action.payload,
              };
            }),
          };
        }),
      };
    /*--- EDITAR COMENTARIO ---*/

    /*--- Eliminar comentario ---*/
    case postConstants.COMMENT_DELETE_STARTED:
      return {
        ...state,
        commentsActions: {
          ...state.commentsActions,
          deleting: true,
          errorDeleting: null,
        },
      };
    case postConstants.COMMENT_DELETE_SUCCESS:
      return {
        ...state,
        posts: state.posts.map((post) => {
          if (post.id !== action.payload.postId) {
            return post;
          }
          return {
            ...post,
            comments: post.comments.filter(
              (comment) => comment.id !== action.payload.commentId
            ),
            commentCount: post.commentCount - 1,
          };
        }),
        commentsActions: {
          ...state.commentsActions,
          deleting: false,
          errorDeleting: null,
        },
      };
    case postConstants.COMMENT_DELETE_FAILURE:
      return {
        ...state,
        commentsActions: {
          ...state.commentsActions,
          deleting: false,
          errorDeleting: action.payload,
        },
      };
    /*--- Eliminar comentario ---*/

    /*--- Actualizar comentario ---*/
    //TODO:
    /*--- Actualizar comentario ---*/

    /*--- Fetch comentarios ---*/
    case postConstants.COMMENT_FETCH_STARTED:
      return {
        ...state,
        commentsActions: {
          ...state.commentsActions,
          fetching: true,
          fetched: false,
          errorFetching: null,
        },
      };
    case postConstants.COMMENT_FETCH_SUCCESS:
      return {
        ...state,
        commentsActions: {
          ...state.commentsActions,
          fetching: false,
          fetched: true,
        },
        posts: state.posts.map((post) => {
          if (post.id !== action.payload.postId) {
            return post;
          }
          return {
            ...post,
            comments: [...post.comments, ...action.payload.comments.comments],
          };
        }),
      };
    case postConstants.COMMENT_FETCH_FAILURE:
      return {
        ...state,
        commentsActions: {
          ...state.commentsActions,
          fetching: false,
          errorFetching: action.payload.error,
        },
      };
    /*--- Fetch comentarios ---*/
    /*--- Likes ---*/
    case postConstants.ADD_LIKE_SUCCESS:
      return {
        ...state,
        posts: state.posts.map((post) => {
          if (post.id !== parseInt(action.payload.postId)) {
            return post;
          }
          return {
            ...post,
            alreadyLiked: true,
            likeCount: post.likeCount + 1,
            likes: [...post.likes, action.payload],
          };
        }),
      };
    case postConstants.ADD_LIKE_FAILURE:
      return { ...state }; //TODO:
    case postConstants.REMOVE_LIKE_SUCCESS:
      return {
        ...state,
        posts: state.posts.map((post) => {
          if (post.id !== action.payload.postId) {
            return post;
          }
          return {
            ...post,
            alreadyLiked: false,
            likes: post.likes.filter(
              (like) => like.user.id !== action.payload.userId
            ),
            likeCount: post.likeCount - 1,
          };
        }),
      };
    case postConstants.REMOVE_LIKE_FAILURE:
      return { ...state }; //TODO:
    /*--- Likes ---*/
    /*--- Follow Organization ---*/
    case postConstants.PROFILE_FOLLOW_STARTED:
      return {
        ...state,
        loadingFollow: true,
      };
    case postConstants.PROFILE_FOLLOW_SUCCESS:
      return {
        ...state,
        loadingFollow: false,
        posts: state.posts.map((post) => {
          if (post.companyId !== action.payload.companyId) {
            return post;
          }
          return {
            ...post,
            user: {
              ...post.user,
              company: {
                ...post.user.company,
                alreadyFollow: true,
              },
            },
          };
        }),
      };
    case postConstants.PROFILE_FOLLOW_FAILURE:
      return {
        ...state,
        loadingFollow: true,
      };
    /*--- Follow Organization ---*/
    /*--- UnFollow Organization ---*/
    case postConstants.PROFILE_UNFOLLOW_STARTED:
      return {
        ...state,
        loadingFollow: true,
      };
    case postConstants.PROFILE_UNFOLLOW_SUCCESS:
      return {
        ...state,
        loadingFollow: false,
        posts: state.posts.map((post) => {
          if (post.companyId !== action.payload.organizationId) {
            return post;
          }
          return {
            ...post,
            user: {
              ...post.user,
              company: {
                ...post.user.company,
                alreadyFollow: false,
              },
            },
          };
        }),
      };
    case postConstants.PROFILE_UNFOLLOW_FAILURE:
      return {
        ...state,
        loadingFollow: false,
      };
    /*--- UnFollow Organization ---*/

    /*--- fetch likes ---*/
    case postConstants.FETCH_LIKES_SUCCESS:
      const { likes, postId } = action.payload;
      const post = state.posts.find((post) => post.id === postId);
      const likesToAdd = likes.filter((like) => {
        if (post.likes.find((oldLike) => oldLike.id === like.id)) {
          return false;
        }
        return like;
      });
      return {
        ...state,
        posts: state.posts.map((post) => {
          if (post.id !== action.payload.postId) {
            return post;
          }
          return {
            ...post,
            likes: [...post.likes, ...likesToAdd],
          };
        }),
      };
    /*--- fetch likes ---*/
    default:
      return state;
  }
}
