import { indicatorConstants } from '../types'

const defaultIndicatorDialog = {
  open: false,
  create: false,
  tabValue: 0,
  indicatorValid: false,
  tagsValid: false,
  data: {
    id: null,
    name: '',
    description: '',
    type: '',
    measure: '',
    frecuency: null,
    frecuencyType: '',
    goal: null,
    managerId: null,
    areaId: null,
    perspectiveId: null,
    subperspectiveId: null,
    contentId: null,
    odsId: null,
    areas: [],
    content: null,
    objective: '',
    branches: [],
    lines: [],
    branchesIds: [],
    linesIds: [],
    encargados: [],
    cualitativo: ''
  }
}

const defaultIndicatorInProgress = {
  open: false,
  create: false,
  tabValue: 0,
  indicatorValid: false,
  tagsValid: false,
  data: {
    id: null,
    name: '',
    description: '',
    type: '',
    measure: '',
    frecuency: null,
    frecuencyType: '',
    goal: null,
    managerId: null,
    areaId: null,
    perspectiveId: null,
    subperspectiveId: null,
    contentId: null,
    odsId: null,
    areas: [],
    content: null,
    objective: '',
    branches: [],
    lines: [],
    encargados: [],
    cualitativo: ''
  }
}

const defaultCompoundIndicator = {
  open: false,
  create: false,
  tabValue: 0,
  id: null,
  name: '',
  description: '',
  indicatorOneId: '',
  indicatorTwoId: '',
  reach: '',
  period: '',
  createRelation: false,
  homologateScales: false,
  numeratorId: '',
  divisorId: '',
  isValid: false
}

const initialState = {
  loading: false,
  error: null,
  indicators: [],
  compoundIndicators: [],
  indicatorDialog: defaultIndicatorDialog,
  indicatorInProgress: defaultIndicatorInProgress,
  compoundIndicator: defaultCompoundIndicator
}

export default function (state = initialState, action) {
  switch (action.type) {
    /*--- SET initial state ---*/
    case indicatorConstants.SET_INITIAL_STATE:
      return initialState
    /*--- SET initial state ---*/

    /*--- FETCH ---*/
    case indicatorConstants.FETCH_STARTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case indicatorConstants.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        indicators: action.payload
      }
    case indicatorConstants.FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    /*--- FETCH ---*/

    /**
     * Crea un indicador
     */
    case indicatorConstants.CREATE:
      return {
        ...state,
        indicatorDialog: {
          ...state.indicatorDialog,
          data: {
            ...state.indicatorDialog.data,
            ...action.payload
          }
        },
        indicators: [...state.indicators, action.payload]
      }

    /**
     * Actualiza un indicador
     */
    case indicatorConstants.UPDATE:
      return {
        ...state,
        indicators: state.indicators.map((indicator) => {
          if (indicator.id !== action.payload.indicatorId) {
            return indicator
          }
          return {
            ...indicator,
            ...action.payload.data
          }
        }),
        indicatorDialog: {
          ...state.indicatorDialog,
          data: {
            ...state.indicatorDialog.data,
            ...action.payload.data
          }
        }
      }

    /*--- DELETE ---*/
    case indicatorConstants.DELETE_STARTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case indicatorConstants.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        indicators: state.indicators.filter(
          (indicator) => indicator.id !== action.payload.id
        )
      }
    case indicatorConstants.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    /*--- DELETE ---*/

    /*--- Crea un log de indicador ---*/
    case indicatorConstants.CREATE_LOG:
      return {
        ...state,
        indicators: state.indicators.map((indicator) => {
          if (indicator.id !== action.payload.indicatorId) {
            return indicator
          }
          return {
            ...indicator,
            currentStatus: action.payload.data.indicator.currentStatus,
            accumulated: action.payload.data.indicator.accumulated,
            logs: action.payload.data.indicator.logs
          }
        })
      }

    /**
     * Actualiza un log de indicador.
     */
    case indicatorConstants.UPDATE_LOG:
      return {
        ...state,
        indicators: state.indicators.map((indicator) => {
          if (indicator.id !== action.payload.indicatorId) {
            return indicator
          }
          return {
            ...indicator,
            logs: action.payload.data.indicator.logs,
            currentStatus: action.payload.data.indicator.currentStatus
          }
        })
      }

    /**
     * Elimina un log de indicador
     */
    case indicatorConstants.DELETE_LOG:
      return {
        ...state,
        indicators: state.indicators.map((indicator) => {
          if (indicator.id !== action.payload.indicatorId) {
            return indicator
          }
          return {
            ...indicator,
            logs: action.payload.data.logs,
            currentStatus: action.payload.data.currentStatus
          }
        })
      }

    // /*--- LOGS ---*/
    // case indicatorConstants.CREATE_LOG_STARTED:
    //   return { ...state, loading: true, error: null }
    // case indicatorConstants.CREATE_LOG_SUCCESS:
    //   const indicators = state.indicators.map((indicator) => {
    //     if (indicator.id !== action.payload.log.indicatorId) {
    //       return indicator
    //     }
    //     return {
    //       ...indicator,
    //       logs: [action.payload.log, ...indicator.logs]
    //     }
    //   })
    //   return {
    //     ...state,
    //     loading: false,
    //     error: null,
    //     indicators: indicators
    //   }
    // case indicatorConstants.CREATE_LOG_FAILURE:
    //   return { ...state, loading: false, error: action.payload }
    // /*--- LOGS ---*/

    /*--- CREATE_INDICATOR_DIALOG ---*/
    case indicatorConstants.CREATE_INDICATOR_DIALOG.OPEN:
      return {
        ...state,
        indicatorDialog: {
          ...state.indicatorDialog,
          open: true,
          create: action.payload.create
            ? action.payload.create
            : state.indicatorDialog.create,
          data: action.payload
            ? {
                ...state.indicatorDialog.data,
                ...action.payload.data
              }
            : state.indicatorDialog.data
        }
      }
    case indicatorConstants.CREATE_INDICATOR_DIALOG.CLOSE:
      return {
        ...state,
        indicatorDialog: defaultIndicatorDialog
      }
    case indicatorConstants.CREATE_INDICATOR_DIALOG.SET:
      return {
        ...state,
        indicatorDialog: {
          ...state.indicatorDialog,
          tabValue:
            action.payload.tabValue !== undefined
              ? action.payload.tabValue
              : state.indicatorDialog.tabValue,
          indicatorValid:
            action.payload.indicatorValid !== undefined
              ? action.payload.indicatorValid
              : state.indicatorDialog.indicatorValid,
          tagsValid:
            action.payload.tagsValid !== undefined
              ? action.payload.tagsValid
              : state.indicatorDialog.tagsValid,
          data: {
            ...state.indicatorDialog.data,
            ...action.payload.data
          }
        }
      }
    case indicatorConstants.CREATE_INDICATOR_DIALOG.CLEAR:
      return {
        ...state,
        indicatorDialog: {
          ...state.indicatorDialog,
          valid: false,
          error: null,
          data: defaultIndicatorDialog.data
        }
      }
    /*--- CREATE_INDICATOR_DIALOG ---*/

    /*--- INDICATOR IN PROGRESS (NEW INDICATOR CREATION FROM LABELS)---START*/
    case indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.OPEN:
      return {
        ...state,
        indicatorInProgress: {
          ...state.indicatorInProgress,
          open: true,
          create: action.payload.create
            ? action.payload.create
            : state.indicatorInProgress.create,
          data: action.payload
            ? {
                ...state.indicatorInProgress.data,
                ...action.payload.data
              }
            : state.indicatorInProgress.data
        }
      }
    case indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.CLOSE:
      return {
        ...state,
        indicatorInProgress: defaultIndicatorInProgress
      }
    case indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.SET:
      return {
        ...state,
        indicatorInProgress: {
          ...state.indicatorInProgress,
          tabValue:
            action.payload.tabValue !== undefined
              ? action.payload.tabValue
              : state.indicatorInProgress.tabValue,
          tagsValid:
            action.payload.tagsValid !== undefined
              ? action.payload.tagsValid
              : state.indicatorInProgress.tagsValid,
          indicatorValid:
            action.payload.indicatorValid !== undefined
              ? action.payload.indicatorValid
              : state.indicatorInProgress.indicatorValid,

          data: {
            ...state.indicatorInProgress.data,
            ...action.payload.data,
            branches:
              action.payload.branches !== undefined
                ? action.payload.branches
                : state.indicatorInProgress.data.branches,
            branchesIds:
              action.payload.branchesIds !== undefined
                ? [...action.payload.branchesIds]
                : state.indicatorInProgress.data.branchesIds,
            linesIds:
              action.payload.linesIds !== undefined
                ? [...action.payload.linesIds]
                : state.indicatorInProgress.data.linesIds
          }
        }
      }
    case indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.CLEAR:
      return {
        ...state,
        indicatorInProgress: {
          ...state.indicatorInProgress,
          valid: false,
          error: null,
          create: true,
          data: defaultIndicatorInProgress.data
        }
      }

    case indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.CREATE.OPEN:
      return {
        ...state,
        indicatorInProgress: {
          ...state.indicatorInProgress,
          create: true,
          open: true,
          valid: false,
          error: null,
          data: defaultIndicatorInProgress.data
        }
      }

    /*--- INDICATOR IN PROGRESS (NEW INDICATOR CREATION FROM LABELS)---END*/

    /*--- INDICATOR IN PROGRESS SET BRANCHES ---START*/
    case indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.SET_BRANCHES:
      return {
        ...state,
        indicatorInProgress: {
          ...state.indicatorInProgress,
          data: {
            ...state.indicatorInProgress.data,
            ...action.payload.data,
            branches:
              action.payload.branches !== undefined
                ? action.payload.branches
                : state.indicatorInProgress.data.branches
          }
        }
      }

    /*--- INDICATOR IN PROGRESS SET BRANCHES ---END*/

    /*--- INDICATOR IN PROGRESS SET LINES ---START*/
    case indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.SET_LINES:
      return {
        ...state,
        indicatorInProgress: {
          ...state.indicatorInProgress,
          data: {
            ...state.indicatorInProgress.data,
            ...action.payload.data,
            lines:
              action.payload.lines !== undefined
                ? action.payload.lines
                : state.indicatorInProgress.data.lines
          }
        }
      }

    /*--- INDICATOR IN PROGRESS SET LINES ---END*/

    /*--- ADD SPECIFIC CONTENT TO indicatorInProgress.data.content---START*/

    case indicatorConstants.FETCH_GRI_SPECIFIC_CONTENT.SUCCESS:
      return {
        ...state,
        indicatorInProgress: {
          ...state.indicatorInProgress,
          data: {
            ...state.indicatorInProgress.data,
            content: action.payload
          }
        }
      }

    case indicatorConstants.FETCH_GRI_SPECIFIC_CONTENT.FAILED:
      return {
        ...state,
        indicatorInProgress: {
          ...state.indicatorInProgress,
          loading: false,
          error: action.payload
        }
      }
    /*--- ADD SPECIFIC CONTENT TO indicatorInProgress.data.content---END*/

    /*--- ADD AREA ---*/
    case indicatorConstants.ADD_AREA_STARTED:
      return {
        ...state,
        indicatorDialog: {
          ...state.indicatorDialog,
          loading: true,
          error: null
        }
      }

    case indicatorConstants.ADD_AREA_SUCCESS:
      return {
        ...state,
        indicators: state.indicators.map((indicator) => {
          if (indicator.id !== state.indicatorDialog.data.id) {
            return indicator
          }
          return {
            ...indicator,
            areas: [...indicator.areas, action.payload]
          }
        }),
        indicatorDialog: {
          ...state.indicatorDialog,
          loading: false,
          error: null,
          open: state.indicatorDialog.create
            ? state.indicatorDialog.open
            : false
        }
      }
    case indicatorConstants.ADD_AREA_FAILURE:
      return {
        ...state,
        indicatorDialog: {
          ...state.indicatorDialog,
          loading: false,
          error: action.payload
        }
      }
    /*--- ADD AREA ---*/

    /**
     * Asocia sucursales a un indicador
     */
    case indicatorConstants.SET_BRANCHES:
      const { indicatorUpdated } = action.payload
      return {
        ...state,
        indicators: state.indicators.map((indicator) => {
          if (indicator.id !== indicatorUpdated.id) {
            return indicator
          }
          return indicatorUpdated
        }),
        indicatorDialog: {
          ...state.indicatorDialog,
          data: {
            ...state.indicatorDialog.data,
            ...indicatorUpdated
          }
        }
      }

    /**
     * Asocia líneas de negocios a un indicador
     */
    case indicatorConstants.SET_LINES:
      const { indicatorWithLines } = action.payload
      return {
        ...state,
        indicators: state.indicators.map((indicator) => {
          if (indicator.id !== indicatorWithLines.id) {
            return indicator
          }
          return indicatorWithLines
        }),
        indicatorDialog: {
          ...state.indicatorDialog,
          data: {
            ...state.indicatorDialog.data,
            ...indicatorWithLines
          }
        }
      }

    /*--- INDICADORES COMPUESTOS ---*/

    case indicatorConstants.COMPOUND_INDICATOR_IN_PROGRESS_DIALOG.OPEN:
      return {
        ...state,
        compoundIndicator: {
          ...state.compoundIndicator,
          open: true,
          create: action.payload.create,
          id: action.payload.id ? action.payload.id : null,
          indicatorOneId: action.payload.indicatorOneId
            ? action.payload.indicatorOneId
            : state.compoundIndicator.indicatorOneId,
          indicatorTwoId: action.payload.indicatorTwoId
            ? action.payload.indicatorTwoId
            : state.compoundIndicator.indicatorTwoId,
          reach: action.payload.reach
            ? action.payload.reach
            : state.compoundIndicator.reach,
          period: action.payload.period
            ? action.payload.period
            : state.compoundIndicator.period,
          createRelation: action.payload.createRelation,
          homologateScales: action.payload.homologateScales,
          numeratorId: action.payload.numeratorId
            ? action.payload.numeratorId
            : state.compoundIndicator.numeratorId,
          divisorId: action.payload.divisorId
            ? action.payload.divisorId
            : state.compoundIndicator.divisorId,
          name: action.payload.name
            ? action.payload.name
            : state.compoundIndicator.name,
          description: action.payload.description
            ? action.payload.description
            : state.compoundIndicator.description,
          tabValue: isATabCero ? 0 : action.payload.tabValue,
          isValid: action.payload.isValid
        }
      }
    case indicatorConstants.COMPOUND_INDICATOR_IN_PROGRESS_DIALOG.CLOSE:
      return {
        ...state,
        compoundIndicator: defaultCompoundIndicator
      }

    case indicatorConstants.COMPOUND_INDICATOR_IN_PROGRESS_DIALOG.SET:
      const isATabCero = action.payload.tabValue === 0

      return {
        ...state,
        compoundIndicator: {
          ...state.compoundIndicator,
          id: action.payload.id ? action.payload.id : null,
          indicatorOneId: action.payload.indicatorOne
            ? action.payload.indicatorOne
            : state.compoundIndicator.indicatorOneId,
          indicatorTwoId: action.payload.indicatorTwo
            ? action.payload.indicatorTwo
            : state.compoundIndicator.indicatorTwoId,
          reach: action.payload.reach
            ? action.payload.reach
            : state.compoundIndicator.reach,
          period: action.payload.period
            ? action.payload.period
            : state.compoundIndicator.period,
          createRelation: action.payload.createRelation,
          homologateScales: action.payload.homologateScales,
          numeratorId: action.payload.numeratorId
            ? action.payload.numeratorId
            : state.compoundIndicator.numeratorId,
          divisorId: action.payload.divisorId
            ? action.payload.divisorId
            : state.compoundIndicator.divisorId,
          name: action.payload.name
            ? action.payload.name
            : state.compoundIndicator.name,
          description: action.payload.description
            ? action.payload.description
            : state.compoundIndicator.description,
          tabValue: isATabCero ? 0 : action.payload.tabValue,
          isValid: action.payload.isValid
            ? action.payload.isValid
            : state.compoundIndicator.isValid
        }
      }
    // al crear un indicador compuesto

    case indicatorConstants.COMPOUND_INDICATOR_IN_PROGRESS.SAVE_DATA_START:
      return { ...state, error: null, loading: true }
    case indicatorConstants.COMPOUND_INDICATOR_IN_PROGRESS.SAVE_DATA_SUCCESS:
      // revisar cómo viene el payload
      const state1 = state.compoundIndicators.filter(
        (cI) => cI.id !== action.payload.compoundIndicator.id
      )
      return {
        ...state,
        error: null,
        loading: false,
        compoundIndicators: [...state1, action.payload.compoundIndicator]
      }
    case indicatorConstants.COMPOUND_INDICATOR_IN_PROGRESS.SAVE_DATA_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        compoundIndicator: state.compoundIndicator
      }
    case indicatorConstants.COMPOUND_INDICATORS.FETCH_ALL_START:
      return {
        ...state,
        loading: true,
        error: null
      }

    case indicatorConstants.COMPOUND_INDICATORS.FETCH_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        compoundIndicators: action.payload
      }
    case indicatorConstants.COMPOUND_INDICATORS.FETCH_ALL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case indicatorConstants.COMPOUND_INDICATORS.DELETE_START:
      return { ...state, loading: true, error: false }
    case indicatorConstants.COMPOUND_INDICATORS.DELETE_SUCCESS:
      const remainingIndicators = state.compoundIndicators.filter(
        (ele) => ele.id !== action.payload
      )
      return {
        ...state,
        loading: false,
        error: null,
        compoundIndicators: remainingIndicators
      }
    case indicatorConstants.COMPOUND_INDICATORS.DELETE_FAILED:
      return { ...state, loading: false, error: action.payload }

    case indicatorConstants.COMPOUND_INDICATOR_IN_PROGRESS.UPDATE_START:
      return { ...state, loading: true, error: null }
    case indicatorConstants.COMPOUND_INDICATOR_IN_PROGRESS.UPDATE_SUCCESS:
      const updatedId = action.payload.compoundIndicator.id
      const notModifiedInds = state.compoundIndicators.filter(
        (ind) => ind.id !== updatedId
      )
      return {
        ...state,
        loading: false,
        error: null,
        compoundIndicators: [
          ...notModifiedInds,
          action.payload.compoundIndicator
        ]
      }
    case indicatorConstants.COMPOUND_INDICATOR_IN_PROGRESS.UPDATE_FAILED:
      return { ...state, loading: false, error: action.payload }
    /*--- FIN DE INDICADORES COMPUESTOS ---*/

    default:
      return {
        ...state
      }
  }
}
