import React, { useState } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { indicatorActions } from '../../state/actions'
import { indicatorConstants } from '../../state/types'
import { newDataIndicatorsParser } from '../../helpers/indicators_new_ways.helper'
import { enqueueSnackbar } from '../../state/actions'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    margin: '10px 0'
  },
  title: {
    fontSize: 14,
    textAlign: 'left'
  },
  pos: {
    marginBottom: 12
  },
  table: {
    maxWidth: 650
  },
  tbrow: {
    padding: '10px'
  },
  specifics: {
    listStyle: 'none',
    paddingBottom: 1
  },
  contentBorder: {
    backgroundColor: theme.palette.grey['200']
  },
  windowPaper: {
    maxWidth: 300,
    maxHeight: 100,
    overflow: 'auto',
    marginLeft: -15
  },
  windowListItems: {
    fontSize: 14,
    color: theme.palette.primary.main,
    marginBottom: -15
  }
}))

// diccionario para los valores a mostrar

const dictionary = (term) => {
  let word = ''
  switch (term) {
    // tipo
    case 'GLOBAL':
      word = 'Nivel corporativo'
      return word
    case 'BSNS':
      word = 'Por línea de negocio'
      return word
    case 'AGGREGATE':
      word = 'Por instalación'
      return word
    // frequency type
    case 'day':
      word = 'Días'
      return word
    case 'week':
      word = 'Semanas'
      return word
    case 'month':
      word = 'Meses'
      return word
    case 'year':
      word = 'Años'
      return word

    default:
      return word
  }
}

const SynthesisAndCreate = () => {
  // traemos todo lo que está en la pieza indicatorInProgress
  // en submit enviamos todo lo que está en indicatorProgress pero parseado
  // cerramos la ventana de creación
  const dispatch = useDispatch()
  const classes = useStyles()
  const { user } = useSelector((state) => state.user)
  // fetch redux indicatorInProgress data
  const { data } = useSelector((state) => state.indicators.indicatorInProgress)
  const { lines } = useSelector((state) => state.linesOfBsns)
  const { branches } = useSelector((state) => state.organization.organization)

  // newDataIndicatorsParser(data)

  // fetch the real data to use in humanize function
  const areas = useSelector((state) => state.areas.areas)
  const employees = useSelector((state) => state.employees.active)
  const { ods, perspectives, griContents } = useSelector(
    (state) => state.resources
  )

  // component state pieces
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitOk, setSubmitOk] = useState(false)
  const [error, setError] = useState(null)

  // helper function to crete table rows
  const createData = (category, value) => {
    return { category, value }
  }

  // create indicator function

  const createIndicator = (event) => {
    console.log(data);
    data.managerId = user.id
    console.log(data);
    event.preventDefault()
    const submit = async () => {
      setSubmitting(true)
      setError(null)
      setSubmitOk(false)
      if (!data.id) {
        await dispatch(
          indicatorActions.create(newDataIndicatorsParser(data))
          // indicatorActions.create(data)
        )
          .then(() => {
            setSubmitOk(true)
            setTimeout(() => {
              dispatch(
                enqueueSnackbar({
                  variant: 'success',
                  message: 'Indicador creado exitosamente'
                })
              )
              dispatch({
                type: indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.CLOSE
              })
            }, 500)
          })
          .catch((err) => {
            setError(err)
          })
        setLoading(false)
      } else {
        await dispatch(
          indicatorActions.update(data.id, newDataIndicatorsParser(data))
          // indicatorActions.create(data)
        )
          .then(() => {
            setSubmitOk(true)
            setTimeout(() => {
              dispatch(
                enqueueSnackbar({
                  variant: 'success',
                  message: 'Indicador guardado exitosamente'
                })
              )
              dispatch({
                type: indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.CLOSE
              })
            }, 500)
          })
          .catch((err) => {
            setError(err)
          })
        setLoading(false)
      }
    }
    submit()
    setSubmitting(false)
  }

  // procesar la data a valores legibles
  const humanize = (type, value) => {
    let valueToShow = ''
    switch (type) {
      case 'manager':
        if (value !== '') {
          let arrEmployee = employees.find((employee) => employee.id === value)
          if (arrEmployee) {
            valueToShow = arrEmployee.fullName
          } else {
            valueToShow = 'usuario no encontrado'
          }
        } else {
          valueToShow = 'No asignado'
        }
        return valueToShow
      case 'area':
        if (value !== '') {
          let area = areas.find((area) => area.id === value)
          valueToShow = area.name
        } else {
          valueToShow = 'No asignado'
        }
        return valueToShow
      case 'tema':
        if (value !== '') {
          let dimensionGRI = perspectives.find(
            (contenido) => contenido.id === data.perspectiveId
          )
          let tema = dimensionGRI.subperspectives.find(
            (ele) => ele.id === value
          )
          valueToShow = tema.name
        } else {
          valueToShow = 'error'
        }
        return valueToShow
      case 'dimension':
        if (value !== '') {
          let dimen = perspectives.find(
            (dgri) => dgri.id === data.perspectiveId
          )
          valueToShow = dimen.name
        } else {
          valueToShow = 'error'
        }
        return valueToShow
      case 'ods':
        if (value !== '') {
          let odes = ods.find((od) => od.id === data.odsId)
          valueToShow = odes.name
        } else {
          valueToShow = 'no asignado'
        }
        return valueToShow
      case 'meta':
        if (!data.goal) {
          return 'No establecida'
        }
        return `${data.goal} ${data.measure}`
      default:
        return valueToShow
    }
  }

  // renderSpecificAssoc
  const renderSpecificAssoc = (renderData) => {
    let specifics = []
    if (renderData.branchesIds && renderData.branchesIds.length > 0) {
      // specifics = renderData.branches.map((branch) => branch.name)
      specifics = branches.filter((branch) =>
        renderData.branchesIds.find((idTemp) => idTemp === branch.id)
      )
    }

    if (renderData.linesIds && renderData.linesIds.length > 0) {
      specifics = lines.filter((line) =>
        renderData.linesIds.find((idTemp) => idTemp === line.id)
      )
    }
    const list = specifics.map((ele, idx) => (
      <li
        key={`- ${ele}-${idx}`}
        className={classes.specifics}
      >{`- ${ele.name}`}</li>
    ))
    const windowList = (
      <Paper className={classes.windowPaper}>
        <List>
          {specifics.map((ele, idx) => (
            <ListItem
              item
              key={`- ${ele}-${idx}`}
              className={classes.specifics}
            >
              <ListItemText
                classes={{ primary: classes.windowListItems }}
                primary={`- ${ele.name}`}
              ></ListItemText>
            </ListItem>
          ))}
        </List>
      </Paper>
    )

    if (specifics.length > 0) {
      if (specifics.length <= 5) {
        return list
      } else {
        return windowList
      }
    } else if (renderData.type === 'GLOBAL') {
      return 'N/A'
    }

    // return specifics.length > 0 ? list : '--'
  }

  // filas de la tabla
  const rows = [
    createData('Dimensión GRI', humanize('dimension', data.perspectiveId)),
    createData('Tema', humanize('tema', data.subperspectiveId)),
    createData('Contenido', data.content ? data.content.name : 'no asociado'),
    createData('ODS', humanize('ods', data.odsId)),
    createData('Nombre', data.name),
    createData('Descripción', data.description),
    createData('Alcance de registro', dictionary(data.type)),
    createData('Asociado a', renderSpecificAssoc(data)),
    createData('Unidad de medida', data.measure),
    createData('Frecuencia de medición', data.frecuency),
    createData('Tipo de frecuencia', dictionary(data.frecuencyType)),
    createData('¿Es acumulativo?', data.cumulative ? 'Si' : 'No'),
    createData('Meta', humanize('meta', data.goal)),
    createData('Área', humanize('area', data.areaId))
    // createData('Responsable', humanize('manager', data.managerId))
  ]

  console.log(data);

  // tabla para organizar la información
  const indicatorTable = (
    <TableContainer component={Paper}>
      <Table className={classes.table} size='small' aria-label='a dense table'>
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow className={classes.tbrow} key={`tab-${row.name}-${idx}`}>
              <TableCell
                className={classes.tbrow}
                align='left'
                style={{ paddingRight: '90px' }}
              >
                <Typography variant='body2' color='secondary'>
                  {row.category}
                </Typography>
              </TableCell>
              <TableCell className={classes.tbrow} align='left'>
                <Typography variant='body2' color='primary'>
                  {row.value}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <Grid container direction='column' align='center' justifyContent='center'>
      <Card className={classes.root}>
        <CardContent className={classes.contentBorder}>
          <Typography
            className={classes.title}
            color='textSecondary'
            gutterBottom
          >
            Resumen de datos del indicador en creación:
          </Typography>
          {indicatorTable}
        </CardContent>
        {/* <CardActions>
          <Button size='small'>Learn More</Button>
        </CardActions> */}
      </Card>
      <Typography variant='caption'>
        Si estás de acuerdo con los datos, pulsa "crear indicador", de lo
        contrario puedes regresar y corregir haciendo click en la pestaña
        correspondiente
      </Typography>
      <Button
        fullWidth
        variant='contained'
        color={data.id ? 'secondary' : 'primary'}
        onClick={(event) => createIndicator(event)}
        disabled={submitting}
      >
        {submitting ? 'Creando' : 'Guardar'}
      </Button>
    </Grid>
  )
}

export default SynthesisAndCreate
