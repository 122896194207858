import { userConstants, employeeConstants } from '../types'

const defaultInvitationDialog = {
  open: false,
  valid: false,
  loading: false,
  error: null,
  data: {
    id: '',
    name: '',
    lastName: '',
    mail: '',
    jobTitle: '',
    roles: []
  }
}

const initialState = {
  loading: false,
  error: null,
  active: [],
  inactives: [],
  invitationDialog: defaultInvitationDialog
}

export default function (state = initialState, action) {
  switch (action.type) {
    /*--- SET initial state ---*/
    case employeeConstants.SET_INITIAL_STATE:
      return initialState
    /*--- SET initial state ---*/

    /*--- Fetch active ---*/
    case employeeConstants.ACTIVE_FETCH_STARTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case employeeConstants.ACTIVE_FETCH_SUCCESS:
      action.payload.forEach((employee) => {
        employee['label'] = employee.fullName
        employee['value'] = employee.id
      })
      return {
        ...state,
        loading: false,
        error: null,
        active: action.payload
      }
    case employeeConstants.ACTIVE_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    /*--- Fetch active ---*/

    /*--- FETCH INACTIVE ---*/
    case employeeConstants.INACTIVE_FETCH_STARTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case employeeConstants.INACTIVE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        inactives: action.payload
      }
    case employeeConstants.INACTIVE_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    /*--- FETCH INACTIVE ---*/

    /*--- DELETE ACTIVE ---*/
    case employeeConstants.ACTIVE_DELETE_STARTED:
      return {
        ...state,
        error: null
      }
    case employeeConstants.ACTIVE_DELETE_SUCCESS:
      let actives = state.active.filter(
        (employee) => employee.id !== action.payload.id
      )
      return {
        ...state,
        error: null,
        active: actives
      }
    case employeeConstants.ACTIVE_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    /*--- DELETE ACTIVE ---*/

    /*--- DELETE INACTIVE ---*/
    case employeeConstants.INACTIVE_DELETE_STARTED:
      return {
        ...state,
        error: null
      }
    case employeeConstants.INACTIVE_DELETE_SUCCESS:
      let inactives = state.inactives.filter(
        (employee) => employee.id !== action.payload.id
      )
      return { ...state, error: null, inactives: inactives }
    case employeeConstants.INACTIVE_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    /*--- DELETE INACTIVE ---*/

    /*--- INVITATION ---*/
    case userConstants.INVITE_STARTED:
      return {
        ...state,
        invitationDialog: {
          ...state.invitationDialog,
          loading: true,
          error: null
        }
      }
    case userConstants.INVITE_SUCCESS:
      return {
        ...state,
        inactives: [...state.inactives, action.payload],
        invitationDialog: defaultInvitationDialog
      }
    case userConstants.INVITE_FAILURE:
      return {
        ...state,
        invitationDialog: {
          ...state.invitationDialog,
          loading: false,
          error: action.payload
        }
      }
    /*--- INVITATION ---*/

    /*--- INVITATION DIALOG ---*/
    case employeeConstants.DIALOG.OPEN:
      return {
        ...state,
        invitationDialog: {
          ...state.invitationDialog,
          open: true,
          data: {
            ...state.invitationDialog.data,
            ...action.payload
          }
        }
      }
    case employeeConstants.DIALOG.CLOSE:
      return {
        ...state,
        invitationDialog: defaultInvitationDialog
      }

    case employeeConstants.DIALOG.SET:
      return {
        ...state,
        invitationDialog: {
          ...state.invitationDialog,
          valid:
            action.payload.valid !== undefined
              ? action.payload.valid
              : state.invitationDialog.valid,
          data: {
            ...state.invitationDialog.data,
            ...action.payload.data
          }
        }
      }
    /*--- INVITATION DIALOG ---*/

    /*--- CHANGE STATUS ---*/
    case employeeConstants.UPDATE_STATUS_STARTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case employeeConstants.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        active: action.payload.isActive
          ? [
              ...state.active,
              {
                ...action.payload,
                label: action.payload.fullName,
                value: action.payload.id
              }
            ]
          : state.active.filter(
              (employee) => employee.id !== action.payload.id
            ),
        inactives: !action.payload.isActive
          ? [...state.inactives, action.payload]
          : state.inactives.filter(
              (employee) => employee.id !== action.payload.id
            )
      }
    case employeeConstants.UPDATE_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }
    /*--- CHANGE STATUS ---*/

    case employeeConstants.UPDATE_STARTED:
      return {
        ...state,
        invitationDialog: {
          ...state.invitationDialog,
          loading: true,
          error: null
        }
      }
    case employeeConstants.UPDATE_SUCCESS:
      return {
        ...state,
        inactives: state.inactives.map((user) => {
          if (user.id !== action.payload.id) {
            return user
          }
          return action.payload
        }),
        active: state.active.map((user) => {
          if (user.id !== action.payload.id) {
            return user
          }
          return action.payload
        }),
        invitationDialog: defaultInvitationDialog
      }
    case employeeConstants.UPDATE_FAILURE:
      return {
        ...state,
        invitationDialog: {
          ...state.invitationDialog,
          loading: false,
          error: action.payload
        }
      }
    default:
      return state
  }
}
