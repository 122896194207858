import { randomColor } from 'randomcolor'

export const generateColors = (
  numberOfColors,
  theHue = 'random',
  theLumens = 'random'
) => {
  return randomColor({
    count: numberOfColors ? numberOfColors : 5,
    luminosity: theLumens,
    hue: theHue
  })
}

export const indiOneColor = '#43aa8b'
export const indiTwoColor = '#277da1'
// export const indiTwoColor = 'rgba(255, 0, 85, 0.5)'
export const relationLineColor = '#0f99ff'

export const crayolaPallete = [
  '#00F5D4',
  '#FC7A1E',
  '#8FB339',
  '#9B5DE5',
  '#BA5624',
  '#00BBF9',
  '#A74482',
  '#F24C00',
  '#00CFC1',
  '#1B1B3A',
  '#F84AA7',
  '#FF6666',
  '#5D2E8C',
  '#FFA552',
  '#CCFF66',
  '#FF3562'
]

export const babyPallete = [
  '#826AED',
  '#C879FF',
  '#FFB7FF',
  '#3BF4FB',
  '#CAFF8A',
  '#9B5DE5',
  '#F15BB5',
  '#FEE440',
  '#00BBF9',
  '#EF767A',
  '#49DCB1',
  '#CAFF8A',
  '#9B5DE5',
  '#F15BB5'
]

export const springPallete = [
  '#C2F970',
  '#44344F',
  '#8FB339',
  '#D8829D',
  '#EDD83D',
  '#564D80',
  '#D3FCD5',
  '#98A6D4',
  '#61E786'
]

export const corporatePallete = [
  '#f94144',
  '#f3722c',
  '#f8961e',
  '#f9844a',
  '#f9c74f',
  '#90be6d',
  '#43aa8b',
  '#4d908e',
  '#577590',
  '#277da1',
  '#f94144',
  '#f3722c',
  '#f8961e',
  '#f9844a',
  '#f9c74f',
  '#90be6d',
  '#43aa8b',
  '#4d908e',
  '#577590',
  '#277da1',
  '#f94144',
  '#f3722c',
  '#f8961e',
  '#f9844a',
  '#f9c74f',
  '#90be6d',
  '#43aa8b',
  '#4d908e',
  '#577590',
  '#277da1'
]
