import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Tabs, Tab } from '@material-ui/core'
import ReportTab from './Report.tab'
import ContentTab from './Content.tab'
import StructureTab from './Structure.tab'
import DownloadTab from './Download.tab'
import { useSelector, useDispatch } from 'react-redux'
import { reportConstants } from '../../state/types'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

const TabPanel = ({ children }) => {
  return <div>{children}</div>
}

/**
 * Tabs que van dentro del dialog para poder crear y editar reportes globales
 */

const GlobalReportsDialogTabs = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { currentTab, report, create } = useSelector(
    (state) => state.reports.globalReportDialog
  )

  const [disabledTabs, setDisabledTabs] = useState(false)
  useEffect(() => {
    if (report?.id) {
      setDisabledTabs(false)
    } else {
      setDisabledTabs(true)
    }
  }, [report])

  const handleChange = (event, newValue) => {
    event.preventDefault()
    dispatch({
      type: reportConstants.GLOBAL_REPORT_DIALOG.CHANGE_TAB,
      payload: newValue
    })
  }

  return (
    <div className={classes.root}>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        centered
      >
        <Tab label='Reporte' />
        <Tab label='Contenido' disabled={disabledTabs} />
        <Tab label='Estructura' disabled={disabledTabs} />
        <Tab label='Descargar' disabled={disabledTabs} />
      </Tabs>
      {currentTab === 0 && (
        <TabPanel value={0} index={0}>
          <ReportTab report={report} create={create} />
        </TabPanel>
      )}
      {currentTab === 1 && (
        <TabPanel value={1} index={1}>
          <ContentTab report={report} />
        </TabPanel>
      )}
      {currentTab === 2 && (
        <TabPanel value={2} index={2}>
          <StructureTab report={report} />
        </TabPanel>
      )}
      {currentTab === 3 && (
        <TabPanel value={3} index={3}>
          <DownloadTab report={report} />
        </TabPanel>
      )}
    </div>
  )
}

export default GlobalReportsDialogTabs
