export const employeeConstants = {
  SET_INITIAL_STATE: 'EMPLOYEE_SET_INITIAL_STATE',

  ACTIVE_FETCH_STARTED: 'EMPLOYEE_ACTIVE_FETCH_STARTED',
  ACTIVE_FETCH_FAILURE: 'EMPLOYEE_ACTIVE_FETCH_FAILURE',
  ACTIVE_FETCH_SUCCESS: 'EMPLOYEE_ACTIVE_FETCH_SUCCESS',

  INACTIVE_FETCH_STARTED: 'EMPLOYEE_INACTIVE_FETCH_STARTED',
  INACTIVE_FETCH_FAILURE: 'EMPLOYEE_INACTIVE_FETCH_FAILURE',
  INACTIVE_FETCH_SUCCESS: 'EMPLOYEE_INACTIVE_FETCH_SUCCESS',

  UPDATE_STATUS_STARTED: 'EMPLOYEE_UPDATE_STATUS_STARTED',
  UPDATE_STATUS_SUCCESS: 'EMPLOYEE_UPDATE_STATUS_SUCCESS',
  UPDATE_STATUS_FAILURE: 'EMPLOYEE_UPDATE_STATUS_FAILURE',

  ACTIVE_DELETE_STARTED: 'EMPLOYEE_ACTIVE_DELETE_STARTED',
  ACTIVE_DELETE_SUCCESS: 'EMPLOYEE_ACTIVE_DELETE_SUCCESS',
  ACTIVE_DELETE_FAILURE: 'EMPLOYEE_ACTIVE_DELETE_FAILURE',

  INACTIVE_DELETE_STARTED: 'EMPLOYEE_INACTIVE_DELETE_STARTED',
  INACTIVE_DELETE_SUCCESS: 'EMPLOYEE_INACTIVE_DELETE_SUCCESS',
  INACTIVE_DELETE_FAILURE: 'EMPLOYEE_INACTIVE_DELETE_FAILURE',

  DIALOG: {
    OPEN: 'EMPLOYEE_DIALOG_OPEN',
    CLOSE: 'EMPLOYEE_DIALOG_CLOSE',
    CLEAR: 'EMPLOYEE_DIALOG_CLEAR',
    SET: 'EMPLOYEE_DIALOG_SET'
  },

  INVITATION_STARTED: 'EMPLOYEE_INVITATION_STARTED',
  INVITATION_SUCCESS: 'EMPLOYEE_INVITATION_SUCCESS',
  INVITATION_FAILURE: 'EMPLOYEE_INVITATION_FAILURE',

  UPDATE_STARTED: 'EMPLOYEE_UPDATE_STARTED',
  UPDATE_SUCCESS: 'EMPLOYEE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'EMPLOYEE_UPDATE_FAILURE'
}
