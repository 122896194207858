import React, { Fragment, useState, useEffect } from 'react';
import {
    Grid,
    Button,
    TextField,
    IconButton
  } from '@material-ui/core';
//Importación de Datable más estilos y basuras
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import toast, { Toaster } from 'react-hot-toast'
import { connect, useDispatch, useSelector } from 'react-redux'
import Employee from '../Users/Employee';
import { CheckCircle } from '@material-ui/icons';
import ButtonWithLoading from '../UI/ButtonWithLoading'
import { indicatorConstants } from '../../state/types'
import {
  validForm,
  updateFormData,
  generateReduxData
} from '../../helpers/form.helper'
import Select from '@mui/material/Select'
import FormField from '../UI/FormFields';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { enqueueSnackbar } from '../../state/actions'
// import Axios from '../axios'
import Axios from '../../axios';


 const Encargados = (props) => {

  //redux states
  const indicatorInProgress = useSelector(
    (state) => state.indicators.indicatorInProgress
  )

  const encar = JSON.parse(sessionStorage.getItem('encargados'));
  console.log(encar);
  const data = indicatorInProgress.data
      //Dentro de columns se pueden agregar columnas personalizadas con 
    //el nombre de la columna y el tipo de dato que se quiere mostrar

    const columns = [
      //Por cada {} es una columna donde name es el titulo del header
      //Y options sirve para crear filtros personalizados etc.
      {
        name: "ID",
        options: {
          display: false
        },
      },
      {
        name: "Nombre completo",
        options: {}
      },
      {
          name: "Correo",
          options: {}
      },
      {
          name: "Cargo",
          options: {}
      },
      {
        name: "Rol Asignado",
        options: {}
    },
      {
        name: "Rol",
        // options: {}
        options: {
          //customBodyRender: renderiza dentro de la columna el input para filtrar u
          //otros componentes personalizados
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div >
                {/* <select key={value} onChange={handleToast}>
                  <option value="lector">Lector</option>
                  <option value="editor">Editor</option>
                </select> */}

                <FormControl variant="outlined" style={{ minWidth: 125, maxHeight:50 }}>
                <InputLabel id="demo-simple-select-helper-label">Seleccionar</InputLabel>
                  <Select
                    key={value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={handleToast}
                    label="Seleccionar"
                    data-value="{tableMeta.rowData[0]}"
                    name={tableMeta.rowData[0]}
                  >
                    <MenuItem value={1}>Lector</MenuItem>
                    <MenuItem value={2}>Editor</MenuItem>
                  </Select> 
                </FormControl>
              </div>
            );
          }
        }
      }
    ];

    const [noMatchData, setnoMatchData] = useState('Cargando ...');

      const options = {
        tableId: "tablita",
        search: "enabled",
        searchOpen: true,
        filter: false,
        viewColumns: false,   
        selectableRows: 'none',
        responsive: "standard",
        selectToolbarPlacement: "none",
        print: false,
        download: false,
        onRowClick: (rowData, rowState) => {console.log(rowData,rowState)},
        scroll: true,
        textLabels: {
          pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Filas por página:",
            displayRows: "de" // 1-10 of 30
          },
          body: {
            noMatch: noMatchData,
          }      
        }
      };

      const [select, setSelect] = useState("");
      const items = [];

    const handleToast = (value) => {

      // console.log(setSelectPermisos);

      console.log(value.target.value);
      console.log(value.target.name);

      let verifica = 0;
      let verifica1 = 0;

      for(let i = 0; i < encar.users.length; i++){
        if(encar.users[i].id == value.target.name){
          encar.users[i].permissionId = value.target.value;
          verifica1 = 1;
          break;
        }
      }

      sessionStorage.setItem('encargados', JSON.stringify(encar))

      // if(verifica1 == 0){
      //   let item = {"userId": value.target.name, "permissionId": value.target.value};
      //   items.push(item);
      // }

      for(let i = 0; i < items.length; i++){
        if(items[i].id_user == value.target.name){
          items[i].id_role = value.target.value;
          verifica = 1;
          break;
        }
      }
      
      if(verifica == 0){
        let item = {"userId": value.target.name, "permissionId": value.target.value};
        items.push(item);
      }

      console.log(items);

      data.encargados = items;
      console.log(data);

      setTimeout(() => {
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Rol cambiado correctamente'
          })
        )
      }, 50)
       

      }

  const[tipo, setTipo] = useState("");

  const dispatch = useDispatch();
  
  const { datos, create, indicatorValid } = useSelector(
    (state) => state.indicators.indicatorInProgress
  )

  const [data1, setData] = useState([]);
  // const [selectPermisos, setSelectPermisos] = useState([]);
  const [submitOk, setSubmitOk] = useState(false)
  const { user } = useSelector((state) => state.user)
  const setSelectPermisos = [];


  useEffect(() => {
    console.log("TEEEEST");
    // console.log(user);
    // getIndicatorDocumentation();
    console.log(data);
    console.log(encar);
    if(encar){
      console.log("encar");
    var rol = '';
 
    // let item = {"userId": value.target.name, "permissionId": value.target.value};
    // items.push(item);
            // sessionStorage.setItem('encargados', JSON.stringify(response.data))

            const activeEmployees = encar.users.length > 0 ? (
              <>
                {encar.users.map((us) => {
                  // console.log(us)

                  if(us.permissionId == 0){
                    // rol = 'Sin rol asignado';    
                    setData(arr => [...arr,[us.id,
                      us.nombre, 
                      us.email,
                      us.cargo,
                      'Sin rol asignado']])                
                  } else if(us.permissionId == 1) {
                    setData(arr => [...arr,[us.id,
                      us.nombre, 
                      us.email,
                      us.cargo,
                      'Lector']])
                    // rol = 'Lector';                    
                  } else if(us.permissionId == 2){
                    setData(arr => [...arr,[us.id,
                      us.nombre, 
                      us.email,
                      us.cargo,
                      'Editor']])
                    // rol = 'Editor';
                  }                             
                                 
                })}
                
              </>
            ) : (
              ''
            )

    } else {
      console.log("else");
    var n = 0;
    var rol = '';
    if(data.id != null){
      n = data.id;
    }

    Axios.get(`companies/${user.companyId}/usersPermissions/${n}/`)
        .then((response) => {
            // console.log(response);

            sessionStorage.setItem('encargados', JSON.stringify(response.data))

            const activeEmployees = response.data.users.length > 0 ? (
              <>
                {response.data.users.map((us) => {
                  // console.log(us)


                  if(us.permissionId == 0){
                    // rol = 'Sin rol asignado';    
                    setData(arr => [...arr,[us.id,
                      us.nombre, 
                      us.email,
                      us.cargo,
                      'Sin rol asignado']])                
                  } else if(us.permissionId == 1) {
                    setData(arr => [...arr,[us.id,
                      us.nombre, 
                      us.email,
                      us.cargo,
                      'Lector']])
                    // rol = 'Lector';                    
                  } else if(us.permissionId == 2){
                    setData(arr => [...arr,[us.id,
                      us.nombre, 
                      us.email,
                      us.cargo,
                      'Editor']])
                    // rol = 'Editor';
                  }   


                  // if(us.permissionId == 1){
                  //   rol = 'Lector';
                  // } else if(us.permissionId == 2) {
                  //   rol = 'Editor';
                  // } else {
                  //   rol = 'Sin rol asignado';
                  // }                             
                
                  //   setData(arr => [...arr,[us.id,
                  //                           us.nombre, 
                  //                           us.email,
                  //                           us.cargo,
                  //                           rol]])
                })}
                
              </>
            ) : (
              ''
            )
          })
          .catch((err) => {
            setnoMatchData('No se encontraron resultados');
            console.log(err.response);
          })          
    }

    setTimeout(() => {
      setnoMatchData('No se encontraron resultados');
    }, 1000)
    
  },0);

  // const activeEncargados = 
  // Axios.get(`companies/${user.companyId}/usersPermissions/${data.id}/`)
  // .then((response) => {
  //     console.log(response);

  //     const activeEmployees = response.data.users.length > 0 ? (
  //       <>
  //         {response.data.users.map((us) => {
  //           console.log(us)
          
  //           useEffect (() => {setData(arr => [...arr,[us.id,
  //                                     us.nombre, 
  //                                     us.email,
  //                                     us.cargo,
  //                                     'Sin rol asignado']])}, [] )
  //         })}
          
  //       </>
  //     ) : (
  //       ''
  //     )

  //   })
  //   .catch((err) => {
  //     console.log(err.response);
  //   })

  
  // const activeEmployees =
  //   props.activeEmployees.length > 0 ? (
  //     <>
  //       {props.activeEmployees.map((employee) => {
  //         console.log(employee)
        
  //         useEffect (() => {setData(arr => [...arr,[employee.id,
  //                                                   employee.fullName, 
  //                                                   employee.mail,
  //                                                   employee.jobTitle,
  //                                                   'Sin rol asignado']])}, [] )
  //       })}
        
  //     </>
  //   ) : (
  //     ''
  //   )

    const [formData, setValues] = useState({
      
    });

    // console.log(data);

  const thisStepIsDone = (event) => {

    // console.log(data1);

    // console.log(items);
    // console.log(formData);
    
    // data.encargados = items;
    
    // console.log(data);
    event.preventDefault();

    

    const submit = () => {
      setValues(data);
      
      
      setTimeout(() => {
        dispatch({
          type: indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.SET,
          payload: { tabValue: 5 },
          indicatorValid: validForm(formData)
          
        })
      }, 500)
    }
    submit()
  }
  
    //theme es para renderizar de buena forma el datatable y no de errores
    let theme = createTheme();
    theme = responsiveFontSizes(theme);
    
    return (

      <Grid container direction='column' align='center' justifyContent='center'>
        <form id="formEncargados">
          <ThemeProvider theme={theme}>
            <MUIDataTable 
              align='left'
              size='big' 
              aria-label='a dense table'
              data={data1}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
          
          <br></br>
          {/* <div>{activeEncargados}</div> */}
          {/* boton "siguiente" dentro del tab, 
          pero falta la inteligencia para redirigir a 
          resumen y creación */}
          <div>
          <ButtonWithLoading
              text='Siguiente'
              submitOk={submitOk}
              onClick={thisStepIsDone}
              fullWidth
            />
          </div>
      </form>
      
      <Toaster />
      </Grid>
    )
}

function mapStateToProps(state) {
  return {
    activeEmployees: state.employees.active,
    inactiveEmployees: state.employees.inactives
  }
}

export default connect(mapStateToProps)(Encargados)