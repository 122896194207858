import { useState, useEffect } from "react";

export const useHandleError = (data = null, error = null) => {
  const [formDataWithErrors, setFormDataWithErrors] = useState(data);
  useEffect(
    () => {
      if (error) {
        if (error.status === 422) {
          error.data.errors.forEach(err => {
            const newFormData = {
              ...data
            };
            const newElement = {
              ...data[err.path]
            };
            newElement.valid = false;
            newElement.validationMessage = err.message;
            newElement.hasError = true;
            newFormData[err.path] = newElement;
            setFormDataWithErrors({ ...newFormData });
          });
        }
      }
    },
    // eslint-disable-next-line
    [error]
  );
  return [formDataWithErrors];
};
