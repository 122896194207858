import Axios from '../../axios'
import { indicatorConstants } from '../types'
import { uiActions } from './ui.actions'
import { enqueueSnackbar } from './notification.actions'

function fetchAll(organizationId) {
  console.log("FETCHALL");
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.get(`/companies/${organizationId}/indicators/${localStorage.getItem('userId') ? localStorage.getItem('userId') : sessionStorage.getItem('userId')}`)
      .then((response) => {
        // console.log(response.data);
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'warning',
            message: 'No se han podido obtener los indicadores compuestos'
          })
        )
      })
  }

  function start() {
    return {
      type: indicatorConstants.FETCH_STARTED
    }
  }

  function success(data) {
    return {
      type: indicatorConstants.FETCH_SUCCESS,
      payload: data.indicators
    }
  }

  function failure(error) {
    return {
      type: indicatorConstants.FETCH_FAILURE,
      payload: error
    }
  }
}
/**
 * Crea un indicador
 */

const create = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post('/indicators', data)
        .then((response) => {
          dispatch({
            type: indicatorConstants.CREATE,
            payload: response.data.indicator
          })
          resolve()
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido crear el indicador'
            })
          )
          reject(err.response)
        })
    })
  }
}

/**
 * Actualiza un indicador
 */

const update = (indicatorId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.patch(`/indicators/${indicatorId}`, data)
        .then((response) => {
          dispatch({
            type: indicatorConstants.UPDATE,
            payload: {
              indicatorId,
              data: response.data.indicator
            }
          })
          resolve()
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido actualizar el indicador'
            })
          )
          reject(err.response)
        })
    })
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.delete(`/indicators/${id}`)
      .then((response) => {
        dispatch(success(id))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Indicador eliminado exitosamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido eliminar el indicador'
          })
        )
      })
  }

  

  function start() {
    return {
      type: indicatorConstants.DELETE_STARTED
    }
  }

  function success(id) {
    return {
      type: indicatorConstants.DELETE_SUCCESS,
      payload: {
        id: id
      }
    }
  }

  function failure() {
    return {
      type: indicatorConstants.DELETE_FAILURE
    }
  }
}

function _deleteSubc(idSubc) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.delete(`/subcategory/delete/${idSubc}`, {id:idSubc})
      .then((response) => {
        console.log(response);
        // dispatch(success(id))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Subindicador eliminado exitosamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido eliminar el subindicador'
          })
        )
      })
  }

  

  function start() {
    return {
      type: indicatorConstants.DELETE_STARTED
    }
  }

  function success(id) {
    return {
      type: indicatorConstants.DELETE_SUCCESS,
      payload: {
        id: id
      }
    }
  }

  function failure() {
    return {
      type: indicatorConstants.DELETE_FAILURE
    }
  }
}

function subcEdit(dataToSubmit, idSubc) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.post(`/subcategory/update/${idSubc}`, dataToSubmit)
      .then((response) => {
        console.log(response);
        // dispatch(success(id))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Subindicador modificado exitosamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido modificado el subindicador'
          })
        )
      })
  }

  

  function start() {
    return {
      type: indicatorConstants.DELETE_STARTED
    }
  }

  function success(id) {
    return {
      type: indicatorConstants.DELETE_SUCCESS,
      payload: {
        id: id
      }
    }
  }

  function failure() {
    return {
      type: indicatorConstants.DELETE_FAILURE
    }
  }
}

/**
 * Crea un log de indicador
 */
const createLog = (indicatorId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/indicators/${indicatorId}/logs`, data)
        .then((response) => {
          dispatch({
            type: indicatorConstants.CREATE_LOG,
            payload: {
              indicatorId,
              data: response.data.logIndicator
            }
          })
          console.log(indicatorId)
          console.log(response.data.logIndicator)
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Registro ingresado exitosamente'
            })
          )
          resolve()
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido ingresar el registro'
            })
          )
          reject(err.response)
        })
    })
  }
}

/**
 * Crea un log de subindicador
 */
 const createLogSub = (indicatorId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/logsubcateogryindicator/create`, data)
        .then((response) => {
          console.log(response.data.status);
          if(response.data.status == 1){
            // dispatch({
            //   type: indicatorConstants.CREATE_LOG,
            //   payload: {
            //     indicatorId,
            //     data: response.data.logIndicator
            //   }
            // })
            dispatch(
              enqueueSnackbar({
                variant: 'success',
                message: 'Registro ingresado exitosamente'
              })
            )
            resolve()
            // window.load();            
            // setTimeout(() => {
            //   window.location.href = window.location.href;
            // }, 1000)
          } else {
            dispatch(
              enqueueSnackbar({
                variant: 'error',
                message: 'No se ha podido ingresar el registro1'
              })
            )
            reject(err.response)
          }
          
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido ingresar el registro1'
            })
          )
          reject(err.response)
        })
    })
  }
}

const createLogExcel = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/excel`, data)
        .then((response) => {
          console.log(response.status);
          if(response.data.status == 200){
            enqueueSnackbar({
              variant: 'success',
              message: 'Registro ingresado exitosamente'
            })
          } else {
            if(response.data.code){
              enqueueSnackbar({
                variant: 'error',
                message: response.data.message
              })
            } else {
              enqueueSnackbar({
                variant: 'error',
                message: 'No se ha podido ingresar el registro'
              })
            }
            
          }
          // dispatch({
          //   type: indicatorConstants.CREATE_LOG,
          //   payload: {
          //     indicatorId,
          //     data: response.data.logIndicator
          //   }
          // })
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Registro ingresado exitosamente'
            })
          )
          resolve()
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido ingresar el registro'
            })
          )
          reject(err.response)
        })
    })
  }
}

/**
 * Actualiza un log de indicador
 */
const updateLog = (indicatorId, logId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.patch(`/indicators/${indicatorId}/logs/${logId}`, data)
        .then((response) => {          
          dispatch({
            type: indicatorConstants.UPDATE_LOG,
            payload: {
              indicatorId,
              logId,
              data: response.data.logIndicator
            }
          })
          console.log(indicatorId)
          console.log(logId)
          console.log(response.data.logIndicator)
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Registro actualizado exitosamente'
            })
          )
          resolve()
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido actualizar el registro'
            })
          )
          reject(err.response)
        })
    })
  }
}

/**
 * Elimina un log de indicador
 */
const deleteLog = (indicatorId, logId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.delete(`/indicators/${indicatorId}/logs/${logId}`)
        .then((response) => {
          dispatch({
            type: indicatorConstants.DELETE_LOG,
            payload: {
              indicatorId,
              logId,
              data: response.data.indicator
            }
          })
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Registro eliminado exitosamente'
            })
          )
          resolve()
        })
        .catch(() => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido eliminar el registro'
            })
          )
          reject()
        })
    })
  }
}

const updateReportEmpresa = (data, reportId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/customreports/stepTwo/${reportId}`, data)
        .then((response) => {
          console.log(response);
          // dispatch({
          //   type: indicatorConstants.DELETE_LOG,
          //   payload: {
          //     indicatorId,
          //     logId,
          //     data: response.data.indicator
          //   }
          // })
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Se guardo correctamente la presentación de la empresa'
            })
          )
          resolve()
        })
        .catch(() => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido guardar la presentación de la empresa'
            })
          )
          reject()
        })
    })
  }
}

const updateReportPaso3 = (data, reportId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.patch(`/customreports/${reportId}`, data)
        .then((response) => {
          console.log(response);
          // dispatch({
          //   type: indicatorConstants.DELETE_LOG,
          //   payload: {
          //     indicatorId,
          //     logId,
          //     data: response.data.indicator
          //   }
          // })
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Se guardo correctamente la Información general del reporte'
            })
          )
          resolve()
        })
        .catch(() => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido guardar la Información general del reporte'
            })
          )
          reject()
        })
    })
  }
}

function addArea(data) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.post(`/indicators/${data.indicatorArea.indicatorId}/areas`, {
      ...data
    })
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Área asignada correctamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido asignar el área correctamente'
          })
        )
      })

    function start() {
      return {
        type: indicatorConstants.ADD_AREA_STARTED
      }
    }

    function success(data) {
      return {
        type: indicatorConstants.ADD_AREA_SUCCESS,
        payload: data
      }
    }

    function failure(error) {
      return {
        type: indicatorConstants.ADD_AREA_FAILURE,
        payload: error
      }
    }
  }
}

const setBranches = (indicator, branches) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.patch(`/indicators/${indicator.id}`, {
        indicator: {
          branches
        }
      })
        .then((response) => {
          dispatch({
            type: indicatorConstants.SET_BRANCHES,
            payload: {
              indicatorUpdated: response.data.indicator
            }
          })
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Instalaciones agregadas correctamente'
            })
          )
          resolve()
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido agregar las instalaciones'
            })
          )
          reject(error.response)
        })
    })
  }
}
const setLines = (indicator, lines) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.patch(`/indicators/${indicator.id}`, {
        indicator: {
          lines
        }
      })
        .then((response) => {
          dispatch({
            type: indicatorConstants.SET_LINES,
            payload: {
              indicatorWithLines: response.data.indicator
            }
          })
          dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Líneas de negocios agregadas exitosamente'
            })
          )
          resolve()
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se han podido agregar las líneas de negocios'
            })
          )
          reject(error.response)
        })
    })
  }
}

function bringTheContent(id) {
  return (dispatch) => {
    Axios.get(`/gri_contents/${id}`)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'Hubo un problema obteniendo el Contenido GRI'
          })
        )
      })

    function success(data) {
      return {
        type: indicatorConstants.FETCH_GRI_SPECIFIC_CONTENT.SUCCESS,
        payload: data
      }
    }

    function failure(error) {
      return {
        type: indicatorConstants.FETCH_GRI_SPECIFIC_CONTENT.FAILED,
        payload: error
      }
    }
  }
}

const addIndicatorSubc = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/subcategory/create`, data)
        .then((response) => {
          console.log(response.status);
          console.log(response.data);
          console.log(response.data.status);          
          if(response.data.status == 1){
            dispatch(
            enqueueSnackbar({
              variant: 'success',
              message: 'Subcategoría creada exitosamente'
            })
            )
            resolve()
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 1000)
          } else {
            if(response.data.code){
              dispatch(
              enqueueSnackbar({
                variant: 'error',
                message: response.data.message
              })
              )
              resolve()
            } else {
              dispatch(
              enqueueSnackbar({
                variant: 'error',
                message: 'No se ha podido ingresar la subcategoría'
              }))
              resolve()
            }

            // setTimeout(() => {
            //   window.location.href = window.location.href;
            // }, 1000)
            
          }
          // dispatch(
          //   enqueueSnackbar({
          //     variant: 'success',
          //     message: 'Registro ingresado exitosamente'
          //   })
          // )
          
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido ingresar el registro'
            })
          )
          reject(err.response)
        })
    })
  }
}
// indicators/backups/idIndicator
const addIndicatorDocumentation = (data) => {
  return (dispatch) => {
    console.log(data);
    return new Promise((resolve, reject) => {
      Axios.post(`/indicatorBackups/create`, data)
        .then((response) => {
          console.log(response.status);
          if(response.data.status == 1){
            // enqueueSnackbar({
            //   variant: 'success',
            //   message: 'Documentación ingresada exitosamente'
            // })
            // window.location.href = window.location.href;

            dispatch(
              enqueueSnackbar({
                variant: 'success',
                message: 'Documentación ingresada exitosamente'
              })
            )
            resolve()
            // window.load();            
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 5000)

          } else {
            // if(response.data.code){
            //   enqueueSnackbar({
            //     variant: 'error',
            //     message: response.data.message
            //   })
            // } else {
            //   enqueueSnackbar({
            //     variant: 'error',
            //     message: 'No se ha podido ingresar el registro'
            //   })
            // }
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido ingresar la documentación'
            })
          }
          // dispatch(
          //   enqueueSnackbar({
          //     variant: 'success',
          //     message: 'Documentación ingresado exitosamente'
          //   })
          // )
          resolve()
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido ingresar la documentación'
            })
          )
          reject(err.response)
        })
    })
  }
}

const getIndicatorDocumentation = (id) => {
  return (dispatch) => {
    console.log(id);
    return new Promise((resolve, reject) => {
      Axios.get(`/indicatorBackups/${id}`)
      .then((response) => {
          // console.log(response);
          // resolve()
          return response.data;
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido ingresar la documentación'
            })
          )
          reject(err.response)
        })
    })
  }
  
}

function saveGraphicView(id, reachVisualization, periodVisualization) {
  return (dispatch) => {
    Axios.post(`/xxx/${id}`, {
      reach: reachVisualization,
      period: periodVisualization
    })
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'Hubo un problema guardando la vista'
          })
        )
      })

    function success(data) {
      return {
        type: indicatorConstants.FETCH_GRI_SPECIFIC_CONTENT.SUCCESS,
        payload: data
      }
    }

    function failure(error) {
      return {
        type: indicatorConstants.FETCH_GRI_SPECIFIC_CONTENT.FAILED,
        payload: error
      }
    }
  }
}

export const indicatorActions = {
  fetchAll,
  _delete,
  create,
  update,
  createLog,
  addArea,
  setBranches,
  setLines,
  updateLog,
  deleteLog,
  bringTheContent,
  saveGraphicView,
  createLogExcel,
  addIndicatorSubc,
  addIndicatorDocumentation,
  getIndicatorDocumentation,
  createLogSub,
  _deleteSubc,
  subcEdit,
  updateReportEmpresa,
  updateReportPaso3
}
