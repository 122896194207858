import { initiativeConstants, taskConstants } from '../types'

const initialInitiativeModal = {
  open: false,
  create: true,
  tabValue: 0,
  valid: false,
  loading: false,
  error: null,
  data: {
    id: '',
    name: '',
    generalObjective: '',
    initiativeManagerId: '',
    budget: 0,
    currency: '',
    start: null,
    end: null,
    areaId: '',
    perspectiveId: '',
    subperspectiveId: '',
    ods: [],
    tasks: [],
    indicators: [],
    report: []
  }
}

const initialCreateTask = {
  loading: false,
  error: null,
  success: false,
  data: {
    id: null,
    name: null,
    managerId: null,
    date: null
  }
}

const initialTaskDialog = {
  open: false,
  loading: false,
  error: null,
  tasks: [],
  taskToEdit: null
}

const initialState = {
  loading: false,
  error: null,
  initiatives: [],
  initiativeModal: initialInitiativeModal,
  createTask: initialCreateTask,
  taskDialog: initialTaskDialog
}

export default function (state = initialState, action) {
  switch (action.type) {
    // SET initial state
    case initiativeConstants.SET_INITIAL_STATE:
      return initialState

    // FETCH
    case initiativeConstants.FETCH_STARTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case initiativeConstants.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        initiatives: action.payload
      }
    case initiativeConstants.FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    // DIALOGS
    case initiativeConstants.CREATE_INITIATIVE_MODAL.OPEN:
      return {
        ...state,
        initiativeModal: {
          ...initialInitiativeModal,
          open: true,
          create: action.payload ? action.payload.create : true,
          data: action.payload
            ? { ...initialInitiativeModal.data, ...action.payload.data }
            : { ...initialInitiativeModal.data }
        }
      }
    case initiativeConstants.CREATE_INITIATIVE_MODAL.CLOSE:
      return {
        ...state,
        initiativeModal: initialInitiativeModal
      }
    case initiativeConstants.CREATE_INITIATIVE_MODAL.SET:
      return {
        ...state,
        initiativeModal: {
          ...state.initiativeModal,
          tabValue:
            action.payload.tabValue !== undefined
              ? action.payload.tabValue
              : state.initiativeModal.tabValue,
          valid:
            action.payload.valid !== undefined
              ? action.payload.valid
              : state.initiativeModal.valid,
          data: { ...state.initiativeModal.data, ...action.payload.data }
        }
      }
    case initiativeConstants.CREATE_INITIATIVE_MODAL.CLEAR:
      return {
        ...state,
        initiativeModal: initialInitiativeModal
      }

    case initiativeConstants.TASK_DIALOG.OPEN:
      return {
        ...state,
        initiativeModal: {
          ...state.initiativeModal,
          data: {
            ...state.initiativeModal.data,
            ...action.payload.initiative
          }
        }
      }
    case initiativeConstants.TASK_DIALOG.CLOSE:
      return {
        ...state,
        taskDialog: initialTaskDialog,
        initiativeModal: initialInitiativeModal
      }

    /**
     * Permite setear los datos en el formulario para actualizar una tarea
     */
    case taskConstants.SET_TASK_UPDATE_FORM:
      return {
        ...state,
        taskDialog: {
          ...state.taskDialog,
          taskToEdit: action.payload
        }
      }

    /**
     * Permite crear una iniciativa
     */
    case initiativeConstants.CREATE:
      return {
        ...state,
        initiatives: [...state.initiatives, action.payload],
        initiativeModal: {
          ...state.initiativeModal,
          data: action.payload
        }
      }

    /**
     * Crea una tarea de iniciativa
     */
    case taskConstants.CREATE:
      return {
        ...state,
        initiatives: state.initiatives.map((initiative) => {
          if (initiative.id !== action.payload.initiativeId) {
            return initiative
          }
          return {
            ...initiative,
            tasks: [...initiative.tasks, action.payload.data]
          }
        }),
        initiativeModal: {
          ...state.initiativeModal,
          data: {
            ...state.initiativeModal.data,
            tasks: [...state.initiativeModal.data.tasks, action.payload.data]
          }
        }
      }

    /**
     * Actualizar una tarea de iniciativa
     */
    case taskConstants.UPDATE:
      return {
        ...state,
        initiatives: state.initiatives.map((initiative) => {
          if (initiative.id !== action.payload.initiativeId) {
            return initiative
          }
          return {
            ...initiative,
            tasks: initiative.tasks.map((task) => {
              if (task.id !== action.payload.taskId) {
                return task
              }
              return {
                ...task,
                ...action.payload.data
              }
            })
          }
        }),
        initiativeModal: {
          ...state.initiativeModal,
          data: {
            ...state.initiativeModal.data,
            tasks: state.initiativeModal.data.tasks.map((task) => {
              if (task.id !== action.payload.taskId) {
                return task
              }
              return {
                ...task,
                ...action.payload.data
              }
            })
          }
        }
      }

    /**
     * Elimina una tarea de una iniciativa
     */
    case taskConstants.DELETE:
      return {
        ...state,
        initiativeModal: {
          ...state.initiativeModal,
          data: {
            ...state.initiativeModal.data,
            tasks: state.initiativeModal.data.tasks.filter(
              (task) => task.id !== action.payload.taskId
            )
          }
        },
        initiatives: state.initiatives.map((initiative) => {
          if (initiative.id !== action.payload.initiativeId) {
            return initiative
          }
          return {
            ...initiative,
            tasks: initiative.tasks.filter(
              (task) => task.id !== action.payload.taskId
            )
          }
        })
      }

    // DELETE
    case initiativeConstants.DELETE_STARTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case initiativeConstants.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        initiatives: state.initiatives.filter(
          (initiative) => initiative.id !== action.payload.id
        )
      }
    case initiativeConstants.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    /**
     * Actualiza una iniciativa
     */
    case initiativeConstants.UPDATE:
      return {
        ...state,
        initiativeModal: {
          ...state.initiativeModal,
          data: { ...state.initiativeModal.data, ...action.payload.data }
        },
        initiatives: state.initiatives.map((initiative) => {
          if (initiative.id !== action.payload.initiativeId) {
            return initiative
          }
          return action.payload.data
        })
      }

    // ADD INDICATOR
    case initiativeConstants.ADD_INDICATOR_STARTED:
      return {
        ...state,
        initiativeModal: {
          ...state.initiativeModal,
          loading: true,
          error: null
        }
      }
    case initiativeConstants.ADD_INDICATOR_SUCCESS:
      return {
        ...state,
        initiativeModal: {
          ...state.initiativeModal,
          loading: false,
          error: null,
          data: {
            ...state.initiativeModal.data,
            indicators: [
              ...state.initiativeModal.data.indicators,
              action.payload.indicator
            ]
          }
        },
        initiatives: state.initiatives.map((initiative) => {
          if (initiative.id !== parseInt(action.payload.initiative, 10)) {
            return initiative
          }
          return {
            ...initiative,
            indicators: [...initiative.indicators, action.payload.indicator]
          }
        })
      }
    case initiativeConstants.ADD_INDICATOR_FAILURE:
      return {
        ...state,
        initiativeModal: {
          ...state.initiativeModal,
          loading: false,
          error: action.payload
        }
      }

    // DELETE INDICATOR
    case initiativeConstants.DELETE_INDICATOR_STARTED:
      return {
        ...state,
        initiativeModal: {
          ...state.initiativeModal,
          loading: true,
          error: null
        }
      }
    case initiativeConstants.DELETE_INDICATOR_SUCCESS:
      return {
        ...state,
        initiativeModal: {
          ...state.initiativeModal,
          loading: false,
          error: null,
          data: {
            ...state.initiativeModal.data,
            indicators: state.initiativeModal.data.indicators.filter(
              (indicator) => indicator.id !== action.payload.indicatorId
            )
          }
        },
        initiatives: state.initiatives.map((initiative) => {
          if (initiative.id !== action.payload.initiativeId) {
            return initiative
          }
          return {
            ...initiative,
            indicators: initiative.indicators.filter(
              (indicator) => indicator.id !== action.payload.indicatorId
            )
          }
        })
      }
    case initiativeConstants.DELETE_INDICATOR_FAILURE:
      return {
        ...state,
        initiativeModal: {
          ...state.initiativeModal,
          loading: false,
          error: action.payload
        }
      }

    // REPORTS
    case initiativeConstants.REPORTS.CREATE:
      return {
        ...state,
        initiatives: state.initiatives.map((initiative) => {
          if (initiative.id !== action.payload.initiativeId) {
            return initiative
          }
          return {
            ...initiative,
            report: action.payload.report
          }
        })
      }
    case initiativeConstants.REPORTS.UPDATE:
      return {
        ...state,
        initiatives: state.initiatives.map((initiative) => {
          if (initiative.id !== action.payload.initiativeId) {
            return initiative
          }
          return {
            ...initiative,
            report: action.payload.report
          }
        })
      }
    case initiativeConstants.REPORTS.SAVE_SNAPSHOT:
      return {
        ...state,
        initiatives: state.initiatives.map((initiative) => {
          if (initiative.id !== action.payload.initiativeId) {
            return initiative
          }
          return {
            ...initiative,
            report: {
              ...initiative.report,
              snapshots: [
                action.payload.snapshot,
                ...initiative.report.snapshots
              ]
            }
          }
        })
      }

    default:
      return state
  }
}
