export const reportConstants = {
  FETCH: 'REPORTS_FETCH',
  CREATE: 'REPORTS_CREATE',
  UPDATE: 'REPORTS_UPDATE',
  DELETE: 'REPORTS_DELETE',

  GLOBAL_REPORT_DIALOG: {
    OPEN: 'GLOBAL_REPORT_DIALOG_OPEN',
    CLOSE: 'GLOBAL_REPORT_DIALOG_CLOSE',
    CHANGE_TAB: 'GLOBAL_REPORT_DIALOG_CHANGE_TAB',
    CREATE: 'GLOBAL_REPORT_DIALOG_CREATE',
    UPDATE: 'GLOBAL_REPORT_DIALOG_UPDATE',
    SAVE: 'GLOBAL_REPORT_DIALOG_SAVE',
    DELETE_SNAPSHOT: 'GLOBAL_REPORT_DIALOG_DELETE_SNAPSHOT'
  }
}
