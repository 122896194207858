import Axios from '../../axios'
import { employeeConstants } from '../types'
import { uiActions } from './ui.actions'
import { enqueueSnackbar } from './notification.actions'

function updateStatus(employee) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.patch(`/users/${employee.id}`, {
      user: { isActive: !employee.isActive }
    })
      .then((response) => {
        dispatch(success(response.data.user[0]))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: `Usuario ${employee.isActive ? 'desactivado' : 'activado'}`
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: `No se ha podido ${
              employee.isActive ? 'desactivar' : 'activar'
            } el usuario`
          })
        )
      })
  }

  function start() {
    return {
      type: employeeConstants.UPDATE_STATUS_STARTED
    }
  }

  function success(response) {
    return {
      type: employeeConstants.UPDATE_STATUS_SUCCESS,
      payload: response
    }
  }

  function failure(error) {
    return {
      type: employeeConstants.UPDATE_STATUS_FAILURE,
      payload: error
    }
  }
}

function fetchActive(organizationId) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    return Axios.get(`/companies/${organizationId}/employees`)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido obtener los usuarios'
          })
        )
      })
  }

  function start() {
    return { type: employeeConstants.ACTIVE_FETCH_STARTED }
  }

  function success(data) {
    return {
      type: employeeConstants.ACTIVE_FETCH_SUCCESS,
      payload: data.employees
    }
  }

  function failure(error) {
    return {
      type: employeeConstants.ACTIVE_FETCH_FAILURE,
      payload: error
    }
  }
}

function fetchInactive(organizationId) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    return Axios.get(`/companies/${organizationId}/inactives`)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido obtener los usuarios'
          })
        )
      })
  }

  function start() {
    return {
      type: employeeConstants.INACTIVE_FETCH_STARTED
    }
  }

  function success(data) {
    return {
      type: employeeConstants.INACTIVE_FETCH_SUCCESS,
      payload: data.inactives
    }
  }

  function failure(error) {
    return {
      type: employeeConstants.INACTIVE_FETCH_FAILURE,
      payload: error
    }
  }
}

function deleteActive(id) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.delete(`/users/${id}`)
      .then((response) => {
        dispatch(success(id))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Usuario eliminado exitosamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido eliminar el usuario'
          })
        )
      })
  }

  function start() {
    return { type: employeeConstants.ACTIVE_DELETE_STARTED }
  }

  function success(id) {
    return { type: employeeConstants.ACTIVE_DELETE_SUCCESS, payload: { id } }
  }

  function failure(error) {
    return {
      type: employeeConstants.ACTIVE_DELETE_FAILURE,
      payload: { error }
    }
  }
}

function deleteInactive(id) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.delete(`/users/${id}`)
      .then((response) => {
        dispatch(success(id))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Usuario eliminado exitosamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido eliminar el usuario'
          })
        )
      })
  }

  function start() {
    return {
      type: employeeConstants.INACTIVE_DELETE_STARTED
    }
  }

  function success(id) {
    return {
      type: employeeConstants.INACTIVE_DELETE_SUCCESS,
      payload: { id }
    }
  }

  function failure(error) {
    return {
      type: employeeConstants.INACTIVE_DELETE_FAILURE,
      payload: error
    }
  }
}

const updateUser = (user, data) => (dispatch) => {
  dispatch(start())
  dispatch(uiActions.startLoading())
  Axios.patch(`/users/${user}`, data)
    .then((response) => {
      dispatch(success(response.data.user[0]))
      dispatch(uiActions.endLoading())
      dispatch(
        enqueueSnackbar({
          variant: 'success',
          message: `Usuario actualizado`
        })
      )
    })
    .catch((error) => {
      dispatch(failure(error.response))
      dispatch(uiActions.endLoading())
      dispatch(
        enqueueSnackbar({
          variant: 'error',
          message: `No se ha podido actualizar el usuario`
        })
      )
    })

  function start() {
    return {
      type: employeeConstants.UPDATE_STARTED
    }
  }

  function success(response) {
    return {
      type: employeeConstants.UPDATE_SUCCESS,
      payload: response
    }
  }

  function failure(error) {
    return {
      type: employeeConstants.UPDATE_FAILURE,
      payload: error
    }
  }
}

export const employeeActions = {
  updateStatus,
  fetchActive,
  fetchInactive,
  deleteActive,
  deleteInactive,
  updateUser
}
