import React, { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ShowMoreText from 'react-show-more-text'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import griContentReqs from '../../data/gri_requirements.json'
import {
  Grid,
  InputAdornment,
  Card,
  CardContent,
  Typography,
  Tooltip
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import { indicatorConstants } from '../../state/types'
import {
  validForm,
  updateFormData,
  generateReduxData
} from '../../helpers/form.helper'
import FormField from '../UI/FormFields'
import { useHandleError } from '../../hooks/useHandleError'
import { finalBeautify, numberFieldChange } from '../../helpers/numbers.helper'
import ButtonWithLoading from '../UI/ButtonWithLoading'
import SelectNative from '../UI/SelectNative'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: '0.8em',
    background:
      'linear-gradient(to bottom right, rgba(65, 178, 197, 1) 0%, rgba(36, 86, 119, 1) 100%)',
    // color: theme.palette.grey['700'],
    color: theme.palette.common.white,
    borderRadius: 9,
    maxWidth: 400,
    border: 0,
    boxShadow: theme.shadows[5]
  }
}))(Tooltip)

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275
  },
  title: {
    fontSize: 14
  },
  textTail: {
    fontSize: 14,
    color: theme.palette.text.secondary
  },
  pos: {
    marginBottom: 12
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const types = [
  {
    value: 'day',
    label: 'Días'
  },
  {
    value: 'week',
    label: 'Semanas'
  },
  {
    value: 'month',
    label: 'Meses'
  },
  {
    value: 'year',
    label: 'Años'
  }
]
const cumulativeOptions = [
  {
    value: true,
    label: 'Si'
  },
  {
    value: false,
    label: 'No'
  }
]

const sel = [
  { label: 'Cualitativo', value: 'CUALITATIVO' },
  { label: 'Cuantitativo', value: 'CUANTITATIVO' }
]

const BasicInfo = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  // component states
  const [loading, setLoading] = useState(false)
  const [submitOk, setSubmitOk] = useState(false)
  const [error, setError] = useState(null)

  //redux states
  const { data, create, indicatorValid } = useSelector(
    (state) => state.indicators.indicatorInProgress
  )

    console.log(data.quantitative);
    console.log(indicatorValid);
    // console.log(data.name);

  // formData

  const [formData, setValues] = useState({
    //Cauntitativo y cualitativo
    cualitativo: {
      element: 'select',
      value: data.quantitative == undefined ? data.cualitativo : data.quantitative ? 'CUANTITATIVO' : 'CUALITATIVO',
      options: sel,
      config: {
        id: 'cualitativo',
        name: 'cualitativo',
        label: 'Seleccionar tipo de indicador',
        placeholder: 'Seleccionar  tipo de indicador',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true,
        select: true,
        disabled: !create ? true : false
      },
      validation: {
        required: true
      },
      valid: true,
      validationMessage: ''
    },
    // nombre
    name: {
      element: 'input',
      value: data.name ? data.name : '',
      config: {
        id: 'name',
        name: 'name',
        label: 'Nombre del indicador',
        type: 'text',
        placeholder: 'Nombre del indicador',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: true
      },
      valid: data.name ? true : false,
      validationMessage: 'Nombre es requerido'
    },
    // descripción
    description: {
      element: 'input',
      value: data.description ? data.description : '',
      // value: props.indicator ? props.indicator.description : "",
      config: {
        id: 'description',
        name: 'description',
        label: 'Descripción',
        type: 'text',
        placeholder: 'Descripción indicador',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true
      },
      validation: {
        required: false
      },
      valid: true,
      validationMessage: 'Descripción es requerido'
    },
    // unidad de medida
    measure: {
      element: 'input',
      value: data.measure ? data.measure : '',
      config: {
        id: 'measure',
        name: 'measure',
        label: 'Unidad de medida',
        placeholder: 'Unidad de medida',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true,
        type: 'text'
      },
      validation: {
        required: false
      },
      valid: true,
      validationMessage: 'Unidad de medida es requerido'
    },
    // frecuencia
    frecuency: {
      element: 'input',
      value: data.frecuency ? data.frecuency : '',
      config: {
        value: '',
        id: 'frecuency',
        name: 'frecuency',
        label: 'Frecuencia de medicíón',
        placeholder: 'Cada cuánto tiempo',
        variant: 'outlined',
        margin: 'normal',
        type: 'number',
        fullWidth: true
      },
      validation: {
        required: true
      },
      valid: data.frecuency ? true : false,
      validationMessage: 'Frecuencia de medición es requerida'
    },
    // intervalo
    frecuencyType: {
      element: 'select',
      value: data.frecuencyType,
      options: types,
      config: {
        id: 'frecuencyType',
        name: 'frecuencyType',
        label: 'Intervalo',
        placeholder: 'Intervalo',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true,
        select: true
      },
      validation: {
        required: true
      },
      valid: data.frecuencyType ? true : false,
      validationMessage: 'Tipo de frecuencia es requerido'
    },
    // meta
    goal: {
      element: 'input',
      value: data.goal ? data.goal : '',
      config: {
        value: '',
        id: 'goal',
        name: 'goal',
        label: 'Meta Global',
        placeholder: 'Valor al que quiero llegar de manera global',
        variant: 'outlined',
        margin: 'normal',
        type: 'text',
        fullWidth: true
      },
      validation: {
        required: false
      },
      valid: true
    },
    cumulative: {
      element: 'select',
      value: data.cumulative ? data.cumulative : false,
      options: cumulativeOptions,
      config: {
        id: 'cumulativeId',
        name: 'cumulative',
        label: '¿Es acumulativo?',
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true,
        select: true
      },
      validation: {
        required: true
      },
      valid: data.cumulative ? true : false,
      validationMessage: 'Indique por favor si el indicador es acumulativo o no'
    }
  })
  // ir verificando la validez de las entradas del usuario en el formulario
  useEffect(
    () => {
      //verificar si la pieza de estado clear es true

      dispatch({
        type: indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.SET,
        payload: {
          indicatorValid: validForm(formData),
          data: generateReduxData(formData)
        }
      })
    },
    // eslint-disable-next-line
    [formData]
  )

  // checking formData errors
  const [formDataWithErrors] = useHandleError(formData, error)
  useEffect(() => {
    setValues(formDataWithErrors)
  }, [formDataWithErrors])

  //onchange action
  const updateForm = (event) => {
      console.log(event.target.name);
      console.log(event.target.name);
      if(event.target.value == 'CUALITATIVO'){
        console.log("CUALI");
        event.preventDefault()
        setValues(updateFormData(formData, event.target.name, event.target.value))
        // setValues(updateFormData(formData, "measure", "KG"));
        // event.preventDefault()
        // setValues(updateFormData(formData, "measure", "KG"))
      } else if(event.target.value == 'CUANTITATIVO') {
        console.log("CUANTI");
        event.preventDefault()
        setValues(updateFormData(formData, event.target.name, event.target.value))
        // setValues(updateFormData(formData, "measure", ""));
        // formData.measure = "CUANTI";
        // document.getElementById('measure').value = '';
        // event.preventDefault()
        // setValues(updateFormData(formData, "measure", ""))
      } else {
        console.log("OTRO");
        // console.log(event.target.name);
        // console.log(event.target.value);
        // console.log(data);  
        event.preventDefault()
        setValues(updateFormData(formData, event.target.name, event.target.value))
      }
      // console.log("cuali");
      // console.log(event.target.name);
      // console.log(event.target.value);
      
      // formData.measure = "kg";
    // if(event.target.name == 'cualitativo'){
    //   if(event.target.value == 'CUALITATIVO'){      
    //     event.preventDefault()  
    //     setValues(updateFormData(formData, "measure", "NULL"))
    //   }
    // }
      
  }

  // onBlur event
  const onBlur = (event) => {
    event.preventDefault()
    if (event.target.name === 'goal') {
      event.target.value = finalBeautify(event.target.value)
    }
    setValues(updateFormData(formData, event.target.name, event.target.value))
  }

  const submitForm = (event) => {
    event.preventDefault()
    const submit = async () => {
      setLoading(true)
      // setError(null)
      dispatch({
        type: indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.SET,
        payload: {
          indicatorValid: validForm(formData),
          data: generateReduxData(formData)
        }
      })
      setLoading(false)
    }
    submit()
  }

  // este es un submit para almacenar en el estado Redux las selecciones del usuario
  const thisStepIsDone = (event) => {
    console.log(formData)
    event.preventDefault()
    const submit = () => {
      setTimeout(() => {
        dispatch({
          type: indicatorConstants.INDICATOR_IN_PROGRESS_DIALOG.SET,
          payload: { tabValue: 2 },
          indicatorValid: validForm(formData)
        })
      }, 500)
    }
    submit()
  }

  // bring the requirements associated with the selected content

  const GriRequirements = (props) => {
    // showMoreTextFunction and state
    const [isExpanded, setIsExpanded] = useState(false)
    const executeOnClick = (isExpanded) => {
      console.log(isExpanded)
    }
    console.log(data);
    // Data preparation
    let bajada = null
    let specifics = []
    let specificLiteralskeys = []
    let specificLiterals = ''
    let contentIndex = ''
    if (data.content) {
      contentIndex = data.content.name.split(' ')[0]
      console.log(griContentReqs)
      console.log(contentIndex)
      specifics = griContentReqs.filter((ele) => ele.name === contentIndex)
      specifics = specifics[0]
      specificLiterals = specifics['literals']

      if (specificLiterals.hasOwnProperty('bajada')) {
        bajada = specificLiterals.bajada
      }
      specificLiteralskeys = Object.keys(specificLiterals).filter(
        (ele) => ele !== 'bajada'
      )
    }

    //

    return (
      <Fragment>
        <ShowMoreText
          lines={1}
          more='Leer más'
          less='Leer menos'
          onClick={executeOnClick}
          expanded={false}
          className={classes.textTail}
          anchorClass={classes.title}
        >
          <Typography variant='subtitle2' gutterBottom>
            {bajada}
            bajada
          </Typography>
          {specificLiterals !== '' &&
            specificLiteralskeys.length > 0 &&
            specificLiteralskeys.map((ele) => (
              <Fragment key={ele}>
                <Typography
                  className={classes.title}
                  color='textSecondary'
                  gutterBottom
                >
                  {' '}
                  {ele}. {specificLiterals[ele]}
                </Typography>
              </Fragment>
            ))}
        </ShowMoreText>
      </Fragment>
    )
  }
  // card to show the user which labels chose in the previous step

  const indicatorSynthesis = () => {
    return (
      <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.title} color='secondary' gutterBottom>
            El indicador en creación está relacionado con el contenido:
          </Typography>
          <Typography variant='subtitle1' component='h2' gutterBottom>
            {data.content
              ? data.content.name
              : 'no se ha asociado la etiqueta Contenido a este indicador'}
          </Typography>
          <GriRequirements />
        </CardContent>
      </Card>
    )
  }

  return (
    <Fragment>
      {indicatorSynthesis()}
      <form onSubmit={(event) => submitForm(event)}>
        <Grid container direction='column'>
          <Grid item>
          <FormField
              id={'cualitativo'}
              formData={formData.cualitativo}
              change={() => updateForm}
            />
          </Grid>
          <Grid item>
            {/* nombre */}
            <FormField
              id={'name'}
              formData={formData.name}
              change={() => updateForm}
            />
          </Grid>
          
          <Grid item container spacing={1}>
            {/* descripción y unidad de medida */}
            {formData.cualitativo.value == 'CUANTITATIVO' ? 
              <Grid item xs={12} md={9}>
                <FormField
                  id={'description'}
                  formData={formData.description}
                  change={() => updateForm}
                />
              </Grid>
            : 
            <Grid item xs={12} md={12}>
              <FormField
                id={'description'}
                formData={formData.description}
                change={() => updateForm}
              />
            </Grid>}
            {formData.cualitativo.value == 'CUANTITATIVO' ? 
                <Grid item xs={12} md={3}>
                {/* <Grid item item xs={12} md={3}> */}
                <FormField
                  id={'measure'}
                  formData={formData.measure}
                  change={() => updateForm}
                />
                </Grid> 
              : ""}
            
          </Grid>
          <Grid item container direction='row'>
            <Grid item container direction='row' spacing={2}>
              {/* <Grid item md={2} xs={6}>
                <Typography style={{ textAlign: 'center' }}>
                  Frecuencia
                </Typography>
              </Grid> */}

              <Grid item md={6} xs={12}>
                <SelectNative
                  id={'frecuency'}
                  formData={formData.frecuency}
                  change={() => updateForm}
                  inputProps={{ inputProps: { min: 0 } }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <SelectNative
                  id={'frecuencyType'}
                  formData={formData.frecuencyType}
                  change={() => updateForm}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction='row'
            spacing={3}
            alignItems='flex-end'
          >
            <Grid item md={6} xs={4}>
              {/* Meta */}
              <SelectNative
                id={'goal'}
                formData={{
                  ...formData.goal,
                  config: { ...formData.goal.config, onBlur }
                }}
                change={() => updateForm}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {formData.measure.value ? formData.measure.value : ''}
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item md={4} xs={4}>
              {/* Meta */}
              <SelectNative
                id={'cumulativeId'}
                formData={formData.cumulative}
                change={() => updateForm}
              />
            </Grid>
            <Grid item md={2} xs={4} style={{ marginBottom: '0.5em' }}>
              <HtmlTooltip
                placement='top-end'
                title={
                  <div className={classes.tooltipContent}>
                    <div style={{ paddingLeft: '20px', paddingTop: '10px' }}>
                      {' '}
                      Indicador acumulativo es aquel en que se suman los
                      diferentes registros dentro de un período.
                      <em> Ej. Toneladas de residuos</em>
                    </div>{' '}
                    <p> </p>
                    <div style={{ paddingLeft: '20px', paddingBottom: '12px' }}>
                      Indicador no acumulativo es aquel en que se hacen
                      registros puntuales pero estos no se suman.
                      <em> Ej.Porcentaje de mujeres</em>
                    </div>
                    <br />
                  </div>
                }
              >
                <HelpIcon color='secondary' />
              </HtmlTooltip>
            </Grid>
          </Grid>
        </Grid>

        {/* botón guardar  */}
        <Grid container spacing={3}>
          <Grid item xs>
            <ButtonWithLoading
              text='Siguiente'
              loading={loading}
              submitOk={submitOk}
              onClick={thisStepIsDone}
              disabled={loading || !indicatorValid}
              fullWidth
            />
          </Grid>
        </Grid>
      </form>
    </Fragment>
  )
}

export default BasicInfo
