import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks'
import isSameWeek from 'date-fns/isSameWeek'
import startOfWeek from 'date-fns/startOfWeek'
import getISOWeek from 'date-fns/getISOWeek'

/**
 *
 * @param {String} initial Fecha inicial del rango de tiempo yyyy-mm-ddThh:mm:ss
 * @param {String} final Fecha final del rango de tiempo yyyy-mm-ddThh:mm:ss
 * @param {*} dateToCheck Fecha a verificar si se encuentra dentro del rango yyyy-mm-ddThh:mm:ss
 * @returns
 */
export const dateIsInRange = (initial, final, dateToCheck) => {
  const i = Date.parse(initial)
  const f = Date.parse(final)
  const checkit = Date.parse(dateToCheck)
  if (checkit <= f && checkit >= i) {
    return true
  } else {
    return false
  }
}

export const semestralCats = [
  '-01-01T00:00:00',
  '-06-30T23:59:00',
  '-07-01T00:00:00',
  '-12-31T23:59:00'
]

export const tellMeWhichYearSemester = (date) => {
  let year = new Date(date).getFullYear()
  let parsedDate = Date.parse(date)
  // si la fecha es menor a junio y mayor a enero -- del año
  if (
    parsedDate <= Date.parse(`${year}${semestralCats[1]}`) &&
    parsedDate >= Date.parse(`${year}${semestralCats[0]}`)
  ) {
    return `1° Semestre ${year}`
  }
  // si la fecha es menor a diciembre y mayor o igual a julio -- del año
  if (
    parsedDate <= Date.parse(`${year}${semestralCats[3]}`) &&
    parsedDate >= Date.parse(`${year}${semestralCats[2]}`)
  ) {
    return `2° Semestre ${year}`
  }
}

export const trimestralCats = [
  '-01-01T00:00:00', // primero de enero a primera hora[0]
  '-03-31T23:59:00', // ultimo de marzo a último minuto[1]
  '-04-01T00:00:00', // primero de abril[2]
  '-06-30T23:59:00', // ultimo de junio[3]
  '-07-01T00:00:00', // primero de julio[4]
  '-09-30T23:59:00', // último de septiembre[5]
  '-10-01T00:00:00', // primero de octubre[6]
  '-12-31T23:59:00' // último de diciembre[7]
]

export const tellMeWhichYearTrimester = (date) => {
  let year = new Date(date).getFullYear()
  let parsedDate = Date.parse(date)
  // si la fecha es menor a junio y mayor a enero -- del año
  if (
    parsedDate <= Date.parse(`${year}${trimestralCats[1]}`) &&
    parsedDate >= Date.parse(`${year}${trimestralCats[0]}`)
  ) {
    return `1° Trimestre ${year}`
  }
  // si la fecha es menor a diciembre y mayor o igual a julio -- del año
  if (
    parsedDate <= Date.parse(`${year}${trimestralCats[3]}`) &&
    parsedDate >= Date.parse(`${year}${trimestralCats[2]}`)
  ) {
    return `2° Trimestre ${year}`
  }
  if (
    parsedDate <= Date.parse(`${year}${trimestralCats[5]}`) &&
    parsedDate >= Date.parse(`${year}${trimestralCats[4]}`)
  ) {
    return `3° Trimestre ${year}`
  }
  if (
    parsedDate <= Date.parse(`${year}${trimestralCats[7]}`) &&
    parsedDate >= Date.parse(`${year}${trimestralCats[6]}`)
  ) {
    return `4° Trimestre ${year}`
  }
}

export const giveMeYearAndMonth = (date) => {
  const year = new Date(date).getFullYear()
  const month = new Date(date).getMonth() + 1
  return `${year}-${month}`
}

export const reverseDateToYearAndMonth = (dateObj) => {
  // console.log('dateObj', dateObj)
  const year = dateObj.getFullYear()
  const month = dateObj.getMonth() + 1
  return `${year}-${month}`
}

export const calcSpanYears = (domain) => {
  let start = new Date(domain[0])
  let yearToStart = start.getFullYear()
  let end = new Date(domain[1])
  let yearToEnd = end.getFullYear()
  let spanYears = []
  for (let i = yearToStart; i <= yearToEnd; i++) {
    spanYears.push(i)
  }
  return spanYears
}

export const formYMToDate = (YMPair) => {
  // console.log('YMPair', YMPair)
  const year = YMPair.split('-')[0]
  // const month = new Date(year, YMPair.split('-'[1])).getMonth() + 1
  let month = parseInt(YMPair.split('-')[1])
  // console.log(year, month, new Date(year, month - 1, 1))
  return new Date(year, month - 1, 1)
}

export const dateParsedToGraphic = (YMPair) => {
  const year = YMPair.split('-')[0]
  const month = parseInt(YMPair.split('-')[1])
  // console.log(year, month, new Date(year, month - 1, 1))
  return new Date(year, month - 1, 1)
  // console.log(new Date(year, month, 0))
  // return new Date(year, month, 0)
}

/**
 *
 * @param {*} begining Date del primer registro
 * @param {*} end Date del último registro actual
 * @returns
 */
export const weeksInSpanYears = (begining, end) => {
  // el año de inicio de registros
  const begYear = new Date(begining).getFullYear()
  const endYear = new Date(end).getFullYear()
  // recordar en Date obj los meses tienen índice 0
  const endMonth = '11'
  const begMonth = '00'
  const begDay = '01'
  const endDay = '31'
  const weekStartsDay = new Date(begYear, begMonth, begDay).getDay()
  let limitsArray = []

  const result = differenceInCalendarWeeks(
    new Date(begYear, begMonth, begDay),
    new Date(endYear, endMonth, endDay),
    {
      weekStartsOn: weekStartsDay
    }
  )
  let numberOfWeeks = Math.abs(result)
  const initialDate = new Date(begYear, begMonth, begDay)
  for (let i = 0; i <= result; i++) {
    new Date(initialDate.getFullYear(), initialinitialDate.getDay() + 7)
  }
  return limitsArray
}

export const whichNumberOfWeek = (date) => {
  const targetYear = date.getFullYear()
  const beginOfWeek = startOfWeek(date, { weekStartsOn: 1 })
  const weekNumber = getISOWeek(date, { weekStartsOn: 1 })
  return { targetYear, weekNumber, beginOfWeek }
}

// créditos de la siguiente función
// https://stackoverflow.com/questions/16590500/calculate-date-from-week-number-in-javascript
export const fromWeekNumberToWeekStart = (weekNum, year) => {
  const simple = new Date(year, 0, 1 + (weekNum - 1) * 7)
  const dow = simple.getDay()
  var ISOweekStart = simple
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1)
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay())
  return ISOweekStart
}

export const areInTheSameWeek = (weekStart, dateToCompare) => {
  return isSameWeek(weekStart, dateToCompare, {
    weekStartsOn: 1
  })
}
