import React, { Fragment } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'

const FormField = ({ formData, change, inputProps }) => {
  // let fieldError = false // Para setear la variable 'error' en TextField
  // console.log('formData', formData, 'inputProps', inputProps)
  const showError = () => {
    const hasError = formData.validation && !formData.valid
    // if (hasError) {
    //   fieldError = true
    // } else {
    //   fieldError = false
    // }
    let errorMessage = (
      <div className='error_label'>
        {hasError ? formData.validationMessage : null}
      </div>
    )
    return errorMessage
  }  

  if(formData.config.id == "perspective"){
    // console.log(formData);
  }  

  const renderTemplate = () => {
    let formTemplate = null
    switch (formData.element) {
      case 'input':
        formTemplate = (
          <Fragment>
            <TextField
              {...formData.config}
              value={formData.value}
              onChange={change()}
              error={formData.hasError}
              InputProps={inputProps}
            />
            {formData.hasError ? showError() : null}
          </Fragment>
        )
        break
      case 'select':
        const options = formData.options        
        if (formData.autocomplete) {
          formTemplate = (
            <Autocomplete
              id={formData.config.id}
              options={options}
              autoHighlight
              value={formData.value}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <Fragment>
                  <TextField
                    {...params}
                    {...formData.config}
                    value={formData.value}
                    error={formData.hasError}
                    inputProps={{ ...inputProps, autoComplete: 'new-password' }}
                  />
                  {formData.hasError ? showError() : null}
                </Fragment>
              )}
            />
          )
        } else {
          formTemplate = (
            <Fragment>
              <TextField
                {...formData.config}
                value={formData.value}
                onChange={change()}
                error={formData.hasError}
                inputProps={inputProps || formData.config.inputProps}
              >
                {options &&
                  options.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      disabled={formData.config.disabled}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>

              {formData.hasError ? showError() : null}
            </Fragment>
          )
        }

        break
      default:
        formTemplate = null
    }

    return formTemplate
  }

  return <Fragment>{renderTemplate()}</Fragment>
}

export default FormField
