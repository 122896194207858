import { createTheme } from '@material-ui/core/styles'

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#35647e',
      light: '#6492ad',
      dark: '#003a52',
      contrastText: '#ffffff'
    },
    secondary: {
      // main: '#26cad3',
      main: '#00C3CA',
      light: '#6efeff',
      dark: '#0099a2',
      contrastText: '#000000'
    },
    info: {
      main: '#a866fe',
      light: '#8033a3',
      dark: '#60008c',
      contrastText: '#ffffff'
    },
    gradientTopBottom: {
      main: 'linear-gradient(to bottom right, rgba(65, 178, 187, 1) 0%, rgba(36, 86, 119, 1) 100%)',
      translucid:
        'linear-gradient(to bottom right, rgba(65, 178, 187, 0.5) 0%, rgba(36, 86, 119, 0.5) 100%)'
      // main: 'linear-gradient(to bottom right, , rgba(36, 86, 119, 1) 0%, rgba(65, 178, 187, 1) 100%)'
    }
    // error: {},
    // warning: {},
    // info: {},
    // success: {}
  },
  typography: {
    // h1: "",
    // h2: "",
    // h3: "",
    // h4: "",
    // h5: "",
    // h6: "",
    // subtitle1: "",
    // subtitle2: "",
    // body1: "",
    // body2: "",
    // button: "",
    // caption: "",
    // overline: ""
  },
  overrides: {
    MuiListItem: {
      // For ListItem, change this to MuiListItem
      root: {
        '&$selected': {
          // this is to refer to the prop provided by M-UI
          backgroundColor: '#6492ad' // updated backgroundColor
        }
      }
    }
  }
})
