import { initiativeConstants } from '../types'
import { uiActions } from './ui.actions'
import { enqueueSnackbar } from './notification.actions'
import Axios from '../../axios'

function fetchAll(organizationId) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.get(`/companies/${organizationId}/initiatives/${localStorage.getItem('userId') ? localStorage.getItem('userId') : sessionStorage.getItem('userId')}`)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'warning',
            message: 'No se ha podido cargar las iniciativas'
          })
        )
      })
  }

  function start() {
    return {
      type: initiativeConstants.FETCH_STARTED
    }
  }

  function success(data) {
    return {
      type: initiativeConstants.FETCH_SUCCESS,
      payload: data.initiatives
    }
  }

  function failure(error) {
    return {
      type: initiativeConstants.FETCH_FAILURE,
      payload: error
    }
  }
}

/**
 * Permite crear una iniciativa, tanto su información como etiquetas
 */

const create = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post('/initiatives', data)
        .then((response) => {
          dispatch({
            type: initiativeConstants.CREATE,
            payload: response.data.initiative
          })
          resolve()
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido crear la iniciativa'
            })
          )
          reject(err.response)
        })
    })
  }
}

/**
 * Permite actualizar una iniciativa, tanto su información como etiquetas
 */

const update = (initiativeId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.patch(`/initiatives/${initiativeId}`, data)
        .then((response) => {
          dispatch({
            type: initiativeConstants.UPDATE,
            payload: {
              initiativeId: initiativeId,
              data: response.data.initiative
            }
          })
          resolve()
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido actualizar la iniciativa'
            })
          )
          reject(err.response)
        })
    })
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.delete(`/initiatives/${id}`)
      .then((response) => {
        dispatch(success(id))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Iniciativa eliminada exitosamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido eliminar la iniciativa'
          })
        )
      })
  }

  function start() {
    return {
      type: initiativeConstants.DELETE_STARTED
    }
  }

  function success() {
    return {
      type: initiativeConstants.DELETE_SUCCESS,
      payload: { id }
    }
  }

  function failure(error) {
    return {
      type: initiativeConstants.DELETE_FAILURE,
      payload: error
    }
  }
}

function addIndicator(data) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.post(`/initiatives/${data.initiativeId}/indicators`, {
      initiativeIndicator: {
        indicatorId: data.indicatorId
      }
    })
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Indicator asociado exitosamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido asociar el indicador'
          })
        )
      })
  }

  function start() {
    return {
      type: initiativeConstants.ADD_INDICATOR_STARTED
    }
  }

  function success(data) {
    return {
      type: initiativeConstants.ADD_INDICATOR_SUCCESS,
      payload: {
        indicator: data.indicator,
        initiative: data.initiativeId
      }
    }
  }

  function failure(error) {
    return {
      type: initiativeConstants.ADD_INDICATOR_FAILURE,
      payload: error
    }
  }
}

function addIndicatorEncargados(data) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.post(`/initiativesPermission`, {
      initiative: {
        initiativeId: data.initiativeId,
        encargados: data.privilegios
      }
    })
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Roles asociados exitosamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido asociar los roles'
          })
        )
      })
  }

  function start() {
    return {
      type: initiativeConstants.ADD_INDICATOR_STARTED
    }
  }

  function success(data) {
    return {
      type: initiativeConstants.ADD_INDICATOR_SUCCESS,
      payload: {
        indicator: data.indicator,
        initiative: data.initiativeId
      }
    }
  }

  function failure(error) {
    return {
      type: initiativeConstants.ADD_INDICATOR_FAILURE,
      payload: error
    }
  }
}

function deleteIndicator(initiativeId, indicatorId) {
  return (dispatch) => {
    dispatch(start())
    dispatch(uiActions.startLoading())
    Axios.delete(`/initiatives/${initiativeId}/indicators/${indicatorId}`)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'success',
            message: 'Indicador eliminado exitosamente'
          })
        )
      })
      .catch((error) => {
        dispatch(failure(error.response))
        dispatch(uiActions.endLoading())
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: 'No se ha podido eliminar el indicador'
          })
        )
      })

    function start() {
      return {
        type: initiativeConstants.DELETE_INDICATOR_FAILURE
      }
    }

    function success(data) {
      return {
        type: initiativeConstants.DELETE_INDICATOR_SUCCESS,
        payload: {
          initiativeId: initiativeId,
          indicatorId: indicatorId
        }
      }
    }

    function failure(error) {
      return {
        type: initiativeConstants.DELETE_INDICATOR_FAILURE,
        payload: error
      }
    }
  }
}

/**
 * Obtiene los requerimientos de un reporte
 * @param {integer} initiativeID - ID de la iniciativa a generar reporte
 */

const fetchRequirements = (initiativeId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.get(`/initiatives/${initiativeId}/report/requirements`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'Ha ocurrido un error'
            })
          )
          reject(error.response)
        })
    })
  }
}

/**
 * Crear reporte
 * @param {integer} initiativeId - ID de la iniciativa a crear reporte
 * @param {object} data - data del reporte
 */

const createReport = (initiativeId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/initiatives/${initiativeId}/report`, { report: data })
        .then((response) => {
          dispatch({
            type: initiativeConstants.REPORTS.CREATE,
            payload: {
              initiativeId: initiativeId,
              report: response.data.report
            }
          })
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }
}

/**
 * Actualizar reporte
 * @param {integer} initiativeId - ID de la iniciativa a actualizar reporte
 * @param {object} data - data del reporte
 */

const updateReport = (initiativeId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.patch(`/initiatives/${initiativeId}/report`, { report: data })
        .then((response) => {
          dispatch({
            type: initiativeConstants.REPORTS.UPDATE,
            payload: {
              initiativeId: initiativeId,
              report: response.data.report
            }
          })
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }
}

/**
 * Descargar reporte
 * @param {integer} initiativeId - ID de la iniciativa a descargar reporte
 */

const downloadReport = (initiativeId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.get(`/initiatives/${initiativeId}/report/download`, {
        responseType: 'arraybuffer'
      })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              variant: 'error',
              message: 'No se ha podido descargar el reporte'
            })
          )
          reject(error.response)
        })
    })
  }
}

/**
 * Guardar reporte
 * @param {integer} initiativeId - ID de la iniciativa a crear reporte
 * @param {object} data - data del reporte a guardar
 */

const saveReport = (initiativeId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/initiatives/${initiativeId}/report/save`, {
        snapshot: { ...data }
      })
        .then((response) => {
          dispatch({
            type: initiativeConstants.REPORTS.SAVE_SNAPSHOT,
            payload: {
              snapshot: response.data.snapshot,
              initiativeId: initiativeId
            }
          })
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }
}

/**
 * Obtener reporte
 * @param {integer} initiativeId - ID de la iniciativa a crear reporte
 */

const getReport = (initiativeId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Axios.get(`/initiatives/${initiativeId}/report`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  }
}

export const initiativeActions = {
  fetchAll,
  create,
  update,
  _delete,
  addIndicator,
  deleteIndicator,
  createReport,
  fetchRequirements,
  updateReport,
  downloadReport,
  saveReport,
  getReport,
  addIndicatorEncargados
}
