import React, { useEffect, useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import NoContent from '../components/UI/NoContent'
import { Typography, Fab, Container,Button,Grid, useTheme } from '@material-ui/core'
import Progress from '../components/UI/Progress'
import Can from '../auth/Can'
import { Add as AddIcon,
        Visibility as VisibilityIcon,
        Edit,
        CloudDownload,
        Search,
        Delete,
        HelpOutline,
        Info, 
        Visibility} from '@material-ui/icons'
import ShowReports from '../components/Reports/ShowReports'
import CustomDialog from '../components/UI/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { reportActions, uiActions } from '../state/actions'
import GlobalReportsDialogTabs from '../components/Reports/GlobalReportsDialogTabs'
import { reportConstants } from '../state/types'
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { FormField } from 'boosted-materialui-forms'
import { Link } from 'react-router-dom'
import LogsNewVersion from '../components/Indicator/LogsNewVersion'
import TextField from '@mui/material/TextField';
import ButtonWithLoading from '../components/UI/ButtonWithLoading'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TableContainer } from '@mui/material';
import Axios from '../axios'
import Status from '../components/Indicator/Status'
import IndcTabs from '../components/Indicator/IndcTabs'
import griContentReqs from '../data/gri_requirements.json'
import ShowMoreText from 'react-show-more-text'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { enqueueSnackbar } from '../state/actions/notification.actions'

// "/Users/anibal/Desktop/Inosoft/Tero/tero-frontend-master/src/data/gri_requirements"
// src/data/gri_requirements.json src/data/gri_requirements.json

const Paso5 = () => {

  // console.log(griContentReqs)

    const [valuePaso5, setValuePaso5] = React.useState(-1);

    const dispatch = useDispatch()

    var a = [];

    const [DataInfoReport, setDataInfoReport] = useState([]);

    const [buttonActive, setButtonActive] = useState(0);

    const [buttonActiveLogs, setButtonActiveLogs] = useState(0);

    const [openDoc, setOpenDoc] = useState(false)

    const [dataLogs, setDataLogs] = useState([]);

    const [openDocLogs, setOpenDocLogs] = useState(false)

    const [nameIni, setNameIni] = useState("")

    const { user } = useSelector((state) => state.user)

    const [isExpanded, setIsExpanded] = useState(false)
    const executeOnClick = (isExpanded) => {
      console.log(isExpanded)
    }
  
    const handleChangePaso5 = (event, newValue) => {
        console.log(newValue);
        setValuePaso5(newValue);
    };

    const clickTab = (data) => {

      console.log(data)

      setButtonActive(0);
      setDataInfo([])

      setTimeout(() => {
        let bajada = null
        let specifics = []
        let specificLiteralskeys = []
        let specificLiterals = ''
        let contentIndex = ''
        setNameIni(data.name);
        if(data.state == 0){
          setButtonActiveLogs(0)
        } else {
          setButtonActiveLogs(1)
        }
        console.log(data.indicators)
        data.indicators.map((us,i) => {

          contentIndex = us.content.name.split(' ')[0]
          console.log(contentIndex)
          specifics = griContentReqs.filter((ele) => ele.name === contentIndex)
          specifics = specifics[0]
          specificLiterals = specifics['literals']
          // us.alcance = "AGGREGATE"
                
            let item = {"contentId": us.content.id, 
                        "text": us.content.text != null ? us.content.text : '', 
                        "reportId": report.id, 
                        "initiativeId": us.initiativeId, 
                        "revisores": [], 
                        "indicatorId": us.id, 
                        "specificLiterals": specificLiterals,
                        "alcance": us.alcance == null ? '' : us.alcance,
                        "frecuency": us.frecuency == null ? '' : us.frecuency,
                        "userId" : user.id};
            a.push(item);

            if(us.content.id != null){
                setButtonActive(1);
            } else {
                setButtonActive(0);
            }

            if (specificLiterals.hasOwnProperty('bajada')) {
              bajada = specificLiterals.bajada
            }
            specificLiteralskeys = Object.keys(specificLiterals).filter(
              (ele) => ele !== 'bajada'
            )
            us.bajada = bajada
            us.specificLiteralskeys = specificLiteralskeys
            us.specificLiterals = specificLiterals
          }) 
        console.log(a);
        setDataInfoReport(a)
        console.log(data.indicators);
        setDataInfo(data.indicators);
      }, 10)

      
    };

    const test = () => {
        console.log(DataInfoReport);
        setOpenDoc(true);
    };

    const seeReportsHistorial = () => {

      console.log(report.id)
      console.log(DataInfo[0].initiativeId)

      // console.log(idReport);
      // console.log(idInitiative)
  
      Axios.get(`/customreports/reportLogs/list/report/${report.id}/initiative/${DataInfo[0].initiativeId}`)
        .then((response) => {
            console.log(response);
            setDataLogs(response.data.reportLogs)
            setOpenDocLogs(true);
        })
        .catch((err) => {
          console.log(err.response);
        })    
    }

    const saveReports = () => {

      console.log(DataInfoReport)

      // console.log(idReport);
      // console.log(idInitiative)
  
      Axios.post(`/savereportcontent`, DataInfoReport)
        .then((response) => {
            console.log(response);
            if(response.data.status == 1){
              dispatch(
                enqueueSnackbar({
                  variant: 'success',
                  message: 'Datos guardados correctamente'
                })
              )  
            }
        })
        .catch((err) => {
          console.log(err.response);
        })    
    }

    const [columns1, setColumns] = useState([
      //Por cada {} es una columna donde name es el titulo del header
      //Y options sirve para crear filtros personalizados etc.
      {
        name: "Data",
        options: {
          display: false
        },
      },
      {
        name: "Indicador",
        options: {}
      },
      {
        name: "ODS",
        options: {}
      },
      {
        name: "Alcance",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {

            // let b;
            // console.log(tableMeta.rowData[0].alcance)
            // if(tableMeta.rowData[0].alcance == null){
            //   b = "Seleccionar"
            // } else {
            //   if(tableMeta.rowData[0].alcance == "AGGREGATE"){
            //     b = "Por instalación"
            //   } else if(tableMeta.rowData[0].alcance == "BSNS") {
            //     b = "Líneas de negocio"
            //   } else if(tableMeta.rowData[0].alcance == "GLOBAL") {
            //     b = "Nivel Corporativo"
            //   }
            // }

            return (
              <div >
                <FormControl variant="outlined" style={{ minWidth: 125, maxHeight:50 }}>
                  <InputLabel id="demo-simple-select-helper-label">Seleccionar</InputLabel>
                    <Select
                      key={value}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleToast}
                      label="Seleccionar"
                      data-value="{tableMeta.rowData[0]}"
                      name={tableMeta.rowData[0]}
                      // value={b}
                    >
                      <MenuItem value="AGGREGATE">Por instalación</MenuItem>
                      <MenuItem value="BSNS">Líneas de negocio</MenuItem>
                      <MenuItem value="GLOBAL">Nivel Corporativo</MenuItem>
                    </Select> 
                  </FormControl>

              </div>
            );
          }
        }
      },
      {
        name: "Frecuencia",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div >
               {console.log(value)} 
                <FormControl variant="outlined" style={{ minWidth: 125, maxHeight:50 }}>
                  <InputLabel id="demo-simple-select-helper-label">Seleccionar</InputLabel>
                    <Select
                      key={value}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleToastFre}
                      label="Seleccionar"
                      data-value="{tableMeta.rowData[0]}"
                      name={tableMeta.rowData[0]}
                    >
                      <MenuItem value="yearly">Anual</MenuItem>
                      <MenuItem value="semestral">Semestral</MenuItem>
                      <MenuItem value="trimestral">Trimestral</MenuItem>
                      <MenuItem value="monthly">Mensual</MenuItem>
                      <MenuItem value="daily">Diario</MenuItem>
                    </Select> 
                  </FormControl>

              </div>
            );
          }
        }
      },
      {
        name: "",
        options: {}
      },
      // {
      //     name: "",
      //     options: {}
      // },
      // {
      //     options: {customBodyRender: (value,tableMeta,updateValue) => {
      //         return <div><button id={value} /* onClick={() => setOpenProgressDialog(true)} */><Search></Search></button><button><Edit></Edit></button><button><Delete></Delete></button></div>
      //       }}
      // },
      
    ])

    const columns = [
        //Por cada {} es una columna donde name es el titulo del header
        //Y options sirve para crear filtros personalizados etc.
        {
          name: "Data",
          options: {
            display: false
          },
        },
        {
          name: "Indicador",
          options: {}
        },
        {
          name: "ODS",
          options: {}
        },
        {
          name: "Alcance",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              console.log(tableMeta.rowData[0].content.indicator.type)
              return (
                <div >
                  <FormControl variant="outlined" style={{ minWidth: 125, maxHeight:50 }}>
                    <InputLabel id="demo-simple-select-helper-label">Seleccionar</InputLabel>
                      <Select
                        key={value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleToast}
                        label="Seleccionar"
                        data-value="{tableMeta.rowData[0]}"
                        name={tableMeta.rowData[0]}
                        defaultValue={tableMeta.rowData[0].alcance}
                      >
                        {availableReaches(tableMeta.rowData[0].content.indicator.type)}
                        {/* {tableMeta.rowData[0].content.indicator.type = 'AGGREGATE' ? <MenuItem value="AGGREGATE">Por instalación</MenuItem>} */}
                        {/* <MenuItem value="AGGREGATE">Por instalación</MenuItem>
                        <MenuItem value="BSNS">Líneas de negocio</MenuItem>
                        <MenuItem value="GLOBAL">Nivel Corporativo</MenuItem> */}
                      </Select> 
                    </FormControl>

                </div>
              );
            }
          }
        },
        {
          name: "Frecuencia",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div >
                 {console.log(value)} 
                  <FormControl variant="outlined" style={{ minWidth: 125, maxHeight:50 }}>
                    <InputLabel id="demo-simple-select-helper-label">Seleccionar</InputLabel>
                      <Select
                        key={value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleToastFre}
                        label="Seleccionar"
                        data-value="{tableMeta.rowData[0]}"
                        name={tableMeta.rowData[0]}
                        defaultValue={tableMeta.rowData[0].frecuency}
                      >
                        <MenuItem value="yearly">Anual</MenuItem>
                        <MenuItem value="semestral">Semestral</MenuItem>
                        <MenuItem value="trimestral">Trimestral</MenuItem>
                        <MenuItem value="monthly">Mensual</MenuItem>
                        <MenuItem value="daily">Diario</MenuItem>
                      </Select> 
                    </FormControl>

                </div>
              );
            }
          }
        },
        {
          name: "Ver",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <button onClick={() => visibilityIndicator(tableMeta.rowData[0])}>
                    <VisibilityIcon></VisibilityIcon>
                  </button>
                </div>
              );
            }
          }
        },
      ];

      const availableReaches = (indicatorType) => {
        switch (indicatorType) {
          case 'AGGREGATE':
            return [
              <MenuItem value='AGGREGATE'>Por instalación</MenuItem>,
              <MenuItem value='BSNS'>Líneas de negocio</MenuItem>,
              <MenuItem value='GLOBAL'>Nivel Corporativo</MenuItem>
            ]
          case 'BSNS':
            return [
              <MenuItem value='BSNS'>Líneas de negocio</MenuItem>,
              <MenuItem value='GLOBAL'>Nivel Corporativo</MenuItem>
            ]
          case 'GLOBAL':
            return <MenuItem value='GLOBAL'>Nivel Corporativo</MenuItem>
    
          default:
            break
        }
      }

      const handleToast = (value) => {
       
        let val = value.target.value
        let data = value.target.name

        console.log(val);
        console.log(data);

        DataInfoReport.map((us,i) => {
          if(data.content.indicator.id == us.indicatorId){
            us.alcance = val;
          }                             
        })

        DataInfo.map((us,i) => {
          if(data.content.indicator.id == us.content.indicator.id){
            us.alcance = val;
          }                             
        })
        
      }

      const handleToastFre = (value) => {
       
        let val = value.target.value
        let data = value.target.name

        console.log(val);
        console.log(data);

        DataInfoReport.map((us,i) => {
          if(data.content.indicator.id == us.indicatorId){
            us.frecuency = val;
          }                             
        })

        DataInfo.map((us,i) => {
          if(data.content.indicator.id == us.content.indicator.id){
            us.frecuency = val;
          }                             
        })
      
      }

      const dat = [];
      
        const data = [
        ["Empleo total", "ODS 8 - Trabajo decente y crecimiento económico, Completo", "Completo", ],
        ["Rotación personal", "ODS 8 - Trabajo decente y crecimiento económico, Completo", "Completo", ],
        ["Nuevas contrataciones de empleados", "ODS 8 - Trabajo decente y crecimiento económico, Completo", "Incompleto", ]
        ];
      
        const options = {
            search: "enabled",
            searchOpen: false,
            filter: false,
            viewColumns: false,   
            selectableRows: 'none',
            responsive: "standard",
            selectToolbarPlacement: "none",
            print: false,
            download: false,
            textLabels: {
                pagination: {
                  next: "Siguiente",
                  previous: "Anterior",
                  rowsPerPage: "Filas por página:",
                  displayRows: "de" // 1-10 of 30
                },
                body: {
                  noMatch: 'No se encontraron resultados',
                }      
              }
          };

    const [openProgressDialog, setOpenProgressDialog] = useState(false)

    const [data1, setData] = useState([]);
    const [dataTabs, setDataTabs] = useState([]);

    const [DataInfo, setDataInfo] = useState([]);

    const report = JSON.parse(sessionStorage.getItem('report'))

    var infoReportRevision = [];

    // const [openProgressDialog, setOpenProgressDialog] = useState(false)

    const [indi, setIndi] = useState([])

    useEffect(() => {
        document.title = 'Reportes - Paso 5'
        getShowReports();        
    }, [])

    const getShowReports  = () => {

        Axios.get(`/customreports/show/${report.id}/user/${user.id}`)
        // Axios.get(`/customreports/show/${report.id}`)
        .then((response) => {
            console.log(response);            

            if(response.data.repo.list_init_repo_users.listInitiative){
                setDataTabs(response.data.repo.list_init_repo_users.listInitiative);
            }
        })
        .catch((err) => {
        console.log(err.response);
        })

    };

    let theme = createTheme();
    theme = responsiveFontSizes(theme);

    const save  = () => {      

        console.log(dat);
        // close();
        // setOpenDoc(false);
        
    };

    const updateForm  = (event, i) => {

        console.log(event.target.value);        
        // a[i].text = event.target.value;
        console.log(DataInfoReport)
        console.log(DataInfo)
        console.log(DataInfoReport[i]);
        DataInfoReport[i].text = event.target.value;
        DataInfo[i].content.text = event.target.value;
        
    };

    const closeDialog  = () => {         
        console.log("Cerrado");
        setOpenDoc(!openDoc)
        setButtonActive(0);
        setValuePaso5(-1);
        setDataInfoReport([])
        setDataInfo([])
        setDataTabs([])
        getShowReports();
    };

    const closeDialogLogs = () => {         
      console.log("Cerrado Logs");
      setOpenDocLogs(!openDoc)
  };

    const visibilityIndicator = (us) => {         
        console.log(us)
        Axios.get(`/indicators/get/${us.id}`)
        .then((response) => {
            console.log(response.data);
            setIndi(response.data)
            setOpenProgressDialog(true)
          })
          .catch((err) => {
            console.log(err.response);
          })
        // console.log(us.content.indicator)        
    };

    const designtabla = makeStyles((theme) => ({
        fondo: {
            padding: "1rem",
            backgroundColor: "#f2f2f2"
        },
        fondo2: {
            backgroundColor: "#fff",
            padding: "1rem",
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)" 
        }
    }))

    const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  textTail: {
    fontSize: 14,
    color: theme.palette.text.secondary
  },
  title1: {
    fontSize: 14
  },
  title: {
    maxWidth: '160px',
    fontSize: 14,
    color: '#939191',
    fontWeight: 'bold',
    paddingRight: 4,
    paddingTop: 8
  },
  titleLink: {
    fontSize: 14,
    color: '#939191',
    fontWeight: 'bold',
    paddingLeft: 3
  },
  content: {
    fontSize: 14,
    fontWeight: 'lighter',
    color: '#6E6E6E'
  },
  managername: {
    fontSize: 12,
    fontWeight: 'lighter',
    color: '#6E6E6E'
  },
  indicatordescript: {
    fontSize: 13,
    fontWeight: 'lighter',
    fontStyle: 'italic',
    color: '#6E6E6E'
  },
  contentLink: {
    fontSize: 14,
    fontWeight: 'lighter',
    color: '#6E6E6E',
    padding: 8
  },
  contentBlack: {
    fontSize: 14,
    fontWeight: 'lighter',
    color: '#363535'
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  paper: {
    padding: theme.spacing(0, 1),
    minWidth: '100%'
  },
  divider: {
    width: theme.spacing(0.1),
    color: '#E8E9EC',
    backgroundColor: theme.palette.secondary.main
  },
  divi: {
    color: '#888',
    minWidth: '100%',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  actionIcon: {
    fontSize: 22
  },
  showMoreButton: {
    margin: theme.spacing(1),
    width: '100%'
  },
  topContent: {
    display: 'flex',
    flexAlign: 'center',
    position: 'relative',
    minHeight: 120,
    padding: theme.spacing(1)
  },
  indicatorTypeWrapper: {
    // background: theme.palette.primary.main,
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 70
    // flex: 1
    // position: 'absolute',
    // top: 0,
    // left: 0
  },
  indicatorType: {
    color: '#fff'
  },
  typeIcon: {
    color: theme.palette.secondary.main,
    fontSize: 38
  },
  typeLabel: {
    fontSize: 14,
    fontWeight: 'lighter',
    color: theme.palette.secondary.main
  },
  topContentDivider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  indicatorManagerContainer: {
    display: 'flex',
    alignItems: 'center',
    color: '#939191'
  },
  topContentData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1
  },
  menuWrapper: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  contentWrapper: {
    position: 'relative',
    width: '100%'
  },
  logValue: {
    fontSize: 13,
    fontFamily: 'Helvetica',
    color: '#808080'
  },
  card: {},
  titleContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  titleShowMore: {
    bottom: 0,
    right: 25,
    position: 'absolute'
    // backgroundColor: '#fff'
  },
  subc_titl: {
    fontSize: 14,
    color: '#939191',
    fontWeight: 'bold',
    textTransform: 'none',
    
    marginBottom: theme.spacing(1)
  },
  editOrRemove: {
    fontSize: 18,
    paddingLeft: 3,
    marginLeft: 3
  }
}))

    const classes = designtabla();
    const theme1 = useTheme()
    const classes1 = useStyles()

    return (
        <Container>
        <br>
    </br>

        <center>
            <h3>Paso 4 - Ingreso de la información de las iniciativas/temáticas</h3>
                <h4>Esta sección permite ingresar la información a una iniciativa/temática.</h4>
        </center>
                <br>
                </br>
                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <Tabs value={valuePaso5} onChange={handleChangePaso5} centered>

                    {dataTabs.map((us) => ( 
                            <Tab label={us.name} onClick={() => clickTab(us)} />
                            ))}
                                {/* <Tab label="Paso 1 - Informacion general de reporte " />
                                <Tab label="Paso 1 - Informacion general de reporte " /> */}

                    </Tabs>
                </Box>

        {DataInfo.map((us,i) =>         
        us.content.id != null ?         
        (            

            <Grid spacing={1} display="flex" direction="row" width={1000} gap={5}>
                  <Grid direction="row" width={1000} >
                      <Grid>
                      <div id="tabla1" className={classes.fondo}>
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                          title={us.content.name}
                          data={[[us,
                                  us.name,
                                  us.ods.name,
                                  ]]}
                          columns={columns}
                          options={options} />
                          {/* <h4>¿Comó debo completar este contenido? <Info></Info></h4> */}
                      </ThemeProvider>
                      {/* <br></br> */}
                          <div id="tabla2" className={classes.fondo2}>
                          <Grid container spacing={2} width={1500} >
                                <h3 style={{ marginLeft: '1%' }}>¿Comó debo completar este contenido?</h3>
                          </Grid>
                          <Grid container spacing={2} width={1500} style={{ marginLeft: '1%' }}>

                          <Fragment>
                            <ShowMoreText
                              lines={1}
                              more='Leer más'
                              less='Leer menos'
                              onClick={executeOnClick}
                              expanded={false}
                              className={classes1.textTail}
                              anchorClass={classes1.title1}
                            >
                              <Typography variant='subtitle2' gutterBottom>
                                {us.bajada}
                              </Typography>
                              {us.specificLiterals !== '' &&
                                  us.specificLiteralskeys.length > 0 &&
                                  us.specificLiteralskeys.map((ele) => (
                                    <Fragment key={ele}>
                                      <Typography
                                        className={classes1.title1}
                                        color='textSecondary'
                                        gutterBottom
                                      >
                                        {' '}
                                        {ele}. {us.specificLiterals[ele]}
                                      </Typography>
                                    </Fragment>
                                ))}
                            </ShowMoreText>
                          </Fragment>



                                {/* <h5 style={{ marginLeft: '2%' }}>{us.a}</h5> */}
                                {/* {us.specificLiterals !== '' &&
                                  us.specificLiteralskeys.length > 0 &&
                                  us.specificLiteralskeys.map((ele) => (
                                    <Fragment key={ele}>
                                      <Typography
                                        className={classes1.title1}
                                        color='textSecondary'
                                        gutterBottom
                                      >
                                        {' '}
                                        {ele}. {us.specificLiterals[ele]}
                                      </Typography>
                                    </Fragment>
                                ))} */}
                          </Grid>                            
                          <Grid container spacing={2} width={1500}>
                                <h5 style={{ marginLeft: '2%' }}>{us.content.textoPredefinido}</h5>
                          </Grid> 
                          <br>
                            </br>
                          <Grid container spacing={2} width={1500}>
                              <TextField
                                      id="outlined-multiline-static"
                                      label="Comentarios adicionales"
                                      multiline
                                      rows={3}
                                      fullWidth
                                      defaultValue={us.content.text != null ? us.content.text : ''}
                                      onChange={() => updateForm(event,i)}
                                  />
                          </Grid>
                          {/* <br>
                            </br>
                          <Grid container spacing={2} width={1500}>
                            <ButtonWithLoading
                                text='Guardar'
                                fullWidth
                            />
                          </Grid>                          */}
                          </div>
                      </div>
                      </Grid>
                  </Grid>
              </Grid>
        ) : '' )}
        <br>
        </br> 

        <Grid container spacing={2} width={1500}>

        {buttonActive == 1 && report.esTemplate == 0 ? (

        <ButtonWithLoading
            text='Guardar'
            onClick={saveReports}
            fullWidth
        />

        ) : ''}

        </Grid>
        <br>
        </br> 
        <Grid container spacing={2} width={1500}>
                          
        {buttonActive == 1 && report.esTemplate == 0 ? (


        <ButtonWithLoading
            text={buttonActiveLogs == 0 ? 'Validar contenido' : 'Ver estado de validación'}
            onClick={buttonActiveLogs == 0 ? test : seeReportsHistorial}
            fullWidth
        />

        ) : ''}
        </Grid>
        <br>
        </br> 
        <br>
        </br>

        <CustomDialog
            maxWidth='md'
            // dialogtitle={`Nuevo registro para ${indicator.name}`}
            dialogcontent={
            <UsuariosRevisores
                DataInfoReport={DataInfoReport}
                NombreIniciativa={nameIni}
                d={DataInfo}
                close={() => closeDialog()}
            />
            }
            open={openDoc}
            onClose={() => setOpenDoc(false)}
        />

        <CustomDialog
            maxWidth='md'
            // maxWidth='lg'
            dialogtitle={`Avance de indicador ${indi.name}`}
            titlebackground={theme1.palette.gradientTopBottom.main}
            dialogcontent={
            // <Logs indicator={indicator} organization={organization} />
            <IndcTabs  indicator={indi} organization='' />
            // <LogsNewVersion indicator={indi} organization='' />
            }
            open={openProgressDialog}
            onClose={() => setOpenProgressDialog(false)}
        />

        <CustomDialog
            maxWidth='md'
            // dialogtitle={`Nuevo registro para ${indicator.name}`}
            dialogcontent={
            <ListLogs
                Logs={dataLogs}
                close={() => closeDialogLogs()}
            />
            }
            open={openDocLogs}
            onClose={() => setOpenDocLogs(false)}
        />
        
    </Container>
    )            

}

const UsuariosRevisores = (data) => {

    const reportSession = JSON.parse(sessionStorage.getItem('report'))
    console.log(data)

    const [employees, setEmployees] = useState([]);

    const [arrEmployees, setArrEmployees] = useState([]);

    const [report, setInfoR] = useState(data.DataInfoReport);

    const { user } = useSelector((state) => state.user)

    var arrRevisores = []; 
    
    const columns = [
        //Por cada {} es una columna donde name es el titulo del header
        //Y options sirve para crear filtros personalizados etc.
        {
          name: "Nombre",
          options: {}
        },
        {
            name: "Correo",
            options: {}
        },
        {
            name: "Acción",
            options: {}
        },
      ];
    const [noMatchData, setnoMatchData] = useState('Cargando ...');
    const options = {
            search: "enabled",
            searchOpen: false,
            filter: false,
            viewColumns: false,   
            selectableRows: 'none',
            responsive: "standard",
            selectToolbarPlacement: "none",
            print: false,
            download: false,
            textLabels: {
                pagination: {
                  next: "Siguiente",
                  previous: "Anterior",
                  rowsPerPage: "Filas por página:",
                  displayRows: "de" // 1-10 of 30
                },
                body: {
                  noMatch: noMatchData,
                }      
              }
          };
    
    const test = () => {       

        report.map((us,i) => {
            console.log(us.revisores);
            us.revisores = arrEmployees;                              
          }) 


        const dataToSubmit = {report};
        console.log(dataToSubmit);
        

        // Axios.post(`/createreportcontent`, dataToSubmit)
        // .then((response) => {
        //     console.log(response);
        //     data.close();
        //   })
        //   .catch((err) => {
        //     console.log(err.response);
        //   })

    };    

    const addRevisores = (idUser) => {
      arrRevisores = []
        // console.log(idUser);

        // const index = arrRevisores.indexOf(idUser);
        // if (index > -1) {
        //     arrRevisores.splice(index, 1); // 2nd parameter means remove one item only
        // } else {
        //     arrRevisores.push(idUser);
        // }
        arrRevisores.push(idUser);
        // us.userId = user.id
        // setArrEmployees(arrRevisores);
        

        // arrRevisores.push(user.id);
        console.log(arrRevisores);

        report.map((us,i) => {
          // console.log(us.revisores);
          // us.revisores = arrEmployees;                              
          us.revisores = arrRevisores;
        }) 

      const dataToSubmit = {report};
      console.log(dataToSubmit);

        // setTimeout(() => {
            Axios.post(`/createreportcontent`, dataToSubmit)
            .then((response) => {
                console.log(response);
                data.close();
              })
              .catch((err) => {
                console.log(err.response);
            })
        // }, 500)
    };

    const addRevisoresPrimary = (idUser) => {
      console.log(idUser);

      const index = arrRevisores.indexOf(idUser);
      if (index > -1) {
          arrRevisores.splice(index, 1); // 2nd parameter means remove one item only
      } else {
          arrRevisores.push(idUser);
      }
      setArrEmployees(arrRevisores);
      console.log(arrRevisores);
  };

    useEffect(() => {
        // document.title = data.nameIni
        console.log(data);

        Axios.get(`/companies/${reportSession.companyId}/employees`)
        .then((response) => {
            console.log(response);

            // setEmployees
            response.data.employees.map((us,i) => {

                        if(us.id != user.id){
                            // setEmployees(arr => [...arr,[us.fullName,us.mail,<input type="checkbox" onClick={() => addRevisores(us.id)}/>]])
                            setEmployees(arr => [...arr,[us.fullName,us.mail,<ButtonWithLoading text='Solicitar validación' onClick={() => addRevisores(us.id)} fullWidth />]])                            
                        }                        
                  })

                  setTimeout(() => {
                    setnoMatchData('No se encontraron resultados');
                  }, 1000)
          })
          .catch((err) => {
            setnoMatchData('No se encontraron resultados');
            console.log(err.response);
          })

          // addRevisores(user.id)
          // arrRevisores.push(user.id);
          // setArrEmployees(arrRevisores);

      }, [])

    let theme = createTheme();
    theme = responsiveFontSizes(theme);

    const designtabla = makeStyles((theme) => ({
        fondo: {
            padding: "1rem",
            backgroundColor: "#f2f2f2"
        },
        fondo2: {
            backgroundColor: "#fff",
            padding: "1rem",
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)" 
        }
    }))

    const classes = designtabla();

    return (

        <Container>
            <center>
                <h3>{data.NombreIniciativa}</h3>
            </center>

            <Grid spacing={1} display="flex" direction="row" width={1000} gap={5}>
                  <Grid direction="row" width={1000} >
                      <Grid>
                      <div id="tabla1" className={classes.fondo}>
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                          title="Selecciona a los revisores"
                          // PEDIRLE A JASON QUE CREE UN ARRAY DEL INDICADOR DEL CONTENIDO DENTRO DEL CONTENT
                        //   data={[["us.name","us.ods.name","sadasd"]]}
                          data={employees}
                          columns={columns}
                          options={options} />
                          {/* <h4>¿Comó debo completar este contenido? <Info></Info></h4> */}
                      </ThemeProvider>
                      </div>
                      </Grid>
                  </Grid>
              </Grid>

              <Grid container spacing={2} width={1500}>
                          
                {/* <ButtonWithLoading
                        text='Solicitar validación de contenido'
                        onClick={test}
                        fullWidth
                    /> */}
                  
                  {/* <ButtonWithLoading text='Solicitar validación' onClick={() => test(us.id)} fullWidth /> */}

                </Grid>

        </Container>
    )

}

const ListLogs = (data) => {

  const [data1, setData1] = useState([]);

  const columns = [
    //Por cada {} es una columna donde name es el titulo del header
    //Y options sirve para crear filtros personalizados etc.
    {
      name: "Contenido",
      options: {}
    },
    {
        name: "Revisor",
        options: {}
    },
    {
        name: "Comentario",
        options: {}
    },
    {
        name: "Estado",
        options: {}
    },
  ];
  
  const [noMatchData, setnoMatchData] = useState('Cargando ...');
  const options = {
        search: "enabled",
        searchOpen: false,
        filter: false,
        viewColumns: false,   
        selectableRows: 'none',
        responsive: "standard",
        selectToolbarPlacement: "none",
        print: false,
        download: false,
        textLabels: {
            pagination: {
              next: "Siguiente",
              previous: "Anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de" // 1-10 of 30
            },
            body: {
              noMatch: noMatchData,
            }      
          }
      };

      useEffect(() => {
        // document.title = data.nameIni
        console.log(data);

            data.Logs.map((us,i) => {
                            setData1(arr => [...arr,[us.reportLogContent.name,
                                                        us.reportLogRevisor.fullName,
                                                        us.textRevisor != null ? us.textRevisor : '-',
                                                        us.aprobado != null ? us.aprobado == 0 ? 'Con observaciones' : 'Aprobado' : 'Pendiente de revisión']])
            })

            setTimeout(() => {
              setnoMatchData('No se encontraron resultados');
            }, 1000)

      }, [])

      const test = () => {    

        console.log("test")

    };  

    let theme = createTheme();
    theme = responsiveFontSizes(theme);

    const designtabla = makeStyles((theme) => ({
        fondo: {
            padding: "1rem",
            backgroundColor: "#f2f2f2"
        },
        fondo2: {
            backgroundColor: "#fff",
            padding: "1rem",
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)" 
        }
    }))

    const classes = designtabla();

      return (

        <Container>
            <center>
                <h3>Usuario que solicita revisión : {data.Logs[0].reportLogCreador.fullName}</h3>
            </center>

            <Grid spacing={1} display="flex" direction="row" width={1000} gap={5}>
                  <Grid direction="row" width={1000} >
                      <Grid>
                      <div id="tabla1" className={classes.fondo}>
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                          title="Logs"
                          // PEDIRLE A JASON QUE CREE UN ARRAY DEL INDICADOR DEL CONTENIDO DENTRO DEL CONTENT
                        //   data={[["us.name","us.ods.name","sadasd"]]}
                          data={data1}
                          columns={columns}
                          options={options} />
                          {/* <h4>¿Comó debo completar este contenido? <Info></Info></h4> */}
                      </ThemeProvider>
                      </div>
                      </Grid>
                  </Grid>
              </Grid>
{/* 
              <Grid container spacing={2} width={1500}>
                          
                <ButtonWithLoading
                        text='Cerrar'
                        onClick={test}
                        fullWidth
                    />

                </Grid> */}

        </Container>
    )

}

export default Paso5
